/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT"
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH"
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME"
export const CHANGE_SIDEBAR_THEME_IMAGE = "CHANGE_SIDEBAR_THEME_IMAGE"
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE"

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME"

/* RIGHT SIDEBAR */
export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR"
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR"
export const HIDE_RIGHT_SIDEBAR = "HIDE_RIGHT_SIDEBAR"

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER"
export const SET_CART_COURSE_LIST_LENGTH = 'SET_CART_COURSE_LIST_LENGTH';
export const SET_BTN_LOAD = 'SET_BTN_LOAD';
export const SET_INDEX_1 = 'SET_INDEX_1';
export const SET_INDEX_2 = 'SET_INDEX_2';
export const SET_INDEX_3 = 'SET_INDEX_3';
export const AUTH_USER_DATA ='AUTH_USER_DATA'