import React, { Component } from "react"
import PropTypes from "prop-types"
import { BrowserRouter as Router, Switch } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes
import { authProtectedRoutes, publicRoutes ,commonRoutes,comming_soon} from "./routes/"
import AppRoute from "./routes/route"

// layouts
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import ComingSoonLayout from './components/ComingSoonLayout'

// Import scss
import "./assets/scss/theme.scss"
import validateSession from './routes/validateSession'

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

// Import fackbackend Configuration file
// import fakeBackend from "./helpers/AuthType/fakeBackend"

// Activating fake backend
// fakeBackend()

// Activating fake firebase
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataloaded : false,
      userInfo : JSON.parse(sessionStorage.getItem('authUser')) || null
    }
    this.getLayout = this.getLayout.bind(this)
  }

  /**
   * Returns the layout
   */

  async componentDidMount(){

    // var express_session = JSON.parse(localStorage.getItem('express-session'))
    // if(express_session){
    //   var result = await validateSession(express_session)
    //   console.log(result,'result')
    setTimeout(() => {
      this.setState({
        dataloaded : true
      })
    }, 500);
     
    // }
    // else{
    //   this.setState({
    //     dataloaded : true
    //   })
    // }


  }



  // componentDidMount() {
  //   window.addEventListener('storage', this.handleStorageChange);

  //      setTimeout(() => {
  //     this.setState({
  //       dataloaded : true
  //     })
  //   }, 500);
  // }

  // componentWillUnmount() {
  //   window.removeEventListener('storage', this.handleStorageChange);
  // }

  handleStorageChange = (event) => {
    console.log(event,'event')
    if (event.key === null) {
      // Local storage was cleared
      console.log('Local storage cleared manually');
      // Handle the clearing event as needed
    }
  };





  getLayout = () => {
    let layoutCls = VerticalLayout

    switch (this.props.layout.layoutType) {
      case "Vertical":
        layoutCls = VerticalLayout
        break
      default:
        layoutCls = HorizontalLayout
        break
    }
    return layoutCls
  }

  checkAuth=async(express_session)=>{

    var result = await validateSession(express_session)
    // console.log(result,'result')
    return result

  }


  render() {
    const Layout = this.getLayout()
    var express_session = JSON.parse(localStorage.getItem('express-session'))
    // console.log(express_session,'express_session',this.state.userInfo)
    if(express_session){
      // var result = this.checkAuth(express_session)
      // console.log(result,'result')
    }
    else{
      // this.props.history.push
      // sessionStorage.removeItem('authUser')
    }

if(this.state.dataloaded){
  // console.log(publicRoutes,commonRoutes,authProtectedRoutes,'authProtectedRoutes')
    return (
      <React.Fragment>
        <Router>
            <Switch>
            {/* {comming_soon.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={ComingSoonLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))} */}
             {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}
             {commonRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={sessionStorage.getItem('authUser') ? Layout: NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={null}
              />
            ))}
              {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
            </Switch>
        </Router>
      </React.Fragment>
    )
  }
  else{
    return null
  }
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

App.propTypes = {
  layout: PropTypes.object,
}

export default connect(mapStateToProps, null)(App)




















// import React, { Component } from "react"
// import PropTypes from "prop-types"
// import { BrowserRouter as Router, Switch } from "react-router-dom"
// import { connect } from "react-redux"

// // Import Routes
// import { authProtectedRoutes, publicRoutes } from "./routes/"
// import AppRoute from "./routes/route"

// // layouts
// import VerticalLayout from "./components/VerticalLayout/"
// import HorizontalLayout from "./components/HorizontalLayout/"
// import NonAuthLayout from "./components/NonAuthLayout"

// // Import scss
// import "./assets/scss/theme.scss"

// // Import Firebase Configuration file
// // import { initFirebaseBackend } from "./helpers/firebase_helper"

// // Import fackbackend Configuration file
// // import fakeBackend from "./helpers/AuthType/fakeBackend"

// // Activating fake backend
// // fakeBackend()

// // Activating fake firebase
// // const firebaseConfig = {
// //   apiKey: process.env.REACT_APP_APIKEY,
// //   authDomain: process.env.REACT_APP_AUTHDOMAIN,
// //   databaseURL: process.env.REACT_APP_DATABASEURL,
// //   projectId: process.env.REACT_APP_PROJECTID,
// //   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
// //   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
// //   appId: process.env.REACT_APP_APPID,
// //   measurementId: process.env.REACT_APP_MEASUREMENTID,
// // };

// // init firebase backend
// // initFirebaseBackend(firebaseConfig);

// class App extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {}
//     this.getLayout = this.getLayout.bind(this)
//   }

//   /**
//    * Returns the layout
//    */
//   getLayout = () => {
//     let layoutCls = VerticalLayout

//     switch (this.props.layout.layoutType) {
//       case "Vertical":
//         layoutCls = VerticalLayout
//         break
//       default:
//         layoutCls = HorizontalLayout
//         break
//     }
//     return layoutCls
//   }

//   render() {
//     const Layout = this.getLayout()

//     return (
//       <React.Fragment>
//         <Router>
//           <Switch>
//             {publicRoutes.map((route, idx) => (
//               <AppRoute
//                 path={route.path}
//                 layout={NonAuthLayout}
//                 component={route.component}
//                 key={idx}
//                 isAuthProtected={false}
//               />
//             ))}

//             {authProtectedRoutes.map((route, idx) => (
//               <AppRoute
//                 path={route.path}
//                 layout={Layout}
//                 component={route.component}
//                 key={idx}
//                 isAuthProtected={true}
//                 exact
//               />
//             ))}
//           </Switch>
//         </Router>
//       </React.Fragment>
//     )
//   }
// }

// const mapStateToProps = state => {
//   return {
//     layout: state.Layout,
//   }
// }

// App.propTypes = {
//   layout: PropTypes.object,
// }

// export default connect(mapStateToProps, null)(App)
