import React, { Component } from "react"
import PropTypes from 'prop-types'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from "reactstrap"
import { withRouter, Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import user1 from "../../../assets/images/users/avatar-1.jpg"
import {authUser} from '../../../store/layout/actions'

import { connect } from "react-redux"
import urlSocket from "helpers/urlSocket"

const getUserName = () => {
  if (sessionStorage.getItem("authUser")) {
    const obj = JSON.parse(sessionStorage.getItem("authUser"))
    return obj
  } else {
    return false
  }
}

class ProfileMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: false,
      name: "",
      showUser: false,
      authUser : JSON.parse(sessionStorage.getItem('authUser'))
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }))
  }

  logOut = () => {
    try {
      urlSocket.post('handle-session/logout-session', {
        user_id: this.state.authUser._id
      }).then((response) => {
        if (response.data.response_code === 500) {
          sessionStorage.removeItem("getconfsession")
          sessionStorage.removeItem("authUser")
          localStorage.removeItem("authUser")
          localStorage.removeItem("auth_type")
          localStorage.removeItem("socialMediaLoginId")
          localStorage.removeItem("CourseDetails")
          localStorage.removeItem("express-session")
          // this.props.dispatch(authUser(false));
          this.props.history.push("/home")
        }
        else {
          //("some thing went wrong")
        }
      })
    } catch (error) {
      //("some thing went wrong", error)
    }

  }

  

  render() {
    // //("this.props.userInformation", this.props.userInformation)
      return (
        <React.Fragment>
          <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block" >
            <DropdownToggle className="btn header-item" id="page-header-user-dropdown" tag="button" >
              {/* <img className="rounded-circle header-profile-user" src={user1} alt="Header Avatar" />{" "} */}
              <i className='bx bx-user p-1 text-secondary' style={{ fontSize: "22px" }}></i>
              <span className="d-none d-xl-inline-block ms-2 me-1 text-primary">{this.props.userInformation.email}</span>
              {/* <span className="d-none d-xl-inline-block ms-1">{this.state.name}</span> */}
              {/* <i className="mdi mdi-chevron-down d-none d-xl-inline-block" /> */}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              {/* <DropdownItem tag="a" href="/UserPreference">
                <i className="bx bx-user font-size-16 align-middle ms-1" />
                {this.props.t(" Profile")}
              </DropdownItem> */}
              {/* <DropdownItem tag="a" href="/UserPreference">
                <div style={{ fontSize: '16px' }}>
                  <i className="bx bx-user-pin font-size-16 align-middle ms-1" />
                  <span> User Preference</span>
                </div>
              </DropdownItem> */}




              <Link to="/cart-list" className="dropdown-item">
                <i className="bx bx-cart font-size-16 align-middle text-dark me-3 " style={{ color: '#7900E9' }} />
                <span className="text-secondary">{this.props.t("My Cart")}</span>
              </Link>
              <div className="dropdown-divider" />

              <Link to="/my-orders" className="dropdown-item">
                <i className="bx bx-receipt font-size-16 align-middle text-dark me-3 " style={{ color: '#7900E9' }} />
                <span className="text-secondary">{this.props.t("My Orders")}</span>
              </Link>
              <div className="dropdown-divider" />
              <Link to="/my-courses" className="dropdown-item">
                <i className="bx bx-book font-size-16 align-middle text-dark me-3 " style={{ color: '#7900E9' }} />
                <span className="text-secondary">{this.props.t("My Courses")}</span>
              </Link>
              <div className="dropdown-divider" />
              <Link to="/UserPreference" className="dropdown-item">
                <i className="bx bx-user-pin font-size-16 align-middle text-dark me-3" style={{ color: '#7900E9' }} />
                <span className="text-secondary">{this.props.t("My Profile")}</span>
              </Link>
              <div className="dropdown-divider" />

              <Link to="#" className="dropdown-item" onClick={() => {
                // sessionStorage.removeItem("getconfsession")
                // sessionStorage.removeItem("authUser")
                // localStorage.removeItem("authUser")
                // localStorage.removeItem("CourseDetails")
                // localStorage.removeItem("express-session")
                // this.props.history.push("/home")
                this.logOut()
              }}>
                <i className="bx bx-power-off font-size-16 align-middle me-3 text-danger" />
                <span>{this.props.t("Logout")}</span>
              </Link>
            </DropdownMenu>
          </Dropdown>
        </React.Fragment>
      )
  }
}

ProfileMenu.propTypes = {
  t: PropTypes.any,
  success: PropTypes.string
}

const mapStateToProps = state => {
  const { success } = state.Profile
  const { authUser } = state.Layout
  return { success }
}

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(ProfileMenu))
)
