import React from "react"
import PropTypes from 'prop-types'
import { Route, Redirect } from "react-router-dom"

const AppRoute = ({ component: Component, layout: Layout, isAuthProtected, ...rest }) => (

  <Route {...rest} render={props => {
    // console.log(isAuthProtected,'isAuthProtected', props.location,JSON.parse(localStorage.getItem('express-session')),JSON.parse(localStorage.getItem('authUser')))
    if (isAuthProtected && !sessionStorage.getItem("authUser")) {

      return (
        <Redirect
          to={{ pathname: "/home", state: { from: props.location } }}
        />
      )
    }
    else if (isAuthProtected === false && sessionStorage.getItem('authUser')) {
      return (
        <Redirect
          to={{ pathname: "/dashboard", state: { from: props.location } }}
        />
      )
    }
    else {
     return (
        <Layout>
          <Component {...props} />
        </Layout>

      )
    }
  }}
  />
)

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any
}

export default AppRoute