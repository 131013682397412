import React, { Component } from "react"
import { Container } from "reactstrap"
import MetaTags from 'react-meta-tags'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class TermsConditions extends Component {
  render() {
    return (
      <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Kalakriya - Terms and Condtions</title>
                    </MetaTags>
                    <Container>
                        <Breadcrumbs title="Terms and Condtions" breadcrumbItem="Terms and Condtions" />
                        <div style={{ textAlign: "justify" }}>
                            {/* <p > */}
                            For subscription plans, a cancellation period of 7 days from the date of purchase applies if the client does not start availing of our services. The current subscription is non-terminable after 7 days of purchase. However, the client can cancel a further subscription by providing 7 days notice before the start of the next subscription period. Next subscription fees fall due immediately after the end of the validity period of the existing subscription. Upon cancellation, the eligible amount will be refunded to the same account which the client used to pay originally, within 10-15 working days from the date of receipt of the cancellation request from the client.

              We may change the price for subscriptions from time to time and will communicate any price changes to the customer/client. Price changes for subscriptions will take effect at the start of the next subscription period following the date of the price change. By continuing to use the subscription after the price change takes effect, it is understood that the customer/client accepts the new price.Please note you will not receive a refund for the unused portion of your current billing period. However, you will still retain access to the subscription benefits until the end of your current billing period. For example, if you have a yearly subscription plan and you cancel it mid way after a couple of months, you will continue to have access to the subscription content and benefits until the end of the current billing date.How to request a refund?Please send an email to (email id) within 7 days of purchase. Your email must provide us with the details of the product you purchased, and the email address associated with your Kalakriya course account. We will strive to promptly assist you in getting a refund for your courses or bundles as per our refund policy.Please note that the refunds will be credited back to the source account, and it may take 2-3 weeks for the refunds to reflect in your account depending upon your bank partner. No refunds will be processed in cash.Partial RefundsWe do not have the provision for partial refunds. We value your understanding of this policy as we strive to ensure consistency and fairness for all our participants. If you have any questions or need further clarification, please don't hesitate to reach out to us at info@kalakriya.com
                        </div>
                    </Container>
                </div>
                {/* </div> */}
            </React.Fragment>
      // <React.Fragment>
      //   <div className="page-content">
      //     <MetaTags>
      //       <title>Kalakriya</title>
      //     </MetaTags>
      //     <Container fluid>
      //       {/* Render Breadcrumbs */}
      //       <Breadcrumbs title="Terms and Conditions" breadcrumbItem="Terms and Conditions" />

      //       <div>
      //         Introduction

      //         Welcome to the kalakriya.com (“Website” or “kalakriya.com”) service provided by eParampara Infotainment. This website is owned and operated by eParampara Infotainment a Chennai based company.( “eParampara Infotainment “ or eParampara', 'we' or 'us') Registered office located at II Floor, Sribalavinayagar Building, ARK Colony, New No.: 35/3, Eldams Road, Alwarpet, Chennai-600018 India. By using kalakriya.com service  you are deemed to have accepted the Terms and Conditions of the agreement listed below or as may be revised from time to time, which is, for an indefinite period and you understand and agree that you are bound by such terms till the time you access the website. If you want to ask us anything about these terms & conditions or have any comments or complaints on or about our website, You can reach us at our customer care number (044) 4203 0505 between 10:00am and 6:00pm all working days, or you may email us at info@kalakriya.com .We will make all attempts to respond to you within one working day

      //         We reserve the right to change these terms & conditions from time to time without any obligation to inform you and it is your responsibility to look through them as often as possible.

      //         Ownership of rights

      //         All rights, including copyright, in this website are owned by or licensed to eParampara Infotainment. Any use of this website or its contents, including copying or storing it or them in whole or part, other than for your own personal, non-commercial use is prohibited without the written permission of us. You may not modify, distribute or re-post something on this website for any purpose.

      //         Accuracy of content and invitation to offer

      //         We have taken every care in the preparation of the content of this website, in particular to ensure that prices quoted are correct at time of publishing and all products have been fairly described. All prices are displayed inclusive of VAT. Packaging may vary from that shown. The weights, dimensions and capacities given are approximate only. We have made every effort to display as accurately as possible the colours of our merchandise that appear on the website. However, as the actual colours you see will depend on your monitor, we cannot guarantee that your monitor's display of any colour will accurately reflect the colour of the merchandise on delivery. To the extent permitted by applicable law, eParampara disclaims all warranties, express or implied, as to the accuracy of the information contained in any of the materials on this website. eParampara shall not be liable to any person for any loss or damage which may arise from the use of any of the information contained in any of the materials on this website.
      //         All products /services and information displayed on kalakriya.com constitute an invitation to offer. Your order for purchase constitutes your offer which shall be subject to the terms and conditions as listed herein. Kalakriya.com reserves the right to accept or reject your offer in part or in full. Our acceptance of your order will take place upon dispatch of the products ordered. Dispatch of all the products ordered, may or may not happen at the same time, in such a scenario that portion of the order which has been dispatched will be deemed to have been accepted by kalakriya.com and the balance would continue to be an offer to kalakriya.com and we reserve the right to accept or reject such balance order. No act or omission of kalakriya.com prior to the actual dispatch of the products ordered will constitute acceptance of your offer. If you have supplied us with your email address, we will notify you by email as soon as possible to confirm receipt of your order and email you again to confirm dispatch and therefore acceptance of the order.

      //         Eligibility to Use

      //         Use of the Website is available only to persons who can form legally binding contracts under applicable law. Persons who are “incompetent to contract” within the meaning of the Indian Contract Act, 1872 as amended from time to time including its re-enactment thereof & un-discharged insolvents etc. are not eligible to use the Website. If your age is below 18 years, you are prohibited to use/purchase/contract from/with this website. Such persons are also prohibited from purchasing any material which is for adult consumption the sale or purchase of which to/by minors are strictly prohibited. Kalakriya.com reserves the right to terminate your membership and refuse to provide you with access to the website if we discover that you are under the age of 18 years. The website is not available to persons whose membership has been suspended or terminated for any reason whatsoever. If you are registering as a business entity, you represent that you have the authority to bind the entity to this User Agreement. Those who choose to access this website from outside India are responsible for compliance with local laws if and to the extent local laws are applicable. Some Indian states prohibit direct sale of merchandise from other states and require special documentation to effect such a sale without dual taxation, if we receive a order from such states or to be delivered to such states (to see a list please refer our shipping policy) under such circumstances we retain the right to accept or reject the order.

      //         Those who choose to access this website from places which restrict such use are responsible for compliance with local laws if and to the extent local laws are applicable. Kalakriya.com will deliver the products only within places having open import policy and will not be liable for any claims relating to any products ordered from restricted places.

      //         Except where additional terms and conditions are provided which are product specific, these terms and conditions supersede all previous representations, understandings, or agreements and shall prevail notwithstanding any variance with any other terms of any order submitted. By using the services of kalakriya.com you agree to be bound by the Terms and Conditions.

      //         Account and Registration Obligations

      //         Your Information is defined as any information you provide to us in the registration, buying or listing process, in the feedback area or through any e-mail feature or a written letter or form mailed or submitted to any of our stores and offices. We will protect your Information in accordance with our Privacy Policy. If you use the Website, you are responsible for maintaining the confidentiality of your password and account and any activities that occur under your account. eParampara shall not be liable to any person for any loss or damage which may arise as a result of any failure by you to protect your password or account. If you know or suspect that someone else knows your password or suspect any unauthorised use of your password you should notify us by contacting us immediately through the address provided in the beginning of this agreement. If kalakriya.com has reason to believe that there is likely to be a breach of security or misuse of the kalakriya.com Website, we may require you to change your password or we may suspend your account without any liability to kalakriya.com.

      //         Kalakriya.com does not want to receive confidential, proprietary or trade secret information through this Website (excluding information related to any order you submit). Please note that any information, materials, suggestions, ideas or comments sent to kalakriya.com will be deemed non-confidential. By submitting any such information, you are granting kalakriya.com an irrevocable and unrestricted license to use, modify, reproduce, transmit, display and distribute such materials, information, suggestions, ideas or comments for any purpose whatsoever. However, we will not use your name in connection with any such materials, information, suggestions, and ideas or comments unless we first obtain your permission or otherwise are required by law to do so.

      //         You also agree and confirm:
      //         To provide true, accurate, current and complete information about yourself as prompted in the Registration Form or Guest details Form (such information being the “Registration Data”).
      //         To maintain and promptly update the Registration Data to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, incomplete, or not current or if kalakriya.com has reasonable grounds to suspect that such information is untrue, inaccurate, and not current or not in accordance with the User Agreement, kalakriya.com has the right to indefinitely suspend or terminate your membership and refuse to provide you with access to the Website.
      //         That in the event that a non-delivery occurs on account of a mistake by you (i.e. wrong name or address or any other wrong information) any extra cost incurred by kalakriya.com for redelivery shall be claimed from you and you shall pay such cost.
      //         That you will use the services provided by kalakriya.com, its affiliates, consultants and contracted companies, for lawful purposes only and comply with all applicable laws and regulations while using the Website and transacting on the Website.
      //         You will provide authentic and true information in all instances where such information is requested of you. kalakriya.com reserves the right to confirm and validate the information and other details provided by you at any point of time. If upon confirmation your details are found not to be true (wholly or partly), kalakriya.com has the right in its sole discretion to reject the registration and debar you from using the Services of kalakriya.com and or other affiliated websites without prior intimation whatsoever.
      //         That you are accessing the services available on this Website and transacting at your sole risk and are using your best and prudent judgment before entering into any transaction through this Website
      //         That the address at which delivery of the merchandise ordered by you is to be made will be correct and proper in all respects. That before placing an order you will check the product description carefully. By placing an order for a product you agree to be bound by the conditions of sale and invitation of offer included in the product description.
      //         Usage Restrictions

      //         You shall not use the Website for any of the following purposes:
      //         Disseminating any unlawful, harassing, libellous, abusive, threatening, harmful, vulgar, obscene, or otherwise objectionable material.
      //         Transmitting material that encourages conduct that constitutes a criminal offence results in civil liability or otherwise breaches any relevant laws, regulations or code of practice.
      //         Gaining unauthorised access to other computer systems.
      //         Interfering with any other person's use or enjoyment of the Website.
      //         Breaching any applicable laws;
      //         Interfering or disrupting networks or websites connected to the Website.
      //         Making, transmitting or storing electronic copies of materials protected by copyright without the permission of the owner.
      //         Quantity Restrictions

      //         kalakriya.com reserves the right, at our sole discretion, to limit the quantity of merchandise purchased per person, per household or per order. These restrictions may be applicable to orders placed by the same account, the same credit / debit card, and also to orders that use the same billing and/or shipping address. We will provide notification to the customer should such limits be applied. kalakriya.com also reserves the right, at our sole discretion, to prohibit sales to any one as it may deem fit.

      //         Pricing Information

      //         While kalakriya.com strives to provide accurate product and pricing information, pricing or typographical errors may occur. kalakriya.com cannot confirm the price of a product until after you order. In the event that a product is listed at an incorrect price or with incorrect information due to an error in pricing or product information, kalakriya.com shall have the right, at our sole discretion, to refuse or cancel any orders placed for that product, unless the product has already been dispatched. In the event that a product is incorrectly priced, kalakriya.com may, at its discretion, either contact you for instructions or cancel your order and notify you of such cancellation. Unless the product ordered by you has been dispatched, your offer will not be deemed accepted and kalakriya.com will have the right to modify the price of the merchandise and contact you for further instructions using the e-mail address provided by you during the time of registration, or cancel the order and notify you of such cancellation. In the event that kalakriya.com accepts your order the same shall be debited to your credit / debit card account and duly notified to you by email that the payment has been processed. The payment may be processed prior to kalakriya.com dispatches the merchandise that you have ordered. If we have to cancel the order after we have processed the payment, the said amount will be reversed back to your credit / debit card account.

      //         We strive to provide you with best value. However, sometimes a price online may not match the price in a store in your particular geographic region and store pricing may sometimes differ from online prices. Prices and availability are subject to change without notice.

      //         Cancellation by Kalakriya.com

      //         Please note that there may be certain orders that we are unable to accept and must cancel. We reserve the right, at our sole discretion, to refuse or cancel any order for any reason. Some situations that may result in your order being cancelled include limitations on quantities available for purchase, inaccuracies or errors in merchandise or pricing information, or problems identified by our credit and fraud avoidance department. We may also require additional verifications or information before accepting any order. We will inform you if all or any portion of your order is cancelled or if additional information is required to accept your order. If your order is cancelled after your credit / debit card has been charged, the said amount will be reversed back in your Card Account.

      //         We will seek authorization on your Card for the aggregate amount of the order placed by you, however, we may charge your card (capture) only with the amount corresponding to the actual portion of the order that we are able/willing to fulfil, and any associated shipping charges/taxes and levies etc. Any surplus amount, for which we might have originally sought and received an authorization on your card from your Card Issuer, if not captured by us in the time period stipulated by our Merchant Banker, will not be captured / charged. If however the same is charged to your card / bank account in error, we will refund it as soon as the same is realized by us or brought to our notice, whichever is sooner. kalakriya.com reserves the right to capture / charge your card to the full or partial authorized amount for the concerned order.

      //         Cancellations by the customer

      //         You may cancel your order or some merchandise in your order before we bill and ship the merchandise. You may also exchange the merchandise or get a refund by returning the merchandise (with the exception of Gifts) in unused, saleable condition in its original packaging along with original tags, for a return or an exchange, within 14 days from the date on which these merchandise are delivered. For details of how to exercise this right please see our Returns Policy. In case we receive a cancellation notice and the order has not been processed /approved by us, we shall cancel the order and refund the entire amount, if any, charged to your card. The customer agrees not to dispute the decision made by kalakriya.com and accept the decision regarding the cancellation.

      //         Returns/ Exchange through courier collection:
      //         Any refund against a return request made to kalakriya.com customer support, and collected by courier from the delivery address where the merchandise was originally delivered, shall be processed only upon receipt of the merchandise in unused condition, along with the original sales invoice.
      //         After the merchandise is received by kalakriya.com, and subject to verification of the purchase and condition of merchandise we shall process a refund/exchange within 5 working days of receipt of the merchandise.
      //         Refunds of payments charged to the Cardholder’s credit / debit card will only be credited back to the Cardholder’s credit / debit card account, which was used to pay for the original order. Please note that we shall not be responsible for any delays in credit to the Cardholder’s credit / debit card account as that is managed by the Cardholder’s issuing bank.
      //         Some merchandise can only be returned through courier collection process.

      //         Financial Details

      //         You agree, understand and confirm that the credit / debit card details or other financial details provided by you for availing of services on kalakriya.com will be correct and accurate and you shall not use the credit /debit card or financial facility which is not lawfully owned / obtained by you. You also understand that any financial information submitted by you is directly received by our acquiring bank and not taken by kalakriya.com. kalakriya.com will not be liable for any credit / debit card fraud. The liability for use of a card fraudulently will be on you and the onus to 'prove otherwise' shall be exclusively on you. All information collected by us shall be governed as per our Privacy Policy.

      //         We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit  mutually agreed by us with our acquiring bank  from time to time

      //         Fraudulent /Declined Transactions

      //         kalakriya.com and its associated acquiring bank or financial institutions reserve the right to recover the cost of merchandise, collection charges and lawyers fees from persons using the Website fraudulently. kalakriya.com and its associated acquiring banks or financial institutions reserve the right to initiate legal proceedings against such persons for fraudulent use of the Website and any other unlawful acts or acts or omissions in breach of these terms and conditions.

      //         Electronic Communications

      //         When you visit the Website or send emails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by email or by posting notices on the Website. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.

      //         Colors

      //         We have made every effort to display the colors of our merchandise that appear on the Website as accurately as possible. However, as the actual colors you see will depend on your monitor, we cannot guarantee that your monitor's display of any color will be accurate.

      //         Reviews, Feedback, Submissions

      //         All reviews, comments, feedback, postcards, suggestions, ideas, and other submissions disclosed, submitted or offered to kalakriya.com on or by this Website or otherwise disclosed, submitted or offered in connection with your use of this Website (collectively, the “Comments”) shall be and remain kalakriya.com property. Such disclosure, submission or offer of any Comments shall constitute an assignment to kalakriya.com of all worldwide rights, titles and interests in all copyrights and other intellectual properties in the Comments. Thus, kalakriya.com owns exclusively all such rights, titles and interests and shall not be limited in any way in its use, commercial or otherwise, of any Comments. kalakriya.com will be entitled to use, reproduce, disclose, modify, adapt, create derivative works from, publish, display and distribute any Comments you submit for any purpose whatsoever, without restriction and without compensating you in any way. kalakriya.com is and shall be under no obligation (1) to maintain any Comments in confidence; (2) to pay you any compensation for any Comments; or (3) to respond to any Comments. You agree that any Comments submitted by you to the Website will not violate this policy or any right of any third party, including copyright, trademark, privacy or other personal or proprietary right(s), and will not cause injury to any person or entity. You further agree that no Comments submitted by you to the Website will be or contain libellous or otherwise unlawful, threatening, abusive or obscene material, or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings or any form of 'spam'.

      //         Kalakriya.com does not regularly review posted Comments, but does reserve the right (but not the obligation) to monitor and edit or remove any Comments submitted to the Website. You grant kalakriya.com the right to use the name that you submit in connection with any Comments. You agree not to use a false email address, impersonate any person or entity, or otherwise mislead as to the origin of any Comments you submit. You are and shall remain solely responsible for the content of any Comments you make and you agree to indemnify kalakriya.com and its affiliates for all claims resulting from any Comments you submit. kalakriya.com and its affiliates take no responsibility and assume no liability for any Comments submitted by you or any third party.

      //         Copyright & Trademark

      //         kalakriya.com and its suppliers and licensors expressly reserve all intellectual property rights in all text, programs, merchandise, processes, technology, content and other materials, which appear on this Website. Access to this Website does not confer and shall not be considered as conferring upon anyone any license under any of kalakriya.com or any third party's intellectual property rights. All rights, including copyright, in this website are owned by or licensed to kalakriya.com. Any use of this website or its contents, including copying or storing it or them in whole or part, other than for your own personal, non-commercial use is prohibited without the permission of kalakriya.com. You may not modify, distribute or re-post anything on this website for any purpose.

      //         The kalakriya.com names and logos and all related merchandise and service names, design marks and slogans are the trademarks or service marks of eParampara Infotainment. All other marks are the property of their respective companies. No trademark or service mark license is granted in connection with the materials contained on this Website. Access to this Website does not authorize anyone to use any name, logo or mark in any manner.

      //         References on this Website to any names, marks, merchandise or services of third parties or hypertext links to third party websites or information are provided solely as a convenience to you and do not in any way constitute or imply kalakriya.com’s endorsement, sponsorship or recommendation of the third party, information, merchandise or service.

      //         kalakriya.com is not responsible for the content of any third party websites and does not make any representations regarding the content or accuracy of material on such websites. If you decide to link to any such third party websites, you do so entirely at your own risk.

      //         All materials, including images, text, illustrations, designs, icons, photographs, programs, music clips or downloads, video clips and written and other materials that are part of this Website (collectively, the 'Contents') are intended solely for personal, non-commercial use. You may download or copy the Contents and other downloadable materials displayed on the Website for your personal use only. No right, title or interest in any downloaded materials or software is transferred to you as a result of any such downloading or copying. You may not reproduce (except as noted above), publish, transmit, distribute, display, modify, create derivative works from, sell or participate in any sale of or exploit in any way, in whole or in part, any of the Contents, the Website or any related software. All software used on this Website is the property of kalakriya.com or its suppliers and protected by Indian and international copyright laws. The Contents and software on this Website may be used only as a shopping resource. Any other use, including the reproduction, modification, distribution, transmission, republication, display, or performance, of the Contents on this Website is strictly prohibited. Unless otherwise noted, all Contents are copyrights, trademarks, trade dress and/or other intellectual property owned, controlled or licensed by kalakriya.com, one of its affiliates or by third parties who have licensed their materials to kalakriya.com and are protected by Indian and international copyright laws. The compilation (meaning the collection, arrangement, and assembly) of all Contents on this Website is the exclusive property of kalakriya.com and is also protected by Indian and international copyright laws.

      //         Objectionable Material

      //         You understand that by using this Website or any services provided on the Website, you may encounter Content that may be deemed by some to be offensive, indecent, or objectionable, which Content may or may not be identified as such. You agree to use the Website and any service at your sole risk and that to the fullest extent permitted under applicable law, kalakriya.com and its affiliates shall have no liability to you for Content that may be deemed offensive, indecent, or objectionable to you.

      //         Indemnity

      //         You agree to defend, indemnify and hold harmless kalakriya.com, its employees, directors, officers, agents and their successors and assigns from and against any and all claims, liabilities, damages, losses, costs and expenses, including attorney's fees, caused by or arising out of claims based upon your actions or inactions, which may result in any loss or liability to kalakriya.com or any third party including but not limited to breach of any warranties, representations or undertakings or in relation to the non-fulfilment of any of your obligations under this User Agreement or arising out of your violation of any applicable laws, regulations including but not limited to Intellectual Property Rights, payment of statutory dues and taxes, claim of libel, defamation, violation of rights of privacy or publicity, loss of service by other subscribers and infringement of intellectual property or other rights. This clause shall survive the expiry or termination of this User Agreement.

      //         Termination

      //         This User Agreement is effective unless and until terminated by either you or kalakriya.com. You may terminate this User Agreement at any time by informing us in writing through Indian postal system by registered post that you no longer wish to be associated with this website, provided that you discontinue any further use of this Website.

      //         kalakriya.com may terminate this User Agreement at any time and may do so immediately without notice, and accordingly deny you access to the Website. Such termination will be without any liability to kalakriya.com. Upon any termination of the User Agreement by either you or kalakriya.com, you must promptly destroy all materials downloaded or otherwise obtained from this Website, as well as all copies of such materials, whether made under the User Agreement or otherwise. kalakriya.com right to any Comments shall survive any termination of this User Agreement. Any such termination of the User Agreement shall not cancel your obligation to pay for the merchandise already ordered from the Website or affect any liability that may have arisen under the User Agreement.

      //         Limitation of Liability and Disclaimers

      //         The Website is provided without any warranties or guarantees and in an “As Is” condition. You must bear the risks associated with the use of the Website.

      //         The Website provides content from other Internet websites or resources and while kalakriya.com tries to ensure that material included on the Website is correct, reputable and of high quality, it cannot accept responsibility if this is not the case. kalakriya.com will not be responsible for any errors or omissions or for the results obtained from the use of such information or for any technical problems you may experience with the Website.

      //         To the fullest extent permitted under applicable law, kalakriya.com or its group/ affiliates companies or its suppliers shall not be liable for any indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses arising out of or in connection with the Website, its services or this User Agreement.

      //         Without prejudice to the generality of the section above, the total liability of kalakriya.com to you for all liabilities arising out of this User Agreement be it in tort or contract is limited to the amount charged to you, against the value of the merchandise ordered by you.

      //         kalakriya.com, its associates and technology partners make no representations or warranties about the accuracy, reliability, completeness, correctness and/or timeliness of any content, information, software, text, graphics, links or communications provided on or through the use of the Website or that the operation of the Website will be error free and/or uninterrupted. Consequently, kalakriya.com assumes no liability whatsoever for any monetary or other damage suffered by you on account of the delay, failure, interruption, or corruption of any data or other information transmitted in connection with use of the Website; and/or any interruption or errors in the operation of the Website.

      //         Website Security

      //         You are prohibited from violating or attempting to violate the security of the Website, including, without limitation, Accessing data not intended for you or logging onto a server or an account which you are not authorized to access; Attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization; Attempting to interfere with service to any other user, host or network, including, without limitation, via means of submitting a virus to the Website, overloading, 'flooding,' 'spamming', 'mail bombing' or 'crashing;' Sending unsolicited email, including promotions and/or advertising of merchandise or services; or Forging any TCP/IP packet header or any part of the header information in any email or newsgroup posting. Violations of system or network security may result in civil or criminal liability. kalakriya.com will investigate occurrences that may involve such violations and may involve, and cooperate with, law enforcement authorities in prosecuting users who are involved in such violations. You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of this Website or any activity being conducted on this Website. You agree, further, not to use or attempt to use any engine, software, tool, agent or other device or mechanism (including without limitation browsers, spiders, robots, avatars or intelligent agents) to navigate or search this Website other than the search engine and search agents available from kalakriya.com on this Website and other than generally available third party web browsers (e.g., Netscape Navigator, Microsoft Internet Explorer).

      //         Entire Agreement

      //         If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed to be superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect. Unless otherwise specified herein, this agreement constitutes the entire agreement between you and kalakriya.com with respect to the kalakriya.com websites/services and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and kalakriya.com with respect to the kalakriya.com websites/services. Kalakriya.com failure to act with respect to a breach by you or others does not waive its right to act with respect to subsequent or similar breaches.

      //         Privacy Policy

      //         We do not sell or rent your personal information to third parties for their marketing purposes without your explicit consent and we only use your information as described in the Privacy Policy. We view protection of your privacy as a very important community principle. We understand clearly that you and your Information is one of our most important assets. We store and process your Information on computers located in India and abroad that are protected by physical as well as technological security devices. We use third parties to verify and certify our privacy principles. If you object to your Information being transferred or used in this way please do not use the Website.

      //         Governing Law and Jurisdiction

      //         This User Agreement shall be construed in accordance with the applicable laws of India. The Courts at Chennai shall have exclusive jurisdiction in any proceedings arising out of this agreement.

      //         Any dispute or difference either in interpretation or otherwise, of any terms of this User Agreement between the parties hereto, the same shall be referred to an independent arbitrator who will be appointed by eParampara Infotainment and his decision shall be final and binding on the parties hereto. The above arbitration shall be in accordance with the Arbitration and Conciliation Act, 1996 as amended from time to time. The arbitration shall be held in Chennai, India. The High Court of judicature at Chennai alone shall have the jurisdiction and the Laws of India shall apply.

      //         Modification of Terms and conditions of Service

      //         kalakriya.com may at any time modify the User Agreement without any prior notification to you. Subsequent to any modification of the User Agreement, we will inform you of the modifications in the User Agreement via e-mail at the address provided by you while registering on kalakriya.com. You can access the latest version of the User Agreement at any given time on kalakriya.com. You should regularly review the User Agreement on kalakriya.com. In the event the modified User Agreement is not acceptable to you, you should discontinue using the service. However, if you continue to use the service you shall be deemed to have agreed to accept and abide by the modified User Agreement.

      //         Contact Details:-

      //         eParampara Infotainment
      //         II Floor, Sribalavinayagar Building,
      //         ARK Colony, New No.: 35/3
      //         Eldams Road, Alwarpet
      //         Chennai-600018 India

      //         Tel: +91-44-4203 0505/0506
      //         Fax: +91-44-4203 0606 (Please intimate us before sending a fax)
      //         info@kalakriya.com
      //       </div>
      //     </Container>
      //   </div>
      // </React.Fragment>
    )
  }
}

export default TermsConditions
