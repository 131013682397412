import React, { Component } from "react"
import { Container } from "reactstrap"
import MetaTags from 'react-meta-tags';
import { Button, Col, PaginationItem, PaginationLink, Row, Badge, Table, Card, CardBody } from "reactstrap"
import { Link, useParams, withRouter } from "react-router-dom";
import jsPDF from 'jspdf';
import canvas from 'html2canvas';
import urlSocket from "helpers/urlSocket";
import { map, reject } from "lodash";
import { LoadingOutlined } from '@ant-design/icons';

class PaymentLoader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show_button: true,
      showButton: false,
      authUser: JSON.parse(sessionStorage.getItem("authUser")),
      dataLoaded: false,
      blurLoading: true
    }
  }

  async componentDidMount() {
    var payment_type = sessionStorage.getItem('payment_type')
    const searchParams = new URLSearchParams(window.location.search);
    const basket_session_id = searchParams.get('session_id');
    if (payment_type == '0') {
      var basket_data = await this.getBasketData(basket_session_id, false)
      this.setState({
        basketData: basket_data,
      }, () => {
        this.setState({
          dataLoaded: true
        })
        sessionStorage.removeItem('payment_type')
      })
    }
    else if (payment_type == '1') {
      var basket_data = await this.getBasketData(basket_session_id, true)
      this.cartCourseSubscribed(basket_data)
    }
    else {
      this.props.history.push('/home')
    }
  }


  cartCourseSubscribed = (basket_data) => {

    try {
      urlSocket.post('customer_basket/cart-course-subscribed-srtipe-one-time', {
        subscriber_id: this.state.authUser._id,
        session_id: basket_data._id,
        full_name: this.state.authUser.full_name,
        email: this.state.authUser.email,
        full_ph_no: this.state.authUser.full_ph_no,
        basket_data: basket_data,
        userinfo: this.state.authUser,
      }).then(async (response) => {

        if (response.data.response_code === 500) {
          var basket_data = await this.getBasketData(response.data.basket_data._id, false)
          this.setState({
            basketData: basket_data,
          }, () => {
            sessionStorage.setItem('basket_session_id', response.data.basket_data._id)
            this.props.history.push('/payment-success')
          })
        }
      })

    } catch (error) {

    }
  }




  // cartCourseSubscribed = (basket_data) => {

  //   try {
  //     urlSocket.post('customer_basket/cart-course-subscribed-srtipe', {
  //       subscriber_id: this.state.authUser._id,
  //       session_id: basket_data._id,
  //       full_name: this.state.authUser.full_name,
  //       email: this.state.authUser.email,
  //       full_ph_no: this.state.authUser.full_ph_no,
  //       basket_data: basket_data,
  //       userinfo: this.state.authUser,
  //     }).then(async (response) => {

  //       if (response.data.response_code === 500) {
  //         var basket_data = await this.getBasketData(response.data.basket_data._id, false)
  //         this.setState({
  //           basketData: basket_data,
  //         }, () => {
  //           sessionStorage.setItem('basket_session_id', response.data.basket_data._id)
  //           this.props.history.push('/payment-success')
  //         })
  //       }
  //     })

  //   } catch (error) {

  //   }
  // }


  getBasketData = (basket_session_id, encrypted_status) => {

    return new Promise((resolve, reject) => {
      try {
        urlSocket.post('customer_basket/get-customer-basket-after-success', { basket_session_id: basket_session_id, encrypted_status: encrypted_status }).then((response) => {
          if (response.data.response_code === 500) {
            resolve(response.data.data)
          }
        })
      }
      catch (error) {
        reject(error)
      }
    })

  }


  BackToCourse = () => {
    var course_info_id = JSON.parse(sessionStorage.getItem("CourseDetails"))
    var course_info_name = JSON.parse(sessionStorage.getItem("CourseName"))
    var course_name = course_info_name.name_of_the_course.trim().replace(/[^A-Z0-9]\s+/ig, "").replaceAll(' ', '-')
    document.location.href = ("/course/CourseDetails" + '?' + course_info_id + '?' + course_name.toLowerCase())
  }


  render() {
    const { authUser, basketData, dataLoaded, blurLoading } = this.state
    return (
      <>
        {
          blurLoading && (
            <>
              <div className="loader-overlay">
                <div className="loader">
                  <LoadingOutlined />
                </div>
              </div>

            </>
          )
        }
      </>
    )
  }
}

export default withRouter(PaymentLoader)
