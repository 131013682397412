import urlSocket from "helpers/urlSocket";


const getCartCourseList = (subscriber_info) => {

    return new Promise((resolve, reject) => {
        try {
            urlSocket.post('customer_basket/get-user-cart-list'
                , { subscriber_id: subscriber_info._id })
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.data.data)
                    }
                });
        } catch (error) {
            console.log("catch error", error);
            reject(error)
        }
    });
};

const getCourseListAPI = () => {
    let subscriber_info = JSON.parse(sessionStorage.getItem("authUser"))
    return new Promise((resolve, reject) => {
        try {
            urlSocket.post('mngsubcrs/get-subscriber-course', {subscriber_info: subscriber_info })
                .then((response) => {
                    // console.log(response,'response')
                    if (response.status === 200) {
                        resolve(response)
                        
                    }
                }).catch((error)=>{
                    console.log(error,'error')
                })
                ;
        } catch (error) {
            console.log("catch error", error);
            reject(error)
        }
    });
}



const getSubscriberCourseListAPI = () => {
    let subscriber_info = JSON.parse(sessionStorage.getItem("authUser"))
    return new Promise((resolve, reject) => {
        try {
            urlSocket.post('mngsubcrs/get-subscriber-course-list', {subscriber_info: subscriber_info }).then((response)=>{
                console.log(response,'response');
                if (response.status === 200) {
                    resolve(response)
                }
            }).catch((error)=>{
                reject(error) 
            })

        } catch (error) {
            reject(error)
            
        }
        // try {
        //     urlSocket.post('mngsubcrs/get-subscriber-course', {subscriber_info: subscriber_info })
        //         .then((response) => {
        //             // console.log(response,'response')
        //             if (response.status === 200) {
        //                 resolve(response)
                        
        //             }
        //         }).catch((error)=>{
        //             console.log(error,'error')
        //         })
        //         ;
        // } catch (error) {
        //     console.log("catch error", error);
        //     reject(error)
        // }
    });
}


// const getCourseListAPI = () => {
//     let subscriber_info = JSON.parse(localStorage.getItem("authUser"))
//     return new Promise((resolve, reject) => {
//         try {
//             urlSocket.post('mngsubcrs/subd_crs_list', { pageno: 1, limit: 20, subscriber_info: subscriber_info })
//                 .then((response) => {
//                     if (response.status === 200) {
//                         resolve(response.data.data)
//                     }
//                 });
//         } catch (error) {
//             console.log("catch error", error);
//             reject(error)
//         }
//     });
// }


const getOrderListAPI = () => {
    let subscriber_info = JSON.parse(sessionStorage.getItem("authUser"))
    // const dispatch = useDispatch()
    return new Promise((resolve, reject) => {
        try {
            urlSocket.post('customer_basket/get-customer-orders', { user_id: subscriber_info._id })
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response)
                        // dispatch(setCartCourseListLength(response.data.cart_count))
                    }
                });
        } catch (error) {
            console.log("catch error", error);
            reject(error)
        }
    });
}





export { getCartCourseList, getCourseListAPI, getOrderListAPI,getSubscriberCourseListAPI }