import React, { useEffect, useRef, useState, useCallback } from "react"
import { Component } from "reactstrap";
import { Link } from "react-router-dom"
import $ from 'jquery'
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css'
import { InfoCircleTwoTone } from "@ant-design/icons";
import { button } from "aws-amplify";

//-–––––––––––––––----------––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
let { videojs, registerIVSTech, registerIVSQualityPlugin, IVSPlayer } = window
let angles = []
var vdoPlayEvent = true;

registerIVSTech(videojs)
registerIVSQualityPlugin(videojs)

const IVSVideoPlayer = ({ //Props. coming from parent page

  content,
  content_name,
  contentStatus,
  resumeFrom,
  disableSeekingBar,
  closeVideo,
  baseurl,
  thumbnailID,
  contentInformation,
  updateVideoStatus,
  subscribed,
  loadPNVideos,
  disablePrevious,
  disableNext,
  courseAction,
  resumeDataTiming,
  selectedContentIndex,
  totalContent,
  playFromBegining,
  playAgainSame,
  updateLastAccess,
  setPlayer,
  isPreviewAvailable,
  previewStartTime,
  previewEndTime,
  contentPreviewHistory,
  addToCart
}) => {
// console.log(contentStatus,'contentStatus')

  var whereYouAt = 0;

  const playerRef = useRef(null)
  let player;
  let intervalId = useRef(null)
  let vdPlayer = null
  let reqAnimid = null
  let endAtContainer;

  const [showReg, setShowReg] = useState(false)
  const [changedVideo, setVideo] = useState()
  const [thumbnailLoaded, setTL] = useState(false)
  const [videoOnPlay, vidPlay] = useState(false)
  const [mediaFiles, setMediaFiles] = useState()
  const [thumbnailGalleryPosition, setThumbnailGalleryPosition] = useState({ bottom: 60 })
  const [mainVideoURL, setMainVideoURL] = useState(null)
  const [contentName, setContentName] = useState(null)
  const [isMultiAngleContain, setMultiAngleContain] = useState(false)
  const [multiAngleVideos, setMultiAngleVideos] = useState(null)
  const [contentLoaded, setContentLoaded] = useState(null)
  const [videoPlayer, setVideoPlayer] = useState(null)
  const [isMultiAnglePreviewOpened, setIsMultiAnglePreviewOpened] = useState(null)
  const [multiAngleVideoPlayer, setMultiAngleVideoPlayer] = useState([])
  const [playerCurrentTime, setPlayerCurrentTime] = useState(0)
  const [isEndActivityEnabled, setIsEndActivityEnabled] = useState(false)
  const [playerEvent, setPlayerEvent] = useState(null)
  const [playerSize, setPlayerSize] = useState()
  const [contrlbarComponents, setControlBarComponents] = useState(null)
  const [windowSize, setWindowSize] = useState(null)
  // const [isMainPlayerPaused, setMainPlayerPaused] = useState(false)
  // const [videoAnglePlaying, setSelectedAngle] = useState("")


  var MainStreamURL = {
    formattedSize: "",
    input_key: "",
    origina_name: "Normal",
    output_url: content.main_file.output_url,
    preview: "",
    status: true,
    video_id: "",
    viewing_angle: "Normal"
  }

  var videoAngleBtns;
  var videoAngleResBtns;
  var videoAnglePlaying = "Normal";
  var isMainPlayerPaused = false;

 

  useEffect(() => {
    if (content.main_file.output_url !== "") {
      destroyVideo()
      setContentLoaded(true)
      setMainVideoURL(content.main_file.output_url)
      setContentName(content_name)
      setMultiAngleContain(content.multi_angle_video.length > 0 ? true : false)
      setMultiAngleVideos(content.multi_angle_video)
    }
    else {
      destroyVideo()
      setMainVideoURL(null)
      setContentName(null)
      setMultiAngleContain(null)
      setMultiAngleVideos(null)
      setContentLoaded(false)
      setVideoPlayer(null)
    }

  }, [content])

  useEffect(() => {

    if (contentLoaded) {
      setTimeout(() => {
        var playerContainer = document.querySelector("video")
        if (playerContainer != null) {
          setPlayerSize({ width: playerContainer.clientWidth, height: playerContainer.clientHeight, top: playerContainer.clientTop, left: playerContainer.clientLeft });
          window.addEventListener('resize', () => {
            setPlayerSize({ width: playerContainer.clientWidth, height: playerContainer.clientHeight, top: playerContainer.clientTop, left: playerContainer.clientLeft })
          })
        }

      }, 100);

    }

  }, [contentLoaded])



  const destroyVideo = () => {
    clearInterval(intervalId.current)
    if (player) {
      let currentTime = player.currentTime()
      player.dispose()
      player = null
    }
  }

  useEffect(() => {

    if (mainVideoURL) {

      setTimeout(() => {
        const videoJsOptions = {
          playbackRates: [0.25, 0.5, 1, 1.5, 2],
          techOrder: ["AmazonIVS"],
          autoplay: false,
          preload: "none",
          muted: false,
          controls: true,
          inactivityTimeout: 6000,
          enableSmoothSeeking: true,
          controlBar: {
            pictureInPictureToggle: false,
            muteToggle: false
          },
          userActions: {
            hotkeys: (event) => {
              if (event.which === 32) {
                if (!player.paused()) {
                  player.pause();
                  isMainPlayerPaused = true
                }
                else {
                  player.play();
                  isMainPlayerPaused = false
                }
              }
            }
          }
        }

        var player = new videojs(playerRef.current, videoJsOptions);
        const handleRightClick = (event) => {
          event.preventDefault();
        };
    
        player.on('contextmenu', handleRightClick);
        ivsControlEvents(player)
        setPlayer(player)

        player.ready(function () {
          player.src(baseurl + content.main_file.output_url);
          player.enableIVSQualityPlugin();

          var myPlayer = this;

          

          setTimeout(() => {
            if (myPlayer) {
              var contentDuration = myPlayer.duration(); // convert to ms from seconds
              contentInformation({
                duration: contentDuration
              })

              if(subscribed){
                myPlayer.play();
                if (courseAction === "RESUME") {
                  myPlayer.on('playing', function () {
                    if(resumeDataTiming !== 0)  myPlayer.currentTime(resumeDataTiming)
                    myPlayer.off('playing');
                  })
                }
              }
              else
              {
                if(isPreviewAvailable)
                {
                  myPlayer.play();
                  myPlayer.on('playing', function () {

                    if(previewStartTime !== 0) myPlayer.currentTime(previewStartTime)

                  })
                  myPlayer.on('timeupdate', function() {
                    // Check if the current time exceeds the end time
                    //console.log("previewEndTime", previewEndTime)
                    if (myPlayer.currentTime() >= previewEndTime) {
                      // Pause the video
                      myPlayer.pause();
                      var ctrlbtns = document.querySelector(".vjs-custom-controlbtns")
                      if(ctrlbtns) ctrlbtns.style.display="none"
                      const endActivity = document.querySelector(".end-container")
                      if (player && !endActivity) {
                        previewEndActivity()
                      }                      
                    }
                  });
                }
                  
              }

            }

          }, 300);

          createControlBarBtns(isMultiAngleContain, myPlayer)
          enableControllers(myPlayer)

          if(!subscribed){
            hideSeekbar()
          }


          


          myPlayer.on('seeked', function () {
            seekControl(myPlayer)
          });

          myPlayer.on('seeking', function () {
            seekControl(myPlayer)
          })

          isMainPlayerPaused = false
        });
        return () => {
          if (player) {
            playerRef.current.removeEventListener('contextmenu', handleRightClick);
            player.dispose();
          }
        };

      }, 100);

    }
    else {
      if (player) {
        player.dispose();
      }
    }

  }, [mainVideoURL, isMultiAngleContain])


  const seekControl = (myPlayer) => {
    let playerTime = myPlayer.currentTime()
    if (Math.round(myPlayer.currentTime()) >= Math.round(myPlayer.duration())) {
      myPlayer.pause();
      enableVideoPause()
      const endActivity = document.querySelector(".end-container")
      if (!endActivity) {
        initEndActivity(true)
      }
      updateStatus(playerTime, "END")
      setIsEndActivityEnabled(true);
    }
    else {
      setPlayerCurrentTime(playerTime);
      setIsEndActivityEnabled(false);
      initEndActivity(false)
    }
  }



  const createControlBarBtns = async (isMultiAngleContain, player) => {

    setControlBarComponents({
      isMultiAngleContain, player
    })

    var angleBtns = []

    if (isMultiAngleContain) {

       var angleText = await addNewButton({
          player: player,
          icon: "angleText",
          id: "angleleft",
          classname: '',
          insertBeforeClass: "vjs-playback-rate",
          showText: true,
          text: "Multiangle"
        });

        angleText.className = 'd-none d-md-flex'

        var videoAngleLiBtns = []

        var angleContainer = document.createElement('div');
        angleContainer.className = "vjs-menu-button vjs-menu-button-popup vjs-control vjs-button"

        var btnContainer = document.createElement('button');
        btnContainer.className = "mdi mdi-fit-to-page-outline vjs-icon-placeholder vjs-menu-button vjs-menu-button-popup vjs-button"
        btnContainer.type = 'button';
        btnContainer.ariaDisabled = "false"
        btnContainer.ariaHaspopup = "true"
        btnContainer.ariaExpanded = "false"
        btnContainer.title = "Multiangle"

        var mnuContainer = document.createElement('div');
        mnuContainer.className = 'vjs-menu ';

        var ulContainer = document.createElement('ul');
        ulContainer.className = "vjs-menu-content"
        ulContainer.role = "menu"

        var btnSpan = document.createElement('span');
        btnSpan.className = "vjs-control-text";
        btnSpan.innerText = 'Multiangle';

        btnContainer.appendChild(btnSpan);

        var liElement = document.createElement('li');
        liElement.className = "vjs-menu-item vjs-selected"
        liElement.tabIndex = "-1"
        liElement.id = "Normal"
        liElement.role = "menuitemradio"
        liElement.ariaDisabled = "false"
        liElement.ariaChecked = "true"
        liElement.innerHTML = "Normal"

        liElement.onclick = function () {
          if (videoAnglePlaying !== this.id) {
            videoAnglePlaying = (this.id)
          resetResAngleBtnStyle()
          this.className = "vjs-menu-item vjs-selected"
          loadAngleChangedVideo(content.main_file, 0, player)
          }
        }

        videoAngleLiBtns.push(liElement)

        ulContainer.appendChild(liElement)

        if (multiAngleVideos.length > 0) {
          multiAngleVideos.map(async (item, i) => {
            var liElementAngle = document.createElement('li');
            liElementAngle.className = "vjs-menu-item"
            liElementAngle.key = "mnu" + i;
            liElementAngle.id = item.viewing_angle;
            liElementAngle.tabIndex = "-1"
            liElementAngle.role = "menuitemradio"
            liElementAngle.ariaDisabled = "false"
            liElementAngle.ariaChecked = "true"
            liElementAngle.innerHTML = item.viewing_angle

            liElementAngle.onclick = function () {
              if (videoAnglePlaying !== this.id) {
                videoAnglePlaying = (this.id)
                resetResAngleBtnStyle()
                this.className = "vjs-menu-item vjs-selected"
                loadAngleChangedVideo(item, i, player)
                mnuContainer.className = 'vjs-menu';
              }
            }

            videoAngleLiBtns.push(liElementAngle)
            ulContainer.appendChild(liElementAngle)

          })
        }

        videoAngleResBtns = videoAngleLiBtns

        var liElement2 = document.createElement('li');

        ulContainer.appendChild(liElement2)

        mnuContainer.appendChild(ulContainer)
        angleContainer.appendChild(btnContainer)
        angleContainer.appendChild(mnuContainer)

        btnContainer.onmouseenter = function () {
          this.className = "mdi mdi-fit-to-page-outline vjs-icon-placeholder vjs-menu-button vjs-menu-button-popup vjs-button vjs-hover"
          btnContainer.ariaExpanded = "true"
          mnuContainer.className = 'vjs-menu vjs-lock-showing';
        };

        mnuContainer.onmouseenter = function () {
          btnContainer.ariaExpanded = "true"
          mnuContainer.className = 'vjs-menu vjs-lock-showing';
        };

        mnuContainer.onmouseleave = function () {
          mnuContainer.className = 'vjs-menu';
        };

        btnContainer.onclick = function () {
          if (btnContainer.ariaExpanded === "false") {
            btnContainer.ariaExpanded = "true"
            mnuContainer.className = 'vjs-menu vjs-lock-showing';
          }
          else {
            btnContainer.ariaExpanded = "false"
            mnuContainer.className = 'vjs-menu vjs-hidden ';
          }
        };

        btnContainer.onmouseout = function () {
          this.className = "mdi mdi-fit-to-page-outline vjs-icon-placeholder vjs-menu-button vjs-menu-button-popup vjs-button"
          btnContainer.ariaExpanded = "false"
          mnuContainer.className = 'vjs-menu';
        }

    }


    var controlBar = document.getElementsByClassName('vjs-control-bar')[0];
    var insertBeforeNode = document.getElementsByClassName("vjs-playback-rate")[0];
    if(angleContainer) controlBar.insertBefore(angleContainer, insertBeforeNode);
    

    // var studiModeBtn = addNewButton({
    //   player: player,
    //   icon: "bi-fullscreen",
    //   id: "studioModeBtn",
    //   classname: 'studioModeBtn',
    //   insertBeforeClass: "vjs-fullscreen-control",
    //   showText: true,
    //   text: "Studio Mode"
    // });

    // var studioMode = true;

    // studiModeBtn.onclick = function () {
    //   if (player.isFullscreen()) {
    //     document.exitFullscreen();
    //     studioMode = true;
    //   }
    // };

    var fscBtn = document.querySelector('.fscreenBtn');

    var fscreenBtn = await addNewButton({
      player: player,
      icon: "bi-square",
      id: "fscreenBtn",
      classname: 'fscreenBtn',
      insertBeforeClass: "vjs-fullscreen-control",
      showText: true,
      text: "Full Screen"
    });
    fscreenBtn.onclick = function () {
      var fsTrigger = document.getElementsByClassName('vjs-fullscreen-control')[0];
      fsTrigger.click();
    };

  }

  const resetAngleBtnStyle = () => {
    videoAngleBtns.length > 0 &&
      videoAngleBtns.map((item, i) => {
        item.style = "background-color:#4d5154; padding:0 10px; margin-right:0; color:#fff !important;"
      })
  }

  const resetResAngleBtnStyle = () => {
    videoAngleResBtns.length > 0 &&
      videoAngleResBtns.map((item, i) => {
        item.className = "vjs-menu-item"
      })
  }

  const hideSeekbar = () => {
    let seekbar = document.querySelector(".vjs-progress-control")
    seekbar.style.display = "none";

    let duration = document.querySelector(".vjs-duration")
    duration.style.display = "none";

    let currentTime = document.querySelector(".vjs-current-time")
    currentTime.style.display = "none";

    let nextVideo = document.querySelector(".vjs-custom-nextvideo-button")
    if(nextVideo) nextVideo.style.display = "none";

    let previousVideo = document.querySelector(".vjs-custom-prevideo-button")
   if(previousVideo) previousVideo.style.display = "none";

   let forwardVideo = document.querySelector(".vjs-custom-forward-button")
   if(forwardVideo) forwardVideo.style.display = "none";

   let backward = document.querySelector(".vjs-custom-backward-button")
  if(backward) backward.style.display = "none";

     
  }

  const enableControllers = (player) => {

    if (player) {

      if (disableSeekingBar) {
        player.controlBar.progressControl.disable()
      }

      player.on('fullscreenchange', function (e) {
        if (player.isFullscreen()) {
          player.controls(true);
          $('.fscreenBtnspanTxt').text('Normal Mode');
          $('.fscreenBtn').addClass('active');
          $('.studioModeBtnspanTxt').text('Studio Mode')
          $('.studioModeBtn').removeClass('active');
        } else {
          player.controls(false);
          $('.fscreenBtnspanTxt').text('Full Screen');
          $('.fscreenBtn').removeClass('active');
        }
      });

      player.on('pause', () => {
        enableVideoPause();
      });

      player.on('play', (e) => {
        enableVideoPlay();
      });

      vdPlayer = player
      vdPlayer.volume(1)

      var TitleComponent = videojs.getComponent('Component');
      var TitleBar = videojs.extend(TitleComponent, {
        constructor: function (player, options) {
          TitleComponent.apply(this, arguments);
          if (options.text) {
            this.updateTextContent(options.text);
          }
          this.on(this.player(), ["useractive", "userinactive"], this.showHide);
        },
        createEl: function () {
          return videojs.dom.createEl('div', {
            className: 'vjs-title-bar'
          });
        },
        updateTextContent: function (text) {
          if (typeof text !== 'string') {
            text = 'Title Unknown';
          }
          videojs.dom.emptyEl(this.el());
          videojs.dom.appendContent(this.el(), text);
        },
        showHide: function (ev) {
          if (ev.type === 'userinactive') {
            this.hide();
          } else {
            this.show();
          }
        }
      });
      videojs.registerComponent('TitleBar', TitleBar);
      player.addChild('TitleBar', { text: content_name + ' (' + MainStreamURL.viewing_angle + ')' });

      player.on('ended', function (e) {
        var vjsPlay = document.querySelector('.vjs-custom-plyPusebutton');
        var element = vjsPlay;
        element.classList.remove("vjs-custom-play-button");
        element.classList.remove("vjs-custom-pause-button");
        element.classList.add("vjs-custom-play-button");
        player.pause();
        player.currentTime(player.duration());
        //player.dispose()
      });


      // ------- Player Controls ----------------

      var controlBtn = player.addChild("component")

      if (!disablePrevious) {
        var prevVideoBtn = controlBtn.addChild("component");
        var prevVideoBtnDom = prevVideoBtn.el();
        prevVideoBtn.addClass("vjs-custom-button");
        prevVideoBtn.addClass("vjs-custom-prevideo-button");
        prevVideoBtn.addClass("mdi");
        prevVideoBtn.addClass("mdi-skip-previous");

        prevVideoBtnDom.onclick = function () {
          loadPNVideos("PREVIOUS")
        }

      }

      var skipBehindButton = controlBtn.addChild("component");
      var skipBehindButtonDom = skipBehindButton.el();
      //skipBehindButtonDom.innerHTML = "30<<";
      skipBehindButtonDom.innerHTML = "";
      skipBehindButton.addClass("vjs-custom-button");
      skipBehindButton.addClass("vjs-custom-backward-button");
      skipBehindButton.addClass("bx");
      skipBehindButton.addClass("bx-rotate-left");


      skipBehindButtonDom.onclick = function () {
        skipS3MV(-10);
      }

      var playPauseButton = controlBtn.addChild("component");
      var playPauseButtonDom = playPauseButton.el();
      playPauseButton.addClass("vjs-custom-button");
      playPauseButton.addClass("vjs-custom-plyPusebutton");
      playPauseButton.addClass("vjs-custom-pause-button");
      playPauseButton.addClass("bx");
      // playPauseButton.addClass("bx-play-circle");
      playPauseButton.addClass("bx-pause-circle");


      playPauseButtonDom.onclick = function () {
        var element = this;
        if (!player.paused()) {
          element.classList.remove("vjs-custom-pause-button");
          element.classList.add("vjs-custom-play-button");
          element.classList.remove("bx-pause-circle");
          element.classList.add("bx-play-circle");

          let playerVideo = document.querySelector('video')
          var isPlaying = playerVideo.currentTime > 0 && !playerVideo.paused && !playerVideo.ended
            && playerVideo.readyState > playerVideo.HAVE_CURRENT_DATA;

          isPlaying && player.pause()
          let currentTime = player.currentTime()
          setPlayerCurrentTime(currentTime)
          isMainPlayerPaused = true
        }
        else {
          element.classList.remove("vjs-custom-play-button");
          element.classList.add("vjs-custom-pause-button");
          element.classList.remove("bx-play-circle");
          element.classList.add("bx-pause-circle");
          player.play();
          isMainPlayerPaused = false
        }
      }


      var skipAheadButton = controlBtn.addChild("component");
      var skipAheadButtonDom = skipAheadButton.el();
      //skipAheadButtonDom.innerHTML = ">>30";
      skipAheadButtonDom.innerHTML = "";
      skipAheadButton.addClass("vjs-custom-button");
      skipAheadButton.addClass("vjs-custom-forward-button");
      skipAheadButton.addClass("bx");
      skipAheadButton.addClass("bx-rotate-right");

      skipAheadButtonDom.onclick = function () {
        skipS3MV(10);
      }


      function skipS3MV(skipBy) {
        var vjsPlay = document.querySelector('.vjs-custom-plyPusebutton');
        var element = vjsPlay;
        if (player.ended()) {
          element.classList.remove("vjs-custom-play-button");
          element.classList.remove("vjs-custom-pause-button");
          element.classList.add("vjs-custom-play-button");
          element.classList.add("bx-play-circle");
          player.currentTime(0);
          player.pause();
        }
        else {
          if (player.currentTime() >= player.duration()) {
            element.classList.remove("vjs-custom-pause-button");
            element.classList.add("vjs-custom-play-button");
            element.classList.remove("bx-pause-circle");
            element.classList.add("bx-play-circle");

            player.currentTime(0);
            player.pause();
          }
          else if (player.currentTime() <= 0) {
            element.classList.remove("vjs-custom-pause-button");
            element.classList.add("vjs-custom-play-button");
            element.classList.remove("bx-play-circle");
            element.classList.add("bx-pause-circle");

            player.currentTime(0);
            player.pause();
          }
          else {
            player.currentTime(player.currentTime() + skipBy);
            let currentTime = player.currentTime()
            setPlayerCurrentTime(currentTime)
          }
        }
      }

      if (!disableNext) {
        var nextVideoBtn = controlBtn.addChild("component");
        var nextVideoBtnDom = nextVideoBtn.el();
        nextVideoBtn.addClass("vjs-custom-button");
        nextVideoBtn.addClass("vjs-custom-nextvideo-button");
        nextVideoBtn.addClass("mdi");
        nextVideoBtn.addClass("mdi-skip-next");

        nextVideoBtnDom.onclick = function () {
          loadPNVideos("NEXT")
        }


      }
      controlBtn.addClass('vjs-custom-controlbtns');

    }

  }

  const previewEndActivity = (action) => {

      var player = new videojs(playerRef.current);
      var endContainer = player.addChild("component")
      var endActivityContainer = player.addChild("component")

      var col1 = endActivityContainer.addChild("component");
      var col2 = endActivityContainer.addChild("component");

      col1.addClass("col-sm-12");
      col2.addClass("col-sm-12");
      col2.addClass("d-flex");
      col2.addClass("flex-row");
      col2.addClass("justify-content-center");
      // col2.addClass("gap-1 gap-md-2");

      var endActvityText = col1.addChild("component");
      var endActvityTextDOM = endActvityText.el();
      endActvityTextDOM.innerHTML = "To view this content fully, subscribe this course.";
      endActvityTextDOM.style.fontSize = "14px"
      endActvityText.addClass("vjs-ea-title")

      var playAgainButton = col2.addChild("component");
      var playAgainButtonDOM = playAgainButton.el();
      playAgainButtonDOM.innerHTML = "Play Again"
      playAgainButton.addClass("btn");
      playAgainButton.addClass("btn-secondary");
      playAgainButton.addClass("end-card-btn");
      playAgainButton.addClass("me-2");

      playAgainButtonDOM.onclick = function () {
        playAgainSame()
        // setIsEndActivityEnabled(false);
      }

        // var playFBButton = col2.addChild("component");
        // var playFBButtonDOM = playFBButton.el();
        // playFBButtonDOM.innerHTML = "Subscribe"
        // playFBButton.addClass("btn");
        // playFBButton.addClass("btn-dark");
        // playFBButton.addClass("end-card-btn");

        // playFBButtonDOM.onclick = function () {
        //   addToCart()
        // }

      endActivityContainer.addChild(col1)
      endActivityContainer.addChild(col2)
      endActivityContainer.addClass('vjs-custom-end-activity');

      endContainer.addChild(endActivityContainer)
      endContainer.addClass("end-container")

  }

  const initEndActivity = (action) => {


    if (action === true) {

      var player = new videojs(playerRef.current);
      var endContainer = player.addChild("component")
      var endActivityContainer = player.addChild("component")

      var col1 = endActivityContainer.addChild("component");
      var col2 = endActivityContainer.addChild("component");

      col1.addClass("col-sm-12");
      col2.addClass("col-sm-12");
      col2.addClass("d-flex");
      col2.addClass("flex-row");
      col2.addClass("justify-content-center");
      // col2.addClass("gap-1 gap-md-2");

      var endActvityText = col1.addChild("component");
      var endActvityTextDOM = endActvityText.el();
      endActvityTextDOM.innerHTML = "Proceed to the next..."
      endActvityText.addClass("vjs-ea-title")

      var playAgainButton = col2.addChild("component");
      var playAgainButtonDOM = playAgainButton.el();
      playAgainButtonDOM.innerHTML = "Play Again"
      playAgainButton.addClass("btn");
      playAgainButton.addClass("btn-secondary");
      playAgainButton.addClass("end-card-btn");
      playAgainButton.addClass("me-2");

      playAgainButtonDOM.onclick = function () {
        playAgainSame()
        // setIsEndActivityEnabled(false);
      }

      if (selectedContentIndex === totalContent - 1) {
        var playFBButton = col2.addChild("component");
        var playFBButtonDOM = playFBButton.el();
        playFBButtonDOM.innerHTML = "Play From Begining"
        playFBButton.addClass("btn");
        playFBButton.addClass("btn-dark");
        playFBButton.addClass("end-card-btn");

        playFBButtonDOM.onclick = function () {
          playFromBegining()
        }
      }
      else {
        var playNextButton = col2.addChild("component");
        var playNextButtonDOM = playNextButton.el();
        playNextButtonDOM.innerHTML = "Play Next"
        playNextButton.addClass("btn");
        playNextButton.addClass("btn-dark");
        playNextButton.addClass("end-card-btn");

        playNextButtonDOM.onclick = function () {
          loadPNVideos("NEXT")
        }

      }

      endActivityContainer.addChild(col1)
      endActivityContainer.addChild(col2)
      endActivityContainer.addClass('vjs-custom-end-activity');

      endContainer.addChild(endActivityContainer)
      endContainer.addClass("end-container")

    }
    else {

      const endActivity = document.querySelector(".end-container")
      if (endActivity) {
        endActivity.classList.remove("end-container")
        endActivity.style = "display:none !important"
      }

    }
  }

  const removeEndActivity = () => {
  };


  const ivsControlEvents = (player) => {

    const ivsPlayer = player.getIVSPlayer()
    const PlayerState = player.getIVSEvents().PlayerState

    ivsPlayer.addEventListener(PlayerState.PLAYING, () => {
      let currentTime = player.currentTime()
      setIsEndActivityEnabled(false)
      updateStatus(currentTime, "PLAY")
    })


    // const seekBar = player.controlBar.progressControl.seekBar;
    ivsPlayer.addEventListener(PlayerState.BUFFERING, () => {
      let currentTime = player.currentTime()
      // console.log("buffering am triggered")
    })

    ivsPlayer.addEventListener(PlayerState.ENDED, () => {
      let currentTime = player.currentTime()
      let totalDuration = player.duration()
      updateStatus(currentTime, "END")
      setIsEndActivityEnabled(true)
      const endActivity = document.querySelector(".end-container")
      if (player && !endActivity) {
        initEndActivity(true)
      }
      // console.log("ended am triggered")
    })

    ivsPlayer.addEventListener(PlayerState.IDLE, () => {
      // console.log("idle am triggered")
      let currentTime = player.currentTime()
      isMainPlayerPaused = true
    })
  }

  const loadAngleChangedVideo = (item, idx, player) => {
    destroyVideo()
    whereYouAt = player.currentTime()
    player.src(baseurl + item.output_url);
    player.ready(function () {

      player.currentTime(whereYouAt);
      vdoPlayEvent = true;
      if (!isMainPlayerPaused) {
        player.play();
        isMainPlayerPaused = false
        enableVideoPlay();
      }
      else {
        enableVideoPause();
      }
      whereYouAt = player.currentTime();
      vdoPlayEvent = true;

      var angleName = item.viewing_angle === undefined ? 'Normal' : item.viewing_angle
      $('.vjs-title-bar').text(content_name + " (" + angleName + ")");
    })

  }


  const addNewButton = async (data) => {
    var myPlayer = data.player,
      controlBar,
      newElement = document.createElement('div');
    var newLink = document.createElement('a');
    newLink.className = "baseBarIcon";

    newElement.id = data.id;

    if (data.showText) {
      newElement.className = ' vjs-custom-controlbar-icn baseBarIconWrapperWidthAuto' ;
      if (data.icon === "angle") {
        newLink.innerHTML = "<span style='color:#fff; text-align:center; margin-right:0 !important' class=" + data.classname + "spanTxt>" + data.text + "</span> <i class='" + data.classname + " line-height' aria-hidden='true'></i>";
      }
      else {
        newLink.innerHTML = "<span class=" + data.classname + "spanTxt>" + data.text + "</span> <i class='" + data.classname + " line-height' aria-hidden='true'></i>";
      }
    }
    else {
      newElement.className = data.classname + ' vjs-custom-controlbar-icn baseBarIconWrapper';
      newLink.innerHTML = "<i class='bi-" + data.icon + " line-height' aria-hidden='true'></i>";
    }
    newElement.appendChild(newLink);

    controlBar = document.getElementsByClassName('vjs-control-bar')[0];
    var insertBeforeNode = document.getElementsByClassName(data.insertBeforeClass)[0];
    controlBar.insertBefore(newElement, insertBeforeNode);

    return newElement;
  }

  function IsFullScreen() {
    return !!(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement)
  }

  const enableVideoPause = () => {
    var vjsPlay = document.querySelector('.vjs-custom-plyPusebutton');
    var element = vjsPlay;
    element.classList.remove("vjs-custom-pause-button");
    element.classList.remove("vjs-custom-play-button");
    element.classList.remove("bx-play-circle");
    element.classList.remove("bx-pause-circle");
    element.classList.add("vjs-custom-play-button");
    element.classList.add("bx-play-circle");
  }

  const enableVideoPlay = () => {
    var vjsPlay = document.querySelector('.vjs-custom-plyPusebutton');
    var element = vjsPlay;
    element.classList.remove("vjs-custom-pause-button");
    element.classList.remove("vjs-custom-play-button");
    element.classList.remove("bx-play-circle");
    element.classList.remove("bx-pause-circle");
    element.classList.add("vjs-custom-pause-button");
    element.classList.add("bx-pause-circle");
  }


  const updateStatus = (currentTime, event) => {
    // content status : undefined - Not started, 1 - inprogress, 2 - completed
    if (subscribed) {
      if (contentStatus !== 2) {
        var status = event === "END" ? 2 :
          event === "PAUSE" && (contentStatus === undefined || contentStatus === 1) ? 1 :
            event === "PLAY" && (contentStatus === undefined || contentStatus === 1) ? 1 : contentStatus
        var viewing_angle = content.main_file.viewing_angle === undefined ? "Normal" : content.main_file.viewing_angle
        var info = {
          status, currentTime, viewing_angle, event
        }
        updateVideoStatus(info)
      }
      else if (event === "END") {
        var info = {
          status, currentTime, viewing_angle, event
        }
        // updateLastAccess(info)
        //loadPNVideos("NEXT")
        // up
      }
    }
    else{
      contentPreviewHistory()
    }
  }

  // const handleComponentClick = (event) => {
  //   if (event.target.classList.contains('video')) {
  //     // Handle video click
  //     console.log('Video clicked!');
  //   } else if (event.target.classList.contains('control-bar')) {
  //     // Handle control bar click
  //     console.log('Control bar clicked!');
  //   }
  // };


  if (contentLoaded) {

    return (
      <div className="video-container" style={{ textAlign: 'center', margin: '0px auto', position: 'relative' }}  >
        <video ref={playerRef} id="amazon-ivs-videojs" className="video-js vjs-theme vjs-16-9 vjs-big-play-centered" controls={!false} playsInline ></video>
      </div>
    )
  }
  else {
    return null
  }

}

export default IVSVideoPlayer