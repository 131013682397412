import React, { Component } from 'react'
// import ReactJWPlayer from 'react-jw-player'
// import uuid from "react-uuid"
import PropTypes from 'prop-types'

import ReactHlsPlayer from 'react-hls-player'

export default class VideoPlayer extends Component {

    errorHandle = (e) => {
        console.log(e)
    }

    render() {
        return (
            // <ReactJWPlayer
            //     // style={{ height: 100, width: 'auto', }}
            //     playerId={"jw-player1" + uuid()}
            //     playerScript="https://content.jwplatform.com/libraries/jvJ1Gu3c.js"
            //     onError={(e) => { this.errorHandle(e) }}
            //     onPlay={(e) => { this.props.onPlay(e) }}
            //     onPause={(e) => { this.props.onPause(e) }}
            //     onTime={(e) => { this.props.onTime(e) }}

            //     file={this.props.fullUrl}
            // />
            <ReactHlsPlayer src={this.props.fullUrl} autoPlay={false} controls={true} width="100%" height="auto" />

        )
    }
}

VideoPlayer.propTypes = {
    onPlay: PropTypes.func,
    onPause: PropTypes.func,
    onTime: PropTypes.func,
    fullUrl: PropTypes.string,
}
