import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Button, Label,Alert } from "reactstrap"
import Select from 'react-select'
import { useRef,useEffect } from 'react';
import urlSocket from 'helpers/urlSocket';
import { useHistory } from 'react-router-dom';
import CryptoJS from 'crypto-js'
import AuthCode from "react-auth-code-input"
import { LoadingOutlined, EditOutlined } from '@ant-design/icons'

const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const NewUserForm = (props) => {
    const history = useHistory()
    const [Gender, setGender] = useState('')
    const [defaultCountryCode, setdefaultCountryCode] = useState('+91 India')
    const [GenderInvalid, setGenderInvalid] = useState(false)
    const [countryCode, setCountryCode] = useState('+91')
    const [yearRange, setyearRange] = useState('')
    const [birthYearInvalid, setbirthYearInvalid] = useState(false)
    const [phoneNumberExist, setphoneNumberExist] = useState(false)
    const [emailExist, setemailExist] = useState(false)
    const [phoneNumberValid, setphoneNumberValid] = useState(false)
    const [authType, setauthType] = useState(localStorage.getItem("auth_type"))
    const [validateOtp, setvalidateOtp] = useState(false)
    const [userInfo, setuserInfo] = useState(null)
    const [Otp, setOtp] = useState("")
    const [message, setmessage] = useState('')
    const [alertEnable, setalertEnable] = useState(false)
    const [alertColor, setalertColor] = useState("")
    const [passWord,setPassword] = useState('')
    const [btnLoader,setbtnLoader] = useState(false)
    const [userEmail, setuserEmail] = useState(props.userEmail)
    const [selectedCode,setSelectedCode] = useState(false)

    useEffect(() => {
        console.log(props,'props')
        if(props.registeredUser){
            awsRegistrationUser(props.userInfo)
            setuserInfo(props.userInfo)
            setvalidateOtp(true)


        }

    }, [props.registeredUser])



    const googleRegistrationUser=(userInfo)=>{
        setbtnLoader(true)
        try {
            urlSocket.post('google/register-google-user',userInfo).then((response)=>{
                setbtnLoader(false)
                if(response.data.response_code === 500 && response.data.data.length >0){
                    var loginSession = response.data.data[0]
                    localStorage.setItem("authUser", JSON.stringify(loginSession))
                    sessionStorage.setItem("authUser", JSON.stringify(loginSession))
                    localStorage.setItem("auth_type", 2)

                    localStorage.setItem('express-session', JSON.stringify({
                        session_id: loginSession.session_id,
                        device_info: loginSession.device_info,
                    }))
                    localStorage.setItem('socialMediaLoginId', loginSession.google_info.googleId)
                    history.push("/dashboard")

                }
            })

        } catch (error) {
            setmessage("Something has went wrong , Please try again later.")
            setalertEnable(true)
            setalertColor("danger")
            fadeAlert()
        }

    }


    const confirmSignup=(events,values)=>{
        if(Otp){
            setbtnLoader(true)
            var oldPassword= "123456789"
            var ciphertext1 = CryptoJS.AES.encrypt(oldPassword, props.secretKey).toString()
            var newPassword = values.Password
            var ciphertext = CryptoJS.AES.encrypt(newPassword, props.secretKey).toString()

            try {
                urlSocket.post('sbscrmgt/cnfsgnup', { code:Otp, oldPassword :ciphertext1, newPassword:ciphertext, username:values.username, login_type:2, email:values.username, _id:userInfo._id 
                }).then((response)=>{
                    if(response.data.response_code === 504){
                        setbtnLoader(false)
                        setmessage(response.data.err)
                        setalertEnable(true)
                        setalertColor("danger")
                        fadeAlert()
                    }
                    else if(response.data.response_code === 500){
                        createUserDb(userInfo)
                    }
                })
                
            } catch (error) {
                setbtnLoader(false)
                setmessage("Something has went wrong , Please try again later.")
                setalertEnable(true)
                setalertColor("danger")
                fadeAlert()
            }
        }
        else{
            setmessage("Please Enter the OTP")
            setalertEnable(true)
            setalertColor("danger")
            fadeAlert()
        }
   }

   const createUserDb=(data)=>{

    // username
    if(!props.registeredUser){
    data["gender"]=Gender
    data["age_range"]=yearRange
    }
    var {country_code, username, login_type, email,phone_number, full_ph_no, full_name, gender, age_range, _id} = data
    var ciphertext = CryptoJS.AES.encrypt(passWord, props.secretKey).toString()
 
    try {
        urlSocket.post('sbscrmgt/create_subscr', { country_code:"+"+country_code, username, login_type, email, Password:ciphertext, phone_number, full_ph_no, full_name, gender, age_range, _id })
        .then((res) => {
            setbtnLoader(false)
            if ((res.data.response_code === 504 ||res.data.response_code === 500 )) {
                const loginSession = res.data.data.length > 0 ? res.data.data[0] : res.data.data
                sessionStorage.setItem("authUser", JSON.stringify(loginSession))
                localStorage.setItem("authUser", JSON.stringify(loginSession))
                localStorage.setItem('express-session', JSON.stringify({
                  session_id: loginSession.session_id,
                  device_info: loginSession.device_info,
                }))
                goToLandingPage(loginSession)
            }

        })
        
    } catch (error) {
        setmessage("Something has went wrong , Please try again later.")
        setalertEnable(true)
        setalertColor("danger")
        fadeAlert()
        
    }
   }





 const goToLandingPage = async (loginSession, login_msg) => {
        var courseInfo = await JSON.parse(localStorage.getItem("CourseDetails"))
        if (courseInfo) {
            let name_of_the_course = courseInfo.name_of_the_course.trim().replace(/[^A-Z0-9]\s+/ig, "").replaceAll(' ', '-');
            history.push({
                pathname: '/course/CourseDetails',
                search: '?' + courseInfo._id + '?' + name_of_the_course.toLowerCase(),
            });
        }
        else {
            history.push("/dashboard")
        }

    }


   const fadeAlert = () => {
    setTimeout(() => {
        setmessage("")
        setalertColor("")
        setalertEnable(false)
    }, 3000);
}




    const awsRegistrationUser=(userInfo)=>{
        let Password = "12345678"
        var ciphertext = CryptoJS.AES.encrypt(Password, props.secretKey).toString()
        Password = ciphertext
        userInfo["Password"] = Password
        userInfo["login_type"] = 2
        userInfo["country_code"]=props.registeredUser === true ? props.userInfo.country_code : selectedCode ? countryCode : props.selectedCountrycode !== undefined ? props.selectedCountrycode : countryCode 
        // userInfo["country_code"]=props.registeredUser === true ? props.userInfo.country_code : countryCode
        // console.log(props,'props',userInfo,countryCode)
        setbtnLoader(true)
        try {
            urlSocket.post('sbscrmgt/sgnup', userInfo)
            .then((response) => {
                console.log(response,'response')
                setbtnLoader(false)
                if(response.data.response_code === 500 && response.data.message === "Check your Registered email"){
                    var data =  response.data.data.length > 0 ? response.data.data[0] : response.data.data
                    setuserInfo(data)
                    setvalidateOtp(true)

                }

            })
            
        } catch (error) {
            setmessage("Something has went wrong , Please try again later.")
            setalertEnable(true)
            setalertColor("danger")
            fadeAlert()

        }
    }


    const handleSubmit = (event, values) => {
        if (!GenderInvalid && !birthYearInvalid && !phoneNumberExist && !emailExist) {
            values["age_range"]=yearRange
            values["year_range"]=yearRange
            values["gender"]=Gender
            values["country_code"] = selectedCode ? countryCode : props.selectedCountrycode !== undefined ? props.selectedCountrycode : countryCode 
            values["username"]=values.email
            var code = selectedCode ? countryCode : props.selectedCountrycode !== undefined ? props.selectedCountrycode : countryCode 
            values["full_ph_no"] =code + values.phone_number

            var authType = localStorage.getItem("auth_type")
            if(authType === "2"){              
                googleRegistrationUser(values)
            }
            if(authType === "1"){
                awsRegistrationUser(values)
            }
        }
    }

    const chooseCountryCode = (e) => {
        setdefaultCountryCode(e.code + " " + e.label)
        setCountryCode(e.code)
    }

    const checkMobNumExist=(phone_number)=>{
        try {
            urlSocket.post('google/check-mobile-number-exist',{phone_number,country_code:Number(countryCode) ,email :userEmail || props.userEmail}).then((response)=>{
                if(response.data.response_code === 500 && response.data.data.length >0){
                    setphoneNumberExist(true)
                }
                else{
                    setphoneNumberExist(false)
                }
            })
            
        } catch (error) {
            setmessage("Something has went wrong , Please try again later.")
            setalertEnable(true)
            setalertColor("danger")
            fadeAlert()
        }
   }


   const checkEmailExist=(email)=>{

    try {
        urlSocket.post("google/check-email-exist",{
            email,
            full_ph_no : "+"+countryCode+props.userNum
        }).then((response)=>{
            if(response.data.response_code === 500 && response.data.data.length >0){
                setemailExist(true)
            }
            else{
                setemailExist(false)
            }
        })
        
    } catch (error) {
        
    }


   }


   const validatePhoneNumber=(number)=>{
    var phoneFormat = /^([0-9]{3,})$/;
    var isPhoneFormat = phoneFormat.test(number)
    if(!isPhoneFormat && String(number).length >0){
        setphoneNumberValid(true)
    }
    else{
        setphoneNumberValid(false)
    }

   }



   if(!validateOtp){
    return (
        <AvForm className="form-horizontal" onValidSubmit={handleSubmit} >
            <div className="form-group">
                <div>
                    <div className="text-end d-flex align-items-center mb-2" >
                        <span style={{ fontSize: "12px", color: "#383838" }}>Step 2/3</span>
                    </div>

                    <div >
                        <h2 className="font-inter text-primary">Enter Details</h2>
                    </div>
                    <div className="" style={{ marginBottom: '10px', fontSize: 13 }}>Let us know more about you!</div>
                </div>
                <Row className='mt-4'>
                    <Col md={12} >
                        <Label className='inter-font m-0 fw-bold mb-2'>Full Name</Label>&nbsp;<span className='text-danger'>*</span>
                        <AvField name="full_name"
                            className={`form-control inputStyle`} placeholder="Enter Full Name" errorMessage={"Please enter Full Name"}
                            value={props.userName}
                            required
                        />
                    </Col>
                    <Col md={12} >
                        <Label className='inter-font m-0 fw-bold mb-2'>Mobile Number</Label>&nbsp;<span className='text-danger'>*</span>
                        <div className='loginInputForm'>
                            <Col md={4}>
                                <Select showSearch className="react-select-container " classNamePrefix="react-select"
                                    options={props.countryList}
                                    placeholder='Select Country Code'
                                    value={{ country_list: selectedCode ? defaultCountryCode : props.selectedCountryInfo !== undefined ?props.selectedCountryInfo:  defaultCountryCode }}
                                    onChange={(e) => { chooseCountryCode(e);setSelectedCode(true) }}
                                    getOptionLabel={(option_list) => option_list.country_list}
                                    isDisabled={props.userNum ? true : false}
                                    // disabled={props.selectedCountryInfo !== undefined}
                                    
                                />
                            </Col>
                            <Col md={8}>
                                <AvField name="phone_number"
                                    className={`inputComp`}
                                    placeholder="Enter Mobile No" errorMessage={"Please enter Mobile No."}
                                    type='number'
                                    required
                                    onChange={(e)=>{checkMobNumExist(e.target.value)}}
                                    onBlur={(e)=>{validatePhoneNumber(e.target.value)}}
                                    value={props.userNum}
                                    disabled={props.userNum ? true : false}
                                />
                            </Col>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {
                        phoneNumberExist &&
                        <p style={{ color: 'red' }}>{"Entered Phone Number Already Exist"}</p>
                    }
                    {
                        phoneNumberValid &&
                        <p style={{ color: 'red' }}>{"Entered Phone Number Should be less than 3 number"}</p>
                    }

                </Row>
                <Row className='mt-4'>
                    <Col md={12} >
                        <Label className='inter-font m-0 fw-bold mb-2'>Email Address</Label>&nbsp;<span className='text-danger'>*</span>
                        <div className='w-100' style={{ position: 'relative' }}>
                            <AvField
                                name="email"
                                required
                                className={`form-control inputStyle`}
                                placeholder="Enter Email" errorMessage={"Please enter email"}
                                onChange={(e)=>{checkEmailExist(e.target.value);setuserEmail(e.target.value)}}
                                value={userEmail || props.userEmail}
                                disabled={props.userEmail && !props.userNum ? true : false}
                                
                            />
                        </div>
                    </Col>
                </Row>
                {
                    emailExist &&
                    <p style={{ color: 'red' }}>{"Entered Email ID Already Exist"}</p>

                }
                <Row>
                    <Col md={12}>
                        <Label className={`inter-font m-0 fw-bold mb-2 ${GenderInvalid ? 'is-invalid' : 'is-valid'}`} >Gender</Label>&nbsp;<span className='text-danger'>*</span>
                        <br />
                        <input
                            type="radio"
                            id="male"
                            name="gender"
                            value="Male"
                            checked={Gender === "Male"}
                            onChange={(e) => { setGender(e.target.value); setGenderInvalid(false) }}
                            className="fontInter me-1"
                            style={{ fontSize: "14px" }}
                        />
                        <label htmlFor="male" className='me-3'>Male</label>

                        <input
                            type="radio"
                            id="female"
                            name="gender"
                            value="Female"
                            checked={Gender === "Female"}
                            onChange={(e) => { setGender(e.target.value); setGenderInvalid(false) }}
                            className="fontInter me-1"
                            style={{ fontSize: "14px" }}
                        />
                        <label htmlFor="female" className='me-3'>Female</label>

                        <input
                            type="radio"
                            id="other"
                            name="gender"
                            value="Other"
                            checked={Gender === "Other"}
                            onChange={(e) => { setGender(e.target.value); setGenderInvalid(false) }}
                            className="fontInter me-1"
                            style={{ fontSize: "14px" }}
                        />
                        <label htmlFor="other" className='me-3'>Other</label>
                    </Col>
                    {
                        GenderInvalid &&
                        <p style={{ color: 'red' }}>{"Please Fill the Gender Details "}</p>
                    }
                </Row>
                <Row className='mt-4'>
                    <Col md={12}>
                        <Label >Age Range</Label>&nbsp;<span className='text-danger'>*</span>
                        <Row>
                            <Col md={12}>
                                <select
                                    className="form-control border-primary"
                                    defaultValue={"select"}
                                    onChange={(event) => {
                                        setyearRange(event.target.value);
                                        setbirthYearInvalid(false)
                                    }}

                                >
                                    <option disabled value="select">Select</option>
                                    <option value="<10"> &#60; 10</option>
                                    <option value="10 - 16">10 - 16</option>
                                    <option value="16 - 25" >16 - 25</option>
                                    <option value="25 - 50">25 - 50</option>
                                    <option value=">50"> &#62; 50</option>
                                </select>
                            </Col>
                        </Row>
                        {
                            birthYearInvalid &&
                            <span style={{ fontSize: '80%', marginTop: '0.25 rem', color: 'red' }}>{"Please Fill the Age Range Details "}</span>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="mt-4">
                            <button disabled={btnLoader} className="btn br-5 btn-primary waves-effect waves-light w-100 " type='submit' onClick={() => { !Gender && setGenderInvalid(true) || !yearRange && setbirthYearInvalid(true) }}
                            > {btnLoader && <LoadingOutlined />}{btnLoader ? "Validating.." : "Proceed"}  </button>
                        </div>
                    </Col>
                </Row>
            </div>

        </AvForm>
    );
    }
    else{
        return(
            <AvForm 
            className="form-horizontal" 
            // onValidSubmit={this.state.func_submit} 
            onValidSubmit={confirmSignup}
            autoComplete="off"
            >
            <div className="form-group">
            <div className='mb-4'>
                <div>
                  <div className="text-end d-flex align-items-center mb-2" >
                    <span style={{ fontSize: "12px", color: "#383838" }}>Step 3/3</span>
                  </div>
                  <div className="mb-2">
                    <div style={{ position: "relative" }}>
                      <h2 className="font-inter text-primary">Sign-Up</h2>
                    </div>
                    <div className="" style={{ marginBottom: '10px', fontSize: 13 }}>Your Information is not registered with us, continue with signup</div>
                    <div className='loginInputForm' style={{ marginBottom: "30px" }}>
                          <AvField name="username" value={userEmail || props.userInfo.email } disabled={userEmail || props.userInfo.email ? true : false} className="inputComp fontInter" required errorMessage={"Please enter Mobile No / E-Mail Address"} 
                           />
                    </div>
                  </div>
                  <div>
                    <div style={{ position: "relative" }}>
                      <h5 className="font-inter">Enter OTP</h5>
                    </div>

                    <p>We have sent one time password on {userEmail}. Never share your OTP with anyone.</p>
                    <div className="text-center otpBox">
                      <AuthCode
                        characters={6}
                        className="form-control form-control-lg text-center otpInput"
                        allowedCharacters="^[0-9]"
                        required
                        inputStyle={{
                          padding: '7px',
                          borderRadius: '0.4rem',
                          fontSize: '1rem',
                          textAlign: 'center',
                          marginRight: '7px',
                          border: '1px solid rgb(206, 212, 218)',
                          textTransform: 'uppercase',
                        }}
                        onChange={(otp)=>{setOtp(otp)}}
                      />
                    </div>
                  </div>
                </div>
            </div>

            <div className="mt-2">
              <div>
                <h5 className="font-inter">Enter New Password</h5>
              </div>

              <AvField
                name="Password"
                autoComplete='new-password'
                value=""
                type="password"
                className="form-control"
                required
                placeholder="Enter New Password"
                errorMessage={"Please enter Password"}
                onChange={(e)=>{setPassword(e.target.value)}}
              />
            </div>
            <Row>
                    <Col md={12}>
                        <div className="mt-4">
                            <button className="btn br-5 btn-primary waves-effect waves-light w-100 " type='submit'
                           disabled={btnLoader}
                            >
                                {btnLoader && <LoadingOutlined />}  {btnLoader ? 'Authenticating..' : 'Verify'}
                                 {/* Verify  */}
                                 </button>
                        </div>
                    </Col>
                </Row>
                {
                                alertEnable &&
                                <Alert color={alertColor} className="mt-2" >{message}</Alert>
                            }

          </div>
          </AvForm>
        )
    }
}

NewUserForm.propTypes = propTypes;
NewUserForm.defaultProps = defaultProps;

export default NewUserForm;