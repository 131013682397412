import React from "react"
import { Redirect } from "react-router-dom"
// Authenticated
import Exams from "../pages/Exams/index"
import Landing from "../pages/Landing/index"
import UserPreference from "../pages/UserPreference/index"

import CartList from '../pages/cartList'
import CartCheckout from "../pages/cartList/CartCheckout"
import MyOrders from "../pages/cartList/MyOrders"

import MyCourseCardIndex from "../pages/MyCourses/index"
import CourseDetails from "../pages/CourseDetails/index"
// import Course from "../pages/Course/index"
// import MastersInfo from "../pages/Course/Pages/MastersInfo/index"

//Home Page
import Home from "pages/Home/index"

//About Us Page
import Aboutus from "../pages/AboutUs/index"

import VideosWatching from "../pages/Videos/Pages/VideosWatching"
import Paymentgway from "../pages/Paymentgway/index"
import Settings from "../pages/Settings/index"
import Reports from "../pages/Reports/index"
import Videos from "../pages/Videos/index"

// Authentication related pages
import Logout from "../pages/Authentication/Logout"
// import Login from "../pages/Authentication/Login"
import Login from '../pages/Authentication/Login/Login'

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import PagesTimeline from "../pages/Utility/pages-timeline"
import PagesStarter from "../pages/Utility/pages-starter"
import PagesPricing from "../pages/Utility/pages-pricing"
import PagesFaqs from "../pages/Utility/pages-faqs"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

import TermsConditions from "pages/Others/TermsConditions"
import PaymentLoader from "pages/cartList/MyCourses/Payment/PaymentLoader"
import PaymentSuccess from "pages/cartList/MyCourses/Payment/PaymentSuccess"
import InvoicesDetail from "pages/cartList/Invoices/invoices-detail"
import PrivacyAndPolicy from "pages/Others/PrivacyAndPolicy"
import Cancellation from "pages/Others/Cancellation"
import AboutUs from "pages/Others/AboutUs"
import Comingsoon from "../pages/Common/CommingSoon"
import NewLogin from '../pages/Authentication/Login/Login'



const authProtectedRoutes = [
  { path: "/dashboard", component: Landing },
  { path: "/UserPreference", component: UserPreference },
  { path: "/cart-list", component: CartList },
  { path: "/cart-checkout", component: CartCheckout },
  { path: "/my-courses", component: MyCourseCardIndex },
  { path: "/my-orders", component: MyOrders },
  { path: "/invoice", component: InvoicesDetail },
  { path: "/payment-loader", component: PaymentLoader },
  { path: "/payment-success", component: PaymentSuccess },
  { path: "/course/CourseDetails", component: CourseDetails },
  // { path: "/course/MastersInfo", component: MastersInfo },
  // { path: "/course", component: Course },  
    
  { path: "/subsvidply", component: VideosWatching },
  { path: "/paymentgway", component: Paymentgway },
  { path: "/settings", component: Settings },
  { path: "/reports", component: Reports },
  { path: "/videos", component: Videos },
  //Utility
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-pricing", component: PagesPricing },
  { path: "/pages-starter", component: PagesStarter },
  { path: "/pages-faqs", component: PagesFaqs },
  
  { path: "/pages-404", component: Pages404 },
  //About Us page
  { path: "/aboutus", component: Aboutus },
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: "*",  component: () => <Redirect to="/dashboard" /> },
  
]

const publicRoutes = [
  { path: "/home", component: Home },
  // { path: "/home", component: Comingsoon },
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  // { path: "/new-login", component: NewLogin },
  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
  // { path: "/", exact: true, component: () => <Redirect to="/home" /> },


]

const commonRoutes = [
  { path: "/termsandconditions", component: TermsConditions  },  
  { path: "/privacyandpolicy", component: PrivacyAndPolicy  },  
  { path: "/cancellation", component: Cancellation  },  
  { path: "/pages-404", component: Pages404 },
  { path: "/aboutus", component: AboutUs },
]

// const comming_soon =[
// { path: "/coming-soon", component: Comingsoon },
// // { path: "/", exact: true, component: () => <Redirect to="/comming-soon" /> },


// ]

export { authProtectedRoutes, publicRoutes,commonRoutes }