import React, { Component } from "react"
import { Container, Row, Col } from "reactstrap"
import urlSocket from '../../../helpers/urlSocket'
import { Modal } from 'antd'
import Pricing from './Component/Pricing'
import VideoPlayer from './Component/VideoPlayer'
import PropTypes from 'prop-types'
import getSubsSysInfo from '../../../common/functions'

class VideosWatching extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: "",
            baseurl: '',
            output_url: '',
            plansList: [],
            user_id: "",
            showPlans: false,
            is_free: false,
            valid_user: false,
            video_id: null,
        }
    }

    async componentDidMount() {
        await this.getSessionData()
        await this.selected_course()
        let SysInfo = await getSubsSysInfo()
        console.log(`SysInfo`, SysInfo)
    }

    getSessionData = async () => {
        var data = JSON.parse(sessionStorage.getItem("authUser"))
        console.log(`data`, data)
        var getconfsession = JSON.parse(sessionStorage.getItem("getconfsession"))
        console.log(`getconfsession`, getconfsession)
        let baseurl = getconfsession.baseurl
        await this.setState({ user_id: data._id, baseurl })
    }

    selected_course = async () => {
        var data = JSON.parse(sessionStorage.getItem("subsvidply"))
        if (data !== null) {
            // console.log('selected_course', data)
            let { description, title, language, video_info, is_free, _id } = data
            let output_url = video_info[0].output_url
            this.setState({ description, title, language, output_url, is_free: is_free === '0', video_id: _id })
            if (is_free === '0') {
                await this.validUserCheckAPI()
            } else {
                this.setState({ is_free: true })
            }
        }
    }

    validUserCheckAPI = async () => {

        try {
            let subscriber_id = this.state.user_id
            console.log(`subscriber_id`, subscriber_id)
            urlSocket.post('subscriber/validusrchk', { subscriber_id })
                .then((res) => {
                    console.log(`res`, res.data)
                    if (res.data.valid) {
                        this.setState({ showPlans: false, valid_user: true })
                    } else {
                        this.listAPICall()
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }

    listAPICall = () => {
        let user_id = this.state.user_id
        console.log('{user_id}', {user_id})
        try {
            urlSocket.post('/subscriber/subsplans', { user_id })
                .then((response) => {
                    // console.log("response", response.data.data)
                    let plansList = response.data.data
                    this.setState({ showPlans: true, plansList })
                })
                .catch(error => {
                    console.log("error", error)
                })
        }
        catch (error) {
            console.log(error)
        }
    }

    onPlay = (e) => {
        console.log(`e`, e)
        let { title, user_id, language, video_id } = this.state
        let data = { subscriber_video_id: video_id, subscriber_video_name: title, subscriber_id: user_id, language, }
        console.log(`data`, data)

        try {
            urlSocket.post('/subscriber/onplayvid', { data })
                .then((response) => {
                    console.log("response", response.data.data)
                })
                .catch(error => {
                    console.log("error", error)
                })
        }
        catch (error) {
            console.log(error)
        }

    }

    onPause = (e) => {
        console.log(`e`, e)

    }

    onTime = (e) => {
        console.log(`e`, e)

    }

    render() {
        let { showPlans, baseurl, output_url, title, description, language, plansList, valid_user, is_free } = this.state
        return (
            <React.Fragment>
                <div className="page-content" style={{ background: 'white', minHeight: '100vh' }}>
                    <Container fluid >
                        <Row>
                            {/* <Col md={6}><h4>Manage Subscriber Videos </h4></Col> */}
                            <Col md={12} style={{ textAlign: 'right' }}><button className="btn btn-primary btn-block waves-effect waves-light" onClick={() => { this.props.history.push("/videos") }}>Back</button></Col>
                        </Row>
                        <Row className="mt-2 justify-content-center">
                            <Col md={8} >
                                {
                                    (is_free || valid_user) ?
                                        <VideoPlayer fullUrl={baseurl + output_url} onPlay={(e) => { this.onPlay(e) }} onPause={(e) => { this.onPause(e) }} onTime={(e) => { this.onTime(e) }} />
                                        :
                                        null
                                }
                            </Col>
                        </Row>
                        <hr />
                        <Row className="mt-2">
                            <Col md={4}>
                                <Row className="mt-2">
                                    <Col md={12}>
                                        <h1>{title}</h1>
                                        <p>{description}</p>
                                        <p>{language}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className="price">
                            <Modal centered visible={showPlans} width={1000} footer={null} closable={false}  >
                                <Pricing plansList={plansList} />
                            </Modal>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default VideosWatching

VideosWatching.propTypes = {
    history: PropTypes.object,
}
