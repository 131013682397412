import React, { Component } from "react"
import { Container } from "reactstrap"
import urlSocket from '../../helpers/urlSocket'
import { ClockLoader } from "react-spinners"
import { css } from "@emotion/react"
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'

import pg_success from '../../assets/images/pg_success.png'

class paymentPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            content_subscription_plan: '',
            duration: '',
            duration_type: '',
            plan_id: '',
            tempid: '',
            pgdata: '',
            subscriber_id: ''
        }
    }

    componentDidMount() {
        var saveSelectedPlan = JSON.parse(sessionStorage.getItem("saveSelectedPlan"))
        var getconfsession = JSON.parse(sessionStorage.getItem("getconfsession"))
        var data = JSON.parse(sessionStorage.getItem("authUser"))

        let content_subscription_plan = saveSelectedPlan.content_subscription_plan
        let duration = saveSelectedPlan.duration
        let duration_type = saveSelectedPlan.duration_type
        let plan_id = saveSelectedPlan._id
        let payment_gateway_url = getconfsession.payment_gateway_url
        let country_code = data.country_code
        let email = data.email
        let name = data.full_name
        let phone = data.phone_number
        var getPrice = saveSelectedPlan.price_info.filter((data) => { return String(data.country_code) === String(country_code) })
        let currency = getPrice[0].currency_name
        let amount = getPrice[0].price
        let subscriber_id = data._id

        this.setState({ country_code, currency, amount, subscriber_id, payment_gateway_url, content_subscription_plan, duration, duration_type, plan_id, email })

        let pgdata = {
            qpaypwd: "", responseurl: "", qpayid: "", orderid: "", transactiontype: "", mode: "", secure_hash: "", submerchantid: "", submerchantname: "", address: "-", city: "-", state: "-", country: "India", postal_code: "-",
            content_subscription_plan, country_code, currency, amount, email, phone, subscriber_id, name,
        }
        let otherDatas = { subscriber_id, content_subscription_plan, plan_id, currency, amount }

        this.paymentGatewayAPI(pgdata, otherDatas)
    }


    paymentGatewayAPI = (pgdata, data) => {
        try {
            urlSocket.post('pg_process/pg_temp_data', { pgdata, data, created_on: new Date() })
                .then((res) => {
                    if (res.data.response_code === 500) {
                        let tempid = res.data.data._id
                        let pgdata = res.data.data.pgdata
                        setTimeout(() => {
                            this.setState({ loading: false, tempid, pgdata })
                            this.paymentSuccessAPI()
                        }, 1000)

                    }
                })
                .catch((error) => {

                })
        } catch (error) {

        }
    }


    paymentSuccessAPI = () => {

        let { email, subscriber_id, content_subscription_plan, duration, duration_type, plan_id, amount, tempid, pgdata } = this.state
        let pg_response = { MSPReferenceID: '', MerchantOrderID: tempid, ResponseCode: '', Message: 'Approved+in+test+mode', PaymentMode: 'C', TransactionType: '', Secure_hash: '', }
        let data = { amount, content_subscription_plan, duration, duration_type, plan_id, tempid, pgdata, subscriber_id, payment_date: new Date() }

        data.email_id = email
        data.expiry_date = ''


        try {
            urlSocket.post("pg_process/pg_status", { data, pg_response })
                .then(res => {
                    if (res.data.response_code === 500) {
                        console.log(`pg_status`, res.data.data)

                        setTimeout(() => { this.setState({ loading: false }) }, 1000)

                        setTimeout(() => {
                            this.props.history.push('/subsvidply')
                        }, 3000)


                    } else {
                        console.log('else')
                    }
                })
                .catch(err => {

                })
        } catch (error) {

        }
    }


    render() {
        let { loading } = this.state
        return (
            <div className="page-content" style={{ background: 'white' }}>
                <Container fluid >

                    {
                        loading ?
                            <div >
                                <div style={{ margin: '0 auto' }} >
                                    <div className="sweet-loading">
                                        <ClockLoader color={'#2f8545'} loading={loading} css={css`display: block;  margin: 0 auto;  border-color: red;`} size={75} />
                                        <div className="text-center mt-2">
                                            <label style={{ color: 'white' }}>Loading...</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <h1>Wait</h1>
                                    <p>Payment in progress...<br />Please wait, dont close or refresh this page.</p>
                                </div>
                            </div>
                            :
                            <div>
                                <div style={{ margin: '0 auto' }} >
                                    <div className="text-center">
                                        <img src={pg_success} style={{ height: 300 }} alt={"Success"} />
                                    </div>
                                </div>
                                <div className="text-center">
                                    <p>We received your purchase request<br />It will redirect automatically or <Link to={'subsvidply'}><b><u>Click here</u></b></Link></p>
                                </div>
                            </div>

                    }
                </Container>
            </div>
        )
    }
}

export default paymentPage

paymentPage.propTypes = {
    history: PropTypes.object,
}
