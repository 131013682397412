import React,{Component} from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { map } from "lodash";
import { Button, Badge, Card, CardBody, Col, Row, Container } from "reactstrap";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.css';



import img1 from "../../../../assets/images/slider/lms_homepg_500.jpg"
import img2 from "../../../../assets/images/slider/lms_homepg_500.jpg"


class BannerSwiper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Button:true,
      configure : JSON.parse(sessionStorage.getItem('getconfsession'))
    };
  }


  render() {   

    return (
      <React.Fragment>

            <Swiper
              className="swiperOne "
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}           
              loop={true}
              modules={[Navigation, Pagination, Autoplay]} 
              navigation={true}
              spaceBetween={0}
              slidesPerView={1}              
              // onSlideChange={() => console.log('slide change')}              
              // onSwiper={(swiper) => console.log(swiper)}                        
              >
                {
                  this.state.configure?.banner_images.map((ele,idx)=>{
                    return(
                      <SwiperSlide key={idx}>
                          <div className="customSliderSection d-none d-xs-none d-sm-none d-md-block d-lg-block">
                          <img src={this.state.configure ? this.state.configure.baseurl + ele.image : img1} style={{ width: "100%", height: "auto" }} />
                        </div>

                        <div className="customSliderSection d-sm-block d-md-none">
                          <img src={this.state.configure ? this.state.configure.baseurl + ele.mobile_view_image : img1} style={{ width: "100%", height: "auto" }} />
                        </div>

                      </SwiperSlide>
                    )
                  })
                }
            </Swiper>
      </React.Fragment>
    );
  }
}

export default BannerSwiper;
