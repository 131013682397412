import React, { useEffect, useState, useRef } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Table,
    Input,
    CardTitle,
    Breadcrumb, BreadcrumbItem, Alert, UncontrolledAlert
} from "reactstrap";
import { Link } from "react-router-dom";

import urlSocket from "helpers/urlSocket";
import { HeartTwoTone } from '@ant-design/icons';
import { Empty } from "antd";
import kalakriya_logo from '../../assets/images/kalakriya_logo.png'
import razorpayLogo from '../../assets/images/razorpay_logo.jpeg'
import moment from 'moment';
import Header from "components/HorizontalLayout/Header";
import bannerImage from '../../assets/images/slider/banner-1.png'
import kLogo from "../../assets/images/k-logo.png"
// import { loadStripe } from '@stripe/stripe-js';
import _ from 'lodash'
import { LoadingOutlined } from '@ant-design/icons';
import { setCartCourseListLength } from "../../store/layout/actions";
import { useDispatch } from "react-redux";
import Swal from 'sweetalert2'

const CartList = (props) => {
    const dispatch = useDispatch()
    document.title = "Kalakriya - My Cart";
    const [addCartList, setAddCartList] = useState([])
    const [baseUrl, setBaseUrl] = useState('')
    const [annualTotal, setAnnualTotal] = useState(0)
    const [authUser, setauthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")))
    const [selectedCurrency, setSelectedCurrency] = useState('ind');
    const [basketData, setBasketData] = useState({});
    const [currentDate, setCurrentDate] = useState(new Date());
    const [showHeader, setShowHeader] = useState(false);
    const [blurLoading, setBlurLoading] = useState(false);
    const [isPopupBlockedEnabled, setIsPopupBlockedEnabled] = useState(false)
    const [disableCheckout, setdisableCheckout] = useState(false)

    useEffect(() => {
        var getconfsession = JSON.parse(sessionStorage.getItem("getconfsession"))
        let { baseurl, self_regn } = getconfsession
        setBaseUrl(baseurl)
        getCourseListAPI()
    }, []);

    const getCourseListAPI = () => {
        var subscriber_info = {}
        if (sessionStorage.getItem("authUser")) {
            subscriber_info = JSON.parse(sessionStorage.getItem("authUser"))
        }
        let country_code = subscriber_info.country_code

        try {
            urlSocket.post('customer_basket/get-user-cart-list'
                , { subscriber_id: subscriber_info._id }
            )
                .then((res) => {
                    let courseList = res.data.data
                    let basket_data = res.data.basket_data !== undefined ? res.data.basket_data[0] : []
                    if (res.data.response_code === 500) {
                        setAddCartList(courseList)
                        setBasketData(basket_data)
                        dispatch(setCartCourseListLength(courseList.length))
                        if (courseList.length > 0) {
                            setdisableCheckout(false)
                            sessionStorage.setItem('payment_type', basket_data["payment_type"])
                            const totalInrPriceAnnually = courseList.reduce((total, course) => {
                                const coursePrice = basket_data.payment_type === '0' ? course.course_price : course.usd_price;
                                return total + (coursePrice ? parseInt(coursePrice) : 0);
                            }, 0);
                            setAnnualTotal(totalInrPriceAnnually)
                        }

                    }

                })
        } catch (error) {

        }
    }


    const removeCartItem = (product) => {
        try {
            urlSocket.post('customer_basket/remove-from-customer-cart', { session_id: basketData._id, user_id: authUser._id, course_id: product.crs_referal_id, session_id: product.basket_session_id, annual_plan: product.course_price, total_amount: annualTotal }).then((response) => {

                if (response.data.response_code === 500) {
                    setShowHeader(true)
                    getCourseListAPI()
                }

            })
        }
        catch (error) {

        }
    }

    const handleCurrencyChange = async (value) => {
        setSelectedCurrency(value);
        if (value === 'ind') {
            basketData["payment_type"] = '0'
            basketData["payment_type_name"] = 'Razorpay'
        }
        else {
            basketData["payment_type"] = '1'
            basketData["payment_type_name"] = 'Stripe'
        }
        basketData["inr_plan"] = null
        basketData["subscrptn_info"] = null
        basketData["order_info"] = null
        sessionStorage.setItem('payment_type', basketData["payment_type"])
        setdisableCheckout(true)
        await updateBasketPaymentType(basketData)
    };

    const updateBasketPaymentType = (basketData) => {
        try {
            urlSocket.post('customer_basket/updt-basket-payment-type', basketData).then((response) => {
                if (response.data.response_code === 500) {
                    getCourseListAPI()
                }
            })

        } catch (error) {

        }

    }



    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script')
            script.src = src
            script.onload = () => {
                resolve(true)
            }
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script)
        })
    }



    const updateBasketInfo = (basketData, order_info, triggerType) => {

        try {
            urlSocket.post('customer_basket/updt-basket-info', {
                basketData,
                order_info,
                triggerType: triggerType
            }).then((response) => {
                if (response.data.response_code === 500) {
                    setBlurLoading(false)
                    getCourseListAPI()
                }
            })

        } catch (error) {

        }
    }

    const isPopupBlocked = () => {
        const razorpayWindow = window.open('about:blank', '_blank');
        if (!razorpayWindow || razorpayWindow.closed || typeof razorpayWindow.opener === 'undefined') {
            return true
        }
        else {
            razorpayWindow.close();
            return false
        }
    };

    const onCheckOutCourse = () => {
        setTimeout(() => {
            if (isPopupBlocked()) {
                setIsPopupBlockedEnabled(true)
            }
            else {
                setBlurLoading(true)
                let data = {
                    period: 'yearly',
                    interval: 1,
                    item: { name: basketData._id, amount: (String(annualTotal) + "00"), currency: 'INR', description: `Plan created for customer name on ${authUser.full_name} ` + new Date() },
                    notes: { crs_subscptn_plan: 'Annually', crs_subscptn_plan_id: 4 }
                }
                try {
                    urlSocket.post('customer_basket/create-razorpay-plan-one-time', { data: data })
                        .then(async (response) => {
                            // console.log(response,'response')
                            if (response.data.response_code === 500) {
                                const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
                                var data = response.data.data
                                // var subscrptn_info = response.data.subscrptn_info
                                // var plan_info = response.data.plan_info
                                var order_info = response.data.data
                                if (!res) {
                                    alert('Razorpay SDK failed to load. Are you online?')
                                    return
                                }
                                var getconfsession = JSON.parse(sessionStorage.getItem("getconfsession"))

                                const options = {
                                    key: getconfsession.razor_pay.key_id,
                                    currency: data.currency,
                                    amount: data.amount.toString(),
                                    order_id: data.id,
                                    name: 'Course Purchase',
                                    description: 'Thank you for purchasing.',
                                    image: kalakriya_logo,
                                    // subscription_id: order_info.id,
                                    handler: (response) => {
                                        // console.log(response,'response')
                                        let { razorpay_payment_id, razorpay_order_id, razorpay_signature } = response
                                        let data = { razorpay_payment_id, razorpay_order_id, razorpay_signature, crs_subscptn_plan: "Annually" }
                                        const currentUnixTimestamp = Math.floor(Date.now() / 1000);
                                        response["created_at"] = currentUnixTimestamp
                                        cartCourseSubscribed(data, response)
                                        // updateBasketInfo(basketData, order_info, "captured")
                                        sessionStorage.setItem('basket_session_id', basketData["_id"])
                                    },
                                    prefill: {
                                        name: authUser.full_name,
                                        email: authUser.email,
                                        phone_number: authUser.full_ph_no
                                    },
                                    "modal": {
                                        "ondismiss": function () {
                                            setBlurLoading(false)
                                            updateBasketInfo(basketData, order_info, "closed")
                                        }
                                    },
                                }

                                const paymentObject = new window.Razorpay(options)
                                paymentObject.open()

                                paymentObject.on('payment.failed', function (response) {
                                    updateBasketInfo(basketData, order_info, "cancelled")
                                    setBlurLoading(false)
                                });

                            }

                        }).catch((error) => {

                        })
                }
                catch (error) {

                }
            }
        }, 1000);
    }




    // const onCheckOutCourse = () => {
    //     setTimeout(() => {
    //         if (isPopupBlocked()) {
    //             setIsPopupBlockedEnabled(true)
    //         }
    //         else {
    //             setBlurLoading(true)
    //             let data = {
    //                 period: 'yearly',
    //                 interval: 1,
    //                 item: { name: basketData._id, amount: (String(annualTotal) + "00"), currency: 'INR', description: `Plan created for customer name on ${authUser.full_name} ` + new Date() },
    //                 notes: { crs_subscptn_plan: 'Annually', crs_subscptn_plan_id: 4 }
    //             }
    //             try {
    //                 urlSocket.post('customer_basket/create-razorpay-plan', { data: data })
    //                     .then(async (response) => {
    //                         if (response.data.response_code === 500) {
    //                             const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
    //                             var data = response.data.data
    //                             var subscrptn_info = response.data.subscrptn_info
    //                             var plan_info = response.data.plan_info
    //                             var order_info = response.data.data
    //                             if (!res) {
    //                                 alert('Razorpay SDK failed to load. Are you online?')
    //                                 return
    //                             }
    //                             var getconfsession = JSON.parse(sessionStorage.getItem("getconfsession"))

    //                             const options = {
    //                                 key: getconfsession.razor_pay.key_id,
    //                                 currency: data.currency,
    //                                 amount: data.amount.toString(),
    //                                 order_id: data.id,
    //                                 name: 'Course Purchase',
    //                                 description: 'Thank you for purchasing.',
    //                                 image: kalakriya_logo,
    //                                 subscription_id: subscrptn_info.id,
    //                                 handler: (response) => {
    //                                     let { razorpay_payment_id, razorpay_order_id, razorpay_signature } = response
    //                                     let data = { razorpay_payment_id, razorpay_order_id, razorpay_signature, crs_subscptn_plan: "Annually", subscription_id: subscrptn_info.id }
    //                                     const currentUnixTimestamp = Math.floor(Date.now() / 1000);
    //                                     response["created_at"] = currentUnixTimestamp
    //                                     cartCourseSubscribed(subscrptn_info, data, plan_info, response)
    //                                     updateBasketInfo(basketData, order_info, "captured")
    //                                     sessionStorage.setItem('basket_session_id', basketData["_id"])
    //                                 },
    //                                 prefill: {
    //                                     name: authUser.full_name,
    //                                     email: authUser.email,
    //                                     phone_number:authUser.full_ph_no
    //                                 },
    //                                 "modal": {
    //                                     "ondismiss": function () {
    //                                         setBlurLoading(false)
    //                                         updateBasketInfo(basketData, order_info, "closed")
    //                                     }
    //                                 },
    //                             }

    //                             const paymentObject = new window.Razorpay(options)
    //                             paymentObject.open()

    //                             paymentObject.on('payment.failed', function (response) {
    //                                 updateBasketInfo(basketData, order_info, "cancelled")
    //                                 setBlurLoading(false)
    //                             });

    //                         }

    //                     }).catch((error) => {

    //                     })
    //             }
    //             catch (error) {

    //             }
    //         }
    //     }, 1000);
    // }


    const onStripeCheckOutCourse = async () => {
        setBlurLoading(true)
        var name_of_the_product = _.map(addCartList, 'name_of_the_course')
        const concatenatedNames = name_of_the_product.join(', ');
        let data = {
            period: 'yearly',
            interval: 1,
            item: { name: basketData._id, name_of_the_product: concatenatedNames, amount: (String(annualTotal) + "00"), currency: 'USD', description: `Plan created for customer name on ${authUser.full_name} ` + new Date() },
            notes: { crs_subscptn_plan: 'Annually', crs_subscptn_plan_id: 4 },
            customer_email :authUser.email
        }
        // const stripe = await loadStripe('pk_test_51O4ee3SBl8RBtA1Z1sMrw5viL82bY2PRPABQUYAzZ19ZLh9mhKsHEIyQN2h6TOYycyxi10YgdaO9l74c2V54scd400eqp2xcgu')
        try {
            urlSocket.post('customer_basket/create-stripe-plan-one-time', data).then((response) => {
                // console.log(response,'response')
                setBlurLoading(false)
                window.location = response.data.checkout_info.url

            })
        } catch (error) {

        }
    }



    const cartCourseSubscribed = (orderInfo, payment_success) => {
        payment_success["status"] = 'captured'
        try {
            urlSocket.post('customer_basket/cart-course-subscribed-user-one-time', {
                orderInfo,
                subscriber_id: authUser._id,
                session_id: basketData._id,
                full_name: authUser.full_name,
                email: authUser.email,
                full_ph_no: authUser.full_ph_no,
                payment_info: payment_success,
                basket_data: basketData,
                userinfo: authUser,

            }).then((response) => {
                if (response.data.response_code === 500) {
                    props.history.push("/payment-success")
                }
            })

        } catch (error) {

        }
    }


    // const cartCourseSubscribed = (subscrptn_info, orderInfo, planInfo, payment_success) => {
    //     payment_success["status"] = 'captured'
    //     try {
    //         urlSocket.post('customer_basket/cart-course-subscribed-user', {
    //             subscrptn_info, orderInfo, planInfo,
    //             subscriber_id: authUser._id,
    //             session_id: basketData._id,
    //             full_name: authUser.full_name,
    //             email: authUser.email,
    //             full_ph_no: authUser.full_ph_no,
    //             payment_info: payment_success,
    //             basket_data: basketData,
    //             userinfo: authUser,

    //         }).then((response) => {
    //             if (response.data.response_code === 500) {
    //                 props.history.push("/payment-success")
    //             }
    //         })

    //     } catch (error) {

    //     }
    // }

    return (
        <React.Fragment>
            <div className="page-content " style={{ marginTop: 10 }} >
                <Container>
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h3 className='textCapitalize' style={{ fontFamily: "poppins,sans-serif", color: '' }}>{'MY CART'}</h3>
                    </div>
                    {addCartList.length > 0 &&
                        <Row className="mb-2">
                            <Col style={{ border: 0 }}>
                                <div className="btn-group gap-1" role="group">
                                    <input
                                        type="radio"
                                        className="btn-check form-check-input "
                                        id="ind"
                                        name="indregion"
                                        value="ind"
                                        checked={basketData.payment_type === '0'}
                                        onChange={() => handleCurrencyChange('ind')}
                                    />
                                    <label className="btn btn-outline-info" style={{ textAlign: "start" }} htmlFor="ind">
                                        <div className="d-flex flex-row align-items-center">
                                            <div className="quicksand-font fw-bold" style={{ fontSize: 30, lineHeight: 1 }}><i className="bx bx-rupee" /></div>
                                            <div>Accounts in INR <br />with any bank in India (INR)</div>
                                        </div>
                                    </label>
                                    <input
                                        type="radio"
                                        className="btn-check form-check-input"
                                        id="usd"
                                        name="usdregion"
                                        value="usd"
                                        checked={basketData.payment_type === '1'}
                                        onChange={() => handleCurrencyChange('usd')}
                                    />
                                    <label className="btn btn-outline-info" style={{ textAlign: "start" }} htmlFor="usd">
                                        <div className="d-flex flex-row align-items-center">
                                            <div className="quicksand-font fw-bold " style={{ fontSize: 30, lineHeight: 1 }}><i className="bx bx-dollar" /></div>
                                            <div> International <br />(USD)</div>
                                        </div>

                                    </label>
                                </div>

                            </Col>
                        </Row>
                    }
                    {
                        addCartList.length > 0 ?
                            <Row>
                                <Col xl={8}>
                                    <div className="table-responsive">
                                        <Table className="table align-middle mb-0 " responsive>
                                            <thead style={{ borderBottom: "1px solid #dedede" }}>
                                                <tr>
                                                    <th> <h5 className="text-truncate quicksand-font fw-bold mb-1" style={{ lineHeight: 1, margin: 0 }}>Products</h5></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {addCartList?.map((product, index) => (
                                                    <tr key={product._id} style={{ borderBottom: "1px solid #dedede" }}>
                                                        <td>
                                                            <div className="d-flex flex-row gap-3 align-items-center my-2">
                                                                <div >
                                                                    <h2 className="text-truncate quicksand-font fw-bold mb-1" style={{ lineHeight: 1, margin: 0 }}>{index + 1}</h2>
                                                                </div>
                                                                <div style={{ width: 120 }}>
                                                                    <img
                                                                        src={baseUrl + product.crs_thumbnail}
                                                                        alt="product-img"
                                                                        title="product-img"
                                                                        style={{ width: 100, }}
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-row w-100 justify-content-between align-items-center ">
                                                                    <div className="d-flex flex-column" style={{maxWidth:'450px'}}>
                                                                        <h5 className="quicksand-font fw-bold mb-1" style={{ lineHeight: 1, margin: 0 }}>
                                                                                {product.name_of_the_course}
                                                                        </h5>
                                                                        <div className="quicksand-font fw-bold text-info">
                                                                        {basketData.payment_type === '0' ? "₹" : "US$"}
                                                                            {/* <i className={basketData.payment_type === '0' ? "bx bx-rupee" : "bx bx-dollar"} /> */}
                                                                            {basketData.payment_type === '0' ? product.course_price : product.usd_price}</div>
                                                                            
                                                                            <div style={{ fontSize: 13 }}>1 Year Online Access Charges</div>
                                                                        
                                                                        {/* <div style={{ fontSize: 13 }}>{product.crs_subscptn_plans.find(plan => plan.crs_subscptn_plan === 'Annually').crs_subscptn_plan}</div> */}
                                                                    </div>
                                                                    <div>
                                                                        <span
                                                                            to="#"
                                                                            onClick={() => removeCartItem(product)}
                                                                            className="action-icon text-danger"
                                                                            title="Delete from cart"
                                                                            style={{ cursor: 'pointer' }}

                                                                        >
                                                                            {" "}
                                                                            <i className="mdi mdi-trash-can font-size-18" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                    <Row className="mt-4">
                                        <Col sm="6">
                                            <Link
                                                to="/dashboard"
                                                className="btn btn-primary btn-sm"
                                            >
                                                <i className="mdi mdi-arrow-left me-1" /> Continue
                                                Shopping{" "}
                                            </Link>
                                        </Col>
                                    </Row>
                                    {/* <Row className="mt-4">
                                        <Col sm="12">
                                            <span style={{fontSize:"13px",fontWeight:"bold"}}>Subscription Terms and Conditions :</span><br/>
                                            <span style={{fontSize:"13px"}}><b style={{fontWeight:"bold"}}>Subscription Services:</b></span> <span >The Service provided by the Company is only accessible through a paid subscription. Users are required to purchase a subscription plan to access and use the Service.</span><br/>
                                            <span style={{fontSize:"13px"}}><b style={{fontWeight:"bold"}}>No Downloadable Content:</b></span> <span >The Service provided by the Company is strictly for online streaming and access. Users are not permitted to download or store any content provided through the Service.</span>
                                        </Col>
                                    </Row> */}
                                </Col>
                                <Col xl="4" className="p-4">
                                    <div style={{ borderBottom: "1px solid #dedede" }} className="pb-2" >
                                        <div>
                                            <div className="quicksand-font fw-bold">Total</div>
                                            <h1 className="quicksand-font fw-bold text-info align-items-center" style={{ lineHeight: 1, margin: 0, padding: 0 }}>
                                                {/* <i style={{ fontSize: 25 }} className={basketData.payment_type === '0' ? "bx bx-rupee" : "bx bx-dollar"} /> */}
                                                {basketData.payment_type === '0' ? "₹" : "US$"}{annualTotal}</h1>
                                        </div>
                                    </div>

                                    <div className="d-flex mt-3 mb-1 w-100 flex-column">
                                        <button
                                            onClick={() => { basketData.payment_type === '0' ? onCheckOutCourse() : onStripeCheckOutCourse() }}
                                            className="btn btn-success w-100 mb-2"
                                            style={{ fontSize: 20 }}
                                            disabled={disableCheckout}
                                        >
                                            <i className="mdi mdi-cart-arrow-right me-1" />{" "}
                                            Checkout{" "}
                                        </button>
                                        {
                                            <UncontrolledAlert
                                                color="danger"
                                                className="alert-dismissible fade show"
                                                role="alert"
                                                isOpen={isPopupBlockedEnabled} 
                                                toggle={() => {
                                                    setIsPopupBlockedEnabled(false)
                                                  }}
                                                  style={{fontSize:14}}
                                            >
                                                <i className="mdi mdi-block-helper me-2"></i>Popup Blocker Enabled! <br />
                                                To make payment Popup Blocker should be disabled for our website. <br />
                                                Please go to your browser settings<br />
                                                {"=>"} site / website site settings<br />
                                                {"=>"} choose allow permission<br />
                                                to enable popup windows for our website.
                                            </UncontrolledAlert>
                                        }
                                    </div>
                                </Col>
                                <Row className="mt-4">
                                        <Col sm="12">
                                            <span style={{fontSize:"15px",fontWeight:"bold"}}>Subscription Terms and Conditions :</span><br/>
                                            <span style={{fontSize:"13px"}}><b style={{fontWeight:"bold"}}>Subscription Services:</b></span> <span >The Service provided by the Company is only accessible through a paid subscription. Users are required to purchase a subscription plan to access and use the Service.</span><br/>
                                            <span style={{fontSize:"13px"}}><b style={{fontWeight:"bold"}}>No Downloadable Content:</b></span> <span >The Service provided by the Company is strictly for online streaming and access. Users are not permitted to download or store any content provided through the Service.</span>
                                        </Col>
                                    </Row>
                            </Row>
                            :
                            <div className="border d-flex flex-column align-items-center justify-content-center">
                                <div style={{ fontSize: 100, lineHeight: 1 }} ><i className="bx bx-cart" style={{}} /></div>
                                <div className="mb-5">Your cart is empty</div>
                                <img src={kLogo} alt="magarisa_logo" style={{ width: '300px', height: 'auto', marginBottom: 20 }} />
                                <Row className="mt-1">
                                    <Col sm="12" className="d-flex justify-content-center align-items-center">
                                        <Link
                                            to="/dashboard"
                                            className="btn btn-primary btn-lg"
                                        >
                                            <i className="mdi mdi-arrow-left me-1" />Continue Shopping{" "}
                                        </Link>
                                    </Col>
                                </Row>
                            </div>}
                    {blurLoading && (
                        <>
                            <div className="loader-overlay">
                                <div className="loader">
                                    <LoadingOutlined />
                                </div>
                            </div>
                        </>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CartList;
