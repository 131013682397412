import React, { Component } from "react"
import { Container } from "reactstrap"
import MetaTags from 'react-meta-tags';
import { Button,  Col,  PaginationItem,   PaginationLink,  Row, Badge, Table, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom";
import urlSocket from "helpers/urlSocket";
import { map, reject } from "lodash";
import logoLight from '../../../../assets/images/kalakriya_logo.png'
import {setCartCourseListLength} from '../../../../store/actions'
import { useDispatch, connect } from "react-redux";
import moment  from 'moment'

class PaymentSuccess extends Component {
  constructor(props) {
    super(props)
    this.state={
      show_button: true,
      showButton: false,
      authUser : JSON.parse(sessionStorage.getItem("authUser")),
      dataLoaded: false
    }
}

async componentDidMount(){
  var payment_type = sessionStorage.getItem('payment_type')
    if(payment_type){
    var basket_session_id= sessionStorage.getItem('basket_session_id')
    var basket_data = await this.getBasketData(basket_session_id,false)
    this.setState({
      basketData:basket_data,
    },()=>{
      this.setState({
        dataLoaded : true
      })
      sessionStorage.removeItem('payment_type')
      this.getAllCourseList()
    })
    }
    else{
      this.props.history.push('/dashboard')
    }

}


getAllCourseList = async () => {
  var subscriber_info = {}
  if (sessionStorage.getItem("authUser")) {
      subscriber_info = JSON.parse(sessionStorage.getItem("authUser"))
  }
  try {
      urlSocket.post('mngsubcrs/retrive-all-courses', {subscriber_id: subscriber_info._id})
          .then((res) => {
              let data = res.data.data
              let cart_list = res.data.cart_list
              const coursesInCartCount = data.reduce((count, category) => {
                  const coursesWithCart = category.courses.filter(course => course.added_cart);
                  return count + coursesWithCart.length;
                }, 0);
                this.props.dispatch(setCartCourseListLength(coursesInCartCount));                   

          })
  } catch (error) {

  }
}



getBasketData=(basket_session_id,encrypted_status)=>{

  return new Promise((resolve,reject)=>{
    try {
      urlSocket.post('customer_basket/get-customer-basket-after-success', { basket_session_id: basket_session_id, encrypted_status: encrypted_status }).then((response) => {
        if(response.data.response_code ===500){
            resolve(response.data.data)
          }     
      })
    }
    catch (error) {
      reject(error)
    }
  })

}


  BackToCourse = () => {
    var course_info_id = JSON.parse(sessionStorage.getItem("CourseDetails"))
    var course_info_name = JSON.parse(sessionStorage.getItem("CourseName"))
    var course_name = course_info_name.name_of_the_course.trim().replace(/[^A-Z0-9]\s+/ig, "").replaceAll(' ', '-')
    document.location.href = ("/course/CourseDetails" + '?' + course_info_id + '?' + course_name.toLowerCase())
  }

  render() {
    const { authUser, basketData, dataLoaded } = this.state
    if (dataLoaded) {
      return (
        <React.Fragment>
          <div className="page-content">
            <Container >
              <div>
              <Row className="justify-content-end mb-3">
                <div>
                  <Link
                    to="/dashboard"
                    className="btn btn-primary btn-md"
                  >
                    <i className="mdi mdi-arrow-left me-1" />Back to Continue Shopping{" "}
                  </Link>
                </div>
              </Row>
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <div className="invoice-title">
                          <span className="float-end font-size-11">Order ID : <span className="font-size-15" style={{ fontWeight: 500 }}>{basketData.order_id}</span></span>
                          <div className="auth-logo mb-4">
                            <img src={logoLight} alt="logo-light" className="logo-light-element" height="40" />
                          </div>
                        </div>
                        <hr />
                        <Row>
                          <Col sm="6">
                            <address>
                              <strong>Billed To:</strong>
                              <br />
                              <span className="font-size-11">Name : <span className="font-size-15" style={{ fontWeight: 500 }}>{authUser.full_name}</span></span>
                              <br />
                              <span className="font-size-11">Email : <span className="font-size-15" style={{ fontWeight: 500 }}>{authUser.email}</span></span>
                              <br />
                              <span className="font-size-11">Phone : <span className="font-size-15" style={{ fontWeight: 500 }}>{authUser.full_ph_no}</span></span>
                              <br />
                            </address>
                          </Col>
                          <Col sm="6" className="text-sm-end">
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="6" className="mt-3">
                            <address>
                              <strong>Payment Method:</strong>
                              <br />
                              <img src={basketData.payment_type === "0" ? "https://d3pnv0bkd16srd.cloudfront.net/lms-demo/2d444dc-2d86-b27-7ef8-1d02540ec5fcontent.png" : "https://d3pnv0bkd16srd.cloudfront.net/lms-demo/ffedfc6-b81a-36b-e17c-7bfc078ad32content.png"} height={basketData.payment_type === "0" ? 25 : 45} />
                              <br />
                              <span className="font-size-11">{basketData.payment_type === "0" ? "Payment" : "Payment Intern"}  ID : <span className="font-size-15" style={{ fontWeight: 500 }}>{basketData.payment_type === "0" ? basketData.payment_info.razorpay_payment_id : basketData.payment_info.invoice_id}</span></span><br/>
                            {/* {
                              basketData.subscr_cycle !== null ?
                              <>
                              <span className="font-size-11">Renewed for year <strong>{basketData.subscr_cycle}</strong></span><br/>
                              </>
                              :
                              <>
                              <span className="font-size-11">Renewed for year <strong>1</strong></span><br/>
                              </>

                            } */}
                              {/* <span className="font-size-11">Subscription period : <strong>{moment(basketData.payment_info.created_at * 1000).format('DD-MMM-YYYY')} - {(() => {
                                const originalDate = new Date(basketData.payment_info.created_at * 1000);
                                const newDate = new Date(originalDate);
                                newDate.setFullYear(originalDate.getFullYear() + 1);
                                newDate.setDate(newDate.getDate() - 1);
                                return moment(newDate).format('DD-MMM-YYYY');
                              })()}</strong></span> */}
                            </address>
                          </Col>
                          <Col sm="6" className="mt-3 text-sm-end">
                            <address>
                              <strong>Order Date:</strong>
                              <br />
                              {new Intl.DateTimeFormat('en-US', {
                                day: 'numeric',
                                month: 'short',
                                year: 'numeric'
                              }).format(new Date(basketData.order_info?.created_at * 1000))}
                              <br />
                              <span style={{fontSize:'13px'}} className='text-primary'>(Date as per Indian Standard Time.)</span>
                              <br />
                            </address>
                          </Col>
                        </Row>
                        <div className="py-2 mt-3">
                          <h3 className="font-size-15 fw-bold">Order summary</h3>
                        </div>
                        <div className="table-responsive">
                          <Table className="table-nowrap">
                            <thead>
                              <tr>
                                <th style={{ width: "70px" }}>No.</th>
                                <th>Item</th>
                                <th className="text-end">Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {map(
                                basketData.customer_products,
                                (item, key) => (
                                  <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>
                                      <div>{item.name_of_the_course}</div>
                                      <div style={{fontSize:"13px"}}>Course Access Validity - 1 year</div>
                                      <div style={{fontSize:"13px"}}>Course Access Period - {moment(basketData.payment_info.created_at * 1000).format('DD-MMM-YYYY')} - {(() => {
                                const originalDate = new Date(basketData.payment_info.created_at * 1000);
                                const newDate = new Date(originalDate);
                                newDate.setFullYear(originalDate.getFullYear() + 1);
                                newDate.setDate(newDate.getDate() - 1);
                                return moment(newDate).format('DD-MMM-YYYY');
                              })()}</div>

                                      </td>
                                    <td className="text-end">{basketData.payment_type === '0' ? (
                                      <i className="bx bx-rupee" />
                                    ) : (
                                      <>US<i className="bx bx-dollar" /></>
                                    )}{basketData.payment_type === '0' ? item.inr_price : item.usd_price}</td>
                                  </tr>
                                )
                              )}
                              <tr>
                                <td colSpan="2" className="text-end">
                                  Quantity
                                </td>
                                <td className="text-end">
                                  {basketData.customer_products.length}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="2" className="text-end">
                                  Sub Total
                                </td>
                                <td className="text-end">{basketData.payment_type === '0' ? (
                                  <i className="bx bx-rupee" />
                                ) : (
                                  <i className="bx bx-dollar" />
                                )}{' '}{basketData.order_info.amount / 100}</td>
                              </tr>
                              <tr>
                                <td colSpan="2" className="border-0 text-end">
                                  <strong>Status</strong>
                                </td>
                                <td className="border-0 text-end">
                                  <Badge className={"font-size-12 badge-soft-" + (basketData.status === "2" ? "success" : "danger")} >
                                    {basketData.status === "2" ? "Payment Completed" : "Payment Pending"}
                                  </Badge>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="2" className="border-0 text-end">
                                  <strong>Total</strong>
                                </td>
                                <td className="border-0 text-end">
                                  <h4 className="m-0">
                                    {basketData.payment_type === '0' ? (
                                      <i className="bx bx-rupee" />
                                    ) : (
                                      // <i className="bx bx-dollar" />
                                      <>US<i className="bx bx-dollar" /></>
                                    )}{' '}{basketData.order_info.amount / 100}
                                  </h4></td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </React.Fragment>
      )
    } else {
      return null
    }
  }
}
const mapStateToProps = (state) => ({
});
export default connect(mapStateToProps)(PaymentSuccess)



