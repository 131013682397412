import urlSocket from "helpers/urlSocket";


const validateSession = (express_session,auth_type,socialMediaLoginId) => {
    return new Promise((resolve, reject) => {
        try {
            urlSocket.post('handle-session/validate-session',{express_session,auth_type,socialMediaLoginId}).then((response)=>{
                if(response.data.response_code === 500){
                    resolve(true)
                    if(response.data.data.length === 0){
                        sessionStorage.removeItem('authUser')
                        localStorage.removeItem('auth_type')
                        localStorage.removeItem('authUser')
                        localStorage.removeItem('socialMediaLoginId')

                    }
                    else if(JSON.parse(sessionStorage.getItem("authUser")) === null){
                        sessionStorage.setItem('authUser', JSON.stringify(response.data.data[0]))
                    }
                }
                else{
                    reject(false)
                }
            })

        } catch (error) {
            console.log(error,'error')
            reject(false)

        }
    })




}

export default validateSession