import React from 'react'
import { Row, Col, Button } from "reactstrap"
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'

export default function Pricing({ plansList }) {

    const saveSelectedPlan = (data) => {
        sessionStorage.removeItem("saveSelectedPlan")
        sessionStorage.setItem("saveSelectedPlan", JSON.stringify(data))
    }

    return (
        <Row className="justify-content-center">
            <div className="d-flex justify-content-md-evenly mb-3">
                <h3 className="text-light text-center">You do not have an active content subsription plan! Please subscribe a plan to continue </h3>
                <Link to="videos" className="card-link" ><Button color="success">Back</Button></Link>
            </div>
            {
                plansList.map((data, index) => (
                    <Col md={3} sm={6} key={index} >
                        <div className={`pricingTable ${index === 0 ? 'green' : index === 1 ? 'blue' : index === 2 ? '' : 'red'}`}>
                            <div className="pricingTable-header">
                                <i className={`${index === 0 ? 'fa fa-briefcase' : index === 1 ? 'fa fa-adjust' : index === 2 ? 'fa fa-briefcase' : ''}`} ></i>
                                <div className="price-value">{data.price_info[0].price} <span className="month">{data.duration}-{data.duration_type}</span> </div>
                            </div>
                            <h3 className="heading">{data.content_subscription_plan}</h3>
                            <div className="pricing-content">
                                <ul>
                                    <li><b>500+ </b> Unlimited Content</li>
                                    <li><b>{5 + (index + 1) * 10}</b> 4K Videos</li>
                                    <li><b>{5 + index}</b>Users</li>
                                </ul>
                            </div>
                            <div className="pricingTable-signup">
                                <Link to="paymentpage" onClick={() => { saveSelectedPlan(data) }} >Pay Now</Link>
                            </div>
                        </div>
                    </Col>
                ))
            }
        </Row>
    )
}

Pricing.propTypes = {
    plansList: PropTypes.array,
}
