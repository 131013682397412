import React, { useState } from 'react';
import { Card, Button, Space } from 'antd';
import { Progress, Spinner, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"

import { HeartTwoTone } from '@ant-design/icons';
import PropTypes from 'prop-types';
import noImg from 'assets/images/no-img.jpg';
import ribbon from 'assets/images/icons/combo.png';
import urlSocket from 'helpers/urlSocket';
import { Badge } from 'reactstrap'
import moment from 'moment';
import ShareButtons from "common/ShareButton"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSelector, useDispatch } from 'react-redux';

import baseDomainURL from 'helpers/baseDomainURL';

const { Meta } = Card;

const textStyle = {
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};


function HomeCourseCard({
    image,
    title,
    description_of_the_course,
    courseDetails,
    no_of_content_completed,
    total_percentage,
    isLogedUser,
    selected_course,
    baseurl,
    description,
    handleAddToCart,
    removeFromCart,
    viewCourse,
    btn_loader

}) {

    const [showShareIcons, setShowShareIcons] = useState(false)
    const [buttonHandleClick, setButtonHandleClick] = useState(false)
    const [buttonHandleRemoveClick, setbuttonHandleRemoveClick] = useState(false)

    let completed_content = no_of_content_completed;
    let completed_percentage = 0;
    let calculate = () => {
        countContent();
        completed_percentage =
            completed_content !== 'Not Registered'
                ? parseInt((completed_content / total_percentage) * 100)
                : 'Not Registered';
    };

    calculate();

    // const {btnLoad} = useSelector((state)=> state.L)

    const timeConvert = (n) => {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        return rhours + " hr & " + rminutes + " mints";
    }

    function toHoursAndMinutes(totalSeconds) {
        if (!totalSeconds) return ""
        const secs = totalSeconds;
        const duration = moment.duration(secs, 'seconds');
        const result = `${Math.floor(duration.asHours())} hrs ${duration.minutes()} min`;
        return result

    }

    function padTo2Digits(num) {
        return num.toString();
    }


    async function countContent() {
        completed_content =
            no_of_content_completed !== null && no_of_content_completed !== undefined
                ? no_of_content_completed
                : 'Not Registered';
    }

    let courseCompletedPercentage = selected_course.is_subscribed ? ((selected_course.no_of_content_completed / selected_course.no_of_content) * 100) : 0
    // var shareURL = baseDomainURL + "course/CourseDetails?" + selected_course._id + "?" + selected_course.name_of_the_course
    let name_of_the_course = selected_course.name_of_the_course.trim().replace(/[^A-Z0-9]\s+/ig, "").replaceAll(' ', '-');
    var shareURL = baseDomainURL + "course/CourseDetails?" + selected_course._id + "?" + name_of_the_course.toLowerCase()
    return (
        <Card style={{ backgroundColor: "#f8f8f8", border: "none", }}
            bodyStyle={{ padding: "0", marginTop: 5, }}
            cover={
                <div style={{ cursor: "pointer" }}
                    onClick={() => {
                        viewCourse()
                    }} >
                    {
                        selected_course.combo_crs === 1 ?
                            <div style={{ position: "absolute", top: -3, left: -3 }} >
                                <img alt='' src={ribbon} draggable='false' style={{ padding: 1, width: "50%", }} />
                            </div>
                            : null
                    }
                    <img alt='' src={selected_course.thumbnail === "" ? noImg : baseurl + selected_course.thumbnail} draggable='false' style={{ padding: 1, width: "100%", minHeight: 160 }}
                        onClick={() => {
                            viewCourse()
                        }}
                        role="button"
                    />
                </div>
            }
        >
            <div style={{ display: "flex", flexGrow: 1, flexDirection: "column", justifyContent: "flex-start", marginTop: 0 }}>

                <div style={{ minHeight: 220 }}>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div>
                            <Badge size={"sm"} color={"secondary me-2"} className='mb-2'>{selected_course.media_type === "1" ? "Video" : selected_course.media_type === "2" ? "Audio" : "Video & Audio"}</Badge>
                            {selected_course.is_multi_angle_avl === "1" && <Badge size={"sm"} color={"info"} className='mb-2 me-2'>Multiangle <i className='mdi mdi-fit-to-page-outline' /></Badge>}
                        </div>
                        <div className='d-flex flex-row align-items-center' onClick={() => { viewCourse() }} style={{ cursor: "pointer",}}>
                            <div className='me-1'><span className='text-align-end' style={{lineHeight:1 }} >More </span></div>
                            <div><span><i className='mdi mdi-arrow-right' style={{ lineHeight:1}} ></i></span></div>
                            
                        </div>
                    </div>

                    <div className='mb-2' style={{ minHeight: 60 }}>
                        <div className='text-dark'>{selected_course?.course_category?.category}</div>
                        <div className='textOverFlowEllipsisHeader' style={{ fontSize: 16, lineHeight: 1.2, fontWeight: "bold" }} role='button' onClick={() => { viewCourse(); }} >
                            {selected_course.name_of_the_course}
                        </div>
                    </div>
                    <div style={{ minHeight: 60 }}>
                        <div style={{ lineHeight: 1.2 }}>{selected_course.artist.map((item, idx) => {
                            if(idx<2){
                            return <span key={"srt" + idx} className='text-secondary font-size-13'>{item.full_name}{idx ==1 &&"..."} </span>
                            }
                           
                        })}</div>
                        <div className='d-flex flex-row align-items-center'>
                            <span className='text-secondary font-size-12' style={{ lineHeight: 1 }}>{toHoursAndMinutes(selected_course.course_duration)}</span>&nbsp;&nbsp;&nbsp;
                            <Badge size='sm' className='bg-secondary m-0'>{selected_course?.video_quality[0]?.quality}</Badge>&nbsp;                             
                            <span className='text-secondary font-size-12' style={{ lineHeight: 1 }}>{selected_course?.video_quality[0]?.ratio}</span>
                        </div>

                        <div style={{ lineHeight: 1 }}>
                            {
                                selected_course.narration_discussion === "1" ?
                                    <span style={{ lineHeight: 1 }} className='text-secondary font-size-12'>Narration in English</span> :
                                    selected_course.narration_discussion === "2" ? <span className='text-secondary font-size-12'>Discussion in English</span> :
                                        selected_course.narration_discussion === "3" ? <span className='text-secondary font-size-12'>Narration / Discussion in English</span> :
                                        selected_course.narration_discussion === "4" && <span className='text-secondary font-size-12'>Narration / Discussion in Tamil & English</span> 

                            }
                        </div>
                    </div>
                    {
                        selected_course?.is_subscribed ?
                            <>
                                {
                                    !showShareIcons &&
                                    <div className='my-2 d-flex flex-column justify-content-center'>
                                        <div className='d-flex flex-row align-items-center justify-content-between p-2'>
                                            <div className='quicksand-font ' style={{ fontSize: 15, color: "#74788d", fontWeight: "bold" }}>
                                                {selected_course.no_of_content_completed} / {selected_course.no_of_content}
                                            </div>
                                            <div className='col-auto' style={{ width: 100 }}>
                                                <Progress color="success" value={courseCompletedPercentage} barStyle={{ color: "#fff", backgroundColor: "#dedede" }} style={{ backgroundColor: "#dedede" }}>
                                                </Progress>
                                            </div>
                                            <div><Badge size={"sm"} color={"dark"} style={{ cursor: "pointer" }} className='' onClick={() => { setShowShareIcons(true) }}>Share <i className="mdi mdi-share text-white"></i></Badge></div>
                                        </div>
                                    </div>
                                }
                            </> :
                            <>
                                {
                                    !showShareIcons &&
                                    <div className='my-2 d-flex flex-column justify-content-between ' >
                                        <div className=' d-flex flex-row justify-content-between align-items-center'>
                                            <div>
                                                {/* <div><span>Annual Subscription</span></div> */}
                                                <div><span style={{fontSize:'13px'}}>1 Year Online Access Charges</span></div>
                                                <div  ><span style={{ fontWeight: "800", lineHeight: 1, color: "#0A3161" }} >₹ {selected_course.inr_price} &nbsp;&nbsp;&nbsp;</span><span style={{ fontWeight: "800", lineHeight: 1, color: "#0A3161" }} > US$ {selected_course.usd_price}</span></div>
                                            </div>
                                            <div><Badge size={"sm"} color={"dark"} style={{ cursor: "pointer" }} className='' onClick={() => { setShowShareIcons(true) }}>Share <i className="mdi mdi-share text-white"></i></Badge></div>
                                        </div>
                                    </div>
                                }
                            </>
                    }
                    {
                        showShareIcons && <div className='my-2 d-flex flex-row justify-content-between align-items-center '>
                            <div className='d-flex flex-row'>
                                <ShareButtons course_info={selected_course} shareUrl={shareURL} />
                                <div style={{ width: 22, height: 22, borderRadius: 11 }} className=' bg-dark text-white d-flex justify-content-center align-items-center '><CopyToClipboard text={shareURL}
                                // onCopy={() => this.setState({ copied: true })}
                                >
                                    <span style={{ cursor: "pointer" }}><i className='mdi mdi-content-copy' /></span>
                                </CopyToClipboard></div>
                            </div>
                            <div className='my-2' style={{ cursor: "pointer" }} onClick={() => { setShowShareIcons(false) }}>Close <i className='mdi mdi-close' /></div>
                        </div>

                    }
                </div>
                <div className='mt-1'>
                    {
                        btn_loader ?
                            <div className='mt-1 '>
                                <div className={'bg-secondary'} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", padding: "10px 20px", borderRadius: 8 }}>
                                    {btn_loader && <Spinner color='white' size={"sm"} className='me-2' ></Spinner>}
                                    <span
                                        style={{ cursor: "pointer", fontWeight: "500", color: "#fff", fontSize: 16, lineHeight: 1, marginRight: 10 }}
                                    >Loading..</span><span style={{ color: "#fff", fontSize: 19, lineHeight: 1 }}> <i className="mdi mdi-cart-variant" /> </span>
                                </div>
                            </div>
                            :

                            !selected_course.is_subscribed && selected_course.added_cart ? (
                                <div className='bg-danger' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '10px 20px', borderRadius: 8 }}>
                                    <span
                                        role={"button"}
                                        style={{ cursor: 'pointer', fontWeight: '500', color: '#fff', fontSize: 16, lineHeight: 1, marginRight: 10 }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            !btn_loader && removeFromCart()
                                        }}
                                    >
                                        Remove from Cart
                                    </span>
                                    <span style={{ color: '#fff', fontSize: 19, lineHeight: 1 }}> <i className='mdi mdi-cart-remove' /> </span>
                                </div>
                            ) : !selected_course.is_subscribed && !selected_course.added_cart ? (
                                <div className='bg-primary' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '10px 20px', borderRadius: 8 }}>
                                    <span
                                        style={{ cursor: 'pointer', fontWeight: '500', color: '#fff', fontSize: 16, lineHeight: 1, marginRight: 10 }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            !btn_loader && handleAddToCart()
                                        }}
                                    >
                                        Subscribe
                                    </span>
                                    <span style={{ color: '#fff', fontSize: 19, lineHeight: 1 }}> <i className='mdi mdi-cart-variant' /> </span>
                                </div>
                            ) : (
                                <>
                                    <div className='text-secondary' style={{ fontSize: 14, textAlign: "right", }}>Subscribed</div>
                                    <div className='text-danger' style={{ fontSize: 12, textAlign: "right", }}>Expires on {moment(selected_course.expiry_date).format("ll")}</div>
                                </>
                            )
                    }



                    {/* {

                        buttonHandleClick ?
                            <div className='mt-1 '>
                                <div className={'bg-secondary'} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", padding: "10px 20px", borderRadius: 8 }}>
                                    {btn_loader && <Spinner color='white' size={"sm"} className='me-2' ></Spinner>}
                                    <span
                                        style={{ cursor: "pointer", fontWeight: "500", color: "#fff", fontSize: 16, lineHeight: 1, marginRight: 10 }}
                                    >Loading..</span><span style={{ color: "#fff", fontSize: 19, lineHeight: 1 }}> <i className="mdi mdi-cart-variant" /> </span>
                                </div>
                            </div>
                            :

                            !selected_course.is_subscribed && selected_course.added_cart ? (
                                <div className='bg-danger' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '10px 20px', borderRadius: 8 }}>
                                    <span
                                        role={"button"}
                                        style={{ cursor: 'pointer', fontWeight: '500', color: '#fff', fontSize: 16, lineHeight: 1, marginRight: 10 }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setbuttonHandleRemoveClick(!buttonHandleRemoveClick)
                                            !buttonHandleRemoveClick && removeFromCart()
                                        }}
                                    >
                                        Remove from Cart
                                    </span>
                                    <span style={{ color: '#fff', fontSize: 19, lineHeight: 1 }}> <i className='mdi mdi-cart-remove' /> </span>
                                </div>
                            ) : !selected_course.is_subscribed && !selected_course.added_cart ? (
                                <div className='bg-primary' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '10px 20px', borderRadius: 8 }}>
                                      {buttonHandleClick  && <Spinner color='white' size={"sm"} className='me-2' ></Spinner>}
                                    
                                    <span
                                        style={{ cursor: 'pointer', fontWeight: '500', color: '#fff', fontSize: 16, lineHeight: 1, marginRight: 10 }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            console.log(btn_loader, 'btn_loader');
                                            setButtonHandleClick(!buttonHandleClick)
                                            !buttonHandleClick && handleAddToCart()
                                        }}
                                    >
                                        Subscribe
                                    </span>
                                    <span style={{ color: '#fff', fontSize: 19, lineHeight: 1 }}> <i className='mdi mdi-cart-variant' /> </span>
                                </div>
                            ) : (
                                <>
                                    <div className='text-secondary' style={{ fontSize: 14, textAlign: "right", }}>Subscribed</div>
                                    <div className='text-danger' style={{ fontSize: 12, textAlign: "right", }}>Expires on {moment(selected_course.expiry_date).format("ll")}</div>
                                </>
                            )
                    } */}






                </div>

            </div>


        </Card>
    )
}

export default HomeCourseCard
HomeCourseCard.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    description_of_the_course: PropTypes.string,
    likes: PropTypes.number,
    comp_perc: PropTypes.number,
    pending: PropTypes.number,
    courseDetails: PropTypes.func,
};
