import React, { Component } from 'react';
// import { Radio, Select} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import {
    Button, Card, Col, Container,
    Label, Nav, NavItem, NavLink, Row,
    TabContent, TabPane, CardBody, CardText, CardTitle,
    Collapse, Alert
} from "reactstrap"
import { MetaTags } from 'react-meta-tags'
import { connect } from "react-redux"
import Select from "react-select";
import toastr from "toastr"

import classnames from "classnames"
import Dropzone from "react-dropzone"
// import toastr from "toastr"
import user1 from "../../assets/images/users/avatar-1.jpg"
import user2 from "../../assets/images/users/avatar-9.png"
import urlSocket from "../../helpers/urlSocket"
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"

import Amplify, { Auth } from 'aws-amplify'
import countryList from '../Authentication/Country_codes.json'
import SweetAlert from "react-bootstrap-sweetalert"
import AuthCode from "react-auth-code-input"
import CountdownTimer from "react-component-countdown-timer"

import userImg from '../../assets/icons/user-icon.svg'
import { setCartCourseListLength, toggleRightSidebar } from '../../store/actions';
import { withTranslation } from "react-i18next"
import './Style.css'
import _ from 'lodash';
var CryptoJS = require("crypto-js")


class UserInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            showProfile: false,
            editPassword: false,
            loading: false,
            full_name: "",
            full_ph_no: "",
            email: "",
            gender: "select",
            year_range: "select",
            username: "",
            showAlert: false,
            isUpdateSuccessfull: false,
            show_email_input: true,
            show_gnrt_email_btn: true,
            enable_change_password : false

        }
    }

    componentDidMount() {
        var authuser = JSON.parse(sessionStorage.getItem("authUser"))
        console.log(authuser,'authuser')
        this.setState({
            subscriber: authuser,
            full_name: authuser.full_name,
            full_ph_no: authuser.full_ph_no,
            email: authuser.email,
            gender: authuser.gender,
            year_range: authuser.age_range,
            username: authuser.username,
            enable_change_password : authuser.email_cognito_id !== null && authuser.phone_cognito_id !== null ? true : false
        }, () => {
            this.getSessionDatas()
            this.getConfig()
        })
    }

    getSessionDatas = () => {
        try {
            var getconfsession = JSON.parse(sessionStorage.getItem("getconfsession"))
            let { baseurl, self_regn } = getconfsession
            this.setState({ baseurl, self_regn: self_regn })

        } catch (error) {

        }
    }

    getConfig = () => {
        try {
            urlSocket.post('ctlibmgt/getconf', {
                subscriber_id: JSON.parse(sessionStorage.getItem("authUser"))._id
            })
                .then((res) => {
                    let secret_key = res.data.data.secret_key
                    let temp_countryList = res.data.data.countries
                    temp_countryList.map((data, index) => {
                        temp_countryList[index].country_list = data.code + " " + data.label
                    })
                    this.props.dispatch(setCartCourseListLength(res.data.cart_count))
                    this.setState({ secret_key, temp_countryList, dataLoaded: true })

                })
                .catch((error) => {

                })
        } catch (error) {

        }
    }

    updateProfile = (event, values) => {

        this.setState({
            loading: true
        })
        values["username"] = this.state.username
        values["email"] = this.state.email
        values["full_ph_no"] = this.state.full_ph_no
        values["full_name"] = this.state.full_name
        values["year_range"] = this.state.year_range
        values["phone_number"] = this.state.phone_number
        values["gender"] = this.state.gender
        values["age_range"] = this.state.age_range
        try {
            urlSocket.post('sbscrmgt/crudprof', values)
                .then((response) => {
                    const loginSession = response.data.result[0]
                    sessionStorage.removeItem("authUser")
                    sessionStorage.setItem("authUser", JSON.stringify(loginSession))

                    if (response.data.response_code === 500) {
                        this.setState({ alertEnable: true, alertColor: 'success', message: 'Updated Sucessfully', showAlert: true, isUpdateSuccessfull: true, loading: false })

                        setTimeout(() => {
                            this.setState({ showAlert: false, showProfile: false })
                        }, 4000)

                    }
                    else {
                        this.setState({ showAlert: true, isUpdateSuccessfull: false, loading: false })
                        setTimeout(() => {
                            this.setState({ showAlert: false, showProfile: false })
                        }, 4000)
                    }
                })
                .catch(error => {
                    this.setState({ showAlert: true, isUpdateSuccessfull: false, loading: false })
                    setTimeout(() => {
                        this.setState({ showAlert: false, showProfile: false })
                    }, 4000)
                })
        }
        catch (error) {
            this.setState({ showAlert: true, isUpdateSuccessfull: false, loading: false })
            setTimeout(() => {
                this.setState({ showAlert: false, showProfile: false })
            }, 4000)
        }

    }

    onGenerateOTP = (value) => {
        let username
        if (value === 'email') {
            let login_type = 2
            username = this.state.email
            try {
                urlSocket.post('/sbscrmgt/check_user_avail', { username, login_type })
                    .then((res) => {
                        let email_found = res.data.data
                        if (email_found !== undefined) {
                            try {
                                urlSocket.post('/sbscrmgt/fgtpwd', { username, login_type })
                                    .then((res) => {
                                        let email_found = res.data.data
                                        if (res.data.response_code === 500 || res.data.response_code === 200) {
                                            this.setState({ show_otp_box: true, email_otp: true, show_mbil_input: false, show_gnrt_email_btn: false, email_input: true, dsbl_updt_pwd_btn: false })
                                        }
                                        else if (res.data.data !== undefined) {
                                            this.setState({ show_opt_box: true, func_submit: () => this.check_otp, loading: false, show_password_box: true })
                                        }
                                        else if (res.data.error.code == "LimitExceededException") {
                                            let message = "OTP limitation has been exceeded"
                                            let title = "Error"
                                            this.errorNotifation(message, title)
                                            this.setState({ show_password_box: false, otpNotSubmited: false, loading: false, showAlertEmail: true, message })
                                            setTimeout(() => {
                                                this.setState({ showAlertEmail: false })
                                            }, 4000)
                                        }
                                        else {
                                            this.setState({ show_password_box: false, otpNotSubmited: false })
                                            this.setState({ loading: false })
                                            let message = "User Not Registered"
                                            let title = "Error"
                                            this.errorNotifation(message, title)
                                        }
                                    })
                            }
                            catch (err) {

                            }

                        } else {

                            this.errorNotifation("Email Address Does not Exist", "ERROR")
                            this.setState({ loading: false })

                        }
                    })

            } catch (error) {

            }
        } else {
            let login_type = 1
            username = this.state.full_ph_no
            try {
                urlSocket.post('/sbscrmgt/fgtpwd', { username, login_type })
                    .then((res) => {
                        if (res.data.response_code === 500 || res.data.response_code === 200) {
                            this.setState({ show_otp_box: true, show_email_input: false, show_gnrt_mbil_btn: false, course_duration: 40, dsbl_updt_pwd_btn: false })
                        }
                        else {
                            this.setState({ show_password_box: false, loading: false, otpNotSubmited: false })
                            let message = "User Not Registered"
                            let title = "Error"
                            this.errorNotifation(message, title)
                        }
                    })
            }
            catch (err) {

            }
        }
    }

    errorNotifation = (message, title) => {
        toastr.options.closeDuration = 8000
        toastr.options.positionClass = "toast-bottom-center"
        toastr.error(message, title)

    }




    check_otp = () => {
        var username
        var login_type
        var code
        var password
        var phone_number
        if (this.state.email_input === true) {
            var username = this.state.subscriber.email
            var login_type = 2
            var code = this.state.otp
            var password = this.state.reset_password
            var secret_key = this.state.secret_key
            var ciphertext = CryptoJS.AES.encrypt(password, secret_key).toString()
            password = ciphertext
        }
        else {
            var username = this.state.full_ph_no
            var phone_number = this.state.phone_number
            var login_type = 1
            var code = this.state.otp
            var password = this.state.reset_password
            var secret_key = this.state.secret_key
            var ciphertext = CryptoJS.AES.encrypt(password, secret_key).toString()
            password = ciphertext
        }
        this.setState({ password_loading: true }, () => {
            try {
                urlSocket.post('/sbscrmgt/cnfnewpwd', { username, login_type, password: ciphertext, phone_number, code })
                    .then((res) => {
                        if (res.data.response_code === 500) {
                            if (res.data.data.length > 0) {
                                let loginSession = res.data.data[0]
                                sessionStorage.setItem("authUser", JSON.stringify(loginSession))
                                localStorage.setItem("authUser", JSON.stringify(loginSession))
                                this.setState({
                                    showProfile: false,
                                    show_email_input: true,
                                    alertColor: 'success',
                                    showAlert: true,
                                    isUpdateSuccessfull: true
                                })
                                setTimeout(() => {
                                    this.setState({
                                        showAlert: false, editPassword: !this.state.editPassword,
                                        show_mbil_input: true,
                                        show_gnrt_email_btn: true,
                                        show_gnrt_mbil_btn: true,
                                        show_otp_box: false,
                                        loading: false,
                                    })
                                }, 4000)
                            }
                        }
                        else if (res.data.response_code === 504) {
                            let message
                            if (res.data.error.code === "MissingRequiredParameter") {
                                message = "Please Enter the OTP"
                            }
                            else if (res.data.error.code === "InvalidParameterException") {
                                message = "Please Enter the Password"
                            }
                            else {
                                message = res.data.error.message
                            }

                            this.setState({ password_loading: false, alertColor: "danger", alertEnable: true, message })
                            setTimeout(() => { this.setState({ alertEnable: false }) }, 3000)
                        } else if (res.data.err_msg === 'unable to change password' && res.data.response_code === 504) {
                            let message = "Unable to change password"
                            this.setState({ password_loading: false, alertColor: "danger", alertEnable: true, message })
                            setTimeout(() => { this.setState({ alertEnable: false }) }, 3000)
                        }

                    }).catch((error) => {

                    })
            }
            catch (err) {
                let message = "Something went wrong Please Try Again"
                this.setState({ password_loading: false, alertColor: "danger", alertEnable: true, message, editPassword: false })
                this.setState({ alertEnable: false })
            }
        })

    }

    render() {
        if (this.state.dataLoaded) {
            return (
                <React.Fragment>
                    <MetaTags>
          <title>Kalakriya - User Profile</title>
          </MetaTags>
                    <div className="page-content " style={{ marginTop: 10 }} >
                        <Container>
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h3 className='textCapitalize' style={{ fontFamily: "poppins,sans-serif", color: '', textTransform: "uppercase" }}>{'User Profile'}</h3>
                            </div>
                            <Row>
                                <Card>
                                    <CardBody>
                                        <div
                                            className={
                                                "d-flex flex-row align-items-center justify-content-between mb-3"
                                            } >
                                            <div className='d-flex flex-row align-items-center'>
                                                <div className='me-3'>
                                                    <div className="avatar-sm">
                                                        <span className="avatar-title rounded-circle text-uppercase">
                                                            {this.state.subscriber.full_name.charAt(0)}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='text-secondary' style={{ fontSize: 18, fontWeight: "bold" }}>{this.state.subscriber.full_name}</div>
                                                    <div className='text-primary' style={{ fontSize: 13, }}>{this.state.subscriber.email}</div>
                                                </div>
                                            </div>
                                            <div>
                                                <button
                                                    type="button"
                                                    className={this.state.showProfile ? "btn btn-danger btn-label me-2" : "btn btn-primary btn-label me-2"}
                                                    onClick={() => {
                                                        this.setState({
                                                            showProfile: !this.state.showProfile,
                                                            editPassword: false,
                                                            show_email_input: true,
                                                            show_mbil_input: true,
                                                            show_gnrt_email_btn: true,
                                                            show_gnrt_mbil_btn: true,
                                                            show_otp_box: false
                                                        })
                                                    }}
                                                >
                                                    <i className={this.state.showProfile ? "mdi mdi-close label-icon" : "mdi mdi-account-edit label-icon"}></i> {this.state.showProfile ? "Close Profile" : "Edit Profile"}
                                                </button>
                                            </div>
                                        </div>
                                        {
                                            <Collapse isOpen={this.state.showProfile} style={{ borderTop: "1px solid #dedede" }} className='pt-3'>
                                                <AvForm className="form-horizontal" >
                                                    <Row className=''>
                                                        <Col md={12}>
                                                            <div className="mb-4 avLabel">
                                                                <AvField name="full_name"
                                                                    style={{ fontSize: '16px', borderRadius: '8px', padding: '10px' }}
                                                                    value={this.state.full_name} errorMessage={"Please enter Full Name"}
                                                                    label="FULL NAME"
                                                                    className="form-control fontInter border-primary"
                                                                    onChange={(e) => this.setState({ full_name: e.target.value })}
                                                                    placeholder="Enter Full Name"
                                                                    type="text"
                                                                    validate={{
                                                                        required: { value: true },
                                                                        maxLength: { value: 40, errorMessage: "Maximum 40 Characters" },
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mb-4 avLabel'>
                                                        <Col md={6}>
                                                            <Label className='form-label'>MOBILE NUMBER</Label>
                                                            <div className='d-flex align-self-center fakeTextInput border-secondary'>
                                                                <div style={{ flex: 1, opacity: 0.5, fontSize: '16px' }} className='text-start fontInter'>{this.state.full_ph_no}</div>
                                                                <div className='text-end d-flex align-items-center'>
                                                                    <i className='bx bx-check mx-2' style={{ fontSize: '24px', color: '#722C9E' }}></i>
                                                                    <span className='d-none d-sm-block' style={{ fontSize: '12px' }}>VERIFIED</span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Label className='form-label'>EMAIL ADDRESS</Label>
                                                            <div className='d-flex align-self-center fakeTextInput border-secondary'>
                                                                <div style={{ flex: 1, opacity: 0.5, fontSize: '16px' }} className='text-start fontInter'>{this.state.email}</div>
                                                                <div className='text-end d-flex align-items-center'>
                                                                    <i className='bx bx-check mx-2' style={{ fontSize: '24px', color: '#722C9E' }}></i>
                                                                    <span className='d-none d-sm-block' style={{ fontSize: '12px' }}>VERIFIED</span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={6} className='px-3 avLabel'>
                                                            <Label className='form-label'>GENDER</Label>
                                                            <br />
                                                            <div className=''>
                                                                <select
                                                                    className="form-control border-primary"
                                                                    defaultValue={this.state.gender}
                                                                    onChange={(event) => {
                                                                        this.setState({
                                                                            gender: event.target.value
                                                                        })
                                                                    }}
                                                                >
                                                                    <option disabled value="select">Select</option>
                                                                    <option value="Male"> Male</option>
                                                                    <option value="Female">Female</option>
                                                                    <option value="Other" >Other</option>
                                                                </select>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className='px-3 avLabel'>
                                                            <Label className='form-label'>AGE RANGE</Label>
                                                            <Row>
                                                                <Col md={12} style={{ position: 'relative' }}>
                                                                    <select
                                                                        className="form-control border-primary"
                                                                        defaultValue={this.state.year_range}
                                                                        onChange={(event) => {
                                                                            this.setState({
                                                                                year_range: event.target.value
                                                                            })
                                                                        }}
                                                                    >
                                                                        <option disabled value="select">Select</option>
                                                                        <option value="<10"> &#60; 10</option>
                                                                        <option value="10 - 16">10 - 16</option>
                                                                        <option value="16 - 25" >16 - 25</option>
                                                                        <option value="25 - 50">25 - 50</option>
                                                                        <option value=">50"> &#62; 50</option>
                                                                    </select>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>

                                                    <Row className='mt-4'>
                                                        <Col md={12} className='d-inline-block'>
                                                            <button
                                                                type="submit"
                                                                disabled={this.state.loading}
                                                                onClick={(e) => { this.updateProfile(e, {}) }}
                                                                className={this.state.loading ? 'fontIter btn btn-secondary me-2' : 'fontIter btn btn-primary me-2'}
                                                            ><span>{
                                                                this.state.loading &&
                                                                <LoadingOutlined />
                                                            }{this.state.loading ? 'loading' : 'Update'}
                                                                </span>
                                                            </button>
                                                            <button
                                                                className='btn btn-danger'
                                                                onClick={() => {
                                                                    this.setState({
                                                                        showProfile: false
                                                                    })
                                                                }}
                                                            >Close</button>
                                                            {
                                                                this.state.showAlert &&
                                                                <Alert color={this.state.isUpdateSuccessfull ? "success" : "danger"} role="alert" className='mt-2'>
                                                                    {this.state.isUpdateSuccessfull ? "Your information is successfully updated." : "Something went wrong. Please try again after sometime."}
                                                                </Alert>
                                                            }

                                                        </Col>
                                                    </Row>
                                                </AvForm>

                                            </Collapse>
                                        }
                                    </CardBody>
                                </Card>
                            </Row>
                            {
                                this.state.enable_change_password &&
                                <Row>
                                <Card>
                                    <CardBody>
                                        <div className='d-flex flex-row align-items-center justify-content-between mb-3 '>
                                            <div>
                                                <button
                                                    type="button"
                                                    className={this.state.editPassword ? "btn btn-danger btn-label" : "btn btn-primary btn-label"}
                                                    onClick={() => {
                                                        this.setState({
                                                            editPassword: !this.state.editPassword,
                                                            showProfile: false,
                                                            show_email_input: true,
                                                            show_mbil_input: true,
                                                            show_gnrt_email_btn: true,
                                                            show_gnrt_mbil_btn: true,
                                                            show_otp_box: false
                                                        })
                                                    }}
                                                >
                                                    <i className={this.state.editPassword ? "mdi mdi-close label-icon" : "mdi mdi-form-textbox-password label-icon"}></i> {this.state.editPassword ? "Close" : "Change Password"}
                                                </button>
                                            </div>
                                        </div>
                                        <Collapse isOpen={this.state.editPassword} style={{ borderTop: "1px solid #dedede" }} className='pt-3'>
                                            <AvForm className="form-horizontal">
                                                {
                                                    this.state.show_email_input ?
                                                        <>
                                                            <div className="form-group">
                                                                <div className='rowWrapper'>
                                                                    <Row className='mt-2'>
                                                                        <Col md={8} className="px-0 avFieldLabel">
                                                                            <AvField disabled value={this.state.email} name="username" label="EMAIL ID" autoComplete="off" className="form-control inputStyle usrFrmFlds" placeholder="Enter Email" required errorMessage={"Please enter Email "} onChange={(e) => { this.setState({ username: e.target.value, }) }} />
                                                                        </Col>
                                                                        {
                                                                            this.state.showAlertEmail &&
                                                                            <Alert color={"danger"} role="alert" className='mt-2'>
                                                                                {this.state.message}
                                                                            </Alert>
                                                                        }
                                                                        {
                                                                            this.state.show_gnrt_email_btn ?
                                                                                <Col md={4} className="align-self-center notifyTxt">
                                                                                    <Button className='prefConfirmBtn' style={{ borderRadius: '10px', paddingLeft: '15px', border: '2px solid rgb(204, 204, 204)' }} type="submit" value='email' outline onClick={(e) => { this.onGenerateOTP(e.target.value) }}> SEND OTP</Button>
                                                                                </Col>
                                                                                : null
                                                                        }

                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                                {
                                                    this.state.show_otp_box &&
                                                    <>
                                                        <Container>
                                                            <div className='rowWrapper'>
                                                                <div className='mt-4 text-center'>
                                                                    <Col md={8}>
                                                                        <h3 className="fontInter header-subtitle" style={{ fontSize: '22px' }}>Enter OTP</h3>

                                                                        {
                                                                            this.state.email_otp ?
                                                                                <>
                                                                                    <Label>Please Check email and Enter Verification Code</Label>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <Label>Please Check mobile and Enter Verification Code</Label>
                                                                                </>
                                                                        }
                                                                        <AuthCode
                                                                            characters={6}
                                                                            onChange={(otp) => this.setState({ otp })}
                                                                            className="form-control form-control-lg text-center"
                                                                            allowedCharacters="^[0-9]"
                                                                            required
                                                                            inputStyle={{ width: '45px', height: '35px', padding: '3px', borderRadius: '0.4rem', fontSize: '1rem', textAlign: 'center', marginRight: '10px', border: '1px solid rgb(206, 212, 218)', textTransform: 'uppercase', }}
                                                                        />
                                                                    </Col>
                                                                </div>
                                                            </div>
                                                            <div className='rowWrapper'>
                                                                <div className='mt-4 text-center'>
                                                                    <Col md={8}>
                                                                        <AvField name="Password" autoComplete='new-password' label="Enter New Password" value="" type="password" className="form-control inputStyle" style={{ fontSize: '15px' }} placeholder="Enter New Password" errorMessage={"Please enter Password"} onChange={(e) => this.setState({ reset_password: e.target.value })} />
                                                                    </Col>
                                                                </div>
                                                                {
                                                                    this.state.alertEnable &&
                                                                    <Alert color={"danger"} className="mt-2" >{this.state.message}</Alert>
                                                                }
                                                            </div>
                                                            <Row className='mt-4'>
                                                                <Col md={12} className='d-inline-block'>
                                                                    <button
                                                                        onClick={() => { this.check_otp() }}
                                                                        disabled={this.state.loading}
                                                                        className={this.state.loading ? 'fontIter btn btn-secondary me-2' : 'fontIter btn btn-primary me-2'}
                                                                    ><span>{
                                                                        this.state.loading &&
                                                                        <LoadingOutlined />
                                                                    }{this.state.loading ? 'loading' : 'Update Password'}
                                                                        </span>
                                                                    </button>
                                                                    {
                                                                        this.state.showAlert &&
                                                                        <Alert color={this.state.isUpdateSuccessfull ? "success" : "danger"} role="alert" className='mt-2'>
                                                                            {this.state.isUpdateSuccessfull ? "Password updated successfully." : "Something went wrong. Please try again after sometime."}
                                                                        </Alert>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </>
                                                }
                                            </AvForm>
                                        </Collapse>
                                    </CardBody>
                                </Card>
                            </Row>
                            }
                            

                        </Container>
                    </div>
                </React.Fragment>
            )
        }
        else {
            return null
        }
    }
}


const mapStateToProps = state => {
    const { layoutType, cartCourseListLength, SearchCourseList, setCartCourseListLength } = state.Layout;
    return { layoutType, cartCourseListLength, SearchCourseList, setCartCourseListLength };
};

export default connect(mapStateToProps)(UserInformation);

