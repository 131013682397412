import React, { Component } from 'react';
import { 
  FacebookShareButton, 
  TwitterShareButton, 
  LinkedinShareButton, 
  FacebookIcon, 
  TwitterIcon, 
  LinkedinIcon, 
  InstapaperShareButton, 
  InstapaperIcon, 
  WhatsappShareButton, 
  WhatsappIcon ,// Corrected the import name
  // InstagramIcon
} from 'react-share';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faInstagram } from '@fortawesome/free-brands-svg-icons';

class ShareButtons extends Component {
  constructor(props) {
    super(props)
    this.state={

    }
  }
  componentDidMount(){
  }


  render() {
    const shareUrl = this.props.shareUrl
    const title = 'Kalakriya';

    return (
      <div>
        <FacebookShareButton url={shareUrl} quote={title} className='me-2'>
          <FacebookIcon size={22} round={true} />
        </FacebookShareButton>
        {/* <InstapaperShareButton url={shareUrl} title={title} className='me-2'>
          <InstapaperIcon size={22} round={true} />
        </InstapaperShareButton> */}
        <WhatsappShareButton url={shareUrl} title={title} className='me-2'>
          <WhatsappIcon size={22} round={true} /> {/* Corrected the component name */}
        </WhatsappShareButton>
        <TwitterShareButton url={shareUrl} title={title} className='me-2'>
        <TwitterIcon size={22} round={true}/>
          </TwitterShareButton>

      
      </div>
    );
  }
}

export default ShareButtons;