import axios from 'axios'


const getSubsSysInfo = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    // console.log(res.data)
    return res.data
}



export default getSubsSysInfo
