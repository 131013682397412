import React, { Component } from "react"
import PropTypes from "prop-types"
import "react-drawer/lib/react-drawer.css"
import { Menu, Space } from 'antd';
// import Select from "react-select"

import { connect } from "react-redux"
import urlSocket from '../../helpers/urlSocket'

import { Link, useHistory } from "react-router-dom"
import { DownOutlined, CaretDownOutlined } from '@ant-design/icons';

// reactstrap
import { Button, Offcanvas, OffcanvasHeader, OffcanvasBody, Badge, DropdownToggle, Dropdown, Input } from "reactstrap"
import noCardImg from '../../assets/images/noCardImg.png'
// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import mag_logo_dark from "../../assets/images/mag_logo_dark.png"
import kalakriya_logo from '../../assets/images/kalakriya_logo.png'

import SearchDropDown from '../CommonForBoth/TopbarDropdown/SearchDropDown'
import SearchMobileView from "./SearchMobileView";


// Redux Store
import { toggleRightSidebar } from "../../store/actions"
//i18n
import { withTranslation } from "react-i18next"





class HeaderBeforeAuth extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSearch: false,
            open: false,
            myip: null,
            mycountry: null,
            position: "right",
            isLogedIn: false,
            courseList: [],
            baseurl: JSON.parse(sessionStorage.getItem('getconfsession'))?.baseurl,
            language_list: [

            ],
            searchDropDown:false,
            searchName:'',
            aboutMenuitems: [
                {
                    // document.location.href = ("/course/CourseInProgress")
                    label: <span >Team</span>,
                    key: '0',
                },
                {
                    label: <span >Vision</span>,
                    key: '1',
                },
                {
                    label: <span >Mission</span>,
                    key: '2',
                },
                {
                    label: <span >Career</span>,
                    key: '3',
                },
            ],

        }
        this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    }

    async componentDidMount() {
        // console.log("logged out")
    }

    toggle=()=> {
       
        this.setState({
            searchDropDown : !this.state.searchDropDown
        })

      }

      toggleRightCanvas() {
        this.setState({ isRight: !this.state.isRight, courseList:[], searchName:"" });
    }



    searchTerm=(value)=>{
        this.setState({
            searchName : value,
        },()=>{
        if(value.length >=3){
        try {
            urlSocket.post('mngsubcrs/search-course-list',{
                keywords:value,
                subscriber_id: null,
                page_type:2, //api condtional
            }).then((response)=>{
                console.log(response,'response')
                if (response.data.response_code === 500) {
                    var updatedData = response.data.data
                    this.setState({
                        courseList: updatedData,
                        searchDropDown: updatedData.length >0 && value.length >0 ? true : false
                    }, () => { this.courseSuggestionsMenu() })
                }
           }).catch((error)=>{
            console.log(error,'error')
           })

        } catch (error) {
            console.log(error,'error')
            
        }
     }
     else{
        // console.log("search filed is empty")
        this.setState({
            searchDropDown: false,
            courseList:[],
            searchName:""
        })
     }
    })
    }



    goToCourse = (data) => {
        localStorage.setItem("CourseDetails", JSON.stringify({ _id: data._id, name_of_the_course: data.name_of_the_course }))
        // console.log
        // this.props.history.push('/login')
        // window.location.reload('/login')
        document.location.href = '/login'
    }


    courseSuggestionsMenu = () => {
        return (
            <>
                {this.state.courseList.map((category, categoryIndex) => (
                    <Menu key={categoryIndex} mode="vertical" className="course-menu">
                        {category.courses.map((course, courseIndex) => (
                            <Menu.Item
                                key={`${categoryIndex}-${courseIndex}`}
                                onClick={() => this.goToCourse(course)}
                                className="menuItemClass"
                                style={{ borderBottom: 'solid 1px #ccc' }}
                            >
                                <div className="d-flex gap-2 ms-2" style={{ alignItems: 'center' }}>
                                    <img
                                        src={course.thumbnail !== undefined && course.thumbnail !== '' ? this.state.baseurl + course.thumbnail : noCardImg}
                                        alt={course.name_of_the_course}
                                        className="category-thumbnail"
                                        height={50}
                                    />
                                    <div className="">
                                        <h6 className="">{course.name_of_the_course}</h6>
                                        <p className="mb-0">
                                            <span className="font-size-10 ">Status : </span>
                                            <Badge
                                                className={`${course.is_subscribed ? 'badge-soft-success ms-1' : 'badge-soft-danger ms-1'}`}
                                                color={'#fbfbfb'}
                                            >
                                                {course.is_subscribed ? 'SUBSCRIBED' : 'UNSUBSCRIBED'}
                                            </Badge>
                                        </p>

                                    </div>
                                </div>
                            </Menu.Item>
                        ))}
                    </Menu>
                ))}
            </>
        );




    }






    ///////// IP ADDRESS AND LOCATION ENDS //////

    render() {
        return (
            <React.Fragment>
                <header id="page-topbar" style={{ left: 0, backgroundColor: 'white', boxShadow: '0px 1px 10px 0px rgba(0,0,0,0.3)' }}>
                    <div className="navbar-header">
                        <div className="d-flex">
                            <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation" >
                                <div className="p-2">
                                    <a href={this.state.isLogedIn ? "/dashboard" : "/home"}>
                                        <img src={kalakriya_logo} alt="magarisa_logo" style={{ width: '100px', height: 'auto' }} />
                                    </a>
                                </div>



                            </nav>
                        </div>

                        <div style={{ display: 'flex' }} id="header_search_">

                            <Dropdown isOpen={this.state.searchDropDown} toggle={this.toggle} className="dropdown d-none d-md-block" tag="li" >
                                <DropdownToggle className="header-item" tag="button" id="page-header-notifications-dropdown" style={{ display: "none" }} />
                                <form className="app-search me-3 btn header-item" onSubmit={(e) => {
                                    e.preventDefault();
                                    // Add your logic here, for example, call the searchTerm function
                                    // this.searchTerm(e);
                                }}>
                                    <div className="position-relative_search">
                                        <input
                                            type="text"
                                            style={{
                                                padding: 10,
                                                fontSize: '12px',
                                                fontFamily: 'Inter, sans-serif',
                                                borderRadius: '8px 0px 0px 8px',
                                                background: '#f2f2f2',
                                                border: 'none',
                                                width: 200,
                                            }}
                                            placeholder="Search Course, Artist, Category"
                                            value={this.state.searchTerm}
                                            onChange={(e) => this.searchTerm(e.target.value)}
                                            onClick={(e) => this.searchTerm(this.state.searchName)}
                                            // onKeyDown={(e)=>{e.key==="enter"&& e.preventDefault()}}
                                            id="page-header-notifications-dropdown"
                                        />
                                        <button
                                            className="btn"
                                            style={{
                                                background: '#f2f2f2',
                                                color: 'black',
                                                borderRadius: '0px 8px 8px 0px',
                                                border: 'none',
                                                padding: 4.1,
                                            }}

                                            type="submit"
                                        // onClick={this.toggleDropdown}
                                        >
                                            <i className="mdi mdi-magnify" style={{ fontSize: 20 }} />
                                        </button>
                                    </div>
                                </form>

                                <SearchDropDown
                                    courseList={this.state.courseList}
                                    baseurl={this.state.baseurl}
                                    loadCourse={(data) => this.goToCourse(data)}
                                />
                            </Dropdown>


                            {
                                    <div style={{ display: 'flex !important', alignSelf: 'center' }}>
                                        <Link to="/login">
                                            <Button className="magarisaBtn" outline style={{ minWidth: 80 }}>SIGN IN</Button>
                                        </Link>
                                    </div>
                            }

<div className="dropdown d-md-none d-inline-block">
                                    <button
                                        type="button"
                                        className="btn btn-sm mt-1 font-size-16 header-item"
                                        data-toggle="collapse"
                                        onClick={this.toggleRightCanvas}
                                        data-target="#topnav-menu-content"
                                    >
                                        <i className="mdi mdi-magnify " style={{ fontSize: 20 }} />
                                    </button>
                                </div>
                        </div>
                    </div>
                </header>

                <Offcanvas
                        isOpen={this.state.isRight}
                        direction="end"
                        toggle={this.toggleRightCanvas}
                        className="offCanvasNav"
                    >
                        <OffcanvasHeader toggle={this.toggleRightCanvas} style={{ borderBottom: '1px solid #ccc',}}>
                            <div >

                            <form className="" onSubmit={(e) => {
                                        e.preventDefault();
                                    }}>
                                        <div className="d-flex flex-row align-items-center" >
                                        <i className="mdi mdi-magnify me-2" style={{ fontSize: 20 }} />
                                            <Input
                                                type="text"
                                                style={{
                                                     padding: 10,
                                                    fontSize: '12px',
                                                    fontFamily: 'Inter, sans-serif',
                                                    border: 'none',
                                                    width:"250px"
                                                }}
                                                placeholder="Search Course, Artist, Category"
                                                value={this.state.searchTerm}
                                                onClick={(e) => this.searchTerm(this.state.searchName)}

                                                onChange={(e) => this.searchTerm(e.target.value)}
                                                id="page-header-notifications-dropdown"
                                            />
                                            
                                        </div>
                                    </form>

                            </div>
                        </OffcanvasHeader>
                        <OffcanvasBody>
                            <div>

                                <SearchMobileView
                                        courseList={this.state.courseList}
                                        baseurl={this.state.baseurl}
                                        loadCourse={(data) => this.goToCourse(data)}
                                    />

                            </div>

                        </OffcanvasBody>
                    </Offcanvas>

               

            </React.Fragment>
        )
    }
}

HeaderBeforeAuth.propTypes = {
    openLeftMenuCallBack: PropTypes.func,
    t: PropTypes.any,
    menuOpen: PropTypes.any,
    toggleRightSidebar: PropTypes.func,
    router: PropTypes.array,
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout
    return { layoutType }
}

export { HeaderBeforeAuth }

export default connect(mapStatetoProps, { toggleRightSidebar, })(
    withTranslation()(HeaderBeforeAuth)
)


