import React, { useEffect, useState } from 'react'
import { Collapse, } from "reactstrap"
import { Link } from "react-router-dom"
import {
    PlayCircleFilled, FileOutlined, ProfileOutlined,
    FilePdfOutlined, AudioOutlined, FormOutlined, HighlightOutlined,
    LockOutlined, CheckCircleFilled, EllipsisOutlined, RightOutlined
} from '@ant-design/icons'
import classnames from "classnames";
import moment from 'moment';


export default function CourseTree({
    onClickLink,
    course_content,
    subscribed,
    notLogedIn,
    course_flow_id,
    selected_index,
    contentUniquedId,
    selectedIndex
}) {


    const [colOpen, setColOpen] = useState("")
    const [dataUpdated, setDataUpdated] = useState(false)
    const [selectedItem, setSeletectedItem] = useState(true)

    let numbering = 0

    useEffect(() => {
        if (contentUniquedId !== selectedItem) {
            setSeletectedItem(contentUniquedId)
        }
    }, [contentUniquedId])

   const toHoursAndMinutes = (totalSeconds) => {
    if(!totalSeconds) return ""
    //    const secs = totalSeconds;
    //    const duration = moment.duration(secs, 'seconds');
    //     // console.log(duration,'duration')
    //    const result = `${Math.floor(duration.asHours())}hrs ${duration.minutes()}min`;   
    //    return result
       const secs = totalSeconds;
       const duration = moment.duration(secs, 'seconds');

       const hours = Math.floor(duration.asHours());
       const minutes = duration.minutes();
       const seconds = duration.seconds();

       const result = `${hours} hrs ${minutes} min ${seconds} sec`;

       return result;
   
    }


    let recursiveTree = (items, hindex) => {
        if (items !== undefined) {
            return items.map((data2, index3) => {
                if (data2.children !== undefined && data2.children.length !== 0) {
                    return (
                        <div className="accordion-item" key={index3 + "submenu-acrdn"} style={{ border: 0 }}>
                            <h2 className="accordion-header mb-1 fw-bold  text-secondary " id={index3 + "h1"}>
                                <button
                                    className={classnames(
                                        "accordion-button bg-secondary text-white quicksand-font mb-2",
                                        "fw-medium ",
                                        { collapsed: data2["isExpand"] }
                                    )}
                                    type="button"
                                    onClick={() => {
                                        data2["isExpand"] = !data2["isExpand"]
                                        setDataUpdated(!dataUpdated)
                                    }}
                                    style={{ cursor: "pointer", borderRadius: 10, fontSize: 17 }}
                                >
                                    {data2.title}
                                </button>
                            </h2>

                            <Collapse
                                isOpen={!data2.isExpand}
                                className="accordion-collapse"
                            >
                                <div className="accordion-body" style={{ margin: 0, padding: 0 }}>
                                    <ul className="list-group" aria-expanded="true">
                                        {
                                            data2.children !== undefined && recursiveTree(data2.children, index3 + hindex)
                                        }
                                    </ul>
                                </div>
                            </Collapse>
                        </div>


                    )
                } else {
                    numbering = numbering + 1
                    // setSeletectedItem(data2.reference_id)
                    // console.log( selectedItem , data2.unique_id,' selectedItem === data2.unique_id',selectedItem === data2.reference_id)
                   
                    return (
                        <li className='px-2 mb-1'
                            key={'child_' + data2.title + index3}
                            style={{
                                borderRadius: 10,
                                backgroundColor: selectedItem === data2.reference_id ? "#8aefc9" : "#fff",
                            }}
                        >
                           
                            <div to="#"
                                className=' d-flex flex-row align-items-center justify-content-between'
                                style={{
                                    padding: "0 !important",
                                    cursor: "pointer"
                                }}
                                // onFocus={()=>{console.log('ob blur')}}
                                // onBlur={()=>  selectedIndex(hindex)}
                                onClick={() => {
                                    if (subscribed || data2.content[0].avl_preview) {
                                        // data2["selected_pos"]=hindex
                                        onClickLink(data2, data2.reference_id)
                                        setSeletectedItem(data2.reference_id)
                                        selectedIndex(hindex)
                                    }
                                }}
                            >
                                <div className=' d-flex flex-row gap-2 align-items-center'>
                                    <h3 className='ps-2 quicksand-font m-0 p-0 text-secondary text-opacity-25' style={{ lineHeight: 1 }}>{numbering}</h3>
                                {/* {
                                    console.log(data2,'data2')
                                } */}
                                    {
                                        <div><i style={{ fontSize: 13 }} className={
                                            subscribed && (data2.content_status === undefined || data2.content_status === 0) ? 'mdi mdi-circle-outline text-secondary' :
                                                subscribed && (data2.content_status === 1) ? 'mdi mdi-circle text-warning' :
                                                    (subscribed && data2.content_status === 2) && 'mdi mdi-circle text-success'
                                        } /> </div>
                                    }
                                    <div className=' py-2'>
                                    {/* style={{textTransform: 'capitalize'}} */}
                                    <div className='quicksand-font text-dark mb-1' style={{ color: '#101820', fontWeight: 400, lineHeight:1.2, fontSize:15 }}>{data2.title}</div>
                                    <div className='quicksand-font text-secondary font-size-13' style={{ color: '#101820', fontWeight: 400, lineHeight:1.2, fontSize:15 }}>{toHoursAndMinutes(data2.crs_mins)}</div>
                                    </div>

                                </div>
                                <div className=''>
                                    {
                                        !subscribed ?
                                            <div className='d-flex align-items-center'>
                                                {
                                                    (data2.document_type === 1 && data2.content[0].avl_preview) ?
                                                        <div style={{ fontSize: 30 }} className=''><i className='mdi mdi-youtube text-secondary' /></div> :
                                                        <div className='d-flex align-items-center px-2'><LockOutlined className='py-3' /></div>

                                                }
                                            </div>

                                            :
                                            <div style={{ fontSize: data2.content[0].content_type === 1 ? 30 : 25 }} className=''><i className={subscribed && data2.content[0].content_type === 1 ? 'mdi mdi-youtube text-primary' : subscribed && data2.content[0].content_type === 2 ? 'mdi mdi-volume-source text-primary' :'mdi mdi-youtube text-secondary'} /></div>
                                    }

                                </div>
                            </div>
                        </li>
                    )
                }
            })
        } else {
            return (
                <li className='list-group-item'  >
                    <div to="#" className='listGroupItemDiv'>
                        <span to="#" className='menuStyle'>1{items.title}</span>
                        <i style={{ fontSize: 20, color: '#7900E9' }} className="bx bx-chevron-right float-end"></i>
                    </div>
                </li>
            )

        }
    }

    return <div>
        <div id="sidebar-menu" className='menuTotalBorder'>
            <div className="accordion" id="accordion">

                {
                    course_content.map((data, index) => {
                        return (
                            <div className="accordion-item" key={index + "acrdn"} style={{ border: 0, marginTop: index !== 0 && 10 }}>
                                <button
                                    // className="accordion-button bg-info text-white quicksand-font"
                                    className={classnames(
                                        "accordion-button bg-info text-white quicksand-font mb-2",
                                        "fw-bold ",
                                        { collapsed: data["isExpand"] }
                                    )}
                                    type="button"
                                    onClick={() => {
                                        data["isExpand"] = data["isExpand"] ? false : true
                                        setDataUpdated(!dataUpdated)
                                    }}
                                    style={{ cursor: "pointer", borderRadius: 10, }}
                                >
                                    <h5 className="accordion-header mb-1 text-white text-capitalize"  id={index + "h1"}>{data.title}</h5>

                                </button>

                                <Collapse
                                    isOpen={!data["isExpand"]}
                                    className="accordion-collapse"
                                >
                                    <div className="accordion-body" style={{ margin: 0, padding: 0 }}>
                                        <ul className="list-group" aria-expanded="true">
                                            {
                                                data.children !== undefined && recursiveTree(data.children, index)
                                            }
                                        </ul>
                                    </div>
                                </Collapse>
                            </div>
                        )
                    }
                    )
                }

            </div>
        </div>
    </div>
}

