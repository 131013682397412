import React, { Component } from "react"
import { Container } from "reactstrap"
import MetaTags from 'react-meta-tags';
import { Button, Col, PaginationItem, PaginationLink, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import artistImg1 from "../../assets/images/properformance-1.png"
import OurStoryImg from "../../assets/images/our-story.jpg"
import AbtImg1 from "../../assets/images/about-img-1.jpg"
import AbtImg2 from "../../assets/images/about-img-2.jpg"
import Footer from "components/HorizontalLayout/Footer";

import Certification from "pages/Certification";

import "./style.css"

class Aboutus extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    this.handleHashChange()
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this.handleHashChange);
  }

  handleHashChange = () => {
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content aboutuspage">
          <MetaTags>
            <title>About Magarisa</title>
          </MetaTags>

          <div className="aboutPageContainer">

            <div className="abtPageBg">
              <div className="abtPageBgSec"></div>
              <div className="contentContainer d-flex flex-column align-items-center justify-content-center mt-5">
                <h3 className="h3Styling text-white txShadow">Our Story</h3>
                <div className="show mt-2"><p className="storyTextNew">We are the team of Musicians, Technologists with very strong hunger to make Indian Music Learning Experience better than ever.</p></div>
                <img src={OurStoryImg} className="ourStryImg" />
                <div className="d-flex align-items-center mt-4">
                  <div className="me-3 shareText text-light">Share</div>
                  <div className="d-flex align-items-center socialIcons strySocialIcons">
                    <a href="#"> <i className='bx bx-share-alt'></i> </a>
                    <a href="#"> <i className='bx bxl-twitter'></i> </a>
                    <a href="#"> <i className='bx bxl-facebook'></i> </a>
                    <a href="#"> <i className='bx bxl-instagram'></i> </a>
                    <a href="#"> <i className='bx bxl-linkedin'></i> </a>
                    <a href="#"> <i className='bx bxl-whatsapp' ></i> </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid stryContainer pb-3">

              <div className="row align-items-center stryRow" style={{ background: "#F2F5F7" }} id='Team'>
                <Container>
                  <Row>
                    <div className="col-md-12 stryTitCon text-center">
                      <h3 className="abtH3">Our Founding</h3>
                      <p className="abtp abtpleft">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad.</p>
                    </div>
                  </Row>
                </Container>
              </div>

              <div className="row align-items-center stryRow" style={{ background: "#F8F8F8" }} id='Vision'>
                <Container className="abtstxtcntnr" style={{ maxWidth: "846px" }}>
                  <Row>
                    <div className="col-md-12 stryTitCon text-center">
                      <h3 className="abtH3">Our Vision</h3>
                    </div>
                    <div className="col-md-6 stryImgCon d-flex align-items-center justify-content-end">
                      <img src={AbtImg1} style={{ maxWidth: '432px', margin: '0 auto' }} />
                    </div>
                    <div className="col-md-6 d-flex align-items-center stryTextCon">
                      <p className="abtp abtpleft">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                  </Row>
                </Container>
              </div>

              <div className="row align-items-center stryRow" style={{ background: "#fff" }} id="Mission">
                <Container className="abtstxtcntnr" style={{ maxWidth: "900px" }}>
                  <Row>
                    <div className="col-md-12 stryTitCon text-center">
                      <h3 className="abtH3">Our Mission</h3>
                    </div>
                    <div className="col-md-6 d-flex align-items-center stryTextCon">
                      <p className="abtp abtpleft">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div className="col-md-6 stryImgCon d-flex align-items-center justify-content-start">
                      <img src={AbtImg2} style={{ maxWidth: '429px', margin: '0 auto' }} />
                    </div>
                  </Row>
                </Container>
              </div>

            </div>
            <div className="proPerformances" id="Career">
              <div className="imageContainer">
                <img src={artistImg1} className="artistImage" />
                <div className="masPerText">
                  <Button className="primaryMagarisaBgColorOutline proSubscribeBtn">Subscribe Today</Button>
                  <div className="registerNowTxt show mt-2 mb-2 storyText"><p>for complementary Mastro Performances</p></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />

      </React.Fragment>
    )
  }
}

export default Aboutus



