import React, { Component } from "react"
import { Container } from "reactstrap"
import MetaTags from 'react-meta-tags'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
class Cancellation extends React.Component {
constructor(props) {
    super(props);

    this.state = {
    };
}

    render() {
        return (
            <React.Fragment>
              <div className="page-content">
                <MetaTags>
                  <title>Kalakriya - Cancellation and Refund Policy</title>
                </MetaTags>
                <Container>
                  {/* Render Breadcrumbs */}
                  <Breadcrumbs title="Cancellation and Refund Policy" breadcrumbItem="Cancellation and Refund Policy" />
                        <h5>Cancellation</h5>
                        <div style={{textAlign:"justify"}}>
                        For subscription plans, a cancellation period of 7 days from the date of purchase applies if the client does not start availing of our services. The current subscription is non-terminable after 7 days of purchase. However, the client can cancel a further subscription by providing 7 days notice before the start of the next subscription period. Next subscription fees fall due immediately after the end of the validity period of the existing subscription. Upon cancellation, the eligible amount will be refunded to the same account which the client used to pay originally, within 10-15 working days from the date of receipt of the cancellation request from the client.

We may change the price for subscriptions from time to time and will communicate any price changes to the customer/client. Price changes for subscriptions will take effect at the start of the next subscription period following the date of the price change. By continuing to use the subscription after the price change takes effect, it is understood that the customer/client accepts the new price. Please note you will not receive a refund for the unused portion of your current billing period. However, you will still retain access to the subscription benefits until the end of your current billing period. For example, if you have a yearly subscription plan and you cancel it mid way after a couple of months, you will continue to have access to the subscription content and benefits until the end of the current billing date.
                            {/* For subscription plans, a cancellation period of 7 days from the date of purchase applies if the client does not start availing of our services. The current subscription is non-terminable after 7 days of purchase. However, the client can cancel a further subscription by providing 7 days notice before the start of the next subscription period. Next subscription fees fall due immediately after the end of the validity period of the existing subscription. Upon cancellation, the eligible amount will be refunded to the same account which the client used to pay originally, within 10-15 working days from the date of receipt of the cancellation request from the client.

                            We may change the price for subscriptions from time to time and will communicate any price changes to the customer/client. Price changes for subscriptions will take effect at the start of the next subscription period following the date of the price change. By continuing to use the subscription after the price change takes effect, it is understood that the customer/client accepts the new price.Please note you will not receive a refund for the unused portion of your current billing period. However, you will still retain access to the subscription benefits until the end of your current billing period. For example, if you have a yearly subscription plan and you cancel it mid way after a couple of months, you will continue to have access to the subscription content and benefits until the end of the current billing date.How to request a refund?Please send an email to (email id) within 7 days of purchase. Your email must provide us with the details of the product you purchased, and the email address associated with your Kalakriya course account. We will strive to promptly assist you in getting a refund for your courses or bundles as per our refund policy.Please note that the refunds will be credited back to the source account, and it may take 2-3 weeks for the refunds to reflect in your account depending upon your bank partner. No refunds will be processed in cash.Partial RefundsWe do not have the provision for partial refunds. We value your understanding of this policy as we strive to ensure consistency and fairness for all our participants. If you have any questions or need further clarification, please don't hesitate to reach out to us at info@kalakriya.com */}

                        </div>
                        <br/>
                        <h5>How to request a refund?</h5>
                        <div style={{textAlign:"justify"}}>
                        Please send an email to (email id) within 7 days of purchase. Your email must provide us with the details of the product you purchased, and the email address associated with your Kalakriya course account. We will strive to promptly assist you in getting a refund for your courses or bundles as per our refund policy. Please note that the refunds will be credited back to the source account, and it may take 2-3 weeks for the refunds to reflect in your account depending upon your bank partner. No refunds will be processed in cash.
                          </div>
                          <br/>
                          <h5>Partial Refunds</h5>
                          <div style={{textAlign:"justify"}}>
                          We do not have the provision for partial refunds. We value your understanding of this policy as we strive to ensure consistency and fairness for all our participants. If you have any questions or need further clarification, please don't hesitate to reach out to us at &nbsp;
                          <a href="mailto:info@kalakriya.com" rel="noopener noreferrer" target="_blank">
                             info@kalakriya.com
                            {/* {config === null ? "info@kalakriya.com" : config.support_email} */}
                            </a>
                           {/* info@kalakriya.com */}
                        {/* Please send an email to (email id) within 7 days of purchase. Your email must provide us with the details of the product you purchased, and the email address associated with your Kalakriya course account. We will strive to promptly assist you in getting a refund for your courses or bundles as per our refund policy. Please note that the refunds will be credited back to the source account, and it may take 2-3 weeks for the refunds to reflect in your account depending upon your bank partner. No refunds will be processed in cash. */}
                          </div>
                          <br/>
                          <h5>Exchanges</h5>
                          <div style={{textAlign:"justify"}}>We currently do not have provisions for course exchanges.</div>



                    </Container>
                    </div>
                    </React.Fragment>
        )
    }
}

Cancellation.propTypes = propTypes;
Cancellation.defaultProps = defaultProps;
// #endregion

export default Cancellation;