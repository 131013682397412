import React from 'react';
import CarouselPage from '../Component/CarouselPage';
import img1 from "../../../assets/images/bg/bg_1.png"
import MetaTags from 'react-meta-tags';
import { Container, Row, Col,Alert } from "reactstrap"
import SignIn from './SignIn';
import SocialMediaLogin from './SocialMediaLogin';
import urlSocket from 'helpers/urlSocket';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import NewUserForm from './NewUserForm';

const Login = () => {
    const [secretKey, setsecretKey] = useState('')
    const [countryList, setcountryList] = useState([])
    const [newUser, setnewUser] = useState(false)
    const [userEmail, setuserEmail] = useState("")
    const [userName, setuserName] = useState("")
    const [config, setconfig] = useState(null)
    const [blurLoading, setBlurLoading] = useState(false);
    const [alertEnable, setalertEnable] = useState(false)
    const [alertColor, setalertColor] = useState("")
    const [message, setmessage] = useState('')

    const history = useHistory()

    useEffect(() => {
        getConfig()
    }, [])

    const getConfig = () => {
        try {
            urlSocket.post('ctlibmgt/getconf')
                .then((res) => {
                    let secret_key = res.data.data.secret_key
                    setsecretKey(secret_key)
                    let data = res.data.data
                    let coutryList = res.data.data.countries
                    coutryList.map((ele, indx) => {
                        coutryList[indx].country_list = ele.code + " " + ele.label
                    })
                    setcountryList(coutryList)
                    setconfig(data)
                    localStorage.setItem("getconfsession", JSON.stringify(data))

                })
                .catch((error) => {
                    // console.log(error, 'error')
                    setmessage("Something has went wrong , Please try again later. ")
                    setalertColor("danger")
                    setalertEnable(true)
                    fadeAlert()
                })
        } catch (error) {
            // console.log(error, 'error')
            setmessage("Something has went wrong , Please try again later. ")
            setalertColor("danger")
            setalertEnable(true)
            fadeAlert()
        }
    }




    const onSuccessGoogle = (success_info) => {
        setBlurLoading(true)
        // console.log(success_info, 'success_info')
        validateEmail(success_info, success_info.profileObj.email)
    }

    const onSuccessFacebook = (success_info) => {
        console.log(success_info, 'success_info')
    }

    const onFailureGoogle = (failure) => {
        // console.log(failure, 'failure')
        setBlurLoading(false)
        // setmessage("Something has went wrong , Please try again later. ")
        // setalertColor("danger")
        // setalertEnable(true)
        // fadeAlert()
        

    }



    const goToLandingPage = async () => {
        // sessionStorage.setItem("authUser", JSON.stringify(userInfo))
        var courseInfo = await JSON.parse(localStorage.getItem("CourseDetails"))
        if (courseInfo) {
            let name_of_the_course = courseInfo.name_of_the_course.trim().replace(/[^A-Z0-9]\s+/ig, "").replaceAll(' ', '-');
            history.push({
                pathname: '/course/CourseDetails',
                search: '?' + courseInfo._id + '?' + name_of_the_course.toLowerCase(),
            });
        }
        else {
            history.push("/dashboard")
        }
    }


    const validateEmail = (info, email) => {

        try {
            urlSocket.post('google/validate-email', {
                google_info: info,
                email,
                auth_type: "google"
            }).then((response) => {
                // console.log(response, 'response')
                setBlurLoading(false)

                if (response.data.response_code === 500) {
                    if (response.data.user_info.length > 0 && response.data.new_user === false) {
                        var loginSession = response.data.user_info[0]
                        if (loginSession.full_name !== null && loginSession.gender !== null && loginSession.age_range !== null) {
                            localStorage.setItem("authUser", JSON.stringify(loginSession))
                            sessionStorage.setItem("authUser", JSON.stringify(loginSession))
                            localStorage.setItem("auth_type", 2)

                            localStorage.setItem('express-session', JSON.stringify({
                                session_id: loginSession.session_id,
                                device_info: loginSession.device_info,
                            }))
                            localStorage.setItem('socialMediaLoginId', info.googleId)
                            // console.log("history push")
                            goToLandingPage()
                            // history.push("/dashboard")
                        }
                        else {
                            localStorage.setItem("auth_type", 2)
                            setnewUser(true)
                            setuserEmail(loginSession.email)
                            setuserName(info.profileObj.name)
                        }
                    }
                    else if (response.data.new_user === true && response.data.user_info.length === 0) {
                        setnewUser(true)
                        localStorage.setItem("auth_type", 2)
                        setuserEmail(response.data.user_email)
                        setuserName(info.profileObj.name)
                    }
                }
            })

        } catch (error) {
            setmessage("Something has went wrong , Please try again later. ")
            setalertColor("danger")
            setalertEnable(true)
            fadeAlert()
            
        }
    }


    const fadeAlert = () => {
        setTimeout(() => {
            setmessage("")
            setalertColor("")
            setalertEnable(false)
        }, 3000);
    }


    return (
        <React.Fragment>
            <div className="page-content" >
                <MetaTags>
                    <title>Kalakriya - Login</title>
                </MetaTags>
                <Container  >
                    <Row style={{ minHeight: "calc(100vh - 203px - 150px)" }} className='justify-content-center'>
                        <div
                            className='col-md-6 d-flex align-items-center'
                            style={{
                                borderRight: "1px solid #dedede",
                                backgroundImage: `url(${img1})`,
                                backgroundRepeat: "no-repeat",
                                backgroundOrigin: "border-box",
                                backgroundPosition: "right",
                                backgroundSize: 300,
                            }}
                        >
                            <CarouselPage />
                        </div>
                        <div className=' col-md-6 col-lg-6 col-xl-5 d-flex align-items-center ps-3 ps-lg-4 ps-md-4 '>
                            <div className="col-12">
                                {
                                    !newUser ?
                                        <>
                                            <SignIn countryList={countryList} secretKey={secretKey} config={config} onSuccessFacebook={onSuccessFacebook} onSuccessGoogle={onSuccessGoogle} onFailureGoogle={onFailureGoogle} />
                                            {/* <SocialMediaLogin onSuccessGoogle={onSuccessGoogle} /> */}
                                            {
                                                alertEnable &&
                                                <Alert color={alertColor} className="mt-2" >{message}</Alert>
                                            }
                                        
                                        </>
                                        :
                                        <>
                                            <NewUserForm countryList={countryList} userEmail={userEmail} userName={userName} />
                                        </>

                                }

                            </div>
                        </div>
                    </Row>
                </Container>
                {blurLoading && (
                    <>
                        <div className="loader-overlay">
                            <div className="loader">
                            </div>
                        </div>
                    </>
                )}
            </div>
        </React.Fragment>);
}

export default Login;