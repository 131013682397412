import React, { Component } from 'react'
import { Container, Row, Col, } from "reactstrap"
import urlSocket from '../../helpers/urlSocket'
import CardContent from './Component/CardContent'
import { css } from "@emotion/react"
import { MoonLoader } from "react-spinners"
import { Checkbox, Collapse } from 'antd'
import toastr from "toastr"
import PropTypes from 'prop-types'

const { Panel } = Collapse
const _ = require('lodash')

class SubscriberVideos extends Component {
  constructor(props) {
    super(props)
    this.state = {
      courseList: [],
      loading: false,
      filters_list: [],
      artist_filter_list: [],
      artists_selected: [],
      sub_category: [],
      free: null,
      grid: 2,
      animated: false,
      filtered_array: [],
    }
  }

  componentDidMount() {
    this.getSessionData()
    this.listSubVidsAPI()
    this.activeArtist()
    this.activeFilter()

  }

  activeFilter = () => {
    try {
      urlSocket.post("filter_info/active_filter",)
        .then(res => {
          if (res.data.response_code === 500) {
            let filters_list = res.data.data
            this.setState({ filters_list })
          }
        })
        .catch(err => {
          this.errorNotifation()
        })
    } catch (error) {

    }
  }

  activeArtist = () => {
    try {
      urlSocket.get('artist_info/active_artist')
        .then((response) => {
          let artist_filter_list = response.data.data
          this.setState({ artist_filter_list })
        })
        .catch((error) => {

        })
    } catch (error) {
    
    }
  }

  listSubVidsAPI = () => {
    try {
      urlSocket.post('subscriber/listsubvideo', {})
        .then((res) => {
          let courseList = res.data.data
          this.setState({ courseList, loading: true })
        })
        .catch((error) => {

        })
    } catch (error) {

    }
  }

  getSessionData = async () => {
    var getconfsession = JSON.parse(sessionStorage.getItem("getconfsession"))
    let baseurl = getconfsession.baseurl
    this.setState({ baseurl })
  }

  onWatch = (data) => {
    sessionStorage.removeItem("subsvidply")
    sessionStorage.setItem("subsvidply", JSON.stringify(data))
    this.props.history.push("/subsvidply")
  }

  onCheckArtistFilter = (checked, index) => {
    let artist_filter_list = [...this.state.artist_filter_list]
    artist_filter_list[index].checked = checked
    this.setState({ artist_filter_list })

    let selected_opt = _.filter(artist_filter_list, (o) => { return o.checked })
    selected_opt = _.map(selected_opt, "artist_name")
    this.courseFilterByArtist(selected_opt)
  }


  onCheckFilters = (checked, index, index1) => {
    let filters_list = [...this.state.filters_list]
    filters_list[index].filter_values[index1].checked = checked
    this.setState({ filters_list })

    var filtered_array = filters_list
      .filter(element => element.filter_values.some(subElement => subElement.checked === true))
      .map(element => {
        let n = Object.assign({}, element, { 'filter_values': element.filter_values.filter(subElement => subElement.checked === true) })
        return n
      })
    this.courseOtherFilters(filtered_array)
  }


  courseFilterByArtist = (artist_info) => {
    this.setState({ artists_selected: artist_info })
    let { filtered_array } = this.state
    try {
      urlSocket.post("filter_info/new_course_filter", { artist_info, filtered_array }) //sub_category
        .then(res => {
          if (res.data.response_code === 500) {
            let courseList = res.data.data
            if (courseList.length === 0) {
              setTimeout(() => { this.setState({ courseList }) }, 100)

              setTimeout(() => { this.listSubVidsAPI() }, 100)
            } else {
              this.setState({ courseList: [] })
              setTimeout(() => { this.setState({ courseList }) }, 100)
            }
          }
        })
        .catch(err => {

        })
    } catch (error) {

    }
  }

  courseOtherFilters = (filtered_array) => {

    let artist_info = this.state.artists_selected

    filtered_array.forEach(data => {
      delete data.status
      delete data._id
      data.filter_values.forEach((data1) => { delete data1.status })
      data.filter_values = _.map(data.filter_values, "filter_value")
    })
    this.setState({ filtered_array })
    try {

      urlSocket.post("filter_info/new_course_filter", { artist_info, filtered_array }) //sub_category,
        .then(res => {

          if (res.data.response_code === 500) {
            let courseList = res.data.data
            if (courseList.length === 0) {
              this.setState({ courseList: [] })
              setTimeout(() => { this.setState({ courseList }) }, 100)

            } else {
              this.setState({ courseList: [] })
              setTimeout(() => { this.setState({ courseList }) }, 100)

            }
          } else {

          }

        })
        .catch(err => {

        })
    } catch (error) {

    }
  }


  filterFreeCourse = (value, type) => {
    if (type === '0') {
      this.setState({ premium: value })
    } else if (type === '1') {
      this.setState({ free: value })
    }
  }

  errorNotifation = (message = "Something Went wrong", title = "Error") => {
    toastr.options.closeDuration = 8000
    toastr.error(message, title)
  }


  render() {
    const override = css`display: block  margin: 0 auto  border-color: red`
    if (this.state.loading) {
      return (
        <div className="page-content"  >
          <Container fluid >

            <Row className="mt-2">
              <Col md={2}></Col>
              <Col md={10} className="text-center">
                <h1 className="text-white">Subscribers Videos</h1>
              </Col>
            </Row>

            <Row>
              <Col md={2} className="pb-4 themeBG_" style={{ boxShadow: '0 1px 8px 0 rgb(0 0 0 / 6%)' }}>
                <Row className='pt-3'  >
                  <Col md={12}>
                    <Collapse defaultActiveKey={['1']} >
                      <Panel header="Artist" key="1">
                        {
                          this.state.artist_filter_list.map((data, index) => (
                            <div className="mx-3 pt-1 mb-1" key={index}>
                              <Checkbox checked={data.checked} onChange={(e) => this.onCheckArtistFilter(e.target.checked, index)} >{data.artist_name}</Checkbox>
                            </div>
                          ))
                        }
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>

                <Row className="mt-3" >
                  <Col md={12}>
                    <Collapse defaultActiveKey={['1']} >
                      <Panel header="Price" key="1">
                        <div className="mx-3 pt-1 mb-1" >
                          <Checkbox checked={this.state.premium} onChange={(e) => this.filterFreeCourse(e.target.checked, '0')} >Premium</Checkbox>
                        </div>
                        <div className="mx-3 pt-1 mb-1" >
                          <Checkbox checked={this.state.free} onChange={(e) => this.filterFreeCourse(e.target.checked, '1')} >Free</Checkbox>
                        </div>
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>

                <Row className="mt-3" >
                  <Col md={12}>
                    <div >
                      {
                        this.state.filters_list.map((data, index) => (
                          <div key={index} className='mt-1'>

                            <Collapse defaultActiveKey={['1']} >
                              <Panel header={data.filter_name} key="1">
                                {
                                  data.filter_values.map((data1, index1) => (
                                    <div className="mx-3 pt-2 mb-2" key={index1}>
                                      <Checkbox checked={data1.checked} onChange={(e) => this.onCheckFilters(e.target.checked, index, index1, data1._id,)} >{data1.filter_value}</Checkbox>
                                    </div>
                                  ))
                                }
                              </Panel>
                            </Collapse>
                          </div>
                        ))
                      }
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md={10} className="p-3 themeBG_" style={{ boxShadow: '0 1px 8px 0 rgb(0 0 0 / 6%)', background: '#f8f8fb', }}>
                <Row>
                  <Col md={12}>
                    <Row >
                      {
                        this.state.courseList.length === 0 && <label className="text-center text-white">No Videos found</label>
                      }
                      {
                        this.state.courseList.map((data, index) => (
                          (this.state.premium && this.state.free) ?
                            <Col md={this.state.grid} key={index} className="d-flex align-items-stretch">
                              (<CardContent animated={this.state.animated} data={data} onWatch={() => this.onWatch(data)} baseurl={this.state.baseurl} is_free={data.is_free} course_id={data._id} artist_info={data.artist_info} filter_info={data.filter_info} />)
                        </Col>
                            :
                            (this.state.free) ?
                              (data.is_free === '1' && (
                                <Col md={this.state.grid} key={index} className="d-flex align-items-stretch">
                                  <CardContent animated={this.state.animated} data={data} onWatch={() => this.onWatch(data)} baseurl={this.state.baseurl} is_free={data.is_free} course_id={data._id} artist_info={data.artist_info} filter_info={data.filter_info} />
                                </Col>

                              ))
                              :
                              (this.state.premium) ?
                                (data.is_free === '0' && (
                                  <Col md={this.state.grid} key={index} className="d-flex align-items-stretch">
                                    <CardContent animated={this.state.animated} data={data} onWatch={() => this.onWatch(data)} baseurl={this.state.baseurl} is_free={data.is_free} course_id={data._id} artist_info={data.artist_info} filter_info={data.filter_info} />
                                  </Col>

                                ))
                                :
                                <Col md={this.state.grid} key={index} className="d-flex align-items-stretch">
                                  <CardContent animated={this.state.animated} data={data} onWatch={() => this.onWatch(data)} baseurl={this.state.baseurl} is_free={data.is_free} course_id={data._id} artist_info={data.artist_info} filter_info={data.filter_info} />
                                </Col>
                        ))
                      }
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      )
    } else {
      return (
        <div className="page-content">
          <Container fluid >
            <div className="sweet-loading">
              <MoonLoader color={'#2f8545'} loading={this.state.loading} css={override} size={75} />
              <div className="text-center mt-2">
                <label style={{ color: 'white' }}>Loading...</label>
              </div>
            </div>
          </Container>
        </div>
      )
    }
  }
}

export default SubscriberVideos

SubscriberVideos.propTypes = {
  history: PropTypes.object,
}
