import React, { Component } from "react"
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom"
import HeaderBeforeAuth from "./HorizontalLayout/HeaderBeforeAuth"
import Footer from './HorizontalLayout/Footer'

class NonAuthLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.capitalizeFirstLetter.bind(this)
  }

  capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2)
  }

  render() {
    return (
      <React.Fragment >
        <HeaderBeforeAuth />
        {this.props.children}
        <Footer />
      </React.Fragment>
    )
  }
}

NonAuthLayout.propTypes = {
  children: PropTypes.object,
  location: PropTypes.object
}

export default withRouter(NonAuthLayout)
