import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Alert, Row, Col, Label, Button, Modal, Card, CardBody } from "reactstrap"
import { AvField, AvForm, AvInput } from "availity-reactstrap-validation"
import { useState, useEffect } from 'react';
import urlSocket from 'helpers/urlSocket';
import AuthCode from "react-auth-code-input"
import { LoadingOutlined, EditOutlined } from '@ant-design/icons'
import CryptoJS from 'crypto-js'
import { useHistory } from 'react-router-dom';
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const ForgotScreen = (props) => {
    const history = useHistory()

    const [userEmail, setUserEmail] = useState(props.userEmail)
    const [validFormat, setvalidFormat] = useState(false)
    const [message, setmessage] = useState('')
    const [alertEnable, setalertEnable] = useState(false)
    const [alertColor, setalertColor] = useState("")
    const [showOtpBox, setshowOtpBox] = useState(false)
    const [btnLoading, setbtnLoading] = useState(false)
    const [Otp, setOtp] = useState("")
    const [newPassword, setnewPassword] = useState(false)



    useEffect(() => {
        var mailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (mailFormat.test(userEmail)) {
            setvalidFormat(true)
        }
        else {
            setvalidFormat(false)
        }

    }, [])

    const validateUser = (event, values) => {
        if (!showOtpBox) {
            if (validFormat) {
                setbtnLoading(true)
                try {
                    urlSocket.post('sbscrmgt/check_user_avail', { username: values.username, login_type: 2 }).then((response) => {
                        if (response.data.response_code === 500 && response.data.data.length > 0) {
                            try {
                                urlSocket.post('sbscrmgt/fgtpwd', { username: values.username, login_type: 2 }).then((res) => {
                                    if (res.data.data !== undefined) {
                                        setmessage("OTP sent to the given Email ID, Please verify.")
                                        setalertEnable(true)
                                        setalertColor("success")
                                        fadeAlert()
                                        setbtnLoading(false)
                                        setshowOtpBox(true)
                                    }
                                    else if (res.data.error.code == "LimitExceededException") {
                                        setmessage("OTP limitation has been exceeded")
                                        setalertEnable(true)
                                        setalertColor("danger")
                                        fadeAlert()
                                    }
                                    else {
                                        setmessage("User Not Registered")
                                        setalertEnable(true)
                                        setalertColor("danger")
                                        fadeAlert()
                                    }

                                })
                            }
                            catch (error) {
                                setmessage("Something has went wrong please try again later.")
                                setalertEnable(true)
                                setalertColor("danger")
                                fadeAlert()
                            }
                        }
                        else {
                            setbtnLoading(false)
                            setmessage("Email Address Does not Exist")
                            setshowOtpBox(false)
                            setalertEnable(true)
                            setalertColor("danger")
                            fadeAlert()
                        }
                    })

                } catch (error) {
                    setmessage("Something has went wrong please try again later.")
                    setalertEnable(true)
                    setalertColor("danger")
                    fadeAlert()

                }
            }
            else {
                setmessage("Please Enter a Formated Email ID")
                setalertEnable(true)
                setalertColor("danger")
                fadeAlert()
            }
        }
        else {
            setbtnLoading(true)
            validateOTP()
        }
    }

    const validateOTP = () => {

        var ciphertext = CryptoJS.AES.encrypt(newPassword, props.secretKey).toString()
        try {
            urlSocket.post('/sbscrmgt/cnfnewpwd', { username: userEmail, login_type: 2, password: ciphertext, code: Otp })
                .then((response) => {
                    setbtnLoading(false)
                    if (response.data.response_code === 500) {
                        sessionStorage.setItem("authUser", JSON.stringify(response.data.data[0]))
                        localStorage.setItem("authUser", JSON.stringify(response.data.data[0]))
                        history.push("/new-login")
                    }
                    else if (response.data.response_code === 504) {
                        setmessage("OTP is Invalid, Please enter the Valid OTP")
                        setalertEnable(true)
                        setalertColor("danger")
                        fadeAlert()

                    }
                })

        } catch (error) {
            setmessage("Something has went wrong please try again later.")
            setalertEnable(true)
            setalertColor("danger")
            fadeAlert()

        }



    }





    const validateEmail = (value) => {

        var mailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (mailFormat.test(value)) {
            setUserEmail(value)
            setvalidFormat(true)
        }
        else {
            setvalidFormat(false)
        }

    }



    const fadeAlert = () => {
        setTimeout(() => {
            setmessage("")
            setalertColor("")
            setalertEnable(false)
        }, 3000);
    }







    return (
        <div>
            <AvForm className="form-horizontal" onValidSubmit={validateUser} >
                <div className="form-group">
                    <div className='mb-3'>
                        <h3 className='fontInter header-subtitle text-primary'>Forgot Password</h3>
                    </div>
                    <div className='mb-4'>
                        <Label className='lognLabel' >E-Mail Address</Label>
                        <div className='loginInputForm'>
                            <AvField autoComplete='new-name' name="username"
                                value={userEmail}
                                disabled={showOtpBox}
                                className="inputComp" placeholder="Enter E-Mail Address" required errorMessage={"Please Enter E-Mail Address"}
                                onChange={(e) => { validateEmail(e.target.value) }}
                            />
                        </div>
                    </div>
                    {
                        showOtpBox &&
                        <>
                            <div className="form-group">
                                <div>
                                    <Label>Enter OTP</Label>
                                    <div className="text-center otpBox mb-2">
                                        <AuthCode
                                            characters={6}
                                            onChange={(otp) => setOtp(otp)}
                                            className="form-control form-control-lg text-center otpInput"
                                            allowedCharacters="^[0-9]"
                                            required
                                            inputStyle={{
                                                padding: '5px',
                                                borderRadius: '0.4rem',
                                                fontSize: '1rem',
                                                textAlign: 'center',
                                                marginRight: '5px',
                                                border: '1px solid rgb(206, 212, 218)',
                                                textTransform: 'uppercase',
                                            }}
                                        />

                                    </div>
                                    <div><span style={{ fontSize: 13, lineHeight: 1 }}>User to enter the OTP received through email</span></div>
                                    <div>
                                        <span style={{ fontSize: 13, lineHeight: 1 }}>If you do not have access to your Email
                                            <span
                                                // onClick={() => { this.edit_av_field() }}
                                                style={{
                                                    textDecoration: 'underline',
                                                    color: "#5e5e5e",
                                                    cursor: "pointer",
                                                    '&:hover': {
                                                        textDecoration: 'underline'
                                                    }
                                                }}
                                            >
                                                Click Here
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mt-4">
                                <AvField name="Password" autoComplete='new-password' label="Enter New Password" value="" type="password" className="form-control inputStyle" required placeholder="Enter Password" errorMessage={"Please enter Password"}
                                    onChange={(e) => { setnewPassword(e.target.value) }}
                                />
                            </div>
                        </>
                    }
                    <Row className='mt-5 text-center'>
                        <Col className='border-0'>
                            <button
                                className="btn br-5 btn-block waves-effect waves-light w-100 btn-primary"
                                type="submit"
                                disabled={btnLoading}
                            >
                                {btnLoading && <LoadingOutlined />} {btnLoading ? 'Verifying..' : 'Submit'}
                            </button>
                        </Col>
                    </Row>
                    {
                        alertEnable &&
                        <Alert className="mt-2" color={alertColor} >{message}</Alert>

                    }

                </div>

            </AvForm>

        </div>);
}

ForgotScreen.propTypes = propTypes;
ForgotScreen.defaultProps = defaultProps;
// #endregion

export default ForgotScreen;