import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, CardTitle, CardFooter, CardImg } from "reactstrap"
import { Tag } from 'antd'
import no_prev from '../../../assets/images/no_prev.jpeg'
import loadingGif from '../../../assets/images/loadingGif.gif'
import '../CSS/Style.css'
import PropTypes from 'prop-types'

const CardContent = props => {

    const [thumbnail, setThumbnail] = useState(loadingGif)

    useEffect(() => {
        let baseurl = props.baseurl
        let course_id = props.course_id
        setThumbnail(baseurl + course_id + '.png')
        return () => {
            setThumbnail(loadingGif)
        }
        //eslint-disable-next-line
    }, [])


    return (
        <>
            {
                props.animated ?
                    <div className="cardContainer">
                        <div className='cardCard'>
                            <div className='cardImage'>
                                <img src={thumbnail} alt="thumbnail" className='thumbnail_img' onError={() => setThumbnail(no_prev)} />
                            </div>
                            <div className='cardContent'>
                                <h6 className="mt-1">{props.data.title}</h6>
                                <h6>{props.data.description}</h6>
                                <h6>{props.data.language}</h6>
                                <p className="mt-1">
                                    {
                                        props.artist_info.map((e) => { return e.artist_name }).join(', ')
                                    }
                                </p>
                                {
                                    props.filter_info.sort((a, b) => (a.filter_name > b.filter_name) ? 1 : -1).map((data, index) => (
                                        <p key={index}>
                                            <b className="ml-2">{data.filter_name}:  </b>
                                            {
                                                data.filter_value.map((e) => { return e.filter_value }).join(', ')
                                            }
                                        </p>
                                    ))
                                }
                                <Tag color={props.data.is_free === '1' ? "#87d068" : 'orange'}>{props.data.is_free === '1' ? 'Free' : 'Premium'}</Tag>
                                <div className="mt-1">
                                    <Link to="subsvidply" className="card-link" onClick={() => { props.onWatch() }}>Watch Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <Card className='cardShadow'  >

                        <CardImg variant="top" src={thumbnail} alt="thumbnail" onError={() => setThumbnail(no_prev)} className='p-1 thumbnail_img' />

                        <CardBody className="text-center p-1">
                            {/* <div className="text-center">
                                    <img src={thumbnail} alt="thumbnail" className='thumbnail_img' onError={() => setThumbnail(no_prev)} />
                                </div> */}
                            {/* <label>{props.baseurl + props.data.video_info[0].input_url}</label> */}

                            <CardTitle className="mt-1">{props.data.title}</CardTitle>
                            <div className="mt-2" >{props.data.description}</div>
                            <div className="mt-2" >{props.data.language}</div>
                            <Tag className="mt-2" color={props.data.is_free === '1' ? "#87d068" : 'orange'}>{props.data.is_free === '1' ? 'Free' : 'Premium'}</Tag>

                            <div className="mt-2">
                                <b className="ml-2 ">Artist: </b>
                                {
                                    props.artist_info.map((e) => { return e.artist_name }).join(', ')
                                }
                            </div>
                            {
                                props.filter_info.sort((a, b) => (a.filter_name > b.filter_name) ? 1 : -1).map((data, index) => (
                                    <div key={index} className="mt-2">
                                        <b className="ml-2 ">{data.filter_name}:  </b>
                                        {
                                            data.filter_value.map((e) => { return e.filter_value }).join(', ')
                                        }
                                    </div>
                                ))
                            }
                        </CardBody>
                        <Link to="subsvidply" className="text-white" onClick={() => { props.onWatch() }}>
                            <CardFooter className="text-center " style={{ border: '2px solid white', color: 'white', background: props.data.is_free === '1' ? '#87d068' : '#262b37' }} >Watch Now</CardFooter>
                        </Link>
                    </Card>
            }
        </>
    )
}

export default CardContent

CardContent.propTypes = {
    baseurl: PropTypes.string,
    course_id: PropTypes.string,
    data: PropTypes.object,
    onWatch: PropTypes.func,
    filter_info: PropTypes.array,
    artist_info: PropTypes.array,
    animated: PropTypes.bool,
}
