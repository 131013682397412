import React from "react"
import { Col, Row, Container } from "reactstrap";
import mag_logo_dark from "../../assets/images/mag_logo_dark.png"
import Playstore from "../../assets/images/button-playStore.png"
import Appstore from "../../assets/images/button-appStore.png"
import logoWithLabel from "../../assets/images/logo-with-label.png"
import kalakriya_logo from '../../assets/images/kalakriya_logo.png'
import { withRouter, Link } from "react-router-dom"
import { useEffect,useState } from "react";


const Footer = () => {
  const [config,setconfig] = useState(JSON.parse(sessionStorage.getItem('getconfsession')))
  useEffect(() => {
  // console.log(config,'config')
  }, [])



  return (
    <footer >
      <Container fluid>

        <div >
          <Container>

            <div className="d-flex flex-column flex-md-row pt-4 justify-content-center align-items-center" style={{ borderTop: "1px solid #dedede", }}>

              <Col sm={6} md={4} className="d-flex justify-content-center mb-5 mb-lg-0 text-center text-md-start">
                <div className="">
                  <h5>Eparampara Infotainment</h5>
                  <p style={{ fontSize: 13 }}>2ND FLOOR, SRIBALAVINAYAGAR BUILDING, <br/>NO.35/3, ARK COLONY<br />
                    ELDAMS ROAD, ALWARPET, CHENNAI - 600018 <br />TAMIL NADU, INDIA
                  </p>
                </div>
              </Col>
              <Col sm={6} md={4} className="d-flex justify-content-center mb-5 mb-lg-0 text-center text-md-start" >
                <div className="" >
                <p style={{lineHeight:2}} ><a href="mailto:info@kalakriya.com" rel="noopener noreferrer" target="_blank">{config === null ? "info@kalakriya.com" : config.support_email}</a>
                  <br/>
                  <a  href="tel:+91-44-42030505" rel="noopener noreferrer" target="_blank">+91-44-42030505</a></p>

                  <div className="d-flex socialIcons gap-3">
                  {/* <Link to={'https://www.youtube.com/kalakriya'}></Link> */}
                
                    <i className='bx bxl-youtube text-danger' style={{ cursor: "pointer" }} onClick={() => { window.open("https://www.youtube.com/@kalakriya-series") }}></i>
                    <i className='bx bxl-facebook text-primary' style={{ cursor: "pointer" }} onClick={() => { window.open('https://www.facebook.com/kalakriya') }}></i>
                    <i className='bx bxl-instagram' onClick={() => { window.open("https://www.instagram.com/kalakriya_series?igsh=MmZidWJ6cDZwMGpo") }} style={{ color: "#8a3ab9" ,cursor: "pointer"}}></i>
                    {/* <i class='bx bxl-linkedin text-info'></i> */}
                  </div>
                </div>
              </Col>
              <Col sm={6} md={4} className="d-flex justify-content-center mb-5 mb-lg-0 text-center text-md-start">
                <div className="">
                  <h5>Kalakriya Desk</h5>
                  <div >
                    <ul className="me-3" style={{ margin: 0, padding: 0, listStyle: "none" }}>
                      <li style={{ padding: 0, margin: 0, fontSize: 13 }}><Link to="/aboutus">About us</Link></li>
                      {/* <li style={{ padding: 0, margin: 0, fontSize: 13 }}>Customer Registration Process</li>
                      <li style={{ padding: 0, margin: 0, fontSize: 13 }}>Help Desk</li> */}
                    </ul>
                  </div>
                </div>
              </Col>
           </div>

          </Container>
        </div>


        <div className="d-flex flex-column flex-lg-row gap-0 gap-lg-4 py-3 " style={{ borderTop: "1px solid #dedede", justifyContent: "center", alignItems: "center", }}>
          <Col className="col-12 col-lg-auto border-0" style={{ textAlign: "center", }}>
            <div><span style={{ fontSize: 13 }}><span>©</span>2024 - Kalakriya Pvt. Ltd. All rights reserved</span></div>
          </Col>
          <Col className="col-12 col-lg-auto border-0" style={{ textAlign: "center", }}><div><span style={{ fontSize: 13 }}><Link to="/cancellation">Cancellation and Refund Policy</Link></span></div></Col>
          <Col className="col-12 col-lg-auto border-0" style={{ textAlign: "center", }}><div><span style={{ fontSize: 13 }}><Link to="/privacyandpolicy">Privacy Policy</Link></span></div></Col>
          <Col className="col-12 col-lg-auto border-0" style={{ textAlign: "center", }}><div><span style={{ fontSize: 13 }}><Link to="/termsandconditions">Terms & Conditions</Link></span></div></Col>
          <Col className="col-12 col-lg-auto border-0" style={{ textAlign: "center", }}><div><span style={{ fontSize: 13 }}>INDIA</span></div></Col>
        </div>

      </Container>

    </footer>
  )
}

export default Footer


