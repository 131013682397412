import React, { Component } from "react"
import PropTypes from "prop-types"
import "react-drawer/lib/react-drawer.css"
import { Menu, } from 'antd';
// import Select from "react-select"

import { connect } from "react-redux"
import urlSocket from '../../helpers/urlSocket'

import { Link, useHistory } from "react-router-dom"
import { DownOutlined, CaretDownOutlined } from '@ant-design/icons';

// reactstrap
import { Button, Offcanvas, OffcanvasHeader, OffcanvasBody,Badge, Dropdown, DropdownToggle, Input } from "reactstrap"

// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import mag_logo_dark from "../../assets/images/mag_logo_dark.png"
import kalakriya_logo from '../../assets/images/kalakriya_logo.png'
import noCardImg  from '../../assets/images/noCardImg.png'

import SearchDropDown from "components/CommonForBoth/TopbarDropdown/SearchDropDown";
import SearchMobileView from "./SearchMobileView";


// Redux Store
import { toggleRightSidebar } from "../../store/actions"
//i18n
import { withTranslation } from "react-i18next"
import { error } from "toastr";





class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSearch: false,
            open: false,
            myip: null,
            mycountry: null,
            position: "right",
            isLogedIn: false,
            userInformation: null,
            courseList: [],
            searchDropDown:false,
            configuration:JSON.parse(sessionStorage.getItem('getconfsession')),
            language_list: [

            ],
            searchName:'',
            aboutMenuitems: [
                {
                    // document.location.href = ("/course/CourseInProgress")
                    label: <span >Team</span>,
                    key: '0',
                },
                {
                    label: <span >Vision</span>,
                    key: '1',
                },
                {
                    label: <span >Mission</span>,
                    key: '2',
                },
                {
                    label: <span >Career</span>,
                    key: '3',
                },
            ],
            partnersMenuitems: [
                {
                    label: <a href="#">Institutes</a>,
                    key: '0',
                },
                {
                    label: <a href="#">Music Mentors</a>,
                    key: '1',
                },
            ],
            cartCourseList: [],
        }
        this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
        this.toggle = this.toggle.bind(this);
        this.searchTimeout = null;
    }

    async componentDidMount() {
        await this.getUserInformation()
        this.checkLogedIn()
        this.getLanguagesList()
    }

    toggle() {
        this.setState(prevState => ({
            searchDropDown: !prevState.searchDropDown,
        }))
      }

    getAllcourseList=()=>{
        var subscriber_info = JSON.parse(sessionStorage.getItem('authUser'))
    //    var configuration= JSON.parse(sessionStorage.getItem('getconfsession'))
        
        if(subscriber_info){
        try {
            urlSocket.post('mngsubcrs/retrive-all-courses', {subscriber_id: subscriber_info._id})
                .then((res) => {
                    //"CourseList", res)
                    //(res, 'response')
                    let data = res.data.data                   
                    // //(data,'data',this.props)
                    // this.props.dispatch(setSearchCourseList(data));                        
                    
                    if (data.length > 0) {
                        this.setState({ CourseList: data ,baseurl:this.state.configuration.baseurl})
                        
                        // this.props.dispatch(setCartCourseListLength(result.coursesInCart.length));
                    }
                })
        } catch (error) {
            //'error', error)
        }
        }
    }

    getUserInformation = async () => {

        let user_info = await JSON.parse(sessionStorage.getItem("authUser"))
        var getconfsession = JSON.parse(localStorage.getItem("getconfsession"))
        let { baseurl, self_regn } = getconfsession
        this.setState({
            userInformation: user_info,
            baseurl:baseurl
        })

    }

    getLanguagesList = () => {
        var selected_language = JSON.parse(sessionStorage.getItem("selected_language"))

        if (selected_language !== null) {

            if (selected_language.length === 0) {
                setTimeout(() => { this.getLanguagesList() }, 3000)
            } else {

                this.setState({ language_list: selected_language })
            }
        }
    }



    checkLogedIn = () => {
        if (sessionStorage.getItem("authUser")) {
            this.setState({ isLogedIn: true })
        } else {
            this.setState({ isLogedIn: false })
        }
    }


    goToCourse = (data, str) => {
        if (str === "label") {
            let path_name = data.label.props.children
            sessionStorage.setItem("current_path", JSON.stringify(path_name))
            document.location.href = ("/aboutus" + "#" + path_name)

        }
        else {
            let course_name = data.name_of_the_course.trim().replace(/[^A-Z0-9]\s+/ig, "").replaceAll(' ', '-')
            var course_details = sessionStorage.setItem("CourseDetails", JSON.stringify(data._id))
            document.location.href = ("/course/CourseDetails" + '?' + data._id + '?' + course_name.toLowerCase())

        }
    }

    onSelectLanguage = async (language_name) => {
        window.language_name = language_name
        ////'Header', window.language_name)
    }

    toggleRightCanvas() {
        this.setState({ isRight: !this.state.isRight, courseList:[], searchName:"" });
    }


    courseSuggestionsMenu=()=>{
        return (
            <>
                {this.state.courseList.map((category, categoryIndex) => (
                    <Menu key={categoryIndex} mode="vertical" className="course-menu">
                        {category.courses.map((course, courseIndex) => (
                            <Menu.Item
                                key={`${categoryIndex}-${courseIndex}`}
                                onClick={() => this.goToCourse(course)}
                                className="menuItemClass"
                                style={{ borderBottom: 'solid 1px #ccc' }}
                            >
                                <div className="d-flex gap-2 ms-2" style={{ alignItems: 'center' }}>
                                    <img
                                        src={course.thumbnail !== undefined && course.thumbnail !== '' ? this.state.baseurl + course.thumbnail : noCardImg}
                                        alt={course.name_of_the_course}
                                        className="category-thumbnail"
                                        height={50}
                                    />
                                    <div className="">
                                        <h6 className="">{course.name_of_the_course}</h6>
                                        <p className="mb-0">                                        
                                        <span className="font-size-10 ">Status : </span>
                                            <Badge
                                                className={`${course.is_subscribed ? 'badge-soft-success ms-1' : 'badge-soft-danger ms-1'}`}
                                                color={'#fbfbfb'}
                                            >
                                               {course.is_subscribed ? 'SUBSCRIBED' : 'UNSUBSCRIBED'}
                                            </Badge>
                                        </p>                                   
                                       
                                    </div>
                                </div>                              
                            </Menu.Item>
                        ))}
                    </Menu>
                ))}
            </>
        );
    }

    searchTerm=(value)=>{
        // //(event.target.value.length,'value')
        // //(this.state.searchName,'this.state.searchName')
        this.setState({
            searchName : value,
            // searchDropDown:true
        },()=>{
        if(value.length >=3){
        //(value.length,'value232')
       
        try {
            urlSocket.post('mngsubcrs/search-course-list',{
                keywords:value,
                subscriber_id: this.state.userInformation._id,
                page_type:2, //api condtional
            }).then((response)=>{
                // //(response,'response')
                if (response.data.response_code === 500) {
                    var updatedData = response.data.data
                    this.setState({
                        courseList: updatedData,
                        searchDropDown: updatedData.length >0 && value.length >0 ? true : false
                    }, () => { this.courseSuggestionsMenu() })
                }
           }).catch((error)=>{
            //(error,'error')
           })

        } catch (error) {
            //(error,'error')
            
        }
     }
     else{
        // //("search filed is empty")
        this.setState({
            searchDropDown: false,
            courseList:[],
            searchName:""
        })
}
    })
    }

    ///////// IP ADDRESS AND LOCATION ENDS //////

    render() {

        if (this.state.userInformation) {
            return (
                <React.Fragment>
                    <header id="page-topbar" style={{ left: 0, backgroundColor: 'white', boxShadow: '0px 1px 10px 0px rgba(0,0,0,0.3)' }}>
                        <div className="navbar-header">
                            <div className="d-flex">
                                <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation" >
                                    <div className="p-2">
                                        <a href={this.state.isLogedIn ? "/dashboard" : "/home"}>
                                            <img src={kalakriya_logo} alt="magarisa_logo" style={{ width: '100px', height: 'auto' }} />
                                        </a>
                                    </div>
                                </nav>
                            </div>


                            <div style={{ display: 'flex' }} id="header_search_">

                                <Dropdown isOpen={this.state.searchDropDown} toggle={this.toggle} className="dropdown d-none d-md-block" tag="li" >
                                    <DropdownToggle className="header-item" tag="button" id="page-header-notifications-dropdown" style={{ display: "none" }} />
                                    <form className="app-search me-3 btn header-item" onSubmit={(e) => {
                                        e.preventDefault();
                                    }}>
                                        <div className="position-relative_search">
                                            <input
                                                type="text"
                                                style={{
                                                    padding: 10,
                                                    fontSize: '12px',
                                                    fontFamily: 'Inter, sans-serif',
                                                    borderRadius: '8px 0px 0px 8px',
                                                    background: '#f2f2f2',
                                                    border: 'none',
                                                    width: 200,
                                                }}
                                                placeholder="Search Course, Artist, Category"
                                                value={this.state.searchTerm}
                                                onClick={(e) => this.searchTerm(this.state.searchName)}
                                                
                                                onChange={(e) => this.searchTerm(e.target.value)}
                                                id="page-header-notifications-dropdown"
                                            />
                                            <button
                                                className="btn"
                                                style={{
                                                    background: '#f2f2f2',
                                                    color: 'black',
                                                    borderRadius: '0px 8px 8px 0px',
                                                    border: 'none',
                                                    padding: 4.1,
                                                }}
                                                type="submit"
                                            >
                                                <i className="mdi mdi-magnify" style={{ fontSize: 20 }} />
                                            </button>
                                        </div>
                                    </form>

                                    <SearchDropDown
                                        courseList={this.state.courseList}
                                        baseurl={this.state.baseurl}
                                        loadCourse={(data) => this.goToCourse(data)}
                                    />
                                </Dropdown>


                                {
                                    this.state.isLogedIn ?
                                        <>
                                            {/* <NotificationDropdown style={{ color: "#74788d", marginTop: "5px" }} /> */}
                                            <div className="dropdown ms-1">
                                                <Link
                                                    to={"/cart-list"}
                                                    className="text-dark header-item noti-icon "
                                                >
                                                    <button
                                                        type="button"
                                                        className="btn header-item noti-icon "
                                                        data-toggle="fullscreen"
                                                    >
                                                        <i className="bx bx-cart" />
                                                        <span className="badge bg-danger rounded-pill">{this.props.cartCourseListLength}</span>
                                                    </button>
                                                </Link>
                                            </div>
                                            <ProfileMenu userInformation={this.state.userInformation} />
                                        </>
                                        :
                                        <div style={{ display: 'flex !important', alignSelf: 'center' }}>
                                            <Link to="/login">
                                                <Button className="magarisaBtn" outline style={{ minWidth: 80 }}>SIGN IN</Button>
                                            </Link>
                                        </div>
                                }

                                <div className="dropdown d-md-none d-inline-block">
                                    <button
                                        type="button"
                                        className="btn btn-sm mt-1 font-size-16 header-item"
                                        data-toggle="collapse"
                                        onClick={this.toggleRightCanvas}
                                        data-target="#topnav-menu-content"
                                    >
                                        <i className="mdi mdi-magnify " style={{ fontSize: 20 }} />
                                    </button>
                                </div>

                            </div>
                        </div>
                    </header>

                    <Offcanvas
                        isOpen={this.state.isRight}
                        direction="end"
                        toggle={this.toggleRightCanvas}
                        className="offCanvasNav"
                    >
                        <OffcanvasHeader toggle={this.toggleRightCanvas} style={{ borderBottom: '1px solid #ccc',}}>
                            <div >

                            <form className="" onSubmit={(e) => {
                                        e.preventDefault();
                                    }}>
                                        <div className="d-flex flex-row align-items-center" >
                                        <i className="mdi mdi-magnify me-2" style={{ fontSize: 20 }} />
                                            <Input
                                                type="text"
                                                style={{
                                                     padding: 10,
                                                    fontSize: '12px',
                                                    fontFamily: 'Inter, sans-serif',
                                                    border: 'none',
                                                    width:"250px"
                                                }}
                                                placeholder="Search Course, Artist, Category"
                                                value={this.state.searchTerm}
                                                onClick={(e) => this.searchTerm(this.state.searchName)}

                                                onChange={(e) => this.searchTerm(e.target.value)}
                                                id="page-header-notifications-dropdown"
                                            />
                                            
                                        </div>
                                    </form>

                            </div>
                        </OffcanvasHeader>
                        <OffcanvasBody>
                            <div>

                                <SearchMobileView
                                        courseList={this.state.courseList}
                                        baseurl={this.state.baseurl}
                                        loadCourse={(data) => this.goToCourse(data)}
                                    />

                            </div>

                        </OffcanvasBody>
                    </Offcanvas>



                </React.Fragment>
            )
        }
        else {
            return null
        }
    }
}

Header.propTypes = {
    openLeftMenuCallBack: PropTypes.func,
    t: PropTypes.any,
    menuOpen: PropTypes.any,
    toggleRightSidebar: PropTypes.func,
    router: PropTypes.array,
}

const mapStatetoProps = state => {
    const { layoutType, cartCourseListLength, SearchCourseList } = state.Layout
    return { layoutType, cartCourseListLength, SearchCourseList }
}

export { Header }

export default connect(mapStatetoProps, { toggleRightSidebar, })(
    withTranslation()(Header)
)


