import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types'
import { Card, CardBody, Col, Row, Container, Badge } from "reactstrap"

import SortableTree, { getFlatDataFromTree } from 'react-sortable-tree/dist/index.cjs.js'
import Carousel from "react-multi-carousel"
import { Empty } from 'antd'
import SubscribedCard from '../CommonComponents/SubscribedCard';
import { setCartCourseListLength } from "../../store/actions";
import { getCourseListAPI } from '../cartList/CartListAPI'
import { useDispatch } from "react-redux";


function MyCourseCardIndex(props) {
    const dispatch = useDispatch()
    document.title = "Kalakriya - My Courses";
    const [myCourseList, setMyCourseList] = useState([])
    const [baseUrl, setBaseUrl] = useState('')
    const [authUser, setauthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")))
    const [subscribedCourseList, setSubscribedCourseList] = useState([])
    const [interNetConnection, setinterNetConnection] = useState(false)
    const [unSubscribedCourseList, setUnSubscribedCourseList] = useState([])

    const textStyle = {
        maxWidth: '100%',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };


    useEffect(() => {
        var getconfsession = JSON.parse(sessionStorage.getItem("getconfsession"))
        let { baseurl, self_regn } = getconfsession
        setBaseUrl(baseurl)
        getCourseList()
    }, []);

    const getCourseList = async () => {

        try {
            var courseList = await getCourseListAPI()
            dispatch(setCartCourseListLength(courseList.data.cart_count))
            if (courseList) {
                if (courseList.data.response_code === 500) {
                    setSubscribedCourseList(courseList.data.data)
                    setinterNetConnection(false)
                }
                else if (courseList.data.error.message.startsWith('connect ENETUNREACH')) {
                    setinterNetConnection(true)
                }
            }

        } catch (error) {

        }
    }

    const getText = (html) => {
        var divContainer = document.createElement("div");
        divContainer.innerHTML = html
        let data = divContainer.textContent || divContainer.innerText || ""
        data = data.substring(0, 200) + '...'
        return data
    }

    const courseDetails = (data) => {
        let name_of_the_course = data.name_of_the_course.trim().replace(/[^A-Z0-9]\s+/ig, "").replaceAll(' ', '-');
        localStorage.setItem("course_id", JSON.stringify(data.course_info_id))
        let courseId = data.course_info_id !== undefined ? data.course_info_id : data._id
        props.history.push({
            pathname: '/course/CourseDetails',
            search: '?' + courseId + '?' + name_of_the_course,
        });
    }

    if (interNetConnection === false) {
        return (
            <React.Fragment>
                <div className="page-content " style={{ marginTop: 10 }} >
                    <Container >
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h3 className='textCapitalize' style={{ fontFamily: "poppins,sans-serif", color: '' }}>{'MY COURSES'}</h3>
                        </div>
                        {subscribedCourseList.length > 0 ?
                            <Row className="mx-2">
                                {
                                    subscribedCourseList.map((category, index) => (
                                        category.courses.map((data, dataIndex) => (
                                            <Col key={dataIndex} style={{ width: 270, border: 0 }} xxl="5" xl="4" lg="4" md="3" sm="1" className="g-1" >
                                                <SubscribedCard
                                                    selected_course={data}
                                                    isLogedUser={authUser}
                                                    baseurl={baseUrl}
                                                    description={getText(data.description_of_the_course)}
                                                    viewCourse={() => { courseDetails(data) }}
                                                />
                                            </Col>
                                        ))
                                    ))
                                }
                            </Row>
                            :
                            <div className="border d-flex align-items-center justify-content-center" style={{ height: "350px" }}>
                                <Empty description='No Subscribed Courses' />
                            </div>
                        }
                    </Container>
                </div>
            </React.Fragment>
        )
    }
    else if (interNetConnection === true) {
        return <React.Fragment>
            <div className="page-content" style={{ marginTop: 0 }}  >
                <Container fluid >
                    <Row style={{ minHeight: "calc(100vh - 450px)" }}>
                        <div className='d-flex w-100 flex-column justify-content-center align-items-center'>
                            <div>Please Check your internet connection.</div>
                        </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    }
}

MyCourseCardIndex.propTypes = {}

export default MyCourseCardIndex
