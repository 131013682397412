import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types'
import {
    Card, CardBody, Col, Row, Container, Badge, Table, Button, Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Toast,
    ToastHeader,
    ToastBody,
    CardTitle,

} from "reactstrap"
import { LoadingOutlined } from '@ant-design/icons'
import { Empty, Popconfirm } from 'antd'
import SweetAlert from 'react-bootstrap-sweetalert';
import urlSocket from "helpers/urlSocket";
import { getCartCourseList, getOrderListAPI } from './CartListAPI'
import { setCartCourseListLength } from "../../store/actions";
import { useDispatch } from "react-redux";
import OrdersModal from "./OrdersModal";
import moment from 'moment'
import _ from 'lodash'

function MyOrders(props) {
    const dispatch = useDispatch()
    document.title = "Kalakriya - My Orders";
    const [orderList, setOrderList] = useState([])
    const [baseUrl, setBaseUrl] = useState('')
    const [modal1, setModal1] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState([])
    const [isRight, setIsRight] = useState(false);
    const [toast, setToast] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [selectedItem, setSelectedItem] = useState(null)

    useEffect(() => {
        var getconfsession = JSON.parse(sessionStorage.getItem("getconfsession"))
        let { baseurl, self_regn } = getconfsession
        setBaseUrl(baseurl)
        getOrderList()
    }, []);

    const getOrderList = async () => {
        try {
            var getOrderList = await getOrderListAPI()
            dispatch(setCartCourseListLength(getOrderList.data.cart_count))
            if (getOrderList) {
                setToast(false)
                setOrderList(getOrderList.data.data)
            } else {
                setToast(false)
            }

        } catch (error) {

        }
    }

    const onShowInvoice = (item) => {
        sessionStorage.removeItem("invoiceData")
        sessionStorage.setItem("invoiceData", JSON.stringify(item))
        props.history.push('/invoice')

    }

    const cancelSubscription = (item) => {
        
        var course_id = _.map(item.customer_products, 'crs_referal_id')
        if (item.payment_type === "0") {
            try {
                urlSocket.post('razorpay_payment/cancel-razorpay-subscription', {
                    subscription_id: item.subscrptn_info.id,
                    course_id: course_id,
                    user_id: item.cart_customer_id,
                    basket_id: item._id,
                    basket_info: item
                }).then((response) => {
                    if (response.data.response_code === 500) {
                        getOrderList()
                    }
                })

            } catch (error) {

            }
        }
        if (item.payment_type === "1") {
            try {
                urlSocket.post('stripe_payment/cancel-stripe-subscription', {
                    subscription_id: item.subscrptn_info.id,
                    course_id: course_id,
                    user_id: item.cart_customer_id,
                    basket_id: item._id,
                    basket_info: item
                }).then((response) => {
                    if (response.data.response_code === 500) {
                        getOrderList()
                    }
                })

            } catch (error) {

            }
        }

    }



    return (
        <React.Fragment>
            <div className="page-content " style={{ marginTop: 10 }} >
                <Container>
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h3 className='textCapitalize' style={{ fontFamily: "poppins,sans-serif", color: '' }}>{'MY ORDERS'}</h3>
                    </div>
                    <Row>
                        {
                            orderList.length > 0 ?
                                <>
                                    <Table responsive>
                                        <thead>
                                            <tr style={{ borderBottom: "1px solid #dedede", fontSize: 12 }} className="text-dark">
                                                <th >S.No</th>
                                                <th >Order ID</th>
                                                <th >Date</th>
                                                {/* <th >Subscription Period</th> */}
                                                <th >Expiry Date</th>
                                                {/* <th >Status</th> */}
                                                <th >Paid</th>
                                                <th >Payment Status</th>
                                                <th >View Invoice</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderList.map((item, index) => (
                                                <tr key={index} style={{ borderBottom: "1px solid #dedede", fontSize: 14 }}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.order_id !== null && item.order_id}</td>
                                                    <td>{moment(item.updatedAt).format('DD-MMM-YYYY')}</td>
                                                    {/* <td>
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="me-3">
                                                            1 yr
                                                            </div>
                                                            <div>
                                                               
                                                                {item.status === "2" && item.subscr_cycle !==3 &&item.cancel_status !== "0"&&
                                                                    <div>
                                                                        <Button disabled={item.cancel_status === "0" } className='btn btn-danger btn-sm' onClick={() => {
                                                                            setSelectedIndex(index)
                                                                            setSelectedItem(item)
                                                                            setToast(!toast)
                                                                        }}>
                                                                            {
                                                                              selectedIndex === index&&  toast &&
                                                                                <LoadingOutlined />
                                                                            }
                                                                            {
                                                                                selectedIndex === index&&  toast  ?
                                                                                "..."
                                                                                :
                                                                                "Cancel"

                                                                            }
                                                                            
                                                                        </Button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </td> */}
                                                    <td>
                                                        {
                                                            item.cancel_status === "0" ?
                                                                <>
                                                                    -
                                                                </>
                                                                :
                                                                item.subscr_cycle !== 3 ?
                                                                    <>
                                                                        {
                                                                            item.payment_info !== null && (

                                                                                (() => {
                                                                                    const originalDate = new Date(item.payment_info.created_at * 1000);
                                                                                    const newDate = new Date(originalDate);
                                                                                    newDate.setFullYear(originalDate.getFullYear() + 1);
                                                                                    newDate.setDate(newDate.getDate() - 1);

                                                                                    return newDate.toLocaleDateString('en-IN', {
                                                                                        day: '2-digit',
                                                                                        month: 'short',
                                                                                        year: 'numeric',
                                                                                    });
                                                                                })()
                                                                            )
                                                                        }
                                                                    </>
                                                                    :
                                                                    <Badge className={"font-size-12 badge-soft- success"}>Subscription Completed</Badge>
                                                        }
                                                    </td>
                                                    {/* <td> <Badge className={"font-size-12 badge-soft-" + (item.cancel_status === "0" ? "danger" : "success")}>{item.cancel_status === "0" ? "Cancelled" : "Active"}</Badge></td> */}
                                                    <td>
                                                        {item.payment_type === '0' ? (
                                                            <i className="bx bx-rupee" />
                                                        ) : (
                                                             
                                                             <>US<i className="bx bx-dollar" /></>
                                                        )}{''}
                                                        {item.order_info && item.order_info.amount && !isNaN(item.order_info.amount)
                                                            ? Number(item.order_info.amount.toString().slice(0, -2))
                                                            : 'N/A'}
                                                    </td>
                                                    <td> <Badge className={"font-size-12 badge-soft-" + (item.status === "2"  &&item.cancel_status == null ? "success" : "danger")}>{item.status === "2" &&item.cancel_status === "0"? "Refunded" :item.status === "2" &&item.cancel_status === null ? "Success": "Pending"}</Badge></td>
                                                    <td>
                                                        {item.status === "2" &&
                                                            <div title="Invoice">
                                                                <i className="bx bx-receipt " style={{ color: '#7900E9', fontSize: '22px', cursor: "pointer" }} onClick={() => { onShowInvoice(item) }} />
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </>
                                :
                                <>
                                    <div className="border d-flex align-items-center justify-content-center" style={{ height: "350px" }}>
                                        <Empty description='No Orders' />
                                    </div>
                                </>
                        }
                    </Row>
                </Container>
            </div>
            <SweetAlert
                style={{ fontSize: 15 }}
                warning
                show={toast}
                showCancel
                cancelBtnText="No, cancel it"
                cancelBtnBsStyle="success"
                confirmBtnText="Yes, confirm!"
                confirmBtnBsStyle="danger"
                onConfirm={() => {
                    cancelSubscription(selectedItem)
                }}
                onCancel={() => {
                    setToast(false)
                }}
            >
                <span>Confirming subscription cancellation?</span>
            </SweetAlert>

        </React.Fragment>
    )
}

MyOrders.propTypes = {}

export default MyOrders
