import React from 'react'
// import { Link } from "react-router-dom"
import { PlayCircleFilled, FileOutlined, ProfileOutlined, FilePdfOutlined, AudioOutlined, FormOutlined, HighlightOutlined } from '@ant-design/icons'
import { Tag } from 'antd';

export default function CourseTree({ onClickLink, course_content }) {

    // is_finished
    let recursiveTree = (items) => {
        if (items !== undefined) {
            return items.map((data2, index2) => {
                if (data2.items !== undefined) {
                    return (
                        <li key={'child' + data2.title + index2}>
                            <div to="#" className={data2.items !== undefined ? "has-arrow ml-4 menuStyle" : 'ml-4 menuStyle'} onClick={() => { onClickLink(data2) }} >
                                <ProfileOutlined style={{ fontSize: 20, }} className='mx-2 px-2' />
                                {data2.title}
                            </div>
                            <ul key={index2} className="sub-menu mx-4" aria-expanded="true">
                                {
                                    data2.items !== undefined && recursiveTree(data2.items)
                                }
                            </ul>
                        </li>
                    )
                } else {
                    return (
                        <li key={'child_' + data2.title + index2}>
                            <div to="#" className='menuStyle' onClick={() => { onClickLink(data2) }} >
                              
                                {
                                    data2.document_type === 1 ?
                                        data2.content.length > 0 && (data2.content[0].content_type === 1 ? <PlayCircleFilled style={{ fontSize: 20, }} className='mx-2 px-2' /> : data2.content[0].content_type === 2 ? <AudioOutlined style={{ fontSize: 20, }} className='mx-2 px-2' /> : <FilePdfOutlined style={{ fontSize: 20, }} className='mx-2 px-2' />)
                                        :
                                        data2.document_type === 2 ?
                                            <FormOutlined style={{ fontSize: 20, }} className='mx-2 px-2' />
                                            :
                                            data2.document_type === 3 ?
                                                <HighlightOutlined style={{ fontSize: 20, }} className='mx-2 px-2' />
                                                : null
                                }
                                <span className='pr-2 mr-2'>{data2.title +" ("+ data2.unique_id +")"}</span>
                                {/* {
                                    !subscribed && <span className='m-1'>{(data2.document_type === 1 && data2.content[0].avl_preview) ? "" : <LockOutlined />}</span>
                                } */}
                                  {
                                    (data2.content_status === undefined || data2.content_status === 0)  ?
                                        <Tag className='mx-3' color="blue">Not Started</Tag>
                                        :
                                        data2.content_status === 1 ?
                                            <Tag className='mx-3' color="orange">In Progress</Tag>
                                            :
                                            <Tag className='mx-3' color="green">Completed</Tag>

                                    // <span className='m-1'><CheckCircleFilled twoToneColor='#04724b' color='04724b' fill='04724b'  style={{ fontSize: 18, color:'04724b' }}  /></span>
                                }
                               
                            </div>
                        </li>
                    )
                }
            })
        } else {
            return (
                <li>
                    <div to="#" className='menuStyle'>1{items.title}</div>
                </li>
            )

        }
    }

    return <div>

        <div id="sidebar-menu" className='menuTotalBorder'>
            <ul className="metismenu list-unstyled" id="contentMenu">
                {
                    course_content.map((data, index) => (
                        <React.Fragment key={'Parent' + data.title + index}>
                            <li >
                                <div to="#" className={data.children !== undefined ? "has-arrow ml-4 menuAccordian menuStyle" : 'ml-4 menuStyle'}>
                                    <FileOutlined style={{ fontSize: 20, }} className='mx-2 px-2' />
                                    <span>{data.title}</span>
                                </div>
                                <ul className="sub-menu mx-4" aria-expanded="true">
                                    {
                                        data.children !== undefined && recursiveTree(data.children)
                                    }
                                </ul>
                            </li>
                        </React.Fragment>
                    ))
                }

            </ul>
        </div>
    </div>;
}
