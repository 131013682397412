import React from 'react'
import { Input } from 'antd'
import PropTypes from "prop-types"


const { TextArea } = Input
export default function TextInputBox({ OnchangeTextAnswer, user_selected }) {
    return (
        <div className='d-flex' style={{ gap: 20 }}>
            <TextArea rows={4} onChange={(e)=>OnchangeTextAnswer(e.target.value)} value={user_selected} />
        </div>
    )
}

TextInputBox.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    likes: PropTypes.number,
    comp_perc: PropTypes.number,
    pending: PropTypes.number,
}
