import React, { Component } from "react"
import { Container } from "reactstrap"
import MetaTags from 'react-meta-tags';
import { Button,  Col,  PaginationItem,   PaginationLink,  Row } from "reactstrap"

import certificationSuccessImage from '../../assets/images/certification-success.png'
import certificationLogo from '../../assets/images/certificate-logo.png'
import jsPDF from 'jspdf';
import canvas from 'html2canvas';
// import pdf from 'html-pdf'

class Certification extends Component {
  constructor(props) {
    super(props)
    this.state={
      show_button: true
    }
    this.pageDiv = React.createRef();
}

componentDidMount(){
  var course_info_name = JSON.parse(sessionStorage.getItem("CourseName"))
  console.log(course_info_name)
  let name_of_the_course = course_info_name.name_of_the_course
  let artist_name = course_info_name.artist[0].artist_name
  this.setState({name_of_the_course, artist_name})
}


  BackToCourse = () => {
    // sessionStorage.setItem("certificate_back", JSON.stringify(true))
    var course_info_id = JSON.parse(sessionStorage.getItem("CourseDetails"))
    var course_info_name = JSON.parse(sessionStorage.getItem("CourseName"))
    console.log(course_info_id, "course!!", course_info_name)
    var course_name = course_info_name.name_of_the_course.trim().replace(/[^A-Z0-9]\s+/ig, "").replaceAll(' ', '-')
    document.location.href = ("/course/CourseDetails" + '?' + course_info_id + '?' + course_name.toLowerCase())
  }

  downloadPdf =async() => {
    console.log(this.pageDiv)
   await this.setState({show_button: false})
    const pageDiv = this.pageDiv.current;  
    canvas(pageDiv).then(canvas => {
      // const pdf = new jsPDF();
      const pdf = new jsPDF('p', 'mm', 'a4');
      // pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 211, 298);
      pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 211, 211);
      pdf.save('magarisa-certificate.pdf');
    });
  }

  render() {
    return (
      <Container className="pageWrapperBoxFluid paddingTop60">
        <Row className="mt-4">
          <Col style={{textAlign: 'right'}}>
          <Button className="" style={{width: '150px'}} color= 'primary' outline onClick={()=>{this.BackToCourse()}}>Back</Button>
          </Col>
        </Row>
        <Row>
          {/* <div ref={this.pageDiv}> */}
            <div className="justify-content-start align-items-stretch h-100 pageDivWrapper">
              <div className="certificateContentColumnDivWrapper" ref={this.pageDiv}>
                <div className="certificateContentColumnDiv">
                  <img src={certificationSuccessImage} />
                  <h1>Congratulations!</h1>
                  {/* <p className="fontQS">You have succesfully completed your course by <span className="artistName">Dr. Jayanthi Kumaresh </span> on “<span className="artistName">Impact Of Extrinsic Motivation On Intrinsic</span>”</p> */}
                  <p className="fontQS">You have succesfully completed your course by <span className="artistName">{this.state.artist_name}</span><br /> on “<span className="artistName">{this.state.name_of_the_course}</span>”</p>
                  <div className="powrdBy">
                    <p className="fontQS small">Powered by</p>
                    <img src={certificationLogo} />
                  </div>
                {
                  this.state.show_button &&
                  <Button className="downloadCertificateButton downloadCertificateButton" onClick={this.downloadPdf}>Download Certificate</Button>
                }
                  <div className="studentPercentageDiv mt-4">
                    <p>You’re among <strong>8%</strong> of students who has successfully achieved such growth!</p>
                  </div>
                </div>
              </div>
            </div>
          {/* </div> */}
          
           
        </Row>
        
    </Container>
    )
  }
}

export default Certification




// downloadPdf =async() => {
//   console.log(this.pageDiv)
//  await this.setState({show_button: false})
//   const pageDiv = this.pageDiv.current;  
//   html2canvas(pageDiv).then(canvas => {
//     // const pdf = new jsPDF('p', 'mm', 'a4');
//     const imgData = canvas.toDataURL('image/png');
//     const pdf = new jsPDF('p', 'pt', 'a4');
//     pdf.addImage(imgData, 'PNG', 0, 0, 595, 842);
//     pdf.save('magarisa-certificate.pdf');
//   });
// }