import baseAPIURL from "./baseAPIURL"
// import env from 'dotenv'
var axios = require('axios')
var urlSocket = axios.create({
    
    baseURL: baseAPIURL,
    mode: 'no-cors',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: process.env.REACT_APP_APIKEY,
    },
    timeout: 100000,
    
})

export default urlSocket