import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import SimpleBar from "simplebar-react"
import CourseTree from './Component/CourseTree'
import './CSS/Stye.scss'
// import _ from 'lodash'

export default class Reports extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showTable2: false,
      studentName: 'JB',
      tbIndex: 0,
      course_content: [
        {
          "id": 1,
          "document_id": 1,
          "company_id": "6130a7db9266b20611da8737",
          "errorMsg": "",
          "document_type": 0,
          "children": [
            {
              "company_id": "6130a7db9266b20611da8737",
              "owner_id": "616141918fd1240ae4b28b8b",
              "template_id": null,
              "document_type": 1,
              "document_id": 4,
              "parent_id": 1,
              "id": 4,
              "parent": 1,
              "title": "Video",
              "type": 0,
              "content": [
                {
                  "keyword": [
                    "Video"
                  ],
                  "content_name": "Video",
                  "content_type": 1,
                  "indexOfLanguage": 0,
                  "language_name": "Tamil",
                  "language": [
                    {
                      "language_id": 1,
                      "language_name": "Tamil",
                      "value": "Tamil",
                      "content_type": 1,
                      "media_files": {
                        "main_file": {
                          "path": "886kb-flowers.mp4",
                          "preview": "",
                          "formattedSize": "865.38 KB",
                          "media_type": "1",
                          "output_url": "018391db68c5445119b73960d384805bf03d231fcc8b67be3e0be880ab2eaf9e/hls_018391db68c5445119b73960d384805bf03d231fcc8b67be3e0be880ab2eaf9e.m3u8",
                          "video_id": "1643279689566-x0xwlg",
                          "input_key": "lms-demo/ac4e586-2e4c-776d-c4a-6e66b86e8d54content.mp4",
                          "origina_name": "886kb-flowers.mp4",
                          "input_url": "ac4e586-2e4c-776d-c4a-6e66b86e8d54content.mp4"
                        },
                        "multi_angle_video": []
                      }
                    },
                    {
                      "language_id": 2,
                      "language_name": "English",
                      "value": "English",
                      "content_type": 3,
                      "media_files": {
                        "main_file": {
                          "path": "pdf.pdf",
                          "preview": "",
                          "formattedSize": "2.96 KB",
                          "media_type": "0",
                          "output_url": "078b244-666b-7018-df10-5ab234812decontent.pdf",
                          "video_id": "",
                          "input_key": "",
                          "origina_name": "pdf.pdf"
                        },
                        "multi_angle_video": []
                      }
                    },
                    {
                      "language_id": 3,
                      "language_name": "Telugu",
                      "value": "Telugu",
                      "content_type": 2,
                      "media_files": {
                        "main_file": {
                          "path": "Aalaporan-Thamizhan-MassTamilan.com.mp3",
                          "preview": "",
                          "formattedSize": "10.91 MB",
                          "media_type": "1",
                          "output_url": "ed7e462-013e-22c0-0d8e-a605b1360ebccontent.mp3",
                          "video_id": "",
                          "input_key": "",
                          "origina_name": "Aalaporan-Thamizhan-MassTamilan.com.mp3"
                        },
                        "multi_angle_video": []
                      }
                    },
                    {
                      "language_id": 4,
                      "language_name": "Hindi",
                      "value": "Hindi",
                      "content_type": 1,
                      "media_files": {
                        "main_file": {
                          "status": true,
                          "preview": "",
                          "viewing_angle": "",
                          "output_url": "",
                          "video_id": "",
                          "input_key": ""
                        },
                        "multi_angle_video": []
                      }
                    }
                  ],
                  "keyword_invalid": false,
                  "description": "Description",
                  "content_desc_invalid": false,
                  "media_video_upload_status": 3,
                  "file_upload_prgress": null,
                  "avl_preview": true
                }
              ],
              "error": false,
              "errorMsg": ""
            },
            {
              "company_id": "6130a7db9266b20611da8737",
              "owner_id": "616141918fd1240ae4b28b8b",
              "template_id": "61f275a9ea5f821cd7d61814",
              "document_type": 1,
              "document_id": 7,
              "parent_id": 1,
              "id": 7,
              "parent": 1,
              "title": "Audio",
              "type": 0,
              "content": [
                {
                  "keyword": [
                    "Aduio"
                  ],
                  "content_name": "Audio",
                  "content_type": 2,
                  "indexOfLanguage": 0,
                  "language_name": "Tamil",
                  "language": [
                    {
                      "language_id": 1,
                      "language_name": "Tamil",
                      "value": "Tamil",
                      "content_type": 2,
                      "media_files": {
                        "main_file": {
                          "path": "Aalaporan-Thamizhan-MassTamilan.com.mp3",
                          "preview": "",
                          "formattedSize": "10.91 MB",
                          "media_type": "1",
                          "output_url": "f61fa7-1e23-03cc-447-c6f648aeedabcontent.mp3",
                          "video_id": "",
                          "input_key": "",
                          "origina_name": "Aalaporan-Thamizhan-MassTamilan.com.mp3"
                        },
                        "multi_angle_video": []
                      }
                    },
                    {
                      "language_id": 2,
                      "language_name": "English",
                      "value": "English",
                      "content_type": 1,
                      "media_files": {
                        "main_file": {
                          "status": true,
                          "preview": "",
                          "viewing_angle": "",
                          "output_url": "",
                          "video_id": "",
                          "input_key": ""
                        },
                        "multi_angle_video": []
                      }
                    },
                    {
                      "language_id": 3,
                      "language_name": "Telugu",
                      "value": "Telugu",
                      "content_type": 1,
                      "media_files": {
                        "main_file": {
                          "status": true,
                          "preview": "",
                          "viewing_angle": "",
                          "output_url": "",
                          "video_id": "",
                          "input_key": ""
                        },
                        "multi_angle_video": []
                      }
                    },
                    {
                      "language_id": 4,
                      "language_name": "Hindi",
                      "value": "Hindi",
                      "content_type": 1,
                      "media_files": {
                        "main_file": {
                          "status": true,
                          "preview": "",
                          "viewing_angle": "",
                          "output_url": "",
                          "video_id": "",
                          "input_key": ""
                        },
                        "multi_angle_video": []
                      }
                    }
                  ],
                  "keyword_invalid": false,
                  "description": "Description",
                  "content_desc_invalid": false,
                  "main_video_uploading": 0,
                  "media_video_upload_status": 0,
                  "file_upload_prgress": null
                }
              ],
              "error": false,
              "errorMsg": ""
            },
            {
              "company_id": "6130a7db9266b20611da8737",
              "owner_id": "616141918fd1240ae4b28b8b",
              "template_id": "61f275a9ea5f821cd7d61814",
              "document_type": 1,
              "document_id": 8,
              "parent_id": 1,
              "id": 8,
              "parent": 1,
              "title": "PDF",
              "type": 0,
              "content": [
                {
                  "keyword": [
                    "PDF"
                  ],
                  "content_name": "PDF",
                  "content_type": 3,
                  "indexOfLanguage": 0,
                  "language_name": "Tamil",
                  "language": [
                    {
                      "language_id": 1,
                      "language_name": "Tamil",
                      "value": "Tamil",
                      "content_type": 3,
                      "media_files": {
                        "main_file": {
                          "path": "pdf.pdf",
                          "preview": "",
                          "formattedSize": "2.96 KB",
                          "media_type": "0",
                          "output_url": "725a5-0b8-5c-8e18-ba626ab0d16content.pdf",
                          "video_id": "",
                          "input_key": "",
                          "origina_name": "pdf.pdf"
                        },
                        "multi_angle_video": []
                      }
                    },
                    {
                      "language_id": 2,
                      "language_name": "English",
                      "value": "English",
                      "content_type": 1,
                      "media_files": {
                        "main_file": {
                          "status": true,
                          "preview": "",
                          "viewing_angle": "",
                          "output_url": "",
                          "video_id": "",
                          "input_key": ""
                        },
                        "multi_angle_video": []
                      }
                    },
                    {
                      "language_id": 3,
                      "language_name": "Telugu",
                      "value": "Telugu",
                      "content_type": 1,
                      "media_files": {
                        "main_file": {
                          "status": true,
                          "preview": "",
                          "viewing_angle": "",
                          "output_url": "",
                          "video_id": "",
                          "input_key": ""
                        },
                        "multi_angle_video": []
                      }
                    },
                    {
                      "language_id": 4,
                      "language_name": "Hindi",
                      "value": "Hindi",
                      "content_type": 1,
                      "media_files": {
                        "main_file": {
                          "status": true,
                          "preview": "",
                          "viewing_angle": "",
                          "output_url": "",
                          "video_id": "",
                          "input_key": ""
                        },
                        "multi_angle_video": []
                      }
                    }
                  ],
                  "keyword_invalid": false,
                  "description": "PDF Description",
                  "content_desc_invalid": false,
                  "main_video_uploading": 0,
                  "media_video_upload_status": 0,
                  "file_upload_prgress": null
                }
              ],
              "error": false,
              "errorMsg": ""
            }
          ],
          "template_id": null,
          "type": 0,
          "owner_id": "616141918fd1240ae4b28b8b",
          "parent_id": null,
          "parent": null,
          "title": "Content 1",
          "expanded": true,
          "error": false
        },
        {
          "id": 2,
          "document_id": 2,
          "company_id": "6130a7db9266b20611da8737",
          "errorMsg": "",
          "document_type": 0,
          "children": [
            {
              "company_id": "6130a7db9266b20611da8737",
              "assessment_list": {
                "assessment_name": "Assessment",
                "assessment_languages": [],
                "total_no_ques": "12",
                "time_duration": "01:00",
                "re_attempt_duration": "01:00",
                "min_pass_perc": "35",
                "no_of_attempt": "3",
                "max_no_of_optn": "3",
                "display_qstn_lang": "",
                "topicList": {
                  "Test 01": [
                    {
                      "_id": "61729c485090fb2088dd49d0",
                      "topic_name": "Test 01",
                      "question": "இந்தியாவின் தேசிய வருமானத்தில் முக்கிய பங்கு வகிப்பது",
                      "question_img": null,
                      "language": "Tamil",
                      "question_type": "Single-Choice",
                      "sno": "1",
                      "ans_des": null,
                      "options": [
                        {
                          "is_ans": "1",
                          "option": "வேளாண்மை",
                          "option_img": null,
                          "_id": "61729c055090fb2088dd492d"
                        },
                        {
                          "is_ans": "0",
                          "option": "தொழிற்சாலை",
                          "option_img": null,
                          "_id": "61729c055090fb2088dd492c"
                        },
                        {
                          "is_ans": "0",
                          "option": "சுற்றுலா",
                          "option_img": null,
                          "_id": "61729c055090fb2088dd492b"
                        }
                      ],
                      "topic_id": "615ec03b891add1b2c2a6646",
                      "__v": 0,
                      "selected": true
                    },
                    {
                      "_id": "61729c485090fb2088dd49e4",
                      "topic_name": "Test 01",
                      "question": "மிக அதிக நீளமான கடற்கரையைக் கொண்ட தென் மாநிலம் எது?",
                      "question_img": null,
                      "language": "Tamil",
                      "question_type": "Single-Choice",
                      "sno": "2",
                      "ans_des": null,
                      "options": [
                        {
                          "is_ans": "0",
                          "option": "தமிழ்நாடு்",
                          "option_img": null,
                          "_id": "61729c055090fb2088dd490d"
                        },
                        {
                          "is_ans": "1",
                          "option": "ஆந்திரப்பிரதேசம்",
                          "option_img": null,
                          "_id": "61729c055090fb2088dd490c"
                        },
                        {
                          "is_ans": "0",
                          "option": "மகாராஷ்டிரா",
                          "option_img": null,
                          "_id": "61729c055090fb2088dd490b"
                        }
                      ],
                      "topic_id": "615ec03b891add1b2c2a6646",
                      "__v": 0,
                      "selected": true
                    },
                    {
                      "_id": "61779d7721d6d5174c5cc0de",
                      "topic_name": "Test 01",
                      "question": "ஈராக் நாட்டின் தலைநகரம்",
                      "question_img": null,
                      "language": "Tamil",
                      "question_type": "Multi-Choice",
                      "sno": "3",
                      "ans_des": null,
                      "topic_id": "615ec03b891add1b2c2a6646",
                      "options": [
                        {
                          "is_ans": "1",
                          "option": "டெல்லி",
                          "option_img": null,
                          "_id": "61779d7721d6d5174c5cc0e2"
                        },
                        {
                          "is_ans": "1",
                          "option": "பாக்தாக்",
                          "option_img": null,
                          "_id": "61779d7721d6d5174c5cc0e1"
                        },
                        {
                          "is_ans": "1",
                          "option": "பெல்ஜியம்",
                          "option_img": null,
                          "_id": "61779d7721d6d5174c5cc0e0"
                        },
                        {
                          "is_ans": "0",
                          "option": "பிரான்ஸ்",
                          "option_img": null,
                          "_id": "61779d7721d6d5174c5cc0df"
                        }
                      ],
                      "created_by": "616141918fd1240ae4b28b8b",
                      "updated_by": "616141918fd1240ae4b28b8b",
                      "__v": 0,
                      "selected": true
                    },
                    {
                      "_id": "61779fca0140e0183b210294",
                      "topic_name": "Test 01",
                      "question": "இந்திய அறிவயற் கழகம் அமைதுள்ள நகரம்",
                      "question_img": null,
                      "language": "Tamil",
                      "question_type": "Single-Choice",
                      "sno": "4",
                      "ans_des": null,
                      "topic_id": "615ec03b891add1b2c2a6646",
                      "options": [
                        {
                          "is_ans": "0",
                          "option": "ஹைதராபாத்",
                          "option_img": null,
                          "_id": "61779fca0140e0183b210298"
                        },
                        {
                          "is_ans": "1",
                          "option": "பெங்களூர்",
                          "option_img": null,
                          "_id": "61779fca0140e0183b210297"
                        },
                        {
                          "is_ans": "0",
                          "option": "மும்பை",
                          "option_img": null,
                          "_id": "61779fca0140e0183b210296"
                        },
                        {
                          "is_ans": "0",
                          "option": "புதுடில்லி",
                          "option_img": null,
                          "_id": "61779fca0140e0183b210295"
                        }
                      ],
                      "created_by": "616141918fd1240ae4b28b8b",
                      "updated_by": "616141918fd1240ae4b28b8b",
                      "__v": 0,
                      "selected": true
                    },
                    {
                      "_id": "6177a254c7792c1858da964b",
                      "topic_name": "Test 01",
                      "question": "இந்தியா முதல் அணுகுண்டு சோதனை நடத்திய இடம்",
                      "question_img": null,
                      "language": "Tamil",
                      "question_type": "Single-Choice",
                      "sno": "5",
                      "ans_des": null,
                      "topic_id": "615ec03b891add1b2c2a6646",
                      "options": [
                        {
                          "is_ans": "0",
                          "option": "ஸ்ரீஹரிகோட்டா",
                          "option_img": null,
                          "_id": "61779d7721d6d5174c5cc0e2"
                        },
                        {
                          "is_ans": "0",
                          "option": "டிராம்பே",
                          "option_img": null,
                          "_id": "61779d7721d6d5174c5cc0e1"
                        },
                        {
                          "is_ans": "1",
                          "option": "பொகரான்",
                          "option_img": null,
                          "_id": "61779d7721d6d5174c5cc0e0"
                        },
                        {
                          "is_ans": "0",
                          "option": "தாராப்பூர்",
                          "option_img": null,
                          "_id": "61779d7721d6d5174c5cc0df"
                        }
                      ],
                      "created_by": "616141918fd1240ae4b28b8b",
                      "updated_by": "616141918fd1240ae4b28b8b",
                      "__v": 0,
                      "selected": true
                    },
                    {
                      "_id": "6177b056e37fea1de538aef3",
                      "topic_name": "Test 01",
                      "question": "The world smallest country is",
                      "question_img": null,
                      "language": "Tamil",
                      "question_type": "Single-Choice",
                      "sno": "6",
                      "ans_des": null,
                      "topic_id": "616d5725b1be61059b88036e",
                      "options": [
                        {
                          "is_ans": "0",
                          "option": "Canada",
                          "option_img": null,
                          "_id": "6177b056e37fea1de538aef6"
                        },
                        {
                          "is_ans": "0",
                          "option": "Maldives",
                          "option_img": null,
                          "_id": "6177b056e37fea1de538aef5"
                        },
                        {
                          "is_ans": "1",
                          "option": "Vatican City",
                          "option_img": null,
                          "_id": "6177b056e37fea1de538aef4"
                        }
                      ],
                      "created_by": "61613f568fd1240ae4b28b85",
                      "updated_by": "61613f568fd1240ae4b28b85",
                      "__v": 0,
                      "selected": true
                    },
                    {
                      "_id": "61c054e42c27571693a29d5f",
                      "topic_name": "Test 01",
                      "question": "ஒலிம்பிக்கில் பதக்கம் வென்ற முதல் இந்தியப் பெண் யார்?",
                      "question_img": null,
                      "language": "English",
                      "question_type": "Multi-Choice",
                      "sno": "1",
                      "ans_des": null,
                      "options": [
                        {
                          "sno": 1,
                          "option": "ஹம்பி",
                          "option_img": null,
                          "is_ans": "0"
                        },
                        {
                          "sno": 2,
                          "option": "கர்ணம் மல்லேஸ்வரி",
                          "option_img": null,
                          "is_ans": "0"
                        },
                        {
                          "sno": 3,
                          "option": "அனுபமா அப்யங்கள்",
                          "option_img": null,
                          "is_ans": "1"
                        },
                        {
                          "sno": 4,
                          "option": "பி.டி. உஷா",
                          "option_img": null,
                          "is_ans": "1"
                        }
                      ],
                      "topic_id": "61c053562c27571693a29d56",
                      "created_by": "616141918fd1240ae4b28b8b",
                      "updated_by": "616141918fd1240ae4b28b8b",
                      "__v": 0,
                      "selected": true
                    },
                    {
                      "_id": "61c0553d2c27571693a29d62",
                      "topic_name": "Test 01",
                      "question": "Who was the first US President to visit India?",
                      "question_img": null,
                      "language": "English",
                      "question_type": "Multi-Choice",
                      "sno": "2",
                      "ans_des": null,
                      "options": [
                        {
                          "sno": 1,
                          "option": "ஜார்ஜ் வாஷிங்டன்",
                          "option_img": null,
                          "is_ans": "0"
                        },
                        {
                          "sno": 2,
                          "option": "டேவிட் ஜசன் ஹோவர்",
                          "option_img": null,
                          "is_ans": "1"
                        },
                        {
                          "sno": 3,
                          "option": "ரீகன்",
                          "option_img": null,
                          "is_ans": "1"
                        },
                        {
                          "sno": 4,
                          "option": "ஜான் எஃப். கென்னடி",
                          "option_img": null,
                          "is_ans": "1"
                        }
                      ],
                      "topic_id": "61c053562c27571693a29d56",
                      "created_by": "616141918fd1240ae4b28b8b",
                      "updated_by": "616141918fd1240ae4b28b8b",
                      "__v": 0,
                      "selected": true
                    },
                    {
                      "_id": "61c05a4b2c27571693a29d66",
                      "topic_name": "Test 01",
                      "question": "Which of the following was the capital of Chola dynasty?",
                      "question_img": null,
                      "language": "English",
                      "question_type": "Single-Choice",
                      "sno": "3",
                      "ans_des": null,
                      "options": [
                        {
                          "sno": 1,
                          "option": "Madurai",
                          "option_img": null,
                          "is_ans": "0"
                        },
                        {
                          "sno": 2,
                          "option": "Karur",
                          "option_img": null,
                          "is_ans": "0"
                        },
                        {
                          "sno": 3,
                          "option": "Uraiyaur",
                          "option_img": null,
                          "is_ans": "1"
                        },
                        {
                          "sno": 4,
                          "option": "Kaveripattnam",
                          "option_img": null,
                          "is_ans": "0"
                        }
                      ],
                      "topic_id": "61c059f32c27571693a29d63",
                      "created_by": "616141918fd1240ae4b28b8b",
                      "updated_by": "616141918fd1240ae4b28b8b",
                      "__v": 0,
                      "selected": true
                    },
                    {
                      "_id": "61c05a892c27571693a29d69",
                      "topic_name": "Test 01",
                      "question": "Jayamkondam in Tamil Nadu is famous for which among the following minerals?",
                      "question_img": null,
                      "language": "English",
                      "question_type": "Multi-Choice",
                      "sno": "4",
                      "ans_des": null,
                      "options": [
                        {
                          "sno": 1,
                          "option": "Bauxite",
                          "option_img": null,
                          "is_ans": "1"
                        },
                        {
                          "sno": 2,
                          "option": "Lignite",
                          "option_img": null,
                          "is_ans": "0"
                        },
                        {
                          "sno": 3,
                          "option": "Calcite",
                          "option_img": null,
                          "is_ans": "1"
                        },
                        {
                          "sno": 4,
                          "option": "Magnesite",
                          "option_img": null,
                          "is_ans": "0"
                        }
                      ],
                      "topic_id": "61c059f32c27571693a29d63",
                      "created_by": "616141918fd1240ae4b28b8b",
                      "updated_by": "616141918fd1240ae4b28b8b",
                      "__v": 0,
                      "selected": true
                    },
                    {
                      "_id": "61c16b3920fc1f05f6995d6a",
                      "topic_name": "Test 01",
                      "question": "Which day is celebrated as World Environment Day?",
                      "question_img": null,
                      "language": "English",
                      "question_type": "Single-Choice",
                      "sno": "5",
                      "ans_des": null,
                      "options": [
                        {
                          "sno": 1,
                          "option": "16 May",
                          "option_img": null,
                          "is_ans": "0"
                        },
                        {
                          "sno": 2,
                          "option": "5 June",
                          "option_img": null,
                          "is_ans": "1"
                        },
                        {
                          "sno": 3,
                          "option": "17July",
                          "option_img": null,
                          "is_ans": "0"
                        },
                        {
                          "sno": 4,
                          "option": "18 December",
                          "option_img": null,
                          "is_ans": "0"
                        }
                      ],
                      "topic_id": "61c16a4220fc1f05f6995d67",
                      "created_by": "616141918fd1240ae4b28b8b",
                      "updated_by": "616141918fd1240ae4b28b8b",
                      "__v": 0,
                      "selected": true
                    },
                    {
                      "_id": "61c16b7d20fc1f05f6995d6d",
                      "topic_name": "Test 01",
                      "question": "Which day is celebrated as Human Rights Day?",
                      "question_img": null,
                      "language": "English",
                      "question_type": "Single-Choice",
                      "sno": "6",
                      "ans_des": null,
                      "options": [
                        {
                          "sno": 1,
                          "option": "10 December",
                          "option_img": null,
                          "is_ans": "1"
                        },
                        {
                          "sno": 2,
                          "option": "22 September",
                          "option_img": null,
                          "is_ans": "0"
                        },
                        {
                          "sno": 3,
                          "option": "21 January",
                          "option_img": null,
                          "is_ans": "0"
                        },
                        {
                          "sno": 4,
                          "option": "2 April",
                          "option_img": null,
                          "is_ans": "0"
                        }
                      ],
                      "topic_id": "61c16a4220fc1f05f6995d67",
                      "created_by": "616141918fd1240ae4b28b8b",
                      "updated_by": "616141918fd1240ae4b28b8b",
                      "__v": 0,
                      "selected": true
                    }
                  ],
                  "Test 03": [
                    {
                      "_id": "6177e7c16bcbdf361a1bfcd0",
                      "topic_name": "Test 03",
                      "question": "இந்தியா விண்வெளி யுகத்திற்குள் நுழைந்ததற்குக் காரணமானவர்",
                      "question_img": null,
                      "language": "English",
                      "question_type": "Single-Choice",
                      "sno": "1",
                      "ans_des": null,
                      "options": [
                        {
                          "is_ans": "1",
                          "option": "A.P.J. அப்துல் கலாம்",
                          "option_img": null,
                          "_id": "6177e7c16bcbdf361a1bfcd3"
                        },
                        {
                          "is_ans": "0",
                          "option": "சுல்தான்",
                          "option_img": null,
                          "_id": "6177e7c16bcbdf361a1bfcd2"
                        },
                        {
                          "is_ans": "0",
                          "option": "அலிமுகமது",
                          "option_img": null,
                          "_id": "6177e7c16bcbdf361a1bfcd1"
                        }
                      ],
                      "topic_id": "6177e60ed0812935c6906c21",
                      "__v": 0,
                      "selected": true
                    },
                    {
                      "_id": "6177e7c16bcbdf361a1bfcd4",
                      "topic_name": "Test 03",
                      "question": "The first Indian Satellite was",
                      "question_img": null,
                      "language": "English",
                      "question_type": "Multi-Choice",
                      "sno": "2",
                      "ans_des": null,
                      "options": [
                        {
                          "is_ans": "0",
                          "option": "Columbia",
                          "option_img": null,
                          "_id": "6177e7c16bcbdf361a1bfcd7"
                        },
                        {
                          "is_ans": "1",
                          "option": "Bhaskara",
                          "option_img": null,
                          "_id": "6177e7c16bcbdf361a1bfcd6"
                        },
                        {
                          "is_ans": "1",
                          "option": "Aryabhatta",
                          "option_img": null,
                          "_id": "6177e7c16bcbdf361a1bfcd5"
                        }
                      ],
                      "topic_id": "6177e60ed0812935c6906c21",
                      "__v": 0,
                      "selected": true
                    },
                    {
                      "_id": "6177e7c16bcbdf361a1bfcda",
                      "topic_name": "Test 03",
                      "question": "The brighest planet is",
                      "question_img": null,
                      "language": "English",
                      "question_type": "Single-Choice",
                      "sno": "4",
                      "ans_des": null,
                      "options": [
                        {
                          "is_ans": "1",
                          "option": "Venus",
                          "option_img": null,
                          "_id": "6177e7c16bcbdf361a1bfcdc"
                        },
                        {
                          "is_ans": "0",
                          "option": "Mars",
                          "option_img": null,
                          "_id": "6177e7c16bcbdf361a1bfcdb"
                        }
                      ],
                      "topic_id": "6177e60ed0812935c6906c21",
                      "__v": 0,
                      "selected": true
                    },
                    {
                      "_id": "6177e7c16bcbdf361a1bfcdd",
                      "topic_name": "Test 03",
                      "question": "ஆஸ்கார் பரிசு பெற்ற முதல் இந்தியர்",
                      "question_img": null,
                      "language": "English",
                      "question_type": "Single-Choice",
                      "sno": "3",
                      "ans_des": null,
                      "options": [
                        {
                          "is_ans": "0",
                          "option": "பானு அத்தையா",
                          "option_img": null,
                          "_id": "6177e7c16bcbdf361a1bfce0"
                        },
                        {
                          "is_ans": "1",
                          "option": "சத்யஜித்ரே",
                          "option_img": null,
                          "_id": "6177e7c16bcbdf361a1bfcdf"
                        },
                        {
                          "is_ans": "0",
                          "option": "நஸ்ருதீன் ஷா",
                          "option_img": null,
                          "_id": "6177e7c16bcbdf361a1bfcde"
                        }
                      ],
                      "topic_id": "6177e60ed0812935c6906c21",
                      "__v": 0,
                      "selected": true
                    },
                    {
                      "_id": "6177e7c16bcbdf361a1bfce1",
                      "topic_name": "Test 03",
                      "question": "Satellite launching station is",
                      "question_img": null,
                      "language": "English",
                      "question_type": "Single-Choice",
                      "sno": "5",
                      "ans_des": null,
                      "options": [
                        {
                          "is_ans": "1",
                          "option": "Sriharikota",
                          "option_img": null,
                          "_id": "6177e7c16bcbdf361a1bfce3"
                        },
                        {
                          "is_ans": "0",
                          "option": "Pune",
                          "option_img": null,
                          "_id": "6177e7c16bcbdf361a1bfce2"
                        }
                      ],
                      "topic_id": "6177e60ed0812935c6906c21",
                      "__v": 0,
                      "selected": true
                    },
                    {
                      "_id": "61780cc8b8d401401ad788f9",
                      "topic_name": "Test 03",
                      "question": "பூமி ஏறத்தாழ கோள வடிவமானது என்று முதன்முதலில் கூறியவர்",
                      "question_img": null,
                      "language": "English",
                      "question_type": "Single-Choice",
                      "sno": "6",
                      "ans_des": null,
                      "options": [
                        {
                          "sno": 1,
                          "option": "கோபர்நிக்கஸ்",
                          "option_img": null,
                          "is_ans": "0"
                        },
                        {
                          "sno": 2,
                          "option": "ஆர்யபட்டர்",
                          "option_img": null,
                          "is_ans": "0"
                        },
                        {
                          "sno": 3,
                          "option": "பிதாகோரஸ்",
                          "option_img": null,
                          "is_ans": "0"
                        },
                        {
                          "sno": 4,
                          "option": " தாலமி",
                          "option_img": null,
                          "is_ans": "1"
                        }
                      ],
                      "topic_id": "615ec03b891add1b2c2a6646",
                      "created_by": "616141918fd1240ae4b28b8b",
                      "updated_by": "616141918fd1240ae4b28b8b",
                      "__v": 0,
                      "selected": true
                    },
                    {
                      "_id": "61780cecb8d401401ad788fc",
                      "topic_name": "Test 03",
                      "question": "குஜராத் மாநிலத்தின் தலைநகரம்",
                      "question_img": null,
                      "language": "Tamil",
                      "question_type": "Multi-Choice",
                      "sno": "1",
                      "ans_des": null,
                      "options": [
                        {
                          "sno": 1,
                          "option": "ஜூனகாத்",
                          "option_img": null,
                          "is_ans": "0"
                        },
                        {
                          "sno": 2,
                          "option": "சூரத்",
                          "option_img": null,
                          "is_ans": "0"
                        },
                        {
                          "sno": 3,
                          "option": "காந்தி நகர்",
                          "option_img": null,
                          "is_ans": "1"
                        },
                        {
                          "sno": 4,
                          "option": " ராஜ்கோட்",
                          "option_img": null,
                          "is_ans": "1"
                        }
                      ],
                      "topic_id": "615ec03b891add1b2c2a6646",
                      "created_by": "616141918fd1240ae4b28b8b",
                      "updated_by": "616141918fd1240ae4b28b8b",
                      "__v": 0,
                      "selected": true
                    },
                    {
                      "_id": "61780cfcb8d401401ad788ff",
                      "topic_name": "Test 03",
                      "question": "இந்தியாவில் காடுகளின் நிலப்பரப்பு",
                      "question_img": null,
                      "language": "Tamil",
                      "question_type": "Single-Choice",
                      "sno": "2",
                      "ans_des": null,
                      "options": [
                        {
                          "sno": 1,
                          "option": "15 சதவீதம்",
                          "option_img": null,
                          "is_ans": "0"
                        },
                        {
                          "sno": 2,
                          "option": "19 சதவீதம்",
                          "option_img": null,
                          "is_ans": "0"
                        },
                        {
                          "sno": 3,
                          "option": "23 சதவீதம்",
                          "option_img": null,
                          "is_ans": "1"
                        },
                        {
                          "sno": 4,
                          "option": " 27 சதவீதம்",
                          "option_img": null,
                          "is_ans": "1"
                        }
                      ],
                      "topic_id": "615ec03b891add1b2c2a6646",
                      "created_by": "616141918fd1240ae4b28b8b",
                      "updated_by": "616141918fd1240ae4b28b8b",
                      "__v": 0,
                      "selected": true
                    },
                    {
                      "_id": "617d0b60db1a650d682ea368",
                      "topic_name": "Test 03",
                      "question": "ஜம்மு காஷ்மீரின் அரசாங்க மொழி",
                      "question_type": "Single-Choice",
                      "question_img": null,
                      "language": "Tamil",
                      "sno": "3",
                      "ans_des": null,
                      "options": [
                        {
                          "is_ans": "0",
                          "option": "ஹிந்தி",
                          "option_img": null,
                          "answer_description": ""
                        },
                        {
                          "sno": 2,
                          "option": "காஷ்மீர்",
                          "option_img": null,
                          "is_ans": "0"
                        },
                        {
                          "sno": 3,
                          "option": "உருது",
                          "option_img": null,
                          "is_ans": "1"
                        },
                        {
                          "sno": 4,
                          "option": "தோக்ரி",
                          "option_img": null,
                          "is_ans": "0"
                        }
                      ],
                      "session_id": "616141918fd1240ae4b28b8b",
                      "accepred_record": "0",
                      "remarks": [
                        {
                          "remark": "Minimum 2 options required."
                        },
                        {
                          "remark": "No options found."
                        },
                        {
                          "remark": "Both serial number and question text already exist for this topic and language."
                        }
                      ],
                      "created_by": "616141918fd1240ae4b28b8b",
                      "created_on": null,
                      "topic_id": "615ec03b891add1b2c2a6646",
                      "__v": 0,
                      "updated_by": "616141918fd1240ae4b28b8b",
                      "selected": true
                    },
                    {
                      "_id": "617d0b60db1a650d682ea369",
                      "topic_name": "Test 03",
                      "question": "உலகத்தில் எந்த நாடு அதிக அளவில் ரப்பர் உற்பத்தி செய்கிறது?",
                      "question_type": "Single-Choice",
                      "question_img": null,
                      "language": "Tamil",
                      "sno": "4",
                      "ans_des": null,
                      "options": [
                        {
                          "is_ans": "0",
                          "option": "தென் ஆப்பிரிக்கா",
                          "option_img": null,
                          "answer_description": ""
                        },
                        {
                          "is_ans": "0",
                          "option": "ஆஸ்திரேலியா",
                          "option_img": null,
                          "answer_description": ""
                        },
                        {
                          "is_ans": "1",
                          "option": "மலேசியா",
                          "option_img": null,
                          "answer_description": ""
                        },
                        {
                          "sno": 4,
                          "option": "இந்தியா",
                          "option_img": null,
                          "is_ans": "0"
                        }
                      ],
                      "session_id": "616141918fd1240ae4b28b8b",
                      "accepred_record": "0",
                      "remarks": [
                        {
                          "remark": "Question already exists within the excel sheet."
                        },
                        {
                          "remark": "Serial number already exists for this topic and language."
                        }
                      ],
                      "created_by": "616141918fd1240ae4b28b8b",
                      "created_on": null,
                      "topic_id": "615ec03b891add1b2c2a6646",
                      "__v": 0,
                      "updated_by": "616141918fd1240ae4b28b8b",
                      "selected": true
                    },
                    {
                      "_id": "61c053b92c27571693a29d59",
                      "topic_name": "Test 03",
                      "question": "இந்தியாவின் மிகப்பெரிய நதி எது?",
                      "question_img": null,
                      "language": "Tamil",
                      "question_type": "Single-Choice",
                      "sno": "5",
                      "ans_des": null,
                      "options": [
                        {
                          "sno": 1,
                          "option": "பிரம்மபுத்திரா",
                          "option_img": null,
                          "is_ans": "0"
                        },
                        {
                          "sno": 2,
                          "option": "கங்கை",
                          "option_img": null,
                          "is_ans": "1"
                        },
                        {
                          "sno": 3,
                          "option": "கிருஷ்ணா",
                          "option_img": null,
                          "is_ans": "0"
                        },
                        {
                          "sno": 4,
                          "option": "சிந்து",
                          "option_img": null,
                          "is_ans": "0"
                        }
                      ],
                      "topic_id": "61c053562c27571693a29d56",
                      "created_by": "616141918fd1240ae4b28b8b",
                      "updated_by": "616141918fd1240ae4b28b8b",
                      "__v": 0,
                      "selected": true
                    },
                    {
                      "_id": "61c054852c27571693a29d5c",
                      "topic_name": "Test 03",
                      "question": "When did the Constitution of India come into force?",
                      "question_img": null,
                      "language": "Tamil",
                      "question_type": "Single-Choice",
                      "sno": "6",
                      "ans_des": null,
                      "options": [
                        {
                          "sno": 1,
                          "option": "1942",
                          "option_img": null,
                          "is_ans": "0"
                        },
                        {
                          "sno": 2,
                          "option": "1950",
                          "option_img": null,
                          "is_ans": "0"
                        },
                        {
                          "sno": 3,
                          "option": "1945",
                          "option_img": null,
                          "is_ans": "0"
                        },
                        {
                          "sno": 4,
                          "option": "1947",
                          "option_img": null,
                          "is_ans": "1"
                        }
                      ],
                      "topic_id": "61c053562c27571693a29d56",
                      "created_by": "616141918fd1240ae4b28b8b",
                      "updated_by": "616141918fd1240ae4b28b8b",
                      "__v": 0,
                      "selected": true
                    }
                  ]
                },
                "questions_entry": [
                  {
                    "topic_name": "Test 01",
                    "content": [
                      {
                        "_id": "61729c485090fb2088dd49d0",
                        "topic_name": "Test 01",
                        "question": "இந்தியாவின் தேசிய வருமானத்தில் முக்கிய பங்கு வகிப்பது",
                        "question_img": null,
                        "language": "Tamil",
                        "question_type": "Single-Choice",
                        "sno": "1",
                        "ans_des": null,
                        "options": [
                          {
                            "is_ans": "1",
                            "option": "வேளாண்மை",
                            "option_img": null,
                            "_id": "61729c055090fb2088dd492d"
                          },
                          {
                            "is_ans": "0",
                            "option": "தொழிற்சாலை",
                            "option_img": null,
                            "_id": "61729c055090fb2088dd492c"
                          },
                          {
                            "is_ans": "0",
                            "option": "சுற்றுலா",
                            "option_img": null,
                            "_id": "61729c055090fb2088dd492b"
                          }
                        ],
                        "topic_id": "615ec03b891add1b2c2a6646",
                        "__v": 0,
                        "selected": true
                      },
                      {
                        "_id": "61729c485090fb2088dd49e4",
                        "topic_name": "Test 01",
                        "question": "மிக அதிக நீளமான கடற்கரையைக் கொண்ட தென் மாநிலம் எது?",
                        "question_img": null,
                        "language": "Tamil",
                        "question_type": "Single-Choice",
                        "sno": "2",
                        "ans_des": null,
                        "options": [
                          {
                            "is_ans": "0",
                            "option": "தமிழ்நாடு்",
                            "option_img": null,
                            "_id": "61729c055090fb2088dd490d"
                          },
                          {
                            "is_ans": "1",
                            "option": "ஆந்திரப்பிரதேசம்",
                            "option_img": null,
                            "_id": "61729c055090fb2088dd490c"
                          },
                          {
                            "is_ans": "0",
                            "option": "மகாராஷ்டிரா",
                            "option_img": null,
                            "_id": "61729c055090fb2088dd490b"
                          }
                        ],
                        "topic_id": "615ec03b891add1b2c2a6646",
                        "__v": 0,
                        "selected": true
                      },
                      {
                        "_id": "61779d7721d6d5174c5cc0de",
                        "topic_name": "Test 01",
                        "question": "ஈராக் நாட்டின் தலைநகரம்",
                        "question_img": null,
                        "language": "Tamil",
                        "question_type": "Multi-Choice",
                        "sno": "3",
                        "ans_des": null,
                        "topic_id": "615ec03b891add1b2c2a6646",
                        "options": [
                          {
                            "is_ans": "1",
                            "option": "டெல்லி",
                            "option_img": null,
                            "_id": "61779d7721d6d5174c5cc0e2"
                          },
                          {
                            "is_ans": "1",
                            "option": "பாக்தாக்",
                            "option_img": null,
                            "_id": "61779d7721d6d5174c5cc0e1"
                          },
                          {
                            "is_ans": "1",
                            "option": "பெல்ஜியம்",
                            "option_img": null,
                            "_id": "61779d7721d6d5174c5cc0e0"
                          },
                          {
                            "is_ans": "0",
                            "option": "பிரான்ஸ்",
                            "option_img": null,
                            "_id": "61779d7721d6d5174c5cc0df"
                          }
                        ],
                        "created_by": "616141918fd1240ae4b28b8b",
                        "updated_by": "616141918fd1240ae4b28b8b",
                        "__v": 0,
                        "selected": true
                      },
                      {
                        "_id": "61779fca0140e0183b210294",
                        "topic_name": "Test 01",
                        "question": "இந்திய அறிவயற் கழகம் அமைதுள்ள நகரம்",
                        "question_img": null,
                        "language": "Tamil",
                        "question_type": "Single-Choice",
                        "sno": "4",
                        "ans_des": null,
                        "topic_id": "615ec03b891add1b2c2a6646",
                        "options": [
                          {
                            "is_ans": "0",
                            "option": "ஹைதராபாத்",
                            "option_img": null,
                            "_id": "61779fca0140e0183b210298"
                          },
                          {
                            "is_ans": "1",
                            "option": "பெங்களூர்",
                            "option_img": null,
                            "_id": "61779fca0140e0183b210297"
                          },
                          {
                            "is_ans": "0",
                            "option": "மும்பை",
                            "option_img": null,
                            "_id": "61779fca0140e0183b210296"
                          },
                          {
                            "is_ans": "0",
                            "option": "புதுடில்லி",
                            "option_img": null,
                            "_id": "61779fca0140e0183b210295"
                          }
                        ],
                        "created_by": "616141918fd1240ae4b28b8b",
                        "updated_by": "616141918fd1240ae4b28b8b",
                        "__v": 0,
                        "selected": true
                      },
                      {
                        "_id": "6177a254c7792c1858da964b",
                        "topic_name": "Test 01",
                        "question": "இந்தியா முதல் அணுகுண்டு சோதனை நடத்திய இடம்",
                        "question_img": null,
                        "language": "Tamil",
                        "question_type": "Single-Choice",
                        "sno": "5",
                        "ans_des": null,
                        "topic_id": "615ec03b891add1b2c2a6646",
                        "options": [
                          {
                            "is_ans": "0",
                            "option": "ஸ்ரீஹரிகோட்டா",
                            "option_img": null,
                            "_id": "61779d7721d6d5174c5cc0e2"
                          },
                          {
                            "is_ans": "0",
                            "option": "டிராம்பே",
                            "option_img": null,
                            "_id": "61779d7721d6d5174c5cc0e1"
                          },
                          {
                            "is_ans": "1",
                            "option": "பொகரான்",
                            "option_img": null,
                            "_id": "61779d7721d6d5174c5cc0e0"
                          },
                          {
                            "is_ans": "0",
                            "option": "தாராப்பூர்",
                            "option_img": null,
                            "_id": "61779d7721d6d5174c5cc0df"
                          }
                        ],
                        "created_by": "616141918fd1240ae4b28b8b",
                        "updated_by": "616141918fd1240ae4b28b8b",
                        "__v": 0,
                        "selected": true
                      },
                      {
                        "_id": "6177b056e37fea1de538aef3",
                        "topic_name": "Test 01",
                        "question": "The world smallest country is",
                        "question_img": null,
                        "language": "Tamil",
                        "question_type": "Single-Choice",
                        "sno": "6",
                        "ans_des": null,
                        "topic_id": "616d5725b1be61059b88036e",
                        "options": [
                          {
                            "is_ans": "0",
                            "option": "Canada",
                            "option_img": null,
                            "_id": "6177b056e37fea1de538aef6"
                          },
                          {
                            "is_ans": "0",
                            "option": "Maldives",
                            "option_img": null,
                            "_id": "6177b056e37fea1de538aef5"
                          },
                          {
                            "is_ans": "1",
                            "option": "Vatican City",
                            "option_img": null,
                            "_id": "6177b056e37fea1de538aef4"
                          }
                        ],
                        "created_by": "61613f568fd1240ae4b28b85",
                        "updated_by": "61613f568fd1240ae4b28b85",
                        "__v": 0,
                        "selected": true
                      },
                      {
                        "_id": "61c054e42c27571693a29d5f",
                        "topic_name": "Test 01",
                        "question": "ஒலிம்பிக்கில் பதக்கம் வென்ற முதல் இந்தியப் பெண் யார்?",
                        "question_img": null,
                        "language": "English",
                        "question_type": "Multi-Choice",
                        "sno": "1",
                        "ans_des": null,
                        "options": [
                          {
                            "sno": 1,
                            "option": "ஹம்பி",
                            "option_img": null,
                            "is_ans": "0"
                          },
                          {
                            "sno": 2,
                            "option": "கர்ணம் மல்லேஸ்வரி",
                            "option_img": null,
                            "is_ans": "0"
                          },
                          {
                            "sno": 3,
                            "option": "அனுபமா அப்யங்கள்",
                            "option_img": null,
                            "is_ans": "1"
                          },
                          {
                            "sno": 4,
                            "option": "பி.டி. உஷா",
                            "option_img": null,
                            "is_ans": "1"
                          }
                        ],
                        "topic_id": "61c053562c27571693a29d56",
                        "created_by": "616141918fd1240ae4b28b8b",
                        "updated_by": "616141918fd1240ae4b28b8b",
                        "__v": 0,
                        "selected": true
                      },
                      {
                        "_id": "61c0553d2c27571693a29d62",
                        "topic_name": "Test 01",
                        "question": "Who was the first US President to visit India?",
                        "question_img": null,
                        "language": "English",
                        "question_type": "Multi-Choice",
                        "sno": "2",
                        "ans_des": null,
                        "options": [
                          {
                            "sno": 1,
                            "option": "ஜார்ஜ் வாஷிங்டன்",
                            "option_img": null,
                            "is_ans": "0"
                          },
                          {
                            "sno": 2,
                            "option": "டேவிட் ஜசன் ஹோவர்",
                            "option_img": null,
                            "is_ans": "1"
                          },
                          {
                            "sno": 3,
                            "option": "ரீகன்",
                            "option_img": null,
                            "is_ans": "1"
                          },
                          {
                            "sno": 4,
                            "option": "ஜான் எஃப். கென்னடி",
                            "option_img": null,
                            "is_ans": "1"
                          }
                        ],
                        "topic_id": "61c053562c27571693a29d56",
                        "created_by": "616141918fd1240ae4b28b8b",
                        "updated_by": "616141918fd1240ae4b28b8b",
                        "__v": 0,
                        "selected": true
                      },
                      {
                        "_id": "61c05a4b2c27571693a29d66",
                        "topic_name": "Test 01",
                        "question": "Which of the following was the capital of Chola dynasty?",
                        "question_img": null,
                        "language": "English",
                        "question_type": "Single-Choice",
                        "sno": "3",
                        "ans_des": null,
                        "options": [
                          {
                            "sno": 1,
                            "option": "Madurai",
                            "option_img": null,
                            "is_ans": "0"
                          },
                          {
                            "sno": 2,
                            "option": "Karur",
                            "option_img": null,
                            "is_ans": "0"
                          },
                          {
                            "sno": 3,
                            "option": "Uraiyaur",
                            "option_img": null,
                            "is_ans": "1"
                          },
                          {
                            "sno": 4,
                            "option": "Kaveripattnam",
                            "option_img": null,
                            "is_ans": "0"
                          }
                        ],
                        "topic_id": "61c059f32c27571693a29d63",
                        "created_by": "616141918fd1240ae4b28b8b",
                        "updated_by": "616141918fd1240ae4b28b8b",
                        "__v": 0,
                        "selected": true
                      },
                      {
                        "_id": "61c05a892c27571693a29d69",
                        "topic_name": "Test 01",
                        "question": "Jayamkondam in Tamil Nadu is famous for which among the following minerals?",
                        "question_img": null,
                        "language": "English",
                        "question_type": "Multi-Choice",
                        "sno": "4",
                        "ans_des": null,
                        "options": [
                          {
                            "sno": 1,
                            "option": "Bauxite",
                            "option_img": null,
                            "is_ans": "1"
                          },
                          {
                            "sno": 2,
                            "option": "Lignite",
                            "option_img": null,
                            "is_ans": "0"
                          },
                          {
                            "sno": 3,
                            "option": "Calcite",
                            "option_img": null,
                            "is_ans": "1"
                          },
                          {
                            "sno": 4,
                            "option": "Magnesite",
                            "option_img": null,
                            "is_ans": "0"
                          }
                        ],
                        "topic_id": "61c059f32c27571693a29d63",
                        "created_by": "616141918fd1240ae4b28b8b",
                        "updated_by": "616141918fd1240ae4b28b8b",
                        "__v": 0,
                        "selected": true
                      },
                      {
                        "_id": "61c16b3920fc1f05f6995d6a",
                        "topic_name": "Test 01",
                        "question": "Which day is celebrated as World Environment Day?",
                        "question_img": null,
                        "language": "English",
                        "question_type": "Single-Choice",
                        "sno": "5",
                        "ans_des": null,
                        "options": [
                          {
                            "sno": 1,
                            "option": "16 May",
                            "option_img": null,
                            "is_ans": "0"
                          },
                          {
                            "sno": 2,
                            "option": "5 June",
                            "option_img": null,
                            "is_ans": "1"
                          },
                          {
                            "sno": 3,
                            "option": "17July",
                            "option_img": null,
                            "is_ans": "0"
                          },
                          {
                            "sno": 4,
                            "option": "18 December",
                            "option_img": null,
                            "is_ans": "0"
                          }
                        ],
                        "topic_id": "61c16a4220fc1f05f6995d67",
                        "created_by": "616141918fd1240ae4b28b8b",
                        "updated_by": "616141918fd1240ae4b28b8b",
                        "__v": 0,
                        "selected": true
                      },
                      {
                        "_id": "61c16b7d20fc1f05f6995d6d",
                        "topic_name": "Test 01",
                        "question": "Which day is celebrated as Human Rights Day?",
                        "question_img": null,
                        "language": "English",
                        "question_type": "Single-Choice",
                        "sno": "6",
                        "ans_des": null,
                        "options": [
                          {
                            "sno": 1,
                            "option": "10 December",
                            "option_img": null,
                            "is_ans": "1"
                          },
                          {
                            "sno": 2,
                            "option": "22 September",
                            "option_img": null,
                            "is_ans": "0"
                          },
                          {
                            "sno": 3,
                            "option": "21 January",
                            "option_img": null,
                            "is_ans": "0"
                          },
                          {
                            "sno": 4,
                            "option": "2 April",
                            "option_img": null,
                            "is_ans": "0"
                          }
                        ],
                        "topic_id": "61c16a4220fc1f05f6995d67",
                        "created_by": "616141918fd1240ae4b28b8b",
                        "updated_by": "616141918fd1240ae4b28b8b",
                        "__v": 0,
                        "selected": true
                      }
                    ],
                    "min_ques": 5,
                    "min_ques_invalid": false
                  },
                  {
                    "topic_name": "Test 03",
                    "content": [
                      {
                        "_id": "6177e7c16bcbdf361a1bfcd0",
                        "topic_name": "Test 03",
                        "question": "இந்தியா விண்வெளி யுகத்திற்குள் நுழைந்ததற்குக் காரணமானவர்",
                        "question_img": null,
                        "language": "English",
                        "question_type": "Single-Choice",
                        "sno": "1",
                        "ans_des": null,
                        "options": [
                          {
                            "is_ans": "1",
                            "option": "A.P.J. அப்துல் கலாம்",
                            "option_img": null,
                            "_id": "6177e7c16bcbdf361a1bfcd3"
                          },
                          {
                            "is_ans": "0",
                            "option": "சுல்தான்",
                            "option_img": null,
                            "_id": "6177e7c16bcbdf361a1bfcd2"
                          },
                          {
                            "is_ans": "0",
                            "option": "அலிமுகமது",
                            "option_img": null,
                            "_id": "6177e7c16bcbdf361a1bfcd1"
                          }
                        ],
                        "topic_id": "6177e60ed0812935c6906c21",
                        "__v": 0,
                        "selected": true
                      },
                      {
                        "_id": "6177e7c16bcbdf361a1bfcd4",
                        "topic_name": "Test 03",
                        "question": "The first Indian Satellite was",
                        "question_img": null,
                        "language": "English",
                        "question_type": "Multi-Choice",
                        "sno": "2",
                        "ans_des": null,
                        "options": [
                          {
                            "is_ans": "0",
                            "option": "Columbia",
                            "option_img": null,
                            "_id": "6177e7c16bcbdf361a1bfcd7"
                          },
                          {
                            "is_ans": "1",
                            "option": "Bhaskara",
                            "option_img": null,
                            "_id": "6177e7c16bcbdf361a1bfcd6"
                          },
                          {
                            "is_ans": "1",
                            "option": "Aryabhatta",
                            "option_img": null,
                            "_id": "6177e7c16bcbdf361a1bfcd5"
                          }
                        ],
                        "topic_id": "6177e60ed0812935c6906c21",
                        "__v": 0,
                        "selected": true
                      },
                      {
                        "_id": "6177e7c16bcbdf361a1bfcda",
                        "topic_name": "Test 03",
                        "question": "The brighest planet is",
                        "question_img": null,
                        "language": "English",
                        "question_type": "Single-Choice",
                        "sno": "4",
                        "ans_des": null,
                        "options": [
                          {
                            "is_ans": "1",
                            "option": "Venus",
                            "option_img": null,
                            "_id": "6177e7c16bcbdf361a1bfcdc"
                          },
                          {
                            "is_ans": "0",
                            "option": "Mars",
                            "option_img": null,
                            "_id": "6177e7c16bcbdf361a1bfcdb"
                          }
                        ],
                        "topic_id": "6177e60ed0812935c6906c21",
                        "__v": 0,
                        "selected": true
                      },
                      {
                        "_id": "6177e7c16bcbdf361a1bfcdd",
                        "topic_name": "Test 03",
                        "question": "ஆஸ்கார் பரிசு பெற்ற முதல் இந்தியர்",
                        "question_img": null,
                        "language": "English",
                        "question_type": "Single-Choice",
                        "sno": "3",
                        "ans_des": null,
                        "options": [
                          {
                            "is_ans": "0",
                            "option": "பானு அத்தையா",
                            "option_img": null,
                            "_id": "6177e7c16bcbdf361a1bfce0"
                          },
                          {
                            "is_ans": "1",
                            "option": "சத்யஜித்ரே",
                            "option_img": null,
                            "_id": "6177e7c16bcbdf361a1bfcdf"
                          },
                          {
                            "is_ans": "0",
                            "option": "நஸ்ருதீன் ஷா",
                            "option_img": null,
                            "_id": "6177e7c16bcbdf361a1bfcde"
                          }
                        ],
                        "topic_id": "6177e60ed0812935c6906c21",
                        "__v": 0,
                        "selected": true
                      },
                      {
                        "_id": "6177e7c16bcbdf361a1bfce1",
                        "topic_name": "Test 03",
                        "question": "Satellite launching station is",
                        "question_img": null,
                        "language": "English",
                        "question_type": "Single-Choice",
                        "sno": "5",
                        "ans_des": null,
                        "options": [
                          {
                            "is_ans": "1",
                            "option": "Sriharikota",
                            "option_img": null,
                            "_id": "6177e7c16bcbdf361a1bfce3"
                          },
                          {
                            "is_ans": "0",
                            "option": "Pune",
                            "option_img": null,
                            "_id": "6177e7c16bcbdf361a1bfce2"
                          }
                        ],
                        "topic_id": "6177e60ed0812935c6906c21",
                        "__v": 0,
                        "selected": true
                      },
                      {
                        "_id": "61780cc8b8d401401ad788f9",
                        "topic_name": "Test 03",
                        "question": "பூமி ஏறத்தாழ கோள வடிவமானது என்று முதன்முதலில் கூறியவர்",
                        "question_img": null,
                        "language": "English",
                        "question_type": "Single-Choice",
                        "sno": "6",
                        "ans_des": null,
                        "options": [
                          {
                            "sno": 1,
                            "option": "கோபர்நிக்கஸ்",
                            "option_img": null,
                            "is_ans": "0"
                          },
                          {
                            "sno": 2,
                            "option": "ஆர்யபட்டர்",
                            "option_img": null,
                            "is_ans": "0"
                          },
                          {
                            "sno": 3,
                            "option": "பிதாகோரஸ்",
                            "option_img": null,
                            "is_ans": "0"
                          },
                          {
                            "sno": 4,
                            "option": " தாலமி",
                            "option_img": null,
                            "is_ans": "1"
                          }
                        ],
                        "topic_id": "615ec03b891add1b2c2a6646",
                        "created_by": "616141918fd1240ae4b28b8b",
                        "updated_by": "616141918fd1240ae4b28b8b",
                        "__v": 0,
                        "selected": true
                      },
                      {
                        "_id": "61780cecb8d401401ad788fc",
                        "topic_name": "Test 03",
                        "question": "குஜராத் மாநிலத்தின் தலைநகரம்",
                        "question_img": null,
                        "language": "Tamil",
                        "question_type": "Multi-Choice",
                        "sno": "1",
                        "ans_des": null,
                        "options": [
                          {
                            "sno": 1,
                            "option": "ஜூனகாத்",
                            "option_img": null,
                            "is_ans": "0"
                          },
                          {
                            "sno": 2,
                            "option": "சூரத்",
                            "option_img": null,
                            "is_ans": "0"
                          },
                          {
                            "sno": 3,
                            "option": "காந்தி நகர்",
                            "option_img": null,
                            "is_ans": "1"
                          },
                          {
                            "sno": 4,
                            "option": " ராஜ்கோட்",
                            "option_img": null,
                            "is_ans": "1"
                          }
                        ],
                        "topic_id": "615ec03b891add1b2c2a6646",
                        "created_by": "616141918fd1240ae4b28b8b",
                        "updated_by": "616141918fd1240ae4b28b8b",
                        "__v": 0,
                        "selected": true
                      },
                      {
                        "_id": "61780cfcb8d401401ad788ff",
                        "topic_name": "Test 03",
                        "question": "இந்தியாவில் காடுகளின் நிலப்பரப்பு",
                        "question_img": null,
                        "language": "Tamil",
                        "question_type": "Single-Choice",
                        "sno": "2",
                        "ans_des": null,
                        "options": [
                          {
                            "sno": 1,
                            "option": "15 சதவீதம்",
                            "option_img": null,
                            "is_ans": "0"
                          },
                          {
                            "sno": 2,
                            "option": "19 சதவீதம்",
                            "option_img": null,
                            "is_ans": "0"
                          },
                          {
                            "sno": 3,
                            "option": "23 சதவீதம்",
                            "option_img": null,
                            "is_ans": "1"
                          },
                          {
                            "sno": 4,
                            "option": " 27 சதவீதம்",
                            "option_img": null,
                            "is_ans": "1"
                          }
                        ],
                        "topic_id": "615ec03b891add1b2c2a6646",
                        "created_by": "616141918fd1240ae4b28b8b",
                        "updated_by": "616141918fd1240ae4b28b8b",
                        "__v": 0,
                        "selected": true
                      },
                      {
                        "_id": "617d0b60db1a650d682ea368",
                        "topic_name": "Test 03",
                        "question": "ஜம்மு காஷ்மீரின் அரசாங்க மொழி",
                        "question_type": "Single-Choice",
                        "question_img": null,
                        "language": "Tamil",
                        "sno": "3",
                        "ans_des": null,
                        "options": [
                          {
                            "is_ans": "0",
                            "option": "ஹிந்தி",
                            "option_img": null,
                            "answer_description": ""
                          },
                          {
                            "sno": 2,
                            "option": "காஷ்மீர்",
                            "option_img": null,
                            "is_ans": "0"
                          },
                          {
                            "sno": 3,
                            "option": "உருது",
                            "option_img": null,
                            "is_ans": "1"
                          },
                          {
                            "sno": 4,
                            "option": "தோக்ரி",
                            "option_img": null,
                            "is_ans": "0"
                          }
                        ],
                        "session_id": "616141918fd1240ae4b28b8b",
                        "accepred_record": "0",
                        "remarks": [
                          {
                            "remark": "Minimum 2 options required."
                          },
                          {
                            "remark": "No options found."
                          },
                          {
                            "remark": "Both serial number and question text already exist for this topic and language."
                          }
                        ],
                        "created_by": "616141918fd1240ae4b28b8b",
                        "created_on": null,
                        "topic_id": "615ec03b891add1b2c2a6646",
                        "__v": 0,
                        "updated_by": "616141918fd1240ae4b28b8b",
                        "selected": true
                      },
                      {
                        "_id": "617d0b60db1a650d682ea369",
                        "topic_name": "Test 03",
                        "question": "உலகத்தில் எந்த நாடு அதிக அளவில் ரப்பர் உற்பத்தி செய்கிறது?",
                        "question_type": "Single-Choice",
                        "question_img": null,
                        "language": "Tamil",
                        "sno": "4",
                        "ans_des": null,
                        "options": [
                          {
                            "is_ans": "0",
                            "option": "தென் ஆப்பிரிக்கா",
                            "option_img": null,
                            "answer_description": ""
                          },
                          {
                            "is_ans": "0",
                            "option": "ஆஸ்திரேலியா",
                            "option_img": null,
                            "answer_description": ""
                          },
                          {
                            "is_ans": "1",
                            "option": "மலேசியா",
                            "option_img": null,
                            "answer_description": ""
                          },
                          {
                            "sno": 4,
                            "option": "இந்தியா",
                            "option_img": null,
                            "is_ans": "0"
                          }
                        ],
                        "session_id": "616141918fd1240ae4b28b8b",
                        "accepred_record": "0",
                        "remarks": [
                          {
                            "remark": "Question already exists within the excel sheet."
                          },
                          {
                            "remark": "Serial number already exists for this topic and language."
                          }
                        ],
                        "created_by": "616141918fd1240ae4b28b8b",
                        "created_on": null,
                        "topic_id": "615ec03b891add1b2c2a6646",
                        "__v": 0,
                        "updated_by": "616141918fd1240ae4b28b8b",
                        "selected": true
                      },
                      {
                        "_id": "61c053b92c27571693a29d59",
                        "topic_name": "Test 03",
                        "question": "இந்தியாவின் மிகப்பெரிய நதி எது?",
                        "question_img": null,
                        "language": "Tamil",
                        "question_type": "Single-Choice",
                        "sno": "5",
                        "ans_des": null,
                        "options": [
                          {
                            "sno": 1,
                            "option": "பிரம்மபுத்திரா",
                            "option_img": null,
                            "is_ans": "0"
                          },
                          {
                            "sno": 2,
                            "option": "கங்கை",
                            "option_img": null,
                            "is_ans": "1"
                          },
                          {
                            "sno": 3,
                            "option": "கிருஷ்ணா",
                            "option_img": null,
                            "is_ans": "0"
                          },
                          {
                            "sno": 4,
                            "option": "சிந்து",
                            "option_img": null,
                            "is_ans": "0"
                          }
                        ],
                        "topic_id": "61c053562c27571693a29d56",
                        "created_by": "616141918fd1240ae4b28b8b",
                        "updated_by": "616141918fd1240ae4b28b8b",
                        "__v": 0,
                        "selected": true
                      },
                      {
                        "_id": "61c054852c27571693a29d5c",
                        "topic_name": "Test 03",
                        "question": "When did the Constitution of India come into force?",
                        "question_img": null,
                        "language": "Tamil",
                        "question_type": "Single-Choice",
                        "sno": "6",
                        "ans_des": null,
                        "options": [
                          {
                            "sno": 1,
                            "option": "1942",
                            "option_img": null,
                            "is_ans": "0"
                          },
                          {
                            "sno": 2,
                            "option": "1950",
                            "option_img": null,
                            "is_ans": "0"
                          },
                          {
                            "sno": 3,
                            "option": "1945",
                            "option_img": null,
                            "is_ans": "0"
                          },
                          {
                            "sno": 4,
                            "option": "1947",
                            "option_img": null,
                            "is_ans": "1"
                          }
                        ],
                        "topic_id": "61c053562c27571693a29d56",
                        "created_by": "616141918fd1240ae4b28b8b",
                        "updated_by": "616141918fd1240ae4b28b8b",
                        "__v": 0,
                        "selected": true
                      }
                    ],
                    "min_ques": 5,
                    "min_ques_invalid": false
                  }
                ],
                "asses_lang": [
                  {
                    "language_id": 1,
                    "language_name": "Tamil",
                    "value": "Tamil",
                    "content_name": "",
                    "content_type": "Video",
                    "media_files": []
                  },
                  {
                    "language_id": 2,
                    "language_name": "English",
                    "value": "English",
                    "content_name": "",
                    "content_type": "Video",
                    "media_files": []
                  }
                ],
                "assesment_languages_invalid": false,
                "total_no_ques_invalid": false,
                "time_duration_invalid": false,
                "min_pass_perc_invalid": false,
                "max_no_of_optn_invalid": false,
                "no_of_attempt_invalid": false,
                "topicList_invalid": false,
                "re_attempt_duration_invalid": false
              },
              "owner_id": "616141918fd1240ae4b28b8b",
              "template_id": null,
              "document_type": 2,
              "document_id": 5,
              "parent_id": 2,
              "id": 5,
              "parent": 2,
              "title": "Assessment",
              "type": 0,
              "error": false,
              "errorMsg": ""
            }
          ],
          "template_id": null,
          "type": 0,
          "owner_id": "616141918fd1240ae4b28b8b",
          "parent_id": null,
          "parent": null,
          "title": "Content 2",
          "expanded": true,
          "error": false
        },
        {
          "id": 3,
          "document_id": 3,
          "company_id": "6130a7db9266b20611da8737",
          "errorMsg": "",
          "document_type": 0,
          "children": [
            {
              "company_id": "6130a7db9266b20611da8737",
              "assignment_list": {
                "assignment_name": "Assignment 1",
                "max_no_files": "4",
                "file_type": "Image ",
                "max_file_size": "4",
                "max_score": "40",
                "pass_mark": "35",
                "applicable_to": "This Topic",
                "required_for_nxt": true,
                "max_no_files_invalid": false,
                "file_type_invalid": false,
                "max_file_size_invalid": false,
                "max_score_invalid": false,
                "pass_mark_invalid": false
              },
              "owner_id": "616141918fd1240ae4b28b8b",
              "template_id": null,
              "document_type": 3,
              "document_id": 6,
              "parent_id": 3,
              "id": 6,
              "parent": 3,
              "title": "Assignment 1",
              "type": 0,
              "error": false,
              "errorMsg": ""
            }
          ],
          "template_id": null,
          "type": 0,
          "owner_id": "616141918fd1240ae4b28b8b",
          "parent_id": null,
          "parent": null,
          "title": "Content 3",
          "expanded": true,
          "error": false
        },
        {
          "id": 9,
          "document_id": 9,
          "company_id": "6130a7db9266b20611da8737",
          "errorMsg": "",
          "document_type": 0,
          "children": [
            {
              "company_id": "6130a7db9266b20611da8737",
              "assignment_list": {
                "assignment_name": "Song Assignment",
                "max_no_files": "3",
                "file_type": "Audio",
                "max_file_size": "30",
                "max_score": "5",
                "pass_mark": "35",
                "applicable_to": "This Topic",
                "required_for_nxt": false,
                "file_type_invalid": false,
                "max_no_files_invalid": false,
                "max_file_size_invalid": false,
                "max_score_invalid": false,
                "pass_mark_invalid": false,
                "description": "Please sing a song from any Tamil movie."
              },
              "owner_id": "616141918fd1240ae4b28b8b",
              "template_id": "61f275a9ea5f821cd7d61814",
              "document_type": 3,
              "document_id": 11,
              "parent_id": 9,
              "id": 11,
              "parent": 9,
              "title": "Song Assignment",
              "type": 0,
              "error": false,
              "errorMsg": ""
            },
            {
              "company_id": "6130a7db9266b20611da8737",
              "assignment_list": {
                "assignment_name": "Image Assignment",
                "max_no_files": "2",
                "file_type": "Image ",
                "max_file_size": "5",
                "max_score": "30",
                "pass_mark": "35",
                "applicable_to": "This Topic",
                "required_for_nxt": false,
                "file_type_invalid": false,
                "max_file_size_invalid": false,
                "max_score_invalid": false,
                "pass_mark_invalid": false,
                "max_no_files_invalid": false
              },
              "owner_id": "616141918fd1240ae4b28b8b",
              "template_id": "61f275a9ea5f821cd7d61814",
              "document_type": 3,
              "document_id": 12,
              "parent_id": 9,
              "id": 12,
              "parent": 9,
              "title": "Image Assignment",
              "type": 0,
              "error": false,
              "errorMsg": ""
            },
            {
              "company_id": "6130a7db9266b20611da8737",
              "assignment_list": {
                "assignment_name": "Video Assignment",
                "max_no_files": "3",
                "file_type": "Video",
                "max_file_size": "5",
                "max_score": "35",
                "pass_mark": "35",
                "applicable_to": "This Topic",
                "required_for_nxt": false,
                "max_no_files_invalid": false,
                "file_type_invalid": false,
                "max_file_size_invalid": false,
                "max_score_invalid": false,
                "pass_mark_invalid": false
              },
              "owner_id": "616141918fd1240ae4b28b8b",
              "template_id": "61f275a9ea5f821cd7d61814",
              "document_type": 3,
              "document_id": 13,
              "parent_id": 9,
              "id": 13,
              "parent": 9,
              "title": "Video Assignment",
              "type": 0,
              "error": false,
              "errorMsg": ""
            },
            {
              "company_id": "6130a7db9266b20611da8737",
              "assignment_list": {
                "assignment_name": "Docx Assignment",
                "max_no_files": "2",
                "file_type": "DOCX",
                "max_file_size": "10",
                "max_score": "35",
                "pass_mark": "35",
                "applicable_to": "This Topic",
                "required_for_nxt": false,
                "max_no_files_invalid": false,
                "file_type_invalid": false,
                "max_file_size_invalid": false,
                "max_score_invalid": false,
                "pass_mark_invalid": false
              },
              "owner_id": "616141918fd1240ae4b28b8b",
              "template_id": "61f275a9ea5f821cd7d61814",
              "document_type": 3,
              "document_id": 14,
              "parent_id": 9,
              "id": 14,
              "parent": 9,
              "title": "Docx Assignment",
              "type": 0,
              "error": false,
              "errorMsg": ""
            },
            {
              "company_id": "6130a7db9266b20611da8737",
              "assignment_list": {
                "assignment_name": "PDF Assignment",
                "max_no_files": "3",
                "file_type": "PDF",
                "max_file_size": "10",
                "max_score": "35",
                "pass_mark": "35",
                "applicable_to": "This Topic",
                "required_for_nxt": false,
                "file_type_invalid": false,
                "max_no_files_invalid": false,
                "max_file_size_invalid": false,
                "max_score_invalid": false,
                "pass_mark_invalid": false
              },
              "owner_id": "616141918fd1240ae4b28b8b",
              "template_id": "61f275a9ea5f821cd7d61814",
              "document_type": 3,
              "document_id": 15,
              "parent_id": 9,
              "id": 15,
              "parent": 9,
              "title": "PDF Assignment",
              "type": 0,
              "error": false,
              "errorMsg": ""
            },
            {
              "company_id": "6130a7db9266b20611da8737",
              "assignment_list": {
                "assignment_name": "Zip Assignment",
                "max_no_files": "3",
                "file_type": "zip​",
                "max_file_size": "10",
                "max_score": "35",
                "pass_mark": "30",
                "applicable_to": "This Topic",
                "required_for_nxt": false,
                "max_no_files_invalid": false,
                "file_type_invalid": false,
                "max_file_size_invalid": false,
                "max_score_invalid": false,
                "pass_mark_invalid": false
              },
              "owner_id": "616141918fd1240ae4b28b8b",
              "template_id": "61f275a9ea5f821cd7d61814",
              "document_type": 3,
              "document_id": 16,
              "parent_id": 9,
              "id": 16,
              "parent": 9,
              "title": "Zip Assignment",
              "type": 0,
              "error": false,
              "errorMsg": ""
            }
          ],
          "template_id": "61f275a9ea5f821cd7d61814",
          "type": 0,
          "owner_id": "616141918fd1240ae4b28b8b",
          "parent_id": null,
          "parent": null,
          "title": "Content 4",
          "expanded": true,
          "error": false
        },
        {
          "id": 17,
          "document_id": 17,
          "company_id": "6130a7db9266b20611da8737",
          "errorMsg": "",
          "document_type": 0,
          "children": [
            {
              "company_id": "6130a7db9266b20611da8737",
              "owner_id": "616141918fd1240ae4b28b8b",
              "template_id": "61f275a9ea5f821cd7d61814",
              "document_type": 1,
              "document_id": 18,
              "parent_id": 17,
              "id": 18,
              "parent": 17,
              "title": "360 degree video 1",
              "type": 0,
              "content": [
                {
                  "keyword": [
                    "360"
                  ],
                  "content_name": "360 degree video 1",
                  "content_type": 1,
                  "indexOfLanguage": 0,
                  "language_name": "Tamil",
                  "language": [
                    {
                      "language_id": 1,
                      "language_name": "Tamil",
                      "value": "Tamil",
                      "content_type": 1,
                      "media_files": {
                        "main_file": {
                          "path": "18mb360v.mp4",
                          "preview": "",
                          "formattedSize": "17.88 MB",
                          "media_type": "1",
                          "output_url": "b2bf68f2e37ba06012309e7daec91c34cf35eb7df6ff03a73180a78d23d8b21d/hls_b2bf68f2e37ba06012309e7daec91c34cf35eb7df6ff03a73180a78d23d8b21d.m3u8",
                          "video_id": "1644401572149-bvzk46",
                          "input_key": "lms-demo/841a010-ef40-f56b-2a8-0cb3f44ddcccontent.mp4",
                          "origina_name": "18mb360v.mp4",
                          "input_url": "841a010-ef40-f56b-2a8-0cb3f44ddcccontent.mp4"
                        },
                        "multi_angle_video": []
                      }
                    },
                    {
                      "language_id": 2,
                      "language_name": "English",
                      "value": "English",
                      "content_type": 1,
                      "media_files": {
                        "main_file": {
                          "status": true,
                          "preview": "",
                          "viewing_angle": "",
                          "output_url": "",
                          "video_id": "",
                          "input_key": ""
                        },
                        "multi_angle_video": []
                      }
                    },
                    {
                      "language_id": 3,
                      "language_name": "Telugu",
                      "value": "Telugu",
                      "content_type": 1,
                      "media_files": {
                        "main_file": {
                          "status": true,
                          "preview": "",
                          "viewing_angle": "",
                          "output_url": "",
                          "video_id": "",
                          "input_key": ""
                        },
                        "multi_angle_video": []
                      }
                    },
                    {
                      "language_id": 4,
                      "language_name": "Hindi",
                      "value": "Hindi",
                      "content_type": 1,
                      "media_files": {
                        "main_file": {
                          "status": true,
                          "preview": "",
                          "viewing_angle": "",
                          "output_url": "",
                          "video_id": "",
                          "input_key": ""
                        },
                        "multi_angle_video": []
                      }
                    }
                  ],
                  "keyword_invalid": false,
                  "description": "Description",
                  "content_desc_invalid": false,
                  "media_video_upload_status": 3,
                  "avl_preview": false
                }
              ],
              "error": false,
              "errorMsg": ""
            }
          ],
          "template_id": "61f275a9ea5f821cd7d61814",
          "type": 0,
          "owner_id": "616141918fd1240ae4b28b8b",
          "parent_id": null,
          "parent": null,
          "title": "360 Degree Videos",
          "expanded": true,
          "error": false
        },
        {
          "id": 19,
          "document_id": 19,
          "company_id": "6130a7db9266b20611da8737",
          "errorMsg": "",
          "document_type": 0,
          "children": [
            {
              "company_id": "6130a7db9266b20611da8737",
              "owner_id": "616141918fd1240ae4b28b8b",
              "template_id": "61f275a9ea5f821cd7d61814",
              "document_type": 1,
              "document_id": 20,
              "parent_id": 19,
              "id": 20,
              "parent": 19,
              "title": "Cyberpunk Video",
              "type": 0,
              "content": [
                {
                  "keyword": [
                    "Video"
                  ],
                  "content_name": "Cyberpunk Video",
                  "content_type": 1,
                  "indexOfLanguage": 0,
                  "language_name": "Tamil",
                  "language": [
                    {
                      "language_id": 1,
                      "language_name": "Tamil",
                      "value": "Tamil",
                      "content_type": 1,
                      "media_files": {
                        "main_file": {
                          "path": "360mb-cyberpunk2077-game.mp4",
                          "preview": "",
                          "formattedSize": "343.62 MB",
                          "media_type": "1",
                          "output_url": "84a4ddcadfd3098573afa2b96f6d4566f6a4a509231b6eafdae7f6c17a10e3ef/hls_84a4ddcadfd3098573afa2b96f6d4566f6a4a509231b6eafdae7f6c17a10e3ef.m3u8",
                          "video_id": "1644655779404-nq92q9",
                          "input_key": "lms-demo/fd4c6f-87f-fa33-e828-af865d6375f4content.mp4",
                          "origina_name": "360mb-cyberpunk2077-game.mp4",
                          "input_url": "fd4c6f-87f-fa33-e828-af865d6375f4content.mp4"
                        },
                        "multi_angle_video": [
                          {
                            "status": true,
                            "preview": "",
                            "output_url": "8ceb3202e8d23d0b30e80becf6f620e1502695ccd23a0bd54a86e87d4ce1c7ca/hls_8ceb3202e8d23d0b30e80becf6f620e1502695ccd23a0bd54a86e87d4ce1c7ca.m3u8",
                            "video_id": "1644656366439-wmew20",
                            "input_key": "lms-demo/03fe36e-57fc-3b11-278-7c04338cab5ccontent.mp4",
                            "viewing_angle": "Left",
                            "origina_name": "444mb-animals-HD.mp4",
                            "formattedSize": "424.16 MB"
                          },
                          {
                            "status": true,
                            "preview": "",
                            "output_url": "66bafa35b2cb1ae04d355c054f0f4e5d68ca378af1db6ec919e7d287c631cb8f/hls_66bafa35b2cb1ae04d355c054f0f4e5d68ca378af1db6ec919e7d287c631cb8f.m3u8",
                            "video_id": "1644660678392-ijov8h",
                            "input_key": "lms-demo/82b5c60-715e-bf-c1b4-7d07a243f32ccontent.mp4",
                            "viewing_angle": "Top",
                            "origina_name": "100mb-earth.mp4",
                            "formattedSize": "95.8 MB"
                          }
                        ]
                      }
                    },
                    {
                      "language_id": 2,
                      "language_name": "English",
                      "value": "English",
                      "content_type": 1,
                      "media_files": {
                        "main_file": {
                          "path": "100mb-earth.mp4",
                          "preview": "",
                          "formattedSize": "95.8 MB",
                          "media_type": "1",
                          "output_url": "f5e1d394ac7993b7cd7854a87af98afd386790912f6ca974d33b456b21e57360/hls_f5e1d394ac7993b7cd7854a87af98afd386790912f6ca974d33b456b21e57360.m3u8",
                          "video_id": "1644657926823-rxsgff",
                          "input_key": "lms-demo/6668b5d-eebe-20ed-410a-ba3dacc3cfbfcontent.mp4",
                          "origina_name": "100mb-earth.mp4",
                          "input_url": "6668b5d-eebe-20ed-410a-ba3dacc3cfbfcontent.mp4"
                        },
                        "multi_angle_video": []
                      }
                    },
                    {
                      "language_id": 3,
                      "language_name": "Telugu",
                      "value": "Telugu",
                      "content_type": 1,
                      "media_files": {
                        "main_file": {
                          "status": true,
                          "preview": "",
                          "viewing_angle": "",
                          "output_url": "",
                          "video_id": "",
                          "input_key": ""
                        },
                        "multi_angle_video": []
                      }
                    },
                    {
                      "language_id": 4,
                      "language_name": "Hindi",
                      "value": "Hindi",
                      "content_type": 1,
                      "media_files": {
                        "main_file": {
                          "status": true,
                          "preview": "",
                          "viewing_angle": "",
                          "output_url": "",
                          "video_id": "",
                          "input_key": ""
                        },
                        "multi_angle_video": []
                      }
                    }
                  ],
                  "keyword_invalid": false,
                  "description": "Description",
                  "content_desc_invalid": false,
                  "media_video_upload_status": 3,
                  "content_name_invalid": false,
                  "file_upload_prgress": null,
                  "avl_preview": true
                }
              ],
              "error": false,
              "errorMsg": ""
            }
          ],
          "template_id": "61f275a9ea5f821cd7d61814",
          "type": 0,
          "owner_id": "616141918fd1240ae4b28b8b",
          "parent_id": null,
          "parent": null,
          "title": "Teaching Video",
          "expanded": true,
          "error": false
        }
      ],

      sampleTable: [
        {
          name: 'Jb',
          position: 'JS',
          age: 25,
        },
        {
          name: 'Jose',
          position: 'JS',
          age: 22,
        },
        {
          name: 'Swe',
          position: 'Node',
          age: 25,
        }
      ]
    }
  }

  componentDidMount() {
    console.log("Welcome to Page", this.state.course_content)
  }

  onClickLink = (data) => {
    console.log('data', data)
    this.courseFinishedUpdate(data.document_id, 1)
  }

  courseFinishedUpdate = (document_id, status) => {
    let keyToFind = 'document_id'
    let foundObj;
    let entireObj = [...this.state.course_content]

    console.log('document_id', document_id)

    JSON.stringify(entireObj, (_, nestedValue) => {

      if (nestedValue && nestedValue[keyToFind] === document_id) {

        if (nestedValue.children === undefined) {
          nestedValue.children = []
        }
        console.log('nestedValue.children', nestedValue)
        foundObj = nestedValue.content_status = status
      }

      this.setState({ course_content: entireObj })

      return nestedValue

    })
    return foundObj
  }

  render() {
    return (
      <div className="page-content pt-5">
        <Container className="p-5" fluid style={{ background: 'white', minHeight: '85vh' }}>
          {
            !!this.state.course_content && this.state.course_content.length > 0 &&
            <Row className='mt-3 pt-2'>
              <Col md={12}>
                <h3 onClick={() => console.log(this.state.course_content)}>Course Material</h3>
                <SimpleBar className="h-100 " ref={this.refDiv} >
                  <CourseTree onClickLink={(data) => this.onClickLink(data)} subscribed={this.state.subscribed} course_content={this.state.course_content} />
                </SimpleBar>
              </Col>
            </Row>
          }


          {/* <table class="table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Position</th>
                <th scope="col">Age</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.sampleTable.map((data, index) => (
                  <React.Fragment key={index} >
                    <tr >
                      <th scope="row">{data.name}</th>
                      <td>{data.position}</td>
                      <td>{data.age}</td>
                      <td>
                        <Button onClick={() => this.setState({ showTable2: true, tbIndex: index })}>Show</Button>
                      </td>
                    </tr>
                    {
                      (this.state.tbIndex === index) && this.state.showTable2 &&
                      <>
                        <tr>

                          <td colSpan={4}>
                            <table class="table">
                              <tbody>
                                <tr>
                                  <th scope="row">1</th>
                                  <td>Adambakam</td>
                                  <td>Chennai</td>
                                </tr>
                                <tr>
                                  <th scope="row">2</th>
                                  <td>Sattan Kulam</td>
                                  <td>Thoothukudi</td>
                                </tr>
                                <tr>
                                  <th scope="row">3</th>
                                  <td>Alwarpet</td>
                                  <td>Chennai</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </>
                    }
                  </React.Fragment>
                ))
              }



            </tbody>
          </table> */}


        </ Container>
      </div>
    )
  }
}
