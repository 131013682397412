import React, { Component } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Progress, Button } from 'antd'
import { Container, Row, Col } from "reactstrap"
import SweetAlert from 'react-bootstrap-sweetalert'
import CountdownTimer from "react-component-countdown-timer"

import SingleChoiseQuest from './Component/SingleChoiseQuest'
import MultiChoiseQuest from './Component/MultiChoiseQuest'
import TextInputBox from './Component/TextInputBox'
import UserAnswers from './Component/UserAnswers'
import FinalResult from './Component/FinalResult'
import urlSocket from '../../helpers/urlSocket'
import MyAnswers from './Component/MyAnswers'

// import "react-component-countdown-timer/lib/styles.css"

import './CSS/countercss.scss'


export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            randomQues: [],
            attempted_on: new Date(),
            baseurl: '',
            question_type: '',
            correctAnswer: '',
            user_selected_multi: [],
            showMyAns: false,
            submiting: false,
            isSubmited: false,
            colorChange: false,
            isFinalSubmited: false,
            finalSubmitConfirm: false,
            comp_perc: 0,
            totalValue: 0,
            selectedQues: 0,
            attempt_count: 0,
            course_duration: 0,
            timeUp: null,
            isCorrect: null,
            is_correct: null,
            pass_criteria: null,
            user_selected: null,
            min_pass_ques: null,
            attempt_status: null,
            re_attempt_duration: null,
            count_down: false,
            course_content: []
        }
    }

    componentDidMount() {
        console.log("objectobjectobjectobjectobjectobjectobjectobjectobject", this.state.count_down, this.props.get_assessment)
        this.setState({ course_content: this.props.course_content })
        if (this.state.count_down !== true) {
            console.log("!!!")
            window.addEventListener("beforeunload", this.handleBeforeUnload)
        }
        this.getSession()
        this.getQuestionsAPI()
    }

    getSession = () => {
        var getconfsession = JSON.parse(sessionStorage.getItem("getconfsession"))
        let baseurl = getconfsession.baseurl
        this.setState({ baseurl })
    }

    componentWillUnmount() {
        console.log("unmount", this.state.count_down)
        window.removeEventListener("beforeunload", this.handleBeforeUnload)
    }

    handleBeforeUnload = (e) => {
        e.preventDefault()
        const message = "Are you sure you want to leave? All provided data will be lost."
        e.returnValue = message
        return message
    }

    convertToSec = (time_duration) => {
        const [hours, minutes] = time_duration.split(':')
        const totalSeconds = (+hours) * 60 * 60 + (+minutes) * 60
        // console.log(totalSeconds,"tyming")
        return Number(totalSeconds)
    }

    getQuestionsAPI = async () => {

        //var Exams = JSON.parse(sessionStorage.getItem("Exams"))
        var Exams = this.props.examData
        if (Exams !== null) {
            console.log('Exams', Exams)
            let { randomQuestions, max_no_of_optn, min_pass_perc, total_no_ques, time_duration, no_of_attempt, re_attempt_duration, document_id, pass_criteria, min_pass_ques, avl_randomization } = Exams
            let course_duration = this.convertToSec(time_duration)

            let attempted_on = new Date()
            let totalValue = Number(randomQuestions.length)
            var correct_answers = []
            var wrong_answers = []

            // let randomQues = randomQuestions.sort(() => Math.random() - 0.5)

            if (avl_randomization) {
                for (let index = 0; index < randomQuestions.length; index++) {
                    const options = randomQuestions[index].options

                    correct_answers = options.filter(word => word.is_ans === "1")
                    wrong_answers = _.filter(options, { is_ans: "0" })
                    var shuff = _.shuffle(wrong_answers)
                    var remaining_options = max_no_of_optn - correct_answers.length
                    shuff = shuff.slice(0, remaining_options)
                    var total_ans = correct_answers.concat(shuff)
                    var final_shuffled = _.shuffle(total_ans)

                    let all_above_op1 = "None of the above".toLowerCase()
                    let all_above_op2 = "All of the above".toLowerCase()

                    let all_txt_answer = {}
                    let except_all_above_options = []

                    final_shuffled.forEach(data => {
                        if (data.option.toLowerCase() === all_above_op1) {
                            all_txt_answer = data
                            except_all_above_options = final_shuffled.filter((data) => data.option.toLowerCase() !== all_above_op1)
                            // console.log('None of the above', except_all_above_options)
                        }
                        if (data.option.toLowerCase() === all_above_op2) {
                            all_txt_answer = data
                            except_all_above_options = final_shuffled.filter((data) => data.option.toLowerCase() !== all_above_op2)
                            // console.log('All of the above', except_all_above_options)
                        }
                    })
                    if (except_all_above_options.length !== 0) {
                        except_all_above_options.push(all_txt_answer)
                        // console.log('except_all_above_options', except_all_above_options)
                        randomQuestions[index].options_displayed = except_all_above_options
                    } else {
                        randomQuestions[index].options_displayed = final_shuffled
                    }
                }
                // console.log('randomQuestions', randomQuestions)
                randomQuestions = _.shuffle(randomQuestions)
            } else {
                for (let index = 0; index < randomQuestions.length; index++) {
                    const options = randomQuestions[index].options
                    correct_answers = options.filter(word => word.is_ans === "1")
                    wrong_answers = _.filter(options, { is_ans: "0" })
                    var remaining_options = max_no_of_optn - correct_answers.length
                    let shuff = wrong_answers.slice(0, remaining_options)
                    var total_ans = correct_answers.concat(shuff)
                    // total_ans = _.shuffle(total_ans) // if wrong answer on sequence please un commend

                    let all_above_op1 = "None of the above".toLowerCase()
                    let all_above_op2 = "All of the above".toLowerCase()

                    let all_txt_answer = {}
                    let except_all_above_options = []

                    total_ans.forEach(data => {
                        if (data.option.toLowerCase() === all_above_op1) {
                            all_txt_answer = data
                            except_all_above_options = total_ans.filter((data) => data.option.toLowerCase() !== all_above_op1)
                            // console.log('None of the above', except_all_above_options)
                        }
                        if (data.option.toLowerCase() === all_above_op2) {
                            all_txt_answer = data
                            except_all_above_options = total_ans.filter((data) => data.option.toLowerCase() !== all_above_op2)
                            // console.log('All of the above', except_all_above_options)
                        }
                    })
                    if (except_all_above_options.length !== 0) {
                        except_all_above_options.push(all_txt_answer)
                        // console.log('except_all_above_options', except_all_above_options)
                        randomQuestions[index].options_displayed = except_all_above_options
                    } else {
                        randomQuestions[index].options_displayed = total_ans
                    }
                }
                // console.log('randomQuestions', randomQuestions)
            }


            this.setState({ randomQues: randomQuestions, course_duration, totalValue, min_pass_perc, total_no_ques, time_duration, no_of_attempt, attempted_on, re_attempt_duration, document_id, pass_criteria, min_pass_ques })
            this.checkAssessmentAttempt()
        }
    }


    checkAssessmentAttempt = () => {
        var crs_subscr_id = JSON.parse(sessionStorage.getItem("crs_subscr_id"))
        var crs_cntnt_info_id = JSON.parse(sessionStorage.getItem("CourseDetails"))
        let document_id = this.state.document_id
        let data = { crs_cntnt_info_id, crs_subscr_id, document_id }
        console.log('subscr_crs/chk_alrdy_attmptd APIIII', { data })
        try {
            urlSocket.post('subscr_crs/chk_alrdy_attmptd', data)
                .then((res) => {
                    let data = res.data.data
                    // console.log('res.data', res.data)
                    let randomQues = res.data.question_list
                    if (res.data.response_code === 500) {
                        this.setState({ randomQues })
                        console.log('chk_alrdy_attmptd', res.data)
                        let { attempt_status, score, attempt_count, attempted_on, assessment_list } = data[0]
                        attempt_status = Number(attempt_status)
                        if (Number(attempt_status) === 1) {
                            this.setState({ isFinalSubmited: true, course_duration: 0, attempt_count: Number(attempt_count), selectedQues: this.state.randomQues.length + 1, attempt_status, score })
                        } else {
                            if (assessment_list.no_of_attempt) {
                                if (Number(assessment_list.no_of_attempt) > Number(attempt_count)) {
                                    let re_attempt_duration = assessment_list.re_attempt_duration
                                    let re_attempt_duration_sec = this.convertToSec(re_attempt_duration)
                                    console.log('Last attempted_on', attempted_on)
                                    attempted_on = new Date(attempted_on)
                                    var nxt_attmpt = moment(attempted_on).add('seconds', re_attempt_duration_sec)._d
                                    // var nxt_attmpt = moment(attempted_on).add(parseInt(re_attempt_duration_sec), 'seconds')

                                    console.log('Current Date', new Date())
                                    console.log('nxt_attmpt', nxt_attmpt)

                                    if (new Date() > nxt_attmpt) {
                                        console.log('You can attend')
                                        this.setState({ attempt_count, attempt_notification: true })
                                    } else {
                                        console.log('Please Tryback ater Sometime')
                                        this.setState({ randomQues: [], notAllowed: true, try_after: nxt_attmpt })
                                    }
                                }
                            }
                        }
                    }
                })
        } catch (error) {
            console.log('error', error)
        }
    }


    gotoNextQues = () => {
        this.setState(state => ({ selectedQues: state.selectedQues + 1, isCorrect: null, isSubmited: false, user_selected: null, correctAnswer: '', }))
        let totalQuesCount = this.state.randomQues.length
        let currentQuesNo = this.state.selectedQues
        console.log("object", "gotonext", currentQuesNo === totalQuesCount)
        if (currentQuesNo === totalQuesCount) {
            this.setState(state => ({ showMyAns: true, selectedQues: state.selectedQues - 1, isCorrect: null, isSubmited: false, user_selected: null, correctAnswer: '' }))
        }
    }

    onChangeOption = (user_selected, data, index) => {
        console.log(data, "user_data", index, user_selected)
        data.user_selected = user_selected
        // data.submited = false
        let result = data.options_displayed.filter((data) => data.is_ans === '1')[0]
        let correctAnswer = result.option
        let question_type = "Single-Choice"
        this.setState({ selected_index_: index, correctAnswer, user_selected, question_type })
    }

    OnchangeTextAnswer = (value, data) => {
        console.log(value, data, "value of radio")
        data.user_selected = value
        let question_type = "Text-Box"
        this.setState({ question_type, user_selected: value })
    }

    onChangeMultiOption = (e, index, data) => {
        console.log(e, "value", index, "indii", data)
        data.options_displayed[index].user_selected = e
        // // data.submited = false
        let result = data.options_displayed.filter((data) => data.is_ans === '1')
        let user_selected_multi = data.options_displayed.filter((data) => data.user_selected === true)

        let correctAnswer = this.matchAnswers(result, user_selected_multi)
        let question_type = "Multi-Choice"
        console.log('user_selected_multi', user_selected_multi)
        this.setState({ user_selected_multi, correctAnswer, user_selected: user_selected_multi, question_type })
        data.user_selected = user_selected_multi //
    }

    matchAnswers(a, b) {
        return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index])
    }

    onPreviosQues = (selected_index) => {

        let selectedQues = this.state.selectedQues - 1
        let randomQues = this.state.randomQues
        let user_selected = this.state.user_selected
        let already_selected_ans = randomQues[selectedQues].user_selected
        randomQues[selected_index].submited = false
        console.log(randomQues, "randomQues")
        console.log('user_selected', selected_index + 1, user_selected, selectedQues)
        console.log('already_selected_ans', randomQues, already_selected_ans)
        this.setState({ selectedQues, selected_index, user_selected: already_selected_ans })
    }

    onSubmitNext = (selected_index) => {

        console.log(selected_index, "this.state.selectedQues")
        this.setState({ submiting: true })
        let { correctAnswer, user_selected, question_type } = this.state

        let isCorrect = null

        if (question_type === "Single-Choice") {

            if (correctAnswer === user_selected) {
                isCorrect = true
            } else {
                isCorrect = false
            }

        } else if (question_type === "Multi-Choice") {
            isCorrect = this.state.correctAnswer
            console.log(this.state.correctAnswer, "correctans")
        } else if ("Text-Box") {
            console.log('Text-Box')
        } else {

        }
        console.log(isCorrect, "this.state.selectedQues", isCorrect)

        this.setState({ isCorrect })
        this.submitQuesAPI(isCorrect)
    }


    submitQuesAPI = async (isCorrect) => {
        let { randomQues, selectedQues, user_selected } = this.state
        let currentQues = randomQues[selectedQues]

        if (isCorrect === "") {
            isCorrect = this.state.is_correct
            console.log(this.state.correctAnswer, "correctans")
        }
        console.log(this.state.correctAnswer, "correctans", isCorrect)
        currentQues.is_correct = isCorrect
        currentQues.submited = true

        let already_selected_ans = randomQues[selectedQues].user_selected
        console.log('randomQues[selectedQues]', randomQues[selectedQues], currentQues, already_selected_ans)

        if (randomQues[selectedQues].user_selected === null || randomQues[selectedQues].user_selected === undefined) {
            console.log('user_selected', user_selected)
            currentQues.user_selected = user_selected
        } else {
            currentQues.user_selected = already_selected_ans
            console.log('already_selected_ans', already_selected_ans)

        }


        console.log('updt_crs_sbscr_assmnt_rspns', currentQues)
        await urlSocket.post("subscr_crs/updt_crs_sbscr_assmnt_rspns", { data: currentQues })
            .then(async (res) => {
                console.log(res, "response")
                if (res.data.response_code === 500) {
                    // let AllquestionsList = res.data.data

                    this.setState({ randomQues, isSubmited: true, submiting: false })
                    this.gotoNextQues()
                }
            })
            .catch((err) => {
                this.setState({ submiting: false })
            })
    }

    // let wrong_answers_count = _.filter(randomQues, { is_correct: false }).length
    finalSubmition = () => {
        let { attempted_on, attempt_count, randomQues, min_pass_perc, total_no_ques, re_attempt_duration, document_id, pass_criteria, min_pass_ques } = this.state
        var crs_subscr_id = JSON.parse(sessionStorage.getItem("crs_subscr_id"))
        let attempt_status = null
        let data = {}
        let score_percent = ''
        let score = _.filter(randomQues, { is_correct: true }).length
        score_percent = (score / Number(total_no_ques)) * 100

        if (pass_criteria === "Percentage") {

            score_percent = (score / Number(total_no_ques)) * 100

            if (Number(score_percent) <= Number(min_pass_perc)) {
                attempt_status = 0
            } else {
                attempt_status = 1
            }

            let pass_percent = parseFloat(min_pass_perc)

            attempt_count = Number(attempt_count) + 1

            data = { attempted_on, attempt_count, score, score_percent, attempt_status, pass_percent, crs_subscr_id, re_attempt_duration, document_id, all_questions: randomQues, pass_criteria, completed_status: "2" }


        } else {
            // let wrong_answers_count = _.filter(randomQues, { is_correct: false }).length


            if (Number(min_pass_ques) > Number(score)) {
                attempt_status = 0
            } else {
                attempt_status = 1
            }
            console.log('attempt_status', attempt_status)

            data = {
                attempted_on, attempt_count, crs_subscr_id, re_attempt_duration, document_id, all_questions: randomQues, pass_criteria, score, attempt_status, score_percent: score_percent, pass_percent: null, completed_status: "2"
            }

        }
        this.setState({ attempt_status, isFinalSubmited: true, score })

        console.log('data_______', data)

        urlSocket.post("subscr_crs/assmnt_submsn", { data })
            .then(async (res) => {
                console.log(res, "count_timer")
                if (res.data.response_code === 500) {
                    console.log('res', res.data)
                    var flat_data = JSON.parse(sessionStorage.getItem("CourseContent"))
                    this.setState({ showMyAns: false, finalSubmitConfirm: false, count_down: true }, () => { this.courseTopicUpdate(document_id, 2, flat_data) })
                    console.log("removelistner")
                    window.removeEventListener("beforeunload", this.handleBeforeUnload)

                }
            })
    }

    courseTopicUpdate = (document_id, status, flat_data) => {
        console.log(document_id, status)
        let entireObj = [...this.state.course_content]
        // var completed_on = new Date()
        // entireObj[0]
        // entireObj.push(completed_on)
        // console.log(entireObj,"entireObj")
        var course_info_name = JSON.parse(sessionStorage.getItem("CourseName"))
        let course_name = course_info_name.name_of_the_course
        var authUser = JSON.parse(sessionStorage.getItem("authUser"))
        // var crs_subscr_id = authUser._id
        var course_info_id = JSON.parse(sessionStorage.getItem("CourseDetails"))
        console.log(course_name, crs_subscr_id, course_info_id, entireObj)
        var VnestedValue;
        let keyToFind = 'document_id'
        let foundObj;


        JSON.stringify(entireObj, (_, nestedValue) => {
            // console.log(nestedValue,"inside json")
            if (nestedValue && nestedValue[keyToFind] === document_id) {
                if (nestedValue.children === undefined) {
                    nestedValue.children = []
                }
                // console.log("statusstatusstatus", status)
                foundObj = nestedValue.content_status = status
                foundObj = nestedValue.completed_on = new Date()
                VnestedValue = nestedValue
            }
            this.setState({ course_content: entireObj, nestedValue })
            // console.log(nestedValue, "nestedValue")
            // VnestedValue = nestedValue
            return nestedValue

        })
        if (status === 2) {
            var crs_subscr_id = JSON.parse(sessionStorage.getItem("crs_subscr_id"))
            console.log('completed', status, crs_subscr_id)
            try {
                // urlSocket.post('mngsubcrs/updt_crs_tree', { course_content: VnestedValue, name_of_the_course: this.state.name_of_the_course, crs_subscr_id: this.state.crs_subscr_id })
                urlSocket.post('mngsubcrs/updt_crs_tree', { course_content: entireObj, name_of_the_course: course_name, subscriber_id: authUser._id, crs_subscbr_id: crs_subscr_id })
                    .then((res) => {
                        console.log('updt_cntnt_status ', res.data)
                        // var data = JSON.parse(sessionStorage.getItem("onClickLink"))
                        // this.props.onClickLink(data)
                    })
            } catch (error) {
                console.log(error)

            }
            flat_data['completed_status'] = status
            this.onUpdateCourseStatus(entireObj, authUser._id, course_info_id, flat_data)
            return foundObj

        }

    }
    onUpdateCourseStatus = (course_content, subscriber_id, crs_subscr_id, flat_data) => {
        //console.log('course_content', course_content) 
        // let { crs_subscr_id, course_info_id, } = this.state
        var crs_subscr_id = JSON.parse(sessionStorage.getItem("crs_subscr_id"))

        console.log('crs_subscr_id, course_info_id', subscriber_id, crs_subscr_id)

        try {
            urlSocket.post('mngsubcrs/crs_cntnt_status_update', { subscriber_id: subscriber_id, crs_subscr_id, course_content, flat_data: flat_data })
                .then((res) => {
                    console.log('onUpdateCourseStatus :', res.data)
                    if (res.data.response_code === 500) {
                        // let data= _.filter(course_content,e=>{
                        // _.filter(e.children,i=>{
                        // return i.document_type === 2
                        // })
                        // })
                        // var data = JSON.parse(sessionStorage.getItem("onClickLink"))

                        // this.props.onClickLink(data)
                        // return <Link to="/Exams"/>
                        // window.location.reload(false)

                    }
                })
        } catch (error) {

        }
    }

    onTimeupCourse = () => {
        this.setState({ timeUp: true, selectedQues: this.state.randomQues.length + 1 })
        this.finalSubmition()
    }

    getAttemptedQuesCount = (questions, index) => {
        console.log(questions, "questions")
        let attempted_ques = questions.filter((data) => (data.submited !== undefined && data.submited)).length
        console.log(attempted_ques, "attempted_ques")
        return attempted_ques
    }

    previous_ques = (questions) => {
        console.log(questions, "questions")
        let attempted_ques = questions.filter((data) => (data.submited !== undefined && data.submited)).length
        console.log(attempted_ques, "attempted_ques")
        return attempted_ques
    }

    getAttemptedQuesCount_ = (questions) => {
        let attempted_ques = questions.filter((data) => (data.user_selected !== null)).length
        console.log(attempted_ques, "attempted_ques")
        return attempted_ques
    }

    getunAttemptedQuesCount = (questions) => {
        let unattempted = questions.filter((data) => (data.user_selected === null)).length
        return unattempted
    }

    goTomatchedQues = (_id) => {
        let index = this.state.randomQues.findIndex((elem) => { return elem._id === _id })
        return index
    }

    goToQues = (selectedQues) => {
        console.log('this.state.randomQues[', this.state.randomQues[selectedQues], selectedQues)
        let is_correct = this.state.randomQues[selectedQues].is_correct
        console.log(is_correct, "iscorrect", selectedQues)
        this.setState({ selectedQues, showMyAns: false, show_allpage: true, is_correct })
    }

    getAttendedPercentage = (questions) => {
        try {
            if (questions.length) {
                let attempted_ques = questions.filter((data) => (data.submited !== undefined && data.submited)).length
                let total_no_ques = questions.length
                let percentage = ((attempted_ques + 1) / total_no_ques) * 100
                return percentage
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    onSubmitInterMediateReview = async () => {
        this.setState({ submiting: true })
        let { correctAnswer, user_selected, question_type } = this.state

        let isCorrect = null

        if (question_type === "Single-Choice") {

            if (correctAnswer === user_selected) {
                isCorrect = true
            } else {
                isCorrect = false
            }

        }
        if (question_type === "Multi-Choice") {
            isCorrect = this.state.correctAnswer
        } else {
            isCorrect = false
        }


        if ("Text-Box") {
            console.log('Text-Box')
        } else {
        }


        let { randomQues, selectedQues, } = this.state
        let currentQues = randomQues[selectedQues]
        currentQues.is_correct = isCorrect
        currentQues.submited = true

        let already_selected_ans = randomQues[selectedQues].user_selected

        if (randomQues[selectedQues].user_selected === null || randomQues[selectedQues].user_selected === undefined) {
            currentQues.user_selected = user_selected
        } else {
            currentQues.user_selected = already_selected_ans

        }


        console.log('updt_crs_sbscr_assmnt_rspns', currentQues, randomQues, selectedQues, this.state.correctAnswer)
        await urlSocket.post("subscr_crs/updt_crs_sbscr_assmnt_rspns", { data: currentQues })
            .then(async (res) => {
                console.log(res, "res")
                if (res.data.response_code === 500) {
                    // let AllquestionsList = res.data.data

                    this.setState({ randomQues, isSubmited: true, submiting: false, showMyAns: true, isCorrect })
                }
            })
            .catch((err) => {
                this.setState({ submiting: false })
            })


    }

    render() {
        return (
            <React.Fragment>
                <div className="" style={{ marginTop: this.state.isFinalSubmited ? "-10px" : "0" }}>
                    {
                        !this.state.isFinalSubmited && !this.state.showMyAns && (
                            <Container style={{ background: '#ECECEC', maxWidth: '1440px' }}>
                                <Row className='pt-4 pb-3' >
                                    <Col md={12}>
                                        {/* {
 this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length !== this.state.totalValue && this.state.previous !== true ?
 <div className='qaprocessCntr' >You are attempting {this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length + 1}/ {this.state.totalValue}</div>
 :
 this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length === this.state.totalValue && this.state.previous !== true ?
 <div className='qaprocessCntr' >You are attempting {this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length}/ {this.state.totalValue}</div>
 :
 this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length > this.state.totalValue && this.state.previous !== true ?
 <div className='qaprocessCntr' >You are attempting {this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length}/ {this.state.totalValue}</div>
 :
 null

 } */}
                                        {
                                            <div className='qaprocessCntr' >You are attempting {this.state.selectedQues + 1 +""}&nbsp;/ {this.state.totalValue}</div>
                                        }
                                        {/* {
 console.log(this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length + 1 > this.state.totalValue, "start_check", this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length, this.state.totalValue)
 } */}
                                        {/* <div className='qaprocessCntr' >You are attempting {this.getAttemptedQuesCount(this.state.randomQues) + 1}/ {this.state.totalValue}</div> */}
                                        {/* <Progress strokeLinecap="square" percent={this.getAttendedPercentage(this.state.randomQues)} showInfo={false} strokeColor={'#5E5E5E'} /> */}

                                        <Progress strokeLinecap="square" percent={((this.state.selectedQues + 1) / this.state.totalValue) * 100} showInfo={false} strokeColor={'#5E5E5E'} />

                                        {/* {
 this.state.previous === true ?
 <div className='qaprocessCntr' >You are attempting {this.state.selected_index}/ {this.state.totalValue}</div>
 :
 null

 } */}
                                        {/* {
 this.state.previous === true ?
 <Progress strokeLinecap="square" percent={this.getAttendedPercentage(this.state.randomQues)} showInfo={false} strokeColor={'#5E5E5E'} />
 :
 null
 } */}
                                        {/* {
 console.log(this.state.selected_index, "index")
 } */}

                                    </Col>
                                </Row>
                            </Container>
                        )}
                    <Container className="pt-3 pb-3" style={{ background: 'white', maxWidth: '636px' }}>
                        <Row>
                            <div className="col-md-12 ps-0 pe-0">
                                <div className="row">
                                    <div className="col borNone quizTitle ps-0"><h1 style={{ fontSize: '28px', padding: 0, margin: 0 }} onClick={() => console.log('this.state.randomQues', this.state.randomQues)}>Quiz</h1> </div>
                                    <div className="col borNone timerDiv" style={{ textAlign: 'right', padding: 0, margin: 0 }}>
                                        {
                                            // ((this.state.selectedQues + 1) <= (this.state.randomQues.length)) && <div> ({this.state.selectedQues + 1} of {this.state.randomQues.length} )</div>
                                        }
                                        {
                                            this.state.course_duration > 0 && (
                                                <>
                                                    {/* {
 console.log(this.state.course_duration , "this.state.course_duration")
 } */}
                                                    {
                                                        !this.state.count_down ?
                                                            <div className='countDownTimerDiv d-flex align-items-center justify-content-end'>
                                                                <div><i className='bx bx-time-five' style={{ fontSize: '18px', marginTop: '13px', marginRight: '3px' }}></i></div>

                                                                <CountdownTimer className="mt-2" style={{ fontSize: '14px' }} hideDay={true} count={this.state.course_duration} border onEnd={() => { this.onTimeupCourse() }} />

                                                            </div>
                                                            :
                                                            null
                                                    }
                                                    <div className='fs-18'>
                                                        {/* Questions Attempted: {this.getAttemptedQuesCount(this.state.randomQues)} */}
                                                        {
                                                            (this.state.min_pass_ques !== undefined && this.state.min_pass_ques !== null) &&
                                                            <div>
                                                                Min. pass Questions : {this.state.min_pass_ques}
                                                            </div>
                                                        }
                                                    </div>
                                                </>
                                            )
                                        }
                                        {
                                            this.state.timeUp && <h4>Time UP!!!!</h4>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Row>
                        {
                            !this.state.isFinalSubmited && (
                                <hr />
                            )
                        }
                        {
                            !this.state.showMyAns &&
                            <Row className='justify-content-left' style={{ minHeight: "30vh" }}>
                                <div className="col-md-12">
                                    {
                                        this.state.isFinalSubmited ?
                                            <div className=''>
                                                <FinalResult attempt_status={this.state.attempt_status} pass_criteria={this.state.pass_criteria} score={this.state.score} randomQues={this.state.randomQues} goBack={() => this.props.history.push('/course/CourseDetails')} />
                                                <UserAnswers getNextData={(item) => this.props.getNextData(item)} questionsList={this.state.randomQues} score={this.state.score} randomQues={this.state.randomQues} getCourseDetails={this.props.getCourseDetails} />
                                            </div>
                                            :
                                            <>

                                                {
                                                    this.state.randomQues.map((data, index) => (
                                                        <div key={index} >
                                                            {
                                                                index === this.state.selectedQues &&
                                                                <div>
                                                                    <h3 className='mt-4 mb-2 fontInter fontWeightNormal' style={{ fontSize: '16px', fontFamily: 'Inter', fontWeight: '500', color: '#3A4956' }}>{index + 1}. {data.question}</h3>
                                                                    <div className=''>
                                                                        {
                                                                            (data.media_url !== undefined && data.media_url !== null) &&
                                                                            <audio controls>
                                                                                <source src={this.state.baseurl + data.media_url} type="audio/ogg" />
                                                                                <source src={this.state.baseurl + data.media_url} type="audio/mpeg" />
                                                                            </audio>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        data.question_type === "Single-Choice" ?
                                                                            <SingleChoiseQuest options={data.options_displayed} onChangeOption={(e) => this.onChangeOption(e.target.value, data, index)} user_selected={data.user_selected} />
                                                                            :
                                                                            data.question_type === "Multi-Choice" ?
                                                                                // <MultiChoiseQuest options={data.options_displayed} onChangeOption={(value, index) => this.onChangeMultiOption(value,index,data)} user_selected={data.user_selected} />
                                                                                <MultiChoiseQuest options={data.options_displayed} onChangeOption={(e, index) => this.onChangeMultiOption(e, index, data)} user_selected={data.user_selected} />
                                                                                // <MultiChoiseQuest options={data.options_displayed} onChangeOption={(e) => this.onChangeOption(e.target.value, data, index)} user_selected={data.user_selected} />

                                                                                :
                                                                                data.question_type === "Text-Box" ?
                                                                                    <TextInputBox OnchangeTextAnswer={(value) => this.OnchangeTextAnswer(value, data)} user_selected={data.user_selected} />
                                                                                    :
                                                                                    null
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    ))
                                                }

                                            </>
                                    }
                                </div>
                            </Row>
                        }

                        {
                            this.state.showMyAns ?
                                <Row>
                                    <div className="row">
                                        <MyAnswers questionsList={this.state.randomQues} goToQues={(idx) => this.goToQues(idx)} attempted_count={this.getAttemptedQuesCount_(this.state.randomQues)} unattempted_count={this.getunAttemptedQuesCount(this.state.randomQues)} />

                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-left">
                                                <div className=""><Button className='mx-2 quizCntrlBtns quizCntrlBtnsOutlined' style={{ width: '173px', height: '40px' }} onClick={() => this.setState({ showMyAns: false, show_allpage: true })} >Review Responses</Button></div>
                                                <div className=""><Button className='mx-2 quizCntrlBtns quizCntrlBtnsOutlined' style={{ width: '150px', height: '40px' }} onClick={() => this.setState({ finalSubmitConfirm: true })}>Submit Quiz</Button></div>
                                            </div>
                                        </div>

                                    </div>
                                </Row>
                                :
                                null
                        }
                        {/* {
 console.log(this.state.selectedQues, "this.state.selectedQues")
 } */}
                        {
                            (!this.state.showMyAns && !this.state.isFinalSubmited) &&
                            <div className="col-md-12 mt-5">
                                <div className="d-flex justify-content-left">
                                    {
                                        this.state.selectedQues > 0 ?
                                            // <Button className="quizCntrlBtns quizCntrlBtnsOutlined" onClick={() => this.onPreviosQues(this.state.selectedQues)}>Previous</Button>
                                            <>
                                                <Button className="quizCntrlBtns quizCntrlBtnsOutlined" onClick={() => this.onPreviosQues(this.state.selectedQues)}>Previous</Button>
                                                &nbsp; &nbsp;
                                            </>

                                            :
                                            null
                                    }
                                    {/* {
 console.log(this.state.selectedQues + 1,this.state.randomQues.length,this.state.selectedQues + 1 === this.state.randomQues.length,"this.state.selectedQues + 1 !== this.state.randomQues.length","705",this.state.selectedQues + 1 === this.state.randomQues.length)
 } */}
                                    {
                                        (this.state.selectedQues + 1 !== this.state.randomQues.length) &&

                                        this.state.show_allpage && <Button className='mx-2 quizCntrlBtns quizCntrlBtnsOutlined' onClick={() => this.onSubmitInterMediateReview()}>Review Responses</Button>
                                    }
                                    {/* {
 (this.state.selectedQues +1 === this.state.randomQues.length) || (this.state.selectedQues +1 !== this.state.randomQues.length) &&
 
 this.state.show_allpage && <Button className='mx-2 quizCntrlBtns quizCntrlBtnsOutlined' onClick={() => this.onSubmitInterMediateReview()}>Review Responses</Button>
 } */}
                                    {
                                        ((this.state.selectedQues) < (this.state.randomQues.length - 1)) && <Button className="quizCntrlBtns quizCntrlBtnsOutlinedGrey" onClick={() => { this.onSubmitNext(); }}>Next</Button>
                                    }

                                    {
                                        !this.state.isSubmited ?
                                            <>

                                                {
                                                    (this.state.selectedQues + 1 === this.state.randomQues.length) &&

                                                    <>
                                                        <Button className=" quizCntrlBtns quizCntrlBtnsOutlined" style={{ width: '173px' }} type="success" onClick={() => this.onSubmitNext(this.state.selectedQues)} loading={this.state.submiting}>{this.state.submiting ? 'Submiting...' : 'Review Response'} </Button>

                                                        {
                                                            console.log("checking11", this.state.isSubmited)
                                                        }
                                                    </>

                                                }
                                                {/* {
 console.log(this.state.selectedQues -1 === this.state.randomQues.length,"this.state.selectedQues -1 === this.state.randomQues.length")
 } */}
                                            </>
                                            :
                                            this.state.isSubmited ?
                                                <>

                                                    {
                                                        (this.state.selectedQues + 1 === this.state.randomQues.length) &&

                                                        <>
                                                            <Button className=" quizCntrlBtns quizCntrlBtnsOutlined" type="success" onClick={() => this.onSubmitNext(this.state.selectedQues)} loading={this.state.submiting}>{this.state.submiting ? 'Submiting...' : 'Review Response'} </Button>

                                                            {
                                                                console.log("checking11", this.state.isSubmited)
                                                            }
                                                        </>

                                                    }
                                                    {/* {
 console.log(this.state.selectedQues -1 === this.state.randomQues.length,"this.state.selectedQues -1 === this.state.randomQues.length")

 } */}
                                                </>
                                                :
                                                null
                                    }
                                </div>
                            </div>

                        }

                        {
                            this.state.finalSubmitConfirm &&
                            // <SweetAlert warning showCancel confirmBtnText="Yes, Submit!" confirmBtnBsStyle="danger" title="Are you sure you want to submit Assessment?" onConfirm= onCancel= focusCancelBtn>
                            <SweetAlert style={{ width: "450px", margin: "0 auto", borderRadius: '10px', height: '297px' }}
                                title=""
                                showCloseButton
                                //customIcon=""
                                showCancel
                                // confirmBtnText="cancel"
                                //cancelBtnText="OK"
                                //confirmBtnBsStyle="danger"
                                //cancelBtnBsStyle="success"
                                //onCancel={() => this.finalSubmition()}
                                //onConfirm={() => this.setState({ finalSubmitConfirm: false })}

                                customButtons={
                                    <React.Fragment>
                                        <Button className="quizCntrlBtns quizCntrlBtnsFill customSweetAlertButton" style={{ width: '147px', height: '40px' }} onClick={() => this.finalSubmition()}>Yes, Submit</Button>
                                        <Button className="quizCntrlBtns quizCntrlBtnsOutlined customSweetAlertButton" style={{ width: '95px', height: '40px' }} onClick={() => this.setState({ finalSubmitConfirm: false })}>Cancel</Button>
                                    </React.Fragment>
                                }

                            >

                                <div className='customSweetAlertIcn'></div>
                                <div className="customSweetAlertTxt">Are you sure you want to submit assessment?</div>
                                {/* <h4 className='m-4'>Questions Attempted: {this.getAttemptedQuesCount_(this.state.randomQues)}</h4> */}

                                {
                                    this.getunAttemptedQuesCount(this.state.randomQues) > 0 &&
                                    <div>
                                        <h4 className='m-4' style={{ fontFamily :'Poppins'}}> {this.getunAttemptedQuesCount(this.state.randomQues)} Questions are still unattempted</h4>
                                    </div>
                                }
                            </SweetAlert>
                        }
                        {
                            this.state.attempt_notification &&
                            <SweetAlert success title="Re Assessment" onConfirm={() => { this.setState({ attempt_notification: false }) }}>Attempt: {this.state.attempt_count === 0 ? 1 : this.state.attempt_count} </SweetAlert>

                        }

                        {
                            this.state.notAllowed &&
                            <SweetAlert danger title="Re Assessment" onConfirm={() => { this.props.history.push('/course/CourseDetails'); this.setState({ notAllowed: false }) }}>Please Try after {moment(this.state.try_after).format("DD-MM-YYYY HH:mm")} </SweetAlert>
                        }
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}












/////17-10-23
// import React, { Component } from 'react'
// import _ from 'lodash'
// import moment from 'moment'
// import { Progress, Button } from 'antd'
// import { Container, Row, Col } from "reactstrap"
// import SweetAlert from 'react-bootstrap-sweetalert'
// import CountdownTimer from "react-component-countdown-timer"

// import SingleChoiseQuest from './Component/SingleChoiseQuest'
// import MultiChoiseQuest from './Component/MultiChoiseQuest'
// import TextInputBox from './Component/TextInputBox'
// import UserAnswers from './Component/UserAnswers'
// import FinalResult from './Component/FinalResult'
// import urlSocket from '../../helpers/urlSocket'
// import MyAnswers from './Component/MyAnswers'

// // import "react-component-countdown-timer/lib/styles.css"

// import './CSS/countercss.scss'


// export default className index extends Component {
//     constructor(props) {
//         super(props)
//         this.state = {
//             randomQues: [],
//             attempted_on: new Date(),
//             baseurl: '',
//             question_type: '',
//             correctAnswer: '',
//             user_selected_multi: [],
//             showMyAns: false,
//             submiting: false,
//             isSubmited: false,
//             colorChange: false,
//             isFinalSubmited: false,
//             finalSubmitConfirm: false,
//             comp_perc: 0,
//             totalValue: 0,
//             selectedQues: 0,
//             attempt_count: 0,
//             course_duration: 0,
//             timeUp: null,
//             isCorrect: null,
//             is_correct: null,
//             pass_criteria: null,
//             user_selected: null,
//             min_pass_ques: null,
//             attempt_status: null,
//             re_attempt_duration: null,
//             count_down: false,
//             course_content: []
//         }
//     }

//     componentDidMount() {
//         console.log("objectobjectobjectobjectobjectobjectobjectobjectobject", this.state.count_down, this.props.get_assessment)
//         this.setState({ course_content: this.props.course_content })
//         if (this.state.count_down !== true) {
//             console.log("!!!")
//             window.addEventListener("beforeunload", this.handleBeforeUnload)
//         }
//         this.getSession()
//         this.getQuestionsAPI()
//     }

//     getSession = () => {
//         var getconfsession = JSON.parse(sessionStorage.getItem("getconfsession"))
//         let baseurl = getconfsession.baseurl
//         this.setState({ baseurl })
//     }

//     componentWillUnmount() {
//         console.log("unmount", this.state.count_down)
//         window.removeEventListener("beforeunload", this.handleBeforeUnload)
//     }

//     handleBeforeUnload = (e) => {
//         e.preventDefault()
//         const message = "Are you sure you want to leave? All provided data will be lost."
//         e.returnValue = message
//         return message
//     }

//     convertToSec = (time_duration) => {
//         const [hours, minutes] = time_duration.split(':')
//         const totalSeconds = (+hours) * 60 * 60 + (+minutes) * 60
//         // console.log(totalSeconds,"tyming")
//         return Number(totalSeconds)
//     }

//     getQuestionsAPI = async () => {

//         //var Exams = JSON.parse(sessionStorage.getItem("Exams"))
//         var Exams = this.props.examData
//         if (Exams !== null) {
//             console.log('Exams', Exams)
//             let { randomQuestions, max_no_of_optn, min_pass_perc, total_no_ques, time_duration, no_of_attempt, re_attempt_duration, document_id, pass_criteria, min_pass_ques, avl_randomization } = Exams
//             let course_duration = this.convertToSec(time_duration)

//             let attempted_on = new Date()
//             let totalValue = Number(randomQuestions.length)
//             var correct_answers = []
//             var wrong_answers = []

//             // let randomQues = randomQuestions.sort(() => Math.random() - 0.5)

//             if (avl_randomization) {
//                 for (let index = 0; index < randomQuestions.length; index++) {
//                     const options = randomQuestions[index].options

//                     correct_answers = options.filter(word => word.is_ans === "1")
//                     wrong_answers = _.filter(options, { is_ans: "0" })
//                     var shuff = _.shuffle(wrong_answers)
//                     var remaining_options = max_no_of_optn - correct_answers.length
//                     shuff = shuff.slice(0, remaining_options)
//                     var total_ans = correct_answers.concat(shuff)
//                     var final_shuffled = _.shuffle(total_ans)

//                     let all_above_op1 = "None of the above".toLowerCase()
//                     let all_above_op2 = "All of the above".toLowerCase()

//                     let all_txt_answer = {}
//                     let except_all_above_options = []

//                     final_shuffled.forEach(data => {
//                         if (data.option.toLowerCase() === all_above_op1) {
//                             all_txt_answer = data
//                             except_all_above_options = final_shuffled.filter((data) => data.option.toLowerCase() !== all_above_op1)
//                             // console.log('None of the above', except_all_above_options)
//                         }
//                         if (data.option.toLowerCase() === all_above_op2) {
//                             all_txt_answer = data
//                             except_all_above_options = final_shuffled.filter((data) => data.option.toLowerCase() !== all_above_op2)
//                             // console.log('All of the above', except_all_above_options)
//                         }
//                     })
//                     if (except_all_above_options.length !== 0) {
//                         except_all_above_options.push(all_txt_answer)
//                         // console.log('except_all_above_options', except_all_above_options)
//                         randomQuestions[index].options_displayed = except_all_above_options
//                     } else {
//                         randomQuestions[index].options_displayed = final_shuffled
//                     }
//                 }
//                 // console.log('randomQuestions', randomQuestions)
//                 randomQuestions = _.shuffle(randomQuestions)
//             } else {
//                 for (let index = 0; index < randomQuestions.length; index++) {
//                     const options = randomQuestions[index].options
//                     correct_answers = options.filter(word => word.is_ans === "1")
//                     wrong_answers = _.filter(options, { is_ans: "0" })
//                     var remaining_options = max_no_of_optn - correct_answers.length
//                     let shuff = wrong_answers.slice(0, remaining_options)
//                     var total_ans = correct_answers.concat(shuff)
//                     // total_ans = _.shuffle(total_ans) // if wrong answer on sequence please un commend

//                     let all_above_op1 = "None of the above".toLowerCase()
//                     let all_above_op2 = "All of the above".toLowerCase()

//                     let all_txt_answer = {}
//                     let except_all_above_options = []

//                     total_ans.forEach(data => {
//                         if (data.option.toLowerCase() === all_above_op1) {
//                             all_txt_answer = data
//                             except_all_above_options = total_ans.filter((data) => data.option.toLowerCase() !== all_above_op1)
//                             // console.log('None of the above', except_all_above_options)
//                         }
//                         if (data.option.toLowerCase() === all_above_op2) {
//                             all_txt_answer = data
//                             except_all_above_options = total_ans.filter((data) => data.option.toLowerCase() !== all_above_op2)
//                             // console.log('All of the above', except_all_above_options)
//                         }
//                     })
//                     if (except_all_above_options.length !== 0) {
//                         except_all_above_options.push(all_txt_answer)
//                         // console.log('except_all_above_options', except_all_above_options)
//                         randomQuestions[index].options_displayed = except_all_above_options
//                     } else {
//                         randomQuestions[index].options_displayed = total_ans
//                     }
//                 }
//                 // console.log('randomQuestions', randomQuestions)
//             }


//             this.setState({ randomQues: randomQuestions, course_duration, totalValue, min_pass_perc, total_no_ques, time_duration, no_of_attempt, attempted_on, re_attempt_duration, document_id, pass_criteria, min_pass_ques })
//             this.checkAssessmentAttempt()
//         }
//     }


//     checkAssessmentAttempt = () => {
//         var crs_subscr_id = JSON.parse(sessionStorage.getItem("crs_subscr_id"))
//         var crs_cntnt_info_id = JSON.parse(sessionStorage.getItem("CourseDetails"))
//         let document_id = this.state.document_id
//         let data = { crs_cntnt_info_id, crs_subscr_id, document_id }
//         console.log('subscr_crs/chk_alrdy_attmptd APIIII', { data })
//         try {
//             urlSocket.post('subscr_crs/chk_alrdy_attmptd', data)
//                 .then((res) => {
//                     let data = res.data.data
//                     // console.log('res.data', res.data)
//                     let randomQues = res.data.question_list
//                     if (res.data.response_code === 500) {
//                         this.setState({ randomQues })
//                         console.log('chk_alrdy_attmptd', res.data)
//                         let { attempt_status, score, attempt_count, attempted_on, assessment_list } = data[0]
//                         attempt_status = Number(attempt_status)
//                         if (Number(attempt_status) === 1) {
//                             this.setState({ isFinalSubmited: true, course_duration: 0, attempt_count: Number(attempt_count), selectedQues: this.state.randomQues.length + 1, attempt_status, score })
//                         } else {
//                             if (assessment_list.no_of_attempt) {
//                                 if (Number(assessment_list.no_of_attempt) > Number(attempt_count)) {
//                                     let re_attempt_duration = assessment_list.re_attempt_duration
//                                     let re_attempt_duration_sec = this.convertToSec(re_attempt_duration)
//                                     console.log('Last attempted_on', attempted_on)
//                                     attempted_on = new Date(attempted_on)
//                                     var nxt_attmpt = moment(attempted_on).add('seconds', re_attempt_duration_sec)._d
//                                     // var nxt_attmpt = moment(attempted_on).add(parseInt(re_attempt_duration_sec), 'seconds')

//                                     console.log('Current Date', new Date())
//                                     console.log('nxt_attmpt', nxt_attmpt)

//                                     if (new Date() > nxt_attmpt) {
//                                         console.log('You can attend')
//                                         this.setState({ attempt_count, attempt_notification: true })
//                                     } else {
//                                         console.log('Please Tryback ater Sometime')
//                                         this.setState({ randomQues: [], notAllowed: true, try_after: nxt_attmpt })
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 })
//         } catch (error) {
//             console.log('error', error)
//         }
//     }


//     gotoNextQues = () => {
//         this.setState(state => ({ selectedQues: state.selectedQues + 1, isCorrect: null, isSubmited: false, user_selected: null, correctAnswer: '', }))
//         let totalQuesCount = this.state.randomQues.length
//         let currentQuesNo = this.state.selectedQues
//         console.log("object", "gotonext", currentQuesNo === totalQuesCount)
//         if (currentQuesNo === totalQuesCount) {
//             this.setState(state => ({ showMyAns: true, selectedQues: state.selectedQues - 1, isCorrect: null, isSubmited: false, user_selected: null, correctAnswer: '' }))
//         }
//     }

//     onChangeOption = (user_selected, data, index) => {
//         console.log(data, "user_data", index, user_selected)
//         data.user_selected = user_selected
//         // data.submited = false
//         let result = data.options_displayed.filter((data) => data.is_ans === '1')[0]
//         let correctAnswer = result.option
//         let question_type = "Single-Choice"
//         this.setState({ selected_index_: index, correctAnswer, user_selected, question_type })
//     }

//     OnchangeTextAnswer = (value, data) => {
//         console.log(value, data, "value of radio")
//         data.user_selected = value
//         let question_type = "Text-Box"
//         this.setState({ question_type, user_selected: value })
//     }

//     onChangeMultiOption = (e, index, data) => {
//         console.log(e, "value", index, "indii", data)
//         data.options_displayed[index].user_selected = e
//         // // data.submited = false
//         let result = data.options_displayed.filter((data) => data.is_ans === '1')
//         let user_selected_multi = data.options_displayed.filter((data) => data.user_selected === true)

//         let correctAnswer = this.matchAnswers(result, user_selected_multi)
//         let question_type = "Multi-Choice"
//         console.log('user_selected_multi', user_selected_multi)
//         this.setState({ user_selected_multi, correctAnswer, user_selected: user_selected_multi, question_type })
//         data.user_selected = user_selected_multi //
//     }

//     matchAnswers(a, b) {
//         return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index])
//     }

//     onPreviosQues = (selected_index) => {

//         let selectedQues = this.state.selectedQues - 1
//         let randomQues = this.state.randomQues
//         let user_selected = this.state.user_selected
//         let already_selected_ans = randomQues[selectedQues].user_selected
//         randomQues[selected_index].submited = false
//         console.log(randomQues, "randomQues")
//         console.log('user_selected', selected_index + 1, user_selected, selectedQues)
//         console.log('already_selected_ans', randomQues, already_selected_ans)
//         this.setState({ selectedQues, selected_index, user_selected: already_selected_ans })
//     }

//     onSubmitNext = (selected_index) => {

//         console.log(selected_index, "this.state.selectedQues")
//         this.setState({ submiting: true })
//         let { correctAnswer, user_selected, question_type } = this.state

//         let isCorrect = null

//         if (question_type === "Single-Choice") {

//             if (correctAnswer === user_selected) {
//                 isCorrect = true
//             } else {
//                 isCorrect = false
//             }

//         } else if (question_type === "Multi-Choice") {
//             isCorrect = this.state.correctAnswer
//             console.log(this.state.correctAnswer, "correctans")
//         } else if ("Text-Box") {
//             console.log('Text-Box')
//         } else {

//         }
//         console.log(isCorrect, "this.state.selectedQues", isCorrect)

//         this.setState({ isCorrect })
//         this.submitQuesAPI(isCorrect)
//     }


//     submitQuesAPI = async (isCorrect) => {
//         let { randomQues, selectedQues, user_selected } = this.state
//         let currentQues = randomQues[selectedQues]

//         if (isCorrect === "") {
//             isCorrect = this.state.is_correct
//             console.log(this.state.correctAnswer, "correctans")
//         }
//         console.log(this.state.correctAnswer, "correctans", isCorrect)
//         currentQues.is_correct = isCorrect
//         currentQues.submited = true

//         let already_selected_ans = randomQues[selectedQues].user_selected
//         console.log('randomQues[selectedQues]', randomQues[selectedQues], currentQues, already_selected_ans)

//         if (randomQues[selectedQues].user_selected === null || randomQues[selectedQues].user_selected === undefined) {
//             console.log('user_selected', user_selected)
//             currentQues.user_selected = user_selected
//         } else {
//             currentQues.user_selected = already_selected_ans
//             console.log('already_selected_ans', already_selected_ans)

//         }


//         console.log('updt_crs_sbscr_assmnt_rspns', currentQues)
//         await urlSocket.post("subscr_crs/updt_crs_sbscr_assmnt_rspns", { data: currentQues })
//             .then(async (res) => {
//                 console.log(res, "response")
//                 if (res.data.response_code === 500) {
//                     // let AllquestionsList = res.data.data

//                     this.setState({ randomQues, isSubmited: true, submiting: false })
//                     this.gotoNextQues()
//                 }
//             })
//             .catch((err) => {
//                 this.setState({ submiting: false })
//             })
//     }

//     // let wrong_answers_count = _.filter(randomQues, { is_correct: false }).length
//     finalSubmition = () => {
//         let { attempted_on, attempt_count, randomQues, min_pass_perc, total_no_ques, re_attempt_duration, document_id, pass_criteria, min_pass_ques } = this.state
//         var crs_subscr_id = JSON.parse(sessionStorage.getItem("crs_subscr_id"))
//         let attempt_status = null
//         let data = {}
//         let score = _.filter(randomQues, { is_correct: true }).length
//         if (pass_criteria === "Percentage") {

//             let score_percent = (score / Number(total_no_ques)) * 100

//             if (Number(score_percent) <= Number(min_pass_perc)) {
//                 attempt_status = 0
//             } else {
//                 attempt_status = 1
//             }

//             let pass_percent = parseFloat(min_pass_perc)

//             attempt_count = Number(attempt_count) + 1

//             data = { attempted_on, attempt_count, score, score_percent, attempt_status, pass_percent, crs_subscr_id, re_attempt_duration, document_id, all_questions: randomQues, pass_criteria, completed_status: "2" }


//         } else {
//             // let wrong_answers_count = _.filter(randomQues, { is_correct: false }).length


//             if (Number(min_pass_ques) > Number(score)) {
//                 attempt_status = 0
//             } else {
//                 attempt_status = 1
//             }
//             console.log('attempt_status', attempt_status)

//             data = {
//                 attempted_on, attempt_count, crs_subscr_id, re_attempt_duration, document_id, all_questions: randomQues, pass_criteria, score, attempt_status, score_percent: null, pass_percent: null, completed_status: 2
//             }

//         }
//         this.setState({ attempt_status, isFinalSubmited: true, score })

//         console.log('data_______', data)

//         urlSocket.post("subscr_crs/assmnt_submsn", { data })
//             .then(async (res) => {
//                 console.log(res, "count_timer")
//                 if (res.data.response_code === 500) {
//                     console.log('res', res.data)
//                     this.setState({ showMyAns: false, finalSubmitConfirm: false, count_down: true }, () => { this.courseTopicUpdate(document_id, 2) })
//                     console.log("removelistner")
//                     window.removeEventListener("beforeunload", this.handleBeforeUnload)

//                 }
//             })
//     }

//     courseTopicUpdate = (document_id, status) => {
//         console.log(document_id, status)
//         let entireObj = [...this.state.course_content]
//         // var completed_on = new Date()
//         // entireObj[0]
//         // entireObj.push(completed_on)
//         // console.log(entireObj,"entireObj")
//         var course_info_name = JSON.parse(sessionStorage.getItem("CourseName"))
//         let course_name = course_info_name.name_of_the_course
//         var authUser = JSON.parse(sessionStorage.getItem("authUser"))
//         // var crs_subscr_id = authUser._id
//         var course_info_id = JSON.parse(sessionStorage.getItem("CourseDetails"))
//         console.log(course_name, crs_subscr_id, course_info_id, entireObj)
//         var VnestedValue;
//         let keyToFind = 'document_id'
//         let foundObj;


//         JSON.stringify(entireObj, (_, nestedValue) => {
//             // console.log(nestedValue,"inside json")
//             if (nestedValue && nestedValue[keyToFind] === document_id) {
//                 if (nestedValue.children === undefined) {
//                     nestedValue.children = []
//                 }
//                 // console.log("statusstatusstatus", status)
//                 foundObj = nestedValue.content_status = status
//                 foundObj = nestedValue.completed_on = new Date()
//                 VnestedValue = nestedValue
//             }
//             this.setState({ course_content: entireObj, nestedValue })
//             // console.log(nestedValue, "nestedValue")
//             // VnestedValue = nestedValue
//             return nestedValue

//         })
//         if (status === 2) {
//             var crs_subscr_id = JSON.parse(sessionStorage.getItem("crs_subscr_id"))
//             console.log('completed', status, crs_subscr_id)
//             try {
//                 // urlSocket.post('mngsubcrs/updt_crs_tree', { course_content: VnestedValue, name_of_the_course: this.state.name_of_the_course, crs_subscr_id: this.state.crs_subscr_id })
//                 urlSocket.post('mngsubcrs/updt_crs_tree', { course_content: entireObj, name_of_the_course: course_name, subscriber_id: authUser._id, crs_subscbr_id: crs_subscr_id })
//                     .then((res) => {
//                         console.log('updt_cntnt_status ', res.data)
//                         // var data = JSON.parse(sessionStorage.getItem("onClickLink"))
//                         // this.props.onClickLink(data)
//                     })
//             } catch (error) {
//                 console.log(error)

//             }
//             this.onUpdateCourseStatus(entireObj, authUser._id, course_info_id)
//             return foundObj

//         }

//     }
//     onUpdateCourseStatus = (course_content, subscriber_id, crs_subscr_id) => {
//         //console.log('course_content', course_content) 
//         // let { crs_subscr_id, course_info_id, } = this.state
//         console.log('crs_subscr_id, course_info_id', subscriber_id, crs_subscr_id)
//         try {
//             urlSocket.post('mngsubcrs/crs_cntnt_status_update', { subscriber_id: subscriber_id, crs_subscr_id, course_content })
//                 .then((res) => {
//                     console.log('onUpdateCourseStatus :', res.data)
//                     if (res.data.response_code === 500) {
//                         // let data= _.filter(course_content,e=>{
//                         // _.filter(e.children,i=>{
//                         // return i.document_type === 2
//                         // })
//                         // })
//                         // var data = JSON.parse(sessionStorage.getItem("onClickLink"))

//                         // this.props.onClickLink(data)
//                         // return <Link to="/Exams"/>
//                         // window.location.reload(false)

//                     }
//                 })
//         } catch (error) {

//         }
//     }

//     onTimeupCourse = () => {
//         this.setState({ timeUp: true, selectedQues: this.state.randomQues.length + 1 })
//         this.finalSubmition()
//     }

//     getAttemptedQuesCount = (questions, index) => {
//         console.log(questions, "questions")
//         let attempted_ques = questions.filter((data) => (data.submited !== undefined && data.submited)).length
//         console.log(attempted_ques, "attempted_ques")
//         return attempted_ques
//     }

//     previous_ques = (questions) => {
//         console.log(questions, "questions")
//         let attempted_ques = questions.filter((data) => (data.submited !== undefined && data.submited)).length
//         console.log(attempted_ques, "attempted_ques")
//         return attempted_ques
//     }

//     getAttemptedQuesCount_ = (questions) => {
//         let attempted_ques = questions.filter((data) => (data.user_selected !== null)).length
//         console.log(attempted_ques, "attempted_ques")
//         return attempted_ques
//     }

//     getunAttemptedQuesCount = (questions) => {
//         let unattempted = questions.filter((data) => (data.user_selected === null)).length
//         return unattempted
//     }

//     goTomatchedQues = (_id) => {
//         let index = this.state.randomQues.findIndex((elem) => { return elem._id === _id })
//         return index
//     }

//     goToQues = (selectedQues) => {
//         console.log('this.state.randomQues[', this.state.randomQues[selectedQues], selectedQues)
//         let is_correct = this.state.randomQues[selectedQues].is_correct
//         console.log(is_correct, "iscorrect", selectedQues)
//         this.setState({ selectedQues, showMyAns: false, show_allpage: true, is_correct })
//     }

//     getAttendedPercentage = (questions) => {
//         try {
//             if (questions.length) {
//                 let attempted_ques = questions.filter((data) => (data.submited !== undefined && data.submited)).length
//                 let total_no_ques = questions.length
//                 let percentage = ((attempted_ques + 1) / total_no_ques) * 100
//                 return percentage
//             }
//         } catch (error) {
//             console.log('error', error)
//         }
//     }

//     onSubmitInterMediateReview = async () => {
//         this.setState({ submiting: true })
//         let { correctAnswer, user_selected, question_type } = this.state

//         let isCorrect = null

//         if (question_type === "Single-Choice") {

//             if (correctAnswer === user_selected) {
//                 isCorrect = true
//             } else {
//                 isCorrect = false
//             }

//         }
//         if (question_type === "Multi-Choice") {
//             isCorrect = this.state.correctAnswer
//         } else {
//             isCorrect = false
//         }


//         if ("Text-Box") {
//             console.log('Text-Box')
//         } else {
//         }


//         let { randomQues, selectedQues, } = this.state
//         let currentQues = randomQues[selectedQues]
//         currentQues.is_correct = isCorrect
//         currentQues.submited = true

//         let already_selected_ans = randomQues[selectedQues].user_selected

//         if (randomQues[selectedQues].user_selected === null || randomQues[selectedQues].user_selected === undefined) {
//             currentQues.user_selected = user_selected
//         } else {
//             currentQues.user_selected = already_selected_ans

//         }


//         console.log('updt_crs_sbscr_assmnt_rspns', currentQues, randomQues, selectedQues, this.state.correctAnswer)
//         await urlSocket.post("subscr_crs/updt_crs_sbscr_assmnt_rspns", { data: currentQues })
//             .then(async (res) => {
//                 console.log(res, "res")
//                 if (res.data.response_code === 500) {
//                     // let AllquestionsList = res.data.data

//                     this.setState({ randomQues, isSubmited: true, submiting: false, showMyAns: true, isCorrect })
//                 }
//             })
//             .catch((err) => {
//                 this.setState({ submiting: false })
//             })


//     }

//     render() {
//         return (
//             <React.Fragment>
//                 <div className="" style={{ marginTop: this.state.isFinalSubmited ? "-10px" : "0" }}>
//                     {
//                         !this.state.isFinalSubmited && !this.state.showMyAns && (
//                             <Container style={{ background: '#ECECEC', maxWidth: '1440px' }}>
//                                 <Row className='pt-4 pb-3' >
//                                     <Col md={12}>
//                                         {/* {
//  this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length !== this.state.totalValue && this.state.previous !== true ?
//  <div className='qaprocessCntr' >You are attempting {this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length + 1}/ {this.state.totalValue}</div>
//  :
//  this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length === this.state.totalValue && this.state.previous !== true ?
//  <div className='qaprocessCntr' >You are attempting {this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length}/ {this.state.totalValue}</div>
//  :
//  this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length > this.state.totalValue && this.state.previous !== true ?
//  <div className='qaprocessCntr' >You are attempting {this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length}/ {this.state.totalValue}</div>
//  :
//  null

//  } */}
//                                         {
//                                             <div className='qaprocessCntr' >You are attempting {this.state.selectedQues + 1}/ {this.state.totalValue}</div>
//                                         }
//                                         {/* {
//  console.log(this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length + 1 > this.state.totalValue, "start_check", this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length, this.state.totalValue)
//  } */}
//                                         {/* <div className='qaprocessCntr' >You are attempting {this.getAttemptedQuesCount(this.state.randomQues) + 1}/ {this.state.totalValue}</div> */}
//                                         {/* <Progress strokeLinecap="square" percent={this.getAttendedPercentage(this.state.randomQues)} showInfo={false} strokeColor={'#5E5E5E'} /> */}

//                                         <Progress strokeLinecap="square" percent={((this.state.selectedQues + 1) / this.state.totalValue) * 100} showInfo={false} strokeColor={'#5E5E5E'} />

//                                         {/* {
//  this.state.previous === true ?
//  <div className='qaprocessCntr' >You are attempting {this.state.selected_index}/ {this.state.totalValue}</div>
//  :
//  null

//  } */}
//                                         {/* {
//  this.state.previous === true ?
//  <Progress strokeLinecap="square" percent={this.getAttendedPercentage(this.state.randomQues)} showInfo={false} strokeColor={'#5E5E5E'} />
//  :
//  null
//  } */}
//                                         {/* {
//  console.log(this.state.selected_index, "index")
//  } */}

//                                     </Col>
//                                 </Row>
//                             </Container>
//                         )}
//                     <Container className="pt-3 pb-3" style={{ background: 'white', maxWidth: '636px' }}>
//                         <Row>
//                             <div className="col-md-12 ps-0 pe-0">
//                                 <div className="row">
//                                     <div className="col borNone quizTitle ps-0"><h1 style={{ fontSize: '28px', padding: 0, margin: 0 }} onClick={() => console.log('this.state.randomQues', this.state.randomQues)}>Quiz</h1> </div>
//                                     <div className="col borNone timerDiv" style={{ textAlign: 'right', padding: 0, margin: 0 }}>
//                                         {
//                                             // ((this.state.selectedQues + 1) <= (this.state.randomQues.length)) && <div> ({this.state.selectedQues + 1} of {this.state.randomQues.length} )</div>
//                                         }
//                                         {
//                                             this.state.course_duration > 0 && (
//                                                 <>
//                                                     {/* {
//  console.log(this.state.course_duration , "this.state.course_duration")
//  } */}
//                                                     {
//                                                         !this.state.count_down ?
//                                                             <div className='countDownTimerDiv d-flex align-items-center justify-content-end'>
//                                                                 <div><i className='bx bx-time-five' style={{ fontSize: '18px', marginTop: '13px', marginRight: '3px' }}></i></div>

//                                                                 <CountdownTimer className="mt-2" style={{ fontSize: '14px' }} hideDay={true} count={this.state.course_duration} border onEnd={() => { this.onTimeupCourse() }} />

//                                                             </div>
//                                                             :
//                                                             null
//                                                     }
//                                                     <div className='fs-18'>
//                                                         {/* Questions Attempted: {this.getAttemptedQuesCount(this.state.randomQues)} */}
//                                                         {
//                                                             (this.state.min_pass_ques !== undefined && this.state.min_pass_ques !== null) &&
//                                                             <div>
//                                                                 Min. pass Questions : {this.state.min_pass_ques}
//                                                             </div>
//                                                         }
//                                                     </div>
//                                                 </>
//                                             )
//                                         }
//                                         {
//                                             this.state.timeUp && <h4>Time UP!!!!</h4>
//                                         }
//                                     </div>
//                                 </div>
//                             </div>
//                         </Row>
//                         {
//                             !this.state.isFinalSubmited && (
//                                 <hr />
//                             )
//                         }
//                         {
//                             !this.state.showMyAns &&
//                             <Row className='justify-content-left' style={{ minHeight: "30vh" }}>
//                                 <div className="col-md-12">
//                                     {
//                                         this.state.isFinalSubmited ?
//                                             <div className=''>
//                                                 <FinalResult attempt_status={this.state.attempt_status} pass_criteria={this.state.pass_criteria} score={this.state.score} randomQues={this.state.randomQues} goBack={() => this.props.history.push('/course/CourseDetails')} />
//                                                 <UserAnswers getNextData={(item) =>this.props.getNextData(item)} questionsList={this.state.randomQues} score={this.state.score} randomQues={this.state.randomQues} getCourseDetails={this.props.getCourseDetails} />
//                                             </div>
//                                             :
//                                             <>

//                                                 {
//                                                     this.state.randomQues.map((data, index) => (
//                                                         <div key={index} >
//                                                             {
//                                                                 index === this.state.selectedQues &&
//                                                                 <div>
//                                                                     <h3 className='mt-4 mb-2 fontInter fontWeightNormal' style={{fontSize: '16px', fontFamily: 'Inter', fontWeight: '500', color: '#3A4956'}}>{index + 1}. {data.question}</h3>
//                                                                     <div className=''>
//                                                                         {
//                                                                             (data.media_url !== undefined && data.media_url !== null) &&
//                                                                             <audio controls>
//                                                                                 <source src={this.state.baseurl + data.media_url} type="audio/ogg" />
//                                                                                 <source src={this.state.baseurl + data.media_url} type="audio/mpeg" />
//                                                                             </audio>
//                                                                         }
//                                                                     </div>
//                                                                     {
//                                                                         data.question_type === "Single-Choice" ?
//                                                                             <SingleChoiseQuest options={data.options_displayed} onChangeOption={(e) => this.onChangeOption(e.target.value, data, index)} user_selected={data.user_selected} />
//                                                                             :
//                                                                             data.question_type === "Multi-Choice" ?
//                                                                                 // <MultiChoiseQuest options={data.options_displayed} onChangeOption={(value, index) => this.onChangeMultiOption(value,index,data)} user_selected={data.user_selected} />
//                                                                                 <MultiChoiseQuest options={data.options_displayed} onChangeOption={(e, index) => this.onChangeMultiOption(e, index, data)} user_selected={data.user_selected} />
//                                                                                 // <MultiChoiseQuest options={data.options_displayed} onChangeOption={(e) => this.onChangeOption(e.target.value, data, index)} user_selected={data.user_selected} />

//                                                                                 :
//                                                                                 data.question_type === "Text-Box" ?
//                                                                                     <TextInputBox OnchangeTextAnswer={(value) => this.OnchangeTextAnswer(value, data)} user_selected={data.user_selected} />
//                                                                                     :
//                                                                                     null
//                                                                     }
//                                                                 </div>
//                                                             }
//                                                         </div>
//                                                     ))
//                                                 }

//                                             </>
//                                     }
//                                 </div>
//                             </Row>
//                         }

//                         {
//                             this.state.showMyAns ?
//                                 <Row>
//                                     <div className="row">
//                                         <MyAnswers questionsList={this.state.randomQues} goToQues={(idx) => this.goToQues(idx)} attempted_count={this.getAttemptedQuesCount_(this.state.randomQues)} unattempted_count={this.getunAttemptedQuesCount(this.state.randomQues)} />

//                                         <div className="col-md-12">
//                                             <div className="d-flex justify-content-left">
//                                                 <div className=""><Button className='mx-2 quizCntrlBtns quizCntrlBtnsOutlined' style={{width: '173px', height: '40px'}} onClick={() => this.setState({ showMyAns: false, show_allpage: true })} >Review Responses</Button></div>
//                                                 <div className=""><Button className='mx-2 quizCntrlBtns quizCntrlBtnsOutlined' style={{width: '150px', height: '40px'}} onClick={() => this.setState({ finalSubmitConfirm: true })}>Submit Quiz</Button></div>
//                                             </div>
//                                         </div>

//                                     </div>
//                                 </Row>
//                                 :
//                                 null
//                         }
//                         {/* {
//  console.log(this.state.selectedQues, "this.state.selectedQues")
//  } */}
//                         {
//                             (!this.state.showMyAns && !this.state.isFinalSubmited) &&
//                             <div className="col-md-12 mt-5">
//                                 <div className="d-flex justify-content-left">
//                                     {
//                                         this.state.selectedQues > 0 ?
//                                             // <Button className="quizCntrlBtns quizCntrlBtnsOutlined" onClick={() => this.onPreviosQues(this.state.selectedQues)}>Previous</Button>
//                                             <>
//                                             <Button className="quizCntrlBtns quizCntrlBtnsOutlined" onClick={() => this.onPreviosQues(this.state.selectedQues)}>Previous</Button> 
//                                             &nbsp; &nbsp;
//                                             </>

//                                             :
//                                             null
//                                     }
//                                     {/* {
//  console.log(this.state.selectedQues + 1,this.state.randomQues.length,this.state.selectedQues + 1 === this.state.randomQues.length,"this.state.selectedQues + 1 !== this.state.randomQues.length","705",this.state.selectedQues + 1 === this.state.randomQues.length)
//  } */}
//                                     {
//                                         (this.state.selectedQues + 1 !== this.state.randomQues.length) &&

//                                         this.state.show_allpage && <Button className='mx-2 quizCntrlBtns quizCntrlBtnsOutlined' onClick={() => this.onSubmitInterMediateReview()}>Review Responses</Button>
//                                     }
//                                     {/* {
//  (this.state.selectedQues +1 === this.state.randomQues.length) || (this.state.selectedQues +1 !== this.state.randomQues.length) &&
 
//  this.state.show_allpage && <Button className='mx-2 quizCntrlBtns quizCntrlBtnsOutlined' onClick={() => this.onSubmitInterMediateReview()}>Review Responses</Button>
//  } */}
//                                     {
//                                         ((this.state.selectedQues) < (this.state.randomQues.length - 1)) && <Button className="quizCntrlBtns quizCntrlBtnsOutlinedGrey" onClick={() => { this.onSubmitNext(); }}>Next</Button>
//                                     }

//                                     {
//                                         !this.state.isSubmited ?
//                                             <>

//                                                 {
//                                                     (this.state.selectedQues + 1 === this.state.randomQues.length) &&

//                                                     <>
//                                                         <Button className=" quizCntrlBtns quizCntrlBtnsOutlined" style={{width: '173px'}} type="success" onClick={() => this.onSubmitNext(this.state.selectedQues)} loading={this.state.submiting}>{this.state.submiting ? 'Submiting...' : 'Review Response'} </Button>

//                                                         {
//                                                             console.log("checking11", this.state.isSubmited)
//                                                         }
//                                                     </>

//                                                 }
//                                                 {/* {
//                                             console.log(this.state.selectedQues -1 === this.state.randomQues.length,"this.state.selectedQues -1 === this.state.randomQues.length")
//                                             } */}
//                                             </>
//                                             :
//                                             this.state.isSubmited ?
//                                                 <>

//                                                     {
//                                                         (this.state.selectedQues + 1 === this.state.randomQues.length) &&

//                                                         <>
//                                                             <Button className=" quizCntrlBtns quizCntrlBtnsOutlined" type="success" onClick={() => this.onSubmitNext(this.state.selectedQues)} loading={this.state.submiting}>{this.state.submiting ? 'Submiting...' : 'Review Response'} </Button>

//                                                             {
//                                                                 console.log("checking11", this.state.isSubmited)
//                                                             }
//                                                         </>

//                                                     }
//                                                     {/* {
//  console.log(this.state.selectedQues -1 === this.state.randomQues.length,"this.state.selectedQues -1 === this.state.randomQues.length")

//  } */}
//                                                 </>
//                                                 :
//                                                 null
//                                     }
//                                 </div>
//                             </div>

//                         }

//                         {
//                             this.state.finalSubmitConfirm &&
//                             // <SweetAlert warning showCancel confirmBtnText="Yes, Submit!" confirmBtnBsStyle="danger" title="Are you sure you want to submit Assessment?" onConfirm= onCancel= focusCancelBtn>
//                             <SweetAlert style={{ width: "450px", margin: "0 auto", borderRadius: '10px', height: '297px' }}
//                                 title=""
//                                 showCloseButton
//                                 //customIcon=""
//                                 showCancel
//                                 // confirmBtnText="cancel"
//                                 //cancelBtnText="OK"
//                                 //confirmBtnBsStyle="danger"
//                                 //cancelBtnBsStyle="success"
//                                 //onCancel={() => this.finalSubmition()}
//                                 //onConfirm={() => this.setState({ finalSubmitConfirm: false })}

//                                 customButtons={
//                                     <React.Fragment>
//                                         <Button className="quizCntrlBtns quizCntrlBtnsFill customSweetAlertButton" style={{width: '147px', height: '40px'}} onClick={() => this.finalSubmition()}>Yes, Submit</Button>
//                                         <Button className="quizCntrlBtns quizCntrlBtnsOutlined customSweetAlertButton" style={{width: '95px', height: '40px'}} onClick={() => this.setState({ finalSubmitConfirm: false })}>Cancel</Button>
//                                     </React.Fragment>
//                                 }

//                             >

//                                 <div className='customSweetAlertIcn'></div>
//                                 <div className="customSweetAlertTxt">Are you sure you want to submit assessment?</div>
//                                 {/* <h4 className='m-4'>Questions Attempted: {this.getAttemptedQuesCount_(this.state.randomQues)}</h4> */}

//                                 {
//                                     this.getunAttemptedQuesCount(this.state.randomQues) > 0 &&
//                                     <div>
//                                         <h4 className='m-4'> {this.getunAttemptedQuesCount(this.state.randomQues)} Questions are still unattempted</h4>
//                                     </div>
//                                 }
//                             </SweetAlert>
//                         }
//                         {
//                             this.state.attempt_notification &&
//                             <SweetAlert success title="Re Assessment" onConfirm={() => { this.setState({ attempt_notification: false }) }}>Attempt: {this.state.attempt_count === 0 ? 1 : this.state.attempt_count} </SweetAlert>

//                         }

//                         {
//                             this.state.notAllowed &&
//                             <SweetAlert danger title="Re Assessment" onConfirm={() => { this.props.history.push('/course/CourseDetails'); this.setState({ notAllowed: false }) }}>Please Try after {moment(this.state.try_after).format("DD-MM-YYYY HH:mm")} </SweetAlert>
//                         }
//                     </Container>
//                 </div>
//             </React.Fragment>
//         )
//     }
// }














// import React, { Component } from 'react' b4-jan-25-23
// import _ from 'lodash'
// import moment from 'moment'
// import { Progress, Button } from 'antd'
// import { Container, Row, Col } from "reactstrap"
// import SweetAlert from 'react-bootstrap-sweetalert'
// import CountdownTimer from "react-component-countdown-timer"

// import SingleChoiseQuest from './Component/SingleChoiseQuest'
// import MultiChoiseQuest from './Component/MultiChoiseQuest'
// import TextInputBox from './Component/TextInputBox'
// import UserAnswers from './Component/UserAnswers'
// import FinalResult from './Component/FinalResult'
// import urlSocket from '../../helpers/urlSocket'
// import MyAnswers from './Component/MyAnswers'

// // import "react-component-countdown-timer/lib/styles.css"

// import './CSS/countercss.scss'


// export default className index extends Component {
//     constructor(props) {
//         super(props)
//         this.state = {
//             randomQues: [],
//             attempted_on: new Date(),
//             baseurl: '',
//             question_type: '',
//             correctAnswer: '',
//             user_selected_multi: [],
//             showMyAns: false,
//             submiting: false,
//             isSubmited: false,
//             colorChange: false,
//             isFinalSubmited: false,
//             finalSubmitConfirm: false,
//             comp_perc: 0,
//             totalValue: 0,
//             selectedQues: 0,
//             attempt_count: 0,
//             course_duration: 0,
//             timeUp: null,
//             isCorrect: null,
//             is_correct: null,
//             pass_criteria: null,
//             user_selected: null,
//             min_pass_ques: null,
//             attempt_status: null,
//             re_attempt_duration: null,
//             count_down : false
//         }
//     }

//     componentDidMount() {
//         console.log("objectobjectobjectobjectobjectobjectobjectobjectobject",this.state.count_down,this.props.get_assessment)
//         if(this.state.count_down !== true){
//          console.log("!!!")   
//         window.addEventListener("beforeunload", this.handleBeforeUnload)
//         }
//         this.getSession()
//         this.getQuestionsAPI()
//     }

//     getSession = () => {
//         var getconfsession = JSON.parse(sessionStorage.getItem("getconfsession"))
//         let baseurl = getconfsession.baseurl
//         this.setState({ baseurl })
//     }

//     componentWillUnmount() {
//         console.log("unmount",this.state.count_down)
//         window.removeEventListener("beforeunload", this.handleBeforeUnload)
//     }

//     handleBeforeUnload = (e) => {
//         e.preventDefault()
//         const message = "Are you sure you want to leave? All provided data will be lost."
//         e.returnValue = message
//         return message
//     }

//     convertToSec = (time_duration) => {
//         const [hours, minutes] = time_duration.split(':')
//         const totalSeconds = (+hours) * 60 * 60 + (+minutes) * 60
//         // console.log(totalSeconds,"tyming")
//         return Number(totalSeconds)
//     }

//     getQuestionsAPI = async () => {

//         //var Exams = JSON.parse(sessionStorage.getItem("Exams"))
//         var Exams = this.props.examData
//         if (Exams !== null) {
//             console.log('Exams', Exams)
//             let { randomQuestions, max_no_of_optn, min_pass_perc, total_no_ques, time_duration, no_of_attempt, re_attempt_duration, document_id, pass_criteria, min_pass_ques, avl_randomization } = Exams
//             let course_duration = this.convertToSec(time_duration)

//             let attempted_on = new Date()
//             let totalValue = Number(randomQuestions.length)
//             var correct_answers = []
//             var wrong_answers = []

//             // let randomQues = randomQuestions.sort(() => Math.random() - 0.5)

//             if (avl_randomization) {
//                 for (let index = 0; index < randomQuestions.length; index++) {
//                     const options = randomQuestions[index].options

//                     correct_answers = options.filter(word => word.is_ans === "1")
//                     wrong_answers = _.filter(options, { is_ans: "0" })
//                     var shuff = _.shuffle(wrong_answers)
//                     var remaining_options = max_no_of_optn - correct_answers.length
//                     shuff = shuff.slice(0, remaining_options)
//                     var total_ans = correct_answers.concat(shuff)
//                     var final_shuffled = _.shuffle(total_ans)

//                     let all_above_op1 = "None of the above".toLowerCase()
//                     let all_above_op2 = "All of the above".toLowerCase()

//                     let all_txt_answer = {}
//                     let except_all_above_options = []

//                     final_shuffled.forEach(data => {
//                         if (data.option.toLowerCase() === all_above_op1) {
//                             all_txt_answer = data
//                             except_all_above_options = final_shuffled.filter((data) => data.option.toLowerCase() !== all_above_op1)
//                             // console.log('None of the above', except_all_above_options)
//                         }
//                         if (data.option.toLowerCase() === all_above_op2) {
//                             all_txt_answer = data
//                             except_all_above_options = final_shuffled.filter((data) => data.option.toLowerCase() !== all_above_op2)
//                             // console.log('All of the above', except_all_above_options)
//                         }
//                     })
//                     if (except_all_above_options.length !== 0) {
//                         except_all_above_options.push(all_txt_answer)
//                         // console.log('except_all_above_options', except_all_above_options)
//                         randomQuestions[index].options_displayed = except_all_above_options
//                     } else {
//                         randomQuestions[index].options_displayed = final_shuffled
//                     }
//                 }
//                 // console.log('randomQuestions', randomQuestions)
//                 randomQuestions = _.shuffle(randomQuestions)
//             } else {
//                 for (let index = 0; index < randomQuestions.length; index++) {
//                     const options = randomQuestions[index].options
//                     correct_answers = options.filter(word => word.is_ans === "1")
//                     wrong_answers = _.filter(options, { is_ans: "0" })
//                     var remaining_options = max_no_of_optn - correct_answers.length
//                     let shuff = wrong_answers.slice(0, remaining_options)
//                     var total_ans = correct_answers.concat(shuff)
//                     // total_ans = _.shuffle(total_ans)   // if wrong answer on sequence please un commend

//                     let all_above_op1 = "None of the above".toLowerCase()
//                     let all_above_op2 = "All of the above".toLowerCase()

//                     let all_txt_answer = {}
//                     let except_all_above_options = []

//                     total_ans.forEach(data => {
//                         if (data.option.toLowerCase() === all_above_op1) {
//                             all_txt_answer = data
//                             except_all_above_options = total_ans.filter((data) => data.option.toLowerCase() !== all_above_op1)
//                             // console.log('None of the above', except_all_above_options)
//                         }
//                         if (data.option.toLowerCase() === all_above_op2) {
//                             all_txt_answer = data
//                             except_all_above_options = total_ans.filter((data) => data.option.toLowerCase() !== all_above_op2)
//                             // console.log('All of the above', except_all_above_options)
//                         }
//                     })
//                     if (except_all_above_options.length !== 0) {
//                         except_all_above_options.push(all_txt_answer)
//                         // console.log('except_all_above_options', except_all_above_options)
//                         randomQuestions[index].options_displayed = except_all_above_options
//                     } else {
//                         randomQuestions[index].options_displayed = total_ans
//                     }
//                 }
//                 // console.log('randomQuestions', randomQuestions)
//             }


//             this.setState({ randomQues: randomQuestions, course_duration, totalValue, min_pass_perc, total_no_ques, time_duration, no_of_attempt, attempted_on, re_attempt_duration, document_id, pass_criteria, min_pass_ques })
//             this.checkAssessmentAttempt()
//         }
//     }


//     checkAssessmentAttempt = () => {
//         var crs_subscr_id = JSON.parse(sessionStorage.getItem("crs_subscr_id"))
//         var crs_cntnt_info_id = JSON.parse(sessionStorage.getItem("CourseDetails"))
//         let document_id = this.state.document_id
//         let data = { crs_cntnt_info_id, crs_subscr_id, document_id }
//        console.log('subscr_crs/chk_alrdy_attmptd APIIII', { data })
//         try {
//             urlSocket.post('subscr_crs/chk_alrdy_attmptd', data)
//                 .then((res) => {
//                     let data = res.data.data
//                     // console.log('res.data', res.data)
//                     let randomQues = res.data.question_list
//                     if (res.data.response_code === 500) {
//                         this.setState({ randomQues })
//                         console.log('chk_alrdy_attmptd', res.data)
//                         let { attempt_status, score, attempt_count, attempted_on, assessment_list } = data[0]
//                         attempt_status = Number(attempt_status)
//                         if (Number(attempt_status) === 1) {
//                             this.setState({ isFinalSubmited: true, course_duration: 0, attempt_count: Number(attempt_count), selectedQues: this.state.randomQues.length + 1, attempt_status, score })
//                         } else {
//                             if (assessment_list.no_of_attempt) {
//                                 if (Number(assessment_list.no_of_attempt) > Number(attempt_count)) {
//                                     let re_attempt_duration = assessment_list.re_attempt_duration
//                                     let re_attempt_duration_sec = this.convertToSec(re_attempt_duration)
//                                     console.log('Last attempted_on', attempted_on)
//                                     attempted_on = new Date(attempted_on)
//                                     var nxt_attmpt = moment(attempted_on).add('seconds', re_attempt_duration_sec)._d
//                                     // var nxt_attmpt = moment(attempted_on).add(parseInt(re_attempt_duration_sec), 'seconds')

//                                     console.log('Current Date', new Date())
//                                     console.log('nxt_attmpt', nxt_attmpt)

//                                     if (new Date() > nxt_attmpt) {
//                                         console.log('You can attend')
//                                         this.setState({ attempt_count, attempt_notification: true })
//                                     } else {
//                                         console.log('Please Tryback ater Sometime')
//                                         this.setState({ randomQues: [], notAllowed: true, try_after: nxt_attmpt })
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 })
//         } catch (error) {
//             console.log('error', error)
//         }
//     }


//     gotoNextQues = () => {
//         this.setState(state => ({ selectedQues: state.selectedQues + 1, isCorrect: null, isSubmited: false, user_selected: null, correctAnswer: '', }))
//         let totalQuesCount = this.state.randomQues.length
//         let currentQuesNo = this.state.selectedQues
//         console.log("object","gotonext",currentQuesNo === totalQuesCount)
//         if (currentQuesNo === totalQuesCount) {
//             this.setState(state => ({showMyAns: true, selectedQues: state.selectedQues - 1, isCorrect: null, isSubmited: false, user_selected: null, correctAnswer: '' }))
//         }
//     }

//     onChangeOption = (user_selected, data, index) => {
//         console.log(data, "user_data", index,user_selected)
//         data.user_selected = user_selected
//         // data.submited = false
//         let result = data.options_displayed.filter((data) => data.is_ans === '1')[0]
//         let correctAnswer = result.option
//         let question_type = "Single-Choice"
//         this.setState({ selected_index_: index, correctAnswer, user_selected, question_type })
//     }

//     OnchangeTextAnswer = (value, data) => {
//         console.log(value, data, "value of radio")
//         data.user_selected = value
//         let question_type = "Text-Box"
//         this.setState({ question_type, user_selected: value })
//     }

//     onChangeMultiOption = (e,index,data ) => {
//         console.log(e,"value",index,"indii",data)
//         data.options_displayed[index].user_selected = e
//         // // data.submited = false
//         let result = data.options_displayed.filter((data) => data.is_ans === '1')
//         let user_selected_multi = data.options_displayed.filter((data) => data.user_selected === true)

//         let correctAnswer = this.matchAnswers(result, user_selected_multi)
//         let question_type = "Multi-Choice"
//         console.log('user_selected_multi', user_selected_multi)
//         this.setState({ user_selected_multi, correctAnswer, user_selected: user_selected_multi, question_type })
//         data.user_selected = user_selected_multi  //
//     }

//     matchAnswers(a, b) {
//         return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index])
//     }

//     onPreviosQues = (selected_index) => {

//         let selectedQues = this.state.selectedQues - 1
//         let randomQues = this.state.randomQues
//         let user_selected = this.state.user_selected
//         let already_selected_ans = randomQues[selectedQues].user_selected
//         randomQues[selected_index].submited = false
//         console.log(randomQues, "randomQues")
//         console.log('user_selected', selected_index + 1, user_selected, selectedQues)
//         console.log('already_selected_ans', randomQues, already_selected_ans)
//         this.setState({ selectedQues, selected_index, user_selected: already_selected_ans })
//     }

//     onSubmitNext = (selected_index) => {

//         console.log(selected_index, "this.state.selectedQues")
//         this.setState({ submiting: true })
//         let { correctAnswer, user_selected, question_type } = this.state

//         let isCorrect = null

//         if (question_type === "Single-Choice") {

//             if (correctAnswer === user_selected) {
//                 isCorrect = true
//             } else {
//                 isCorrect = false
//             }

//         } else if (question_type === "Multi-Choice") {
//             isCorrect = this.state.correctAnswer
//             console.log(this.state.correctAnswer,"correctans")
//         } else if ("Text-Box") {
//             console.log('Text-Box')
//         } else {

//         }
//         console.log(isCorrect, "this.state.selectedQues",isCorrect)

//         this.setState({ isCorrect})
//         this.submitQuesAPI(isCorrect)
//     }


//     submitQuesAPI = async (isCorrect) => {
//         let { randomQues, selectedQues, user_selected } = this.state
//         let currentQues = randomQues[selectedQues]

//         if (isCorrect === "") {
//             isCorrect = this.state.is_correct
//             console.log(this.state.correctAnswer,"correctans")
//         }
//         console.log(this.state.correctAnswer,"correctans",isCorrect)
//         currentQues.is_correct = isCorrect
//         currentQues.submited = true

//         let already_selected_ans = randomQues[selectedQues].user_selected
//         console.log('randomQues[selectedQues]', randomQues[selectedQues],currentQues,already_selected_ans)

//         if (randomQues[selectedQues].user_selected === null || randomQues[selectedQues].user_selected === undefined) {
//             console.log('user_selected', user_selected)
//             currentQues.user_selected = user_selected
//         } else {
//             currentQues.user_selected = already_selected_ans
//             console.log('already_selected_ans', already_selected_ans)

//         }


//         console.log('updt_crs_sbscr_assmnt_rspns', currentQues)
//         await urlSocket.post("subscr_crs/updt_crs_sbscr_assmnt_rspns", { data: currentQues })
//             .then(async (res) => {
//                 console.log(res,"response")
//                 if (res.data.response_code === 500) {
//                     // let AllquestionsList = res.data.data

//                     this.setState({ randomQues, isSubmited: true, submiting: false })
//                     this.gotoNextQues()
//                 }
//             })
//             .catch((err) => {
//                 this.setState({ submiting: false })
//             })
//     }

//     // let wrong_answers_count = _.filter(randomQues, { is_correct: false }).length
//     finalSubmition = () => {
//         let { attempted_on, attempt_count, randomQues, min_pass_perc, total_no_ques, re_attempt_duration, document_id, pass_criteria, min_pass_ques } = this.state
//         var crs_subscr_id = JSON.parse(sessionStorage.getItem("crs_subscr_id"))
//         let attempt_status = null
//         let data = {}
//         let score = _.filter(randomQues, { is_correct: true }).length
//         if (pass_criteria === "Percentage") {

//             let score_percent = (score / Number(total_no_ques)) * 100

//             if (Number(score_percent) <= Number(min_pass_perc)) {
//                 attempt_status = 0
//             } else {
//                 attempt_status = 1
//             }

//             let pass_percent = parseFloat(min_pass_perc)

//             attempt_count = Number(attempt_count) + 1

//             data = { attempted_on, attempt_count, score, score_percent, attempt_status, pass_percent, crs_subscr_id, re_attempt_duration, document_id, all_questions: randomQues, pass_criteria }


//         } else {
//             // let wrong_answers_count = _.filter(randomQues, { is_correct: false }).length


//             if (Number(min_pass_ques) > Number(score)) {
//                 attempt_status = 0
//             } else {
//                 attempt_status = 1
//             }
//             console.log('attempt_status', attempt_status)

//             data = {
//                 attempted_on, attempt_count, crs_subscr_id, re_attempt_duration, document_id, all_questions: randomQues, pass_criteria, score, attempt_status, score_percent: null, pass_percent: null,
//             }

//         }
//         this.setState({ attempt_status, isFinalSubmited: true, score })

//         console.log('data_______', data)

//         urlSocket.post("subscr_crs/assmnt_submsn", { data })
//             .then(async (res) => {
//                 console.log(res,"count_timer")
//                 if (res.data.response_code === 500) {
//                     console.log('res', res.data)
//                     this.setState({ showMyAns: false, finalSubmitConfirm: false,count_down: true })
//                     console.log("removelistner")
//                       window.removeEventListener("beforeunload", this.handleBeforeUnload)
                    
//                 }
//             })
//     }

//     onTimeupCourse = () => {
//         this.setState({ timeUp: true, selectedQues: this.state.randomQues.length + 1 })
//         this.finalSubmition()
//     }

//     getAttemptedQuesCount = (questions, index) => {
//         console.log(questions, "questions")
//         let attempted_ques = questions.filter((data) => (data.submited !== undefined && data.submited)).length
//         console.log(attempted_ques, "attempted_ques")
//         return attempted_ques
//     }

//     previous_ques = (questions) => {
//         console.log(questions, "questions")
//         let attempted_ques = questions.filter((data) => (data.submited !== undefined && data.submited)).length
//         console.log(attempted_ques, "attempted_ques")
//         return attempted_ques
//     }

//     getAttemptedQuesCount_ = (questions) => {
//         let attempted_ques = questions.filter((data) => (data.user_selected !== null)).length
//         console.log(attempted_ques, "attempted_ques")
//         return attempted_ques
//     }

//     getunAttemptedQuesCount = (questions) => {
//         let unattempted = questions.filter((data) => (data.user_selected === null)).length
//         return unattempted
//     }

//     goTomatchedQues = (_id) => {
//         let index = this.state.randomQues.findIndex((elem) => { return elem._id === _id })
//         return index
//     }

//     goToQues = (selectedQues) => {
//         console.log('this.state.randomQues[', this.state.randomQues[selectedQues],selectedQues)
//         let is_correct = this.state.randomQues[selectedQues].is_correct
//         console.log(is_correct,"iscorrect",selectedQues)
//         this.setState({ selectedQues, showMyAns: false, show_allpage: true, is_correct })
//     }

//     getAttendedPercentage = (questions) => {
//         try {
//             if (questions.length) {
//                 let attempted_ques = questions.filter((data) => (data.submited !== undefined && data.submited)).length
//                 let total_no_ques = questions.length
//                 let percentage = ((attempted_ques+1) / total_no_ques) * 100
//                 return percentage
//             }
//         } catch (error) {
//             console.log('error', error)
//         }
//     }

//     onSubmitInterMediateReview = async () => {
//         this.setState({ submiting: true  })
//         let { correctAnswer, user_selected, question_type } = this.state

//         let isCorrect = null

//         if (question_type === "Single-Choice") {

//             if (correctAnswer === user_selected) {
//                 isCorrect = true
//             } else {
//                 isCorrect = false
//             }

//         } 
//         if (question_type === "Multi-Choice") {
//             isCorrect = this.state.correctAnswer
//         }else{
//             isCorrect = false
//         }
        
        
//          if ("Text-Box") {
//             console.log('Text-Box')
//         } else {
//         }


//         let { randomQues, selectedQues, } = this.state
//         let currentQues = randomQues[selectedQues]
//         currentQues.is_correct = isCorrect
//         currentQues.submited = true

//         let already_selected_ans = randomQues[selectedQues].user_selected

//         if (randomQues[selectedQues].user_selected === null || randomQues[selectedQues].user_selected === undefined) {
//             currentQues.user_selected = user_selected
//         } else {
//             currentQues.user_selected = already_selected_ans

//         }


//         console.log('updt_crs_sbscr_assmnt_rspns', currentQues,randomQues,selectedQues,this.state.correctAnswer)
//         await urlSocket.post("subscr_crs/updt_crs_sbscr_assmnt_rspns", { data: currentQues })
//             .then(async (res) => {
//                 console.log(res,"res")
//                 if (res.data.response_code === 500) {
//                     // let AllquestionsList = res.data.data

//                     this.setState({ randomQues, isSubmited: true, submiting: false, showMyAns: true, isCorrect })
//                 }
//             })
//             .catch((err) => {
//                 this.setState({ submiting: false })
//             })


//     }

//     render() {
//         return (
//             <React.Fragment>
//                 <div className="" style={{ marginTop: this.state.isFinalSubmited ? "-10px" : "0" }}>
//                     {
//                         !this.state.isFinalSubmited && !this.state.showMyAns && (
//                             <Container style={{ background: '#ECECEC', maxWidth: '1440px' }}>
//                                 <Row className='pt-4 pb-3' >
//                                     <Col md={12}>
//                                         {/* {
//                                             this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length !== this.state.totalValue && this.state.previous !== true  ?
//                                                 <div className='qaprocessCntr' >You are attempting {this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length + 1}/ {this.state.totalValue}</div>
//                                                 :
//                                                 this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length === this.state.totalValue && this.state.previous !== true  ?
//                                                     <div className='qaprocessCntr' >You are attempting {this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length}/ {this.state.totalValue}</div>
//                                                     :
//                                                     this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length > this.state.totalValue && this.state.previous !== true  ?
//                                                         <div className='qaprocessCntr' >You are attempting {this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length}/ {this.state.totalValue}</div>
//                                                         :
//                                                         null

//                                         } */}
//                                         {
//                                                 <div className='qaprocessCntr' >You are attempting {this.state.selectedQues + 1}/ {this.state.totalValue}</div>
//                                         }
//                                         {/* {
//                                             console.log(this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length + 1 > this.state.totalValue, "start_check", this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length, this.state.totalValue)
//                                         } */}
//                                         {/* <div  className='qaprocessCntr' >You are attempting {this.getAttemptedQuesCount(this.state.randomQues) + 1}/ {this.state.totalValue}</div> */}
//                                         {/* <Progress strokeLinecap="square" percent={this.getAttendedPercentage(this.state.randomQues)} showInfo={false} strokeColor={'#5E5E5E'} /> */}
                                   
//                                          <Progress strokeLinecap="square" percent={((this.state.selectedQues + 1) / this.state.totalValue)*100} showInfo={false} strokeColor={'#5E5E5E'} />
                                                
//                                         {/* {
//                                             this.state.previous === true  ?
//                                                 <div className='qaprocessCntr' >You are attempting {this.state.selected_index}/ {this.state.totalValue}</div>
//                                                 :
//                                                 null

//                                         } */}
//                                         {/* {
//                                             this.state.previous === true ?
//                                                 <Progress strokeLinecap="square" percent={this.getAttendedPercentage(this.state.randomQues)} showInfo={false} strokeColor={'#5E5E5E'} />
//                                                 :
//                                                 null
//                                         } */}
//                                         {/* {
//                                             console.log(this.state.selected_index, "index")
//                                         } */}

//                                     </Col>
//                                 </Row>
//                             </Container>
//                         )}
//                     <Container className="pt-3 pb-3" style={{ background: 'white', maxWidth: '636px' }}>
//                         <Row>
//                             <div className="col-md-12 ps-0 pe-0">
//                                 <div className="row">
//                                     <div className="col borNone quizTitle ps-0"><h1 style={{ fontSize: '28px', padding: 0, margin: 0 }} onClick={() => console.log('this.state.randomQues', this.state.randomQues)}>Quiz</h1> </div>
//                                     <div className="col borNone timerDiv" style={{ textAlign: 'right', padding: 0, margin: 0 }}>
//                                         {
//                                             // ((this.state.selectedQues + 1) <= (this.state.randomQues.length)) && <div> ({this.state.selectedQues + 1} of {this.state.randomQues.length} )</div>
//                                         }
//                                         {
//                                             this.state.course_duration > 0 && (
//                                                 <>
//                                                 {/* {
//                                                     console.log(this.state.course_duration , "this.state.course_duration")
//                                                 } */}
//                                                  {
//                                                     !this.state.count_down ?
//                                                     <div className='countDownTimerDiv d-flex align-items-center justify-content-end'>
//                                                         <div><i className='bx bx-time-five' style={{ fontSize: '18px', marginTop: '13px', marginRight: '3px' }}></i></div>
                                                           
//                                                                 <CountdownTimer className="mt-2" style={{ fontSize: '14px' }} hideDay={true} count={this.state.course_duration} border onEnd={() => { this.onTimeupCourse() }} />
                                                                
//                                                     </div>
//                                                 :
//                                                 null
//                                                             }
//                                                     <div className='fs-18'>
//                                                         {/* Questions Attempted: {this.getAttemptedQuesCount(this.state.randomQues)} */}
//                                                         {
//                                                             (this.state.min_pass_ques !== undefined && this.state.min_pass_ques !== null) &&
//                                                             <div>
//                                                                 Min. pass Questions : {this.state.min_pass_ques}
//                                                             </div>
//                                                         }
//                                                     </div>
//                                                 </>
//                                             )
//                                         }
//                                         {
//                                             this.state.timeUp && <h4>Time UP!!!!</h4>
//                                         }
//                                     </div>
//                                 </div>
//                             </div>
//                         </Row>
//                         {
//                             !this.state.isFinalSubmited && (
//                                 <hr />
//                             )
//                         }
//                         {
//                             !this.state.showMyAns &&
//                             <Row className='justify-content-left' style={{ minHeight: "30vh" }}>
//                                 <div className="col-md-12">
//                                     {
//                                         this.state.isFinalSubmited ?
//                                             <div className=''>
//                                                 <FinalResult attempt_status={this.state.attempt_status} pass_criteria={this.state.pass_criteria} score={this.state.score} randomQues={this.state.randomQues} goBack={() => this.props.history.push('/course/CourseDetails')} />
//                                                 <UserAnswers questionsList={this.state.randomQues} score={this.state.score} randomQues={this.state.randomQues} getCourseDetails = {this.props.getCourseDetails} />
//                                             </div>
//                                             :
//                                             <>

//                                                 {
//                                                     this.state.randomQues.map((data, index) => (
//                                                         <div key={index} >
//                                                             {
//                                                                 index === this.state.selectedQues &&
//                                                                 <div>
//                                                                     <h3 className='mt-4 mb-4 fontInter fontWeightNormal'>{index + 1}. {data.question}</h3>
//                                                                     <div className=''>
//                                                                         {
//                                                                             (data.media_url !== undefined && data.media_url !== null) &&
//                                                                             <audio controls>
//                                                                                 <source src={this.state.baseurl + data.media_url} type="audio/ogg" />
//                                                                                 <source src={this.state.baseurl + data.media_url} type="audio/mpeg" />
//                                                                             </audio>
//                                                                         }
//                                                                     </div>
//                                                                     {
//                                                                         data.question_type === "Single-Choice" ?
//                                                                             <SingleChoiseQuest options={data.options_displayed} onChangeOption={(e) => this.onChangeOption(e.target.value, data, index)} user_selected={data.user_selected} />
//                                                                             :
//                                                                             data.question_type === "Multi-Choice" ?
//                                                                                 // <MultiChoiseQuest options={data.options_displayed} onChangeOption={(value, index) => this.onChangeMultiOption(value,index,data)} user_selected={data.user_selected} />
//                                                                                 <MultiChoiseQuest options={data.options_displayed} onChangeOption={(e, index) => this.onChangeMultiOption(e, index, data)} user_selected={data.user_selected} />
//                                                                                 // <MultiChoiseQuest options={data.options_displayed} onChangeOption={(e) => this.onChangeOption(e.target.value, data, index)} user_selected={data.user_selected} />

//                                                                                 :
//                                                                                 data.question_type === "Text-Box" ?
//                                                                                     <TextInputBox OnchangeTextAnswer={(value) => this.OnchangeTextAnswer(value, data)} user_selected={data.user_selected} />
//                                                                                     :
//                                                                                     null
//                                                                     }
//                                                                 </div>
//                                                             }
//                                                         </div>
//                                                     ))
//                                                 }

//                                             </>
//                                     }
//                                 </div>
//                             </Row>
//                         }

//                         {
//                             this.state.showMyAns ?
//                                 <Row>
//                                     <div className="row">
//                                         <MyAnswers questionsList={this.state.randomQues} goToQues={(idx) => this.goToQues(idx)} attempted_count={this.getAttemptedQuesCount_(this.state.randomQues)} unattempted_count={this.getunAttemptedQuesCount(this.state.randomQues)} />

//                                         <div className="col-md-12">
//                                             <div className="d-flex justify-content-left">
//                                                 <div className=""><Button className='mx-2 quizCntrlBtns quizCntrlBtnsOutlined' onClick={() => this.setState({ showMyAns: false, show_allpage: true })} >Review Responses</Button></div>
//                                                 <div className=""><Button className='mx-2 quizCntrlBtns quizCntrlBtnsOutlined' onClick={() => this.setState({ finalSubmitConfirm: true })}>Submit Quiz</Button></div>
//                                             </div>
//                                         </div>

//                                     </div>
//                                 </Row>
//                                 :
//                                 null
//                         }
//                         {/* {
//                             console.log(this.state.selectedQues, "this.state.selectedQues")
//                         } */}
//                         {
//                             (!this.state.showMyAns && !this.state.isFinalSubmited) &&
//                             <div className="col-md-12">
//                                 <div className="d-flex justify-content-left">
//                                     {
//                                         this.state.selectedQues > 0 ?
//                                             // <Button className="quizCntrlBtns quizCntrlBtnsOutlined" onClick={() => this.onPreviosQues(this.state.selectedQues)}>Previous</Button>
//                                             <Button className="quizCntrlBtns quizCntrlBtnsOutlined" onClick={() => this.onPreviosQues(this.state.selectedQues)}>Previous</Button>

//                                             :
//                                             null
//                                     }
//                                     {/* {
//                                         console.log(this.state.selectedQues + 1,this.state.randomQues.length,this.state.selectedQues + 1 === this.state.randomQues.length,"this.state.selectedQues + 1 !== this.state.randomQues.length","705",this.state.selectedQues + 1 === this.state.randomQues.length)
//                                     } */}
//                                     {
//                                         (this.state.selectedQues +1 !== this.state.randomQues.length) &&
                                            
//                                         this.state.show_allpage && <Button className='mx-2 quizCntrlBtns quizCntrlBtnsOutlined' onClick={() => this.onSubmitInterMediateReview()}>Review Responses</Button>
//                                     }
//                                     {/* {
//                                         (this.state.selectedQues +1 === this.state.randomQues.length) || (this.state.selectedQues +1 !== this.state.randomQues.length) &&
                                            
//                                         this.state.show_allpage && <Button className='mx-2 quizCntrlBtns quizCntrlBtnsOutlined' onClick={() => this.onSubmitInterMediateReview()}>Review Responses</Button>
//                                     } */}
//                                     {
//                                         ((this.state.selectedQues) < (this.state.randomQues.length - 1)) && <Button className="quizCntrlBtns quizCntrlBtnsOutlinedGrey" onClick={() => { this.onSubmitNext(); }}>Next</Button>
//                                     }

//                                     {
//                                         !this.state.isSubmited ?
//                                             <>

//                                                 {
//                                                     (this.state.selectedQues + 1 === this.state.randomQues.length)&&
                                                    
//                                                     <>
//                                                     <Button className=" quizCntrlBtns quizCntrlBtnsOutlined" type="success" onClick={() => this.onSubmitNext(this.state.selectedQues)} loading={this.state.submiting}>{this.state.submiting ? 'Submiting...' : 'Review Response'} </Button>

//                                                     {
//                                                         console.log("checking11",this.state.isSubmited )
//                                                     }
//                                                     </>
                                                   
//                                                 }
//                                                 {/* {
//                                         console.log(this.state.selectedQues -1 === this.state.randomQues.length,"this.state.selectedQues -1 === this.state.randomQues.length")

//                                                 } */}
//                                             </>
//                                             :
//                                             this.state.isSubmited ?
//                                             <>

//                                             {
//                                                 (this.state.selectedQues + 1 === this.state.randomQues.length)&&
                                                
//                                                 <>
//                                                 <Button className=" quizCntrlBtns quizCntrlBtnsOutlined" type="success" onClick={() => this.onSubmitNext(this.state.selectedQues)} loading={this.state.submiting}>{this.state.submiting ? 'Submiting...' : 'Review Response'} </Button>

//                                                 {
//                                                     console.log("checking11",this.state.isSubmited )
//                                                 }
//                                                 </>
                                               
//                                             }
//                                             {/* {
//                                     console.log(this.state.selectedQues -1 === this.state.randomQues.length,"this.state.selectedQues -1 === this.state.randomQues.length")

//                                             } */}
//                                         </>
//                                         :
//                                         null
//                                         }
//                                 </div>
//                             </div>

//                         }
                                                             
//                         {
//                           this.state.finalSubmitConfirm &&
//                           // <SweetAlert warning showCancel confirmBtnText="Yes, Submit!" confirmBtnBsStyle="danger" title="Are you sure you want to submit Assessment?" onConfirm= onCancel= focusCancelBtn>
//                           <SweetAlert style={{width:"450px", margin:"0 auto"}}
//                                 title="" 
//                                 showCloseButton
//                                 //customIcon="" 
//                                 showCancel 
//                                 //confirmBtnText="cancel" 
//                                 //cancelBtnText="OK" 
//                                 //confirmBtnBsStyle="danger" 
//                                 //cancelBtnBsStyle="success" 
//                                 //onCancel={() => this.finalSubmition()} 
//                                 //onConfirm={() => this.setState({ finalSubmitConfirm: false })} 
                                
//                                 customButtons={
//                                     <React.Fragment>                                                                                
//                                         <Button className="quizCntrlBtns quizCntrlBtnsFill customSweetAlertButton" onClick={() => this.finalSubmition()}>Yes, Submit</Button>
//                                         <Button className="quizCntrlBtns quizCntrlBtnsOutlined customSweetAlertButton" onClick={() => this.setState({ finalSubmitConfirm: false })}>Cancel</Button>
//                                     </React.Fragment>
//                                 }

//                                 >

//                                 <div className='customSweetAlertIcn'></div>
//                                 <div className="customSweetAlertTxt">Are you sure you want to submit assessment?</div>
//                                 {/* <h4 className='m-4'>Questions Attempted: {this.getAttemptedQuesCount_(this.state.randomQues)}</h4> */}
                                
//                                 {
//                                     this.getunAttemptedQuesCount(this.state.randomQues) > 0 &&
//                                     <div>                                        
//                                         <h4 className='m-4'> {this.getunAttemptedQuesCount(this.state.randomQues)} Questions are still unattempted</h4>
//                                     </div>
//                                 }
//                           </SweetAlert>
//                         }
//                         {
//                             this.state.attempt_notification &&
//                             <SweetAlert success title="Re Assessment" onConfirm={() => { this.setState({ attempt_notification: false }) }}>Attempt: {this.state.attempt_count === 0 ? 1 : this.state.attempt_count} </SweetAlert>
                            
//                         }

//                         {
//                             this.state.notAllowed &&
//                             <SweetAlert danger title="Re Assessment" onConfirm={() => { this.props.history.push('/course/CourseDetails'); this.setState({ notAllowed: false }) }}>Please Try after {moment(this.state.try_after).format("DD-MM-YYYY HH:mm")} </SweetAlert>
//                         }
//                     </Container>
//                 </div>
//             </React.Fragment>
//         )
//     }
// }










///// venu updated 23-1-23
// import React, { Component } from 'react'
// import _ from 'lodash'
// import moment from 'moment'
// import { Progress, Button } from 'antd'
// import { Container, Row, Col } from "reactstrap"
// import SweetAlert from 'react-bootstrap-sweetalert'
// import CountdownTimer from "react-component-countdown-timer"

// import SingleChoiseQuest from './Component/SingleChoiseQuest'
// import MultiChoiseQuest from './Component/MultiChoiseQuest'
// import TextInputBox from './Component/TextInputBox'
// import UserAnswers from './Component/UserAnswers'
// import FinalResult from './Component/FinalResult'
// import urlSocket from '../../helpers/urlSocket'
// import MyAnswers from './Component/MyAnswers'
// import { Link } from "react-router-dom"


// // import "react-component-countdown-timer/lib/styles.css"

// import './CSS/countercss.scss'

// export default className index extends Component {
//     constructor(props) {
//         super(props)
//         this.state = {
//             randomQues: [],
//             attempted_on: new Date(),
//             baseurl: '',
//             question_type: '',
//             correctAnswer: '',
//             user_selected_multi: [],
//             showMyAns: false,
//             submiting: false,
//             isSubmited: false,
//             colorChange: false,
//             isFinalSubmited: false,
//             finalSubmitConfirm: false,
//             comp_perc: 0,
//             totalValue: 0,
//             selectedQues: 0,
//             attempt_count: 0,
//             course_duration: 0,
//             timeUp: null,
//             isCorrect: null,
//             is_correct: null,
//             pass_criteria: null,
//             user_selected: null,
//             min_pass_ques: null,
//             attempt_status: null,
//             re_attempt_duration: null,
//             count_down: false,
//             course_content: []
//         }
//     }

//     componentDidMount() {
//         console.log("objectobjectobjectobjectobjectobjectobjectobjectobject", this.state.count_down, this.props.course_content)
//         this.setState({ course_content: this.props.course_content })
//         if (this.state.count_down !== true) {
//             console.log("!!!")
//             window.addEventListener("beforeunload", this.handleBeforeUnload)
//         }
//         this.getSession()
//         this.getQuestionsAPI()
//     }

//     getSession = () => {
//         var getconfsession = JSON.parse(sessionStorage.getItem("getconfsession"))
//         let baseurl = getconfsession.baseurl
//         this.setState({ baseurl })
//     }

//     componentWillUnmount() {
//         console.log("unmount", this.state.count_down)
//         window.removeEventListener("beforeunload", this.handleBeforeUnload)
//     }

//     handleBeforeUnload = (e) => {
//         e.preventDefault()
//         const message = "Are you sure you want to leave? All provided data will be lost."
//         e.returnValue = message
//         return message
//     }

//     convertToSec = (time_duration) => {
//         const [hours, minutes] = time_duration.split(':')
//         const totalSeconds = (+hours) * 60 * 60 + (+minutes) * 60
//         // console.log(totalSeconds,"tyming")
//         return Number(totalSeconds)
//     }

//     getQuestionsAPI = async () => {

//         //var Exams = JSON.parse(sessionStorage.getItem("Exams"))
//         var Exams = this.props.examData
//         if (Exams !== null) {
//             console.log('Exams', Exams)
//             let { randomQuestions, max_no_of_optn, min_pass_perc, total_no_ques, time_duration, no_of_attempt, re_attempt_duration, document_id, pass_criteria, min_pass_ques, avl_randomization } = Exams
//             let course_duration = this.convertToSec(time_duration)

//             let attempted_on = new Date()
//             let totalValue = Number(randomQuestions.length)
//             var correct_answers = []
//             var wrong_answers = []

//             // let randomQues = randomQuestions.sort(() => Math.random() - 0.5)

//             if (avl_randomization) {
//                 for (let index = 0; index < randomQuestions.length; index++) {
//                     const options = randomQuestions[index].options

//                     correct_answers = options.filter(word => word.is_ans === "1")
//                     wrong_answers = _.filter(options, { is_ans: "0" })
//                     var shuff = _.shuffle(wrong_answers)
//                     var remaining_options = max_no_of_optn - correct_answers.length
//                     shuff = shuff.slice(0, remaining_options)
//                     var total_ans = correct_answers.concat(shuff)
//                     var final_shuffled = _.shuffle(total_ans)

//                     let all_above_op1 = "None of the above".toLowerCase()
//                     let all_above_op2 = "All of the above".toLowerCase()

//                     let all_txt_answer = {}
//                     let except_all_above_options = []

//                     final_shuffled.forEach(data => {
//                         if (data.option.toLowerCase() === all_above_op1) {
//                             all_txt_answer = data
//                             except_all_above_options = final_shuffled.filter((data) => data.option.toLowerCase() !== all_above_op1)
//                             // console.log('None of the above', except_all_above_options)
//                         }
//                         if (data.option.toLowerCase() === all_above_op2) {
//                             all_txt_answer = data
//                             except_all_above_options = final_shuffled.filter((data) => data.option.toLowerCase() !== all_above_op2)
//                             // console.log('All of the above', except_all_above_options)
//                         }
//                     })
//                     if (except_all_above_options.length !== 0) {
//                         except_all_above_options.push(all_txt_answer)
//                         // console.log('except_all_above_options', except_all_above_options)
//                         randomQuestions[index].options_displayed = except_all_above_options
//                     } else {
//                         randomQuestions[index].options_displayed = final_shuffled
//                     }
//                 }
//                 // console.log('randomQuestions', randomQuestions)
//                 randomQuestions = _.shuffle(randomQuestions)
//             } else {
//                 for (let index = 0; index < randomQuestions.length; index++) {
//                     const options = randomQuestions[index].options
//                     correct_answers = options.filter(word => word.is_ans === "1")
//                     wrong_answers = _.filter(options, { is_ans: "0" })
//                     var remaining_options = max_no_of_optn - correct_answers.length
//                     let shuff = wrong_answers.slice(0, remaining_options)
//                     var total_ans = correct_answers.concat(shuff)
//                     // total_ans = _.shuffle(total_ans) // if wrong answer on sequence please un commend

//                     let all_above_op1 = "None of the above".toLowerCase()
//                     let all_above_op2 = "All of the above".toLowerCase()

//                     let all_txt_answer = {}
//                     let except_all_above_options = []

//                     total_ans.forEach(data => {
//                         if (data.option.toLowerCase() === all_above_op1) {
//                             all_txt_answer = data
//                             except_all_above_options = total_ans.filter((data) => data.option.toLowerCase() !== all_above_op1)
//                             // console.log('None of the above', except_all_above_options)
//                         }
//                         if (data.option.toLowerCase() === all_above_op2) {
//                             all_txt_answer = data
//                             except_all_above_options = total_ans.filter((data) => data.option.toLowerCase() !== all_above_op2)
//                             // console.log('All of the above', except_all_above_options)
//                         }
//                     })
//                     if (except_all_above_options.length !== 0) {
//                         except_all_above_options.push(all_txt_answer)
//                         // console.log('except_all_above_options', except_all_above_options)
//                         randomQuestions[index].options_displayed = except_all_above_options
//                     } else {
//                         randomQuestions[index].options_displayed = total_ans
//                     }
//                 }
//                 // console.log('randomQuestions', randomQuestions)
//             }


//             this.setState({ randomQues: randomQuestions, course_duration, totalValue, min_pass_perc, total_no_ques, time_duration, no_of_attempt, attempted_on, re_attempt_duration, document_id, pass_criteria, min_pass_ques })
//             this.checkAssessmentAttempt()
//         }
//     }


//     checkAssessmentAttempt = () => {
//         var crs_subscr_id = JSON.parse(sessionStorage.getItem("crs_subscr_id"))
//         var crs_cntnt_info_id = JSON.parse(sessionStorage.getItem("CourseDetails"))
//         let document_id = this.state.document_id
//         let data = { crs_cntnt_info_id, crs_subscr_id, document_id }
//         // console.log('subscr_crs/chk_alrdy_attmptd APIIII', { data })
//         try {
//             urlSocket.post('subscr_crs/chk_alrdy_attmptd', data)
//                 .then((res) => {
//                     let data = res.data.data
//                     // console.log('res.data', res.data)
//                     let randomQues = res.data.question_list
//                     if (res.data.response_code === 500) {
//                         this.setState({ randomQues })
//                         console.log('chk_alrdy_attmptd', res.data)
//                         let { attempt_status, score, attempt_count, attempted_on, assessment_list } = data[0]
//                         attempt_status = Number(attempt_status)
//                         if (Number(attempt_status) === 1) {
//                             this.setState({ isFinalSubmited: true, course_duration: 0, attempt_count: Number(attempt_count), selectedQues: this.state.randomQues.length + 1, attempt_status, score })
//                         } else {
//                             if (assessment_list.no_of_attempt) {
//                                 if (Number(assessment_list.no_of_attempt) > Number(attempt_count)) {
//                                     let re_attempt_duration = assessment_list.re_attempt_duration
//                                     let re_attempt_duration_sec = this.convertToSec(re_attempt_duration)
//                                     console.log('Last attempted_on', attempted_on)
//                                     attempted_on = new Date(attempted_on)
//                                     var nxt_attmpt = moment(attempted_on).add('seconds', re_attempt_duration_sec)._d
//                                     // var nxt_attmpt = moment(attempted_on).add(parseInt(re_attempt_duration_sec), 'seconds')

//                                     console.log('Current Date', new Date())
//                                     console.log('nxt_attmpt', nxt_attmpt)

//                                     if (new Date() > nxt_attmpt) {
//                                         console.log('You can attend')
//                                         this.setState({ attempt_count, attempt_notification: true })
//                                     } else {
//                                         console.log('Please Tryback ater Sometime')
//                                         this.setState({ randomQues: [], notAllowed: true, try_after: nxt_attmpt })
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 })
//         } catch (error) {
//             console.log('error', error)
//         }
//     }


//     gotoNextQues = () => {
//         this.setState(state => ({ selectedQues: state.selectedQues + 1, isCorrect: null, isSubmited: false, user_selected: null, correctAnswer: '', }))
//         let totalQuesCount = this.state.randomQues.length
//         let currentQuesNo = this.state.selectedQues
//         console.log("object", "gotonext", currentQuesNo === totalQuesCount)
//         if (currentQuesNo === totalQuesCount) {
//             this.setState(state => ({ showMyAns: true, selectedQues: state.selectedQues - 1, isCorrect: null, isSubmited: false, user_selected: null, correctAnswer: '' }))
//         }
//     }

//     onChangeOption = (user_selected, data, index) => {
//         console.log(data, "user_data", index, user_selected)
//         data.user_selected = user_selected
//         // data.submited = false
//         let result = data.options_displayed.filter((data) => data.is_ans === '1')[0]
//         let correctAnswer = result.option
//         let question_type = "Single-Choice"
//         this.setState({ selected_index_: index, correctAnswer, user_selected, question_type })
//     }

//     OnchangeTextAnswer = (value, data) => {
//         console.log(value, data, "value of radio")
//         data.user_selected = value
//         let question_type = "Text-Box"
//         this.setState({ question_type, user_selected: value })
//     }

//     onChangeMultiOption = (e, index, data) => {
//         console.log(e, "value", index, "indii", data)
//         data.options_displayed[index].user_selected = e
//         // // data.submited = false
//         let result = data.options_displayed.filter((data) => data.is_ans === '1')
//         let user_selected_multi = data.options_displayed.filter((data) => data.user_selected === true)

//         let correctAnswer = this.matchAnswers(result, user_selected_multi)
//         let question_type = "Multi-Choice"
//         console.log('user_selected_multi', user_selected_multi)
//         this.setState({ user_selected_multi, correctAnswer, user_selected: user_selected_multi, question_type })
//         data.user_selected = user_selected_multi //
//     }

//     matchAnswers(a, b) {
//         return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index])
//     }

//     onPreviosQues = (selected_index) => {

//         let selectedQues = this.state.selectedQues - 1
//         let randomQues = this.state.randomQues
//         let user_selected = this.state.user_selected
//         let already_selected_ans = randomQues[selectedQues].user_selected
//         randomQues[selected_index].submited = false
//         console.log(randomQues, "randomQues")
//         console.log('user_selected', selected_index + 1, user_selected, selectedQues)
//         console.log('already_selected_ans', randomQues, already_selected_ans)
//         this.setState({ selectedQues, selected_index, user_selected: already_selected_ans })
//     }

//     onSubmitNext = (selected_index) => {

//         console.log(selected_index, "this.state.selectedQues")
//         this.setState({ submiting: true })
//         let { correctAnswer, user_selected, question_type } = this.state

//         let isCorrect = null

//         if (question_type === "Single-Choice") {

//             if (correctAnswer === user_selected) {
//                 isCorrect = true
//             } else {
//                 isCorrect = false
//             }

//         } else if (question_type === "Multi-Choice") {
//             isCorrect = this.state.correctAnswer
//             console.log(this.state.correctAnswer, "correctans")
//         } else if ("Text-Box") {
//             console.log('Text-Box')
//         } else {

//         }
//         console.log(isCorrect, "this.state.selectedQues", isCorrect)

//         this.setState({ isCorrect })
//         this.submitQuesAPI(isCorrect)
//     }


//     submitQuesAPI = async (isCorrect) => {
//         let { randomQues, selectedQues, user_selected } = this.state
//         let currentQues = randomQues[selectedQues]

//         if (isCorrect === "") {
//             isCorrect = this.state.is_correct
//             console.log(this.state.correctAnswer, "correctans")
//         }
//         console.log(this.state.correctAnswer, "correctans", isCorrect)
//         currentQues.is_correct = isCorrect
//         currentQues.submited = true

//         let already_selected_ans = randomQues[selectedQues].user_selected
//         console.log('randomQues[selectedQues]', randomQues[selectedQues], currentQues, already_selected_ans)

//         if (randomQues[selectedQues].user_selected === null || randomQues[selectedQues].user_selected === undefined) {
//             console.log('user_selected', user_selected)
//             currentQues.user_selected = user_selected
//         } else {
//             currentQues.user_selected = already_selected_ans
//             console.log('already_selected_ans', already_selected_ans)

//         }


//         console.log('updt_crs_sbscr_assmnt_rspns', currentQues)
//         await urlSocket.post("subscr_crs/updt_crs_sbscr_assmnt_rspns", { data: currentQues })
//             .then(async (res) => {
//                 console.log(res, "response")
//                 if (res.data.response_code === 500) {
//                     // let AllquestionsList = res.data.data

//                     this.setState({ randomQues, isSubmited: true, submiting: false })
//                     this.gotoNextQues()
//                 }
//             })
//             .catch((err) => {
//                 this.setState({ submiting: false })
//             })
//     }

//     // let wrong_answers_count = _.filter(randomQues, { is_correct: false }).length
//     finalSubmition = () => {
//         let { attempted_on, attempt_count, randomQues, min_pass_perc, total_no_ques, re_attempt_duration, document_id, pass_criteria, min_pass_ques } = this.state
//         var crs_subscr_id = JSON.parse(sessionStorage.getItem("crs_subscr_id"))
//         let attempt_status = null
//         let data = {}
//         let score = _.filter(randomQues, { is_correct: true }).length
//         if (pass_criteria === "Percentage") {

//             let score_percent = (score / Number(total_no_ques)) * 100

//             if (Number(score_percent) <= Number(min_pass_perc)) {
//                 attempt_status = 0
//             } else {
//                 attempt_status = 1
//             }

//             let pass_percent = parseFloat(min_pass_perc)

//             attempt_count = Number(attempt_count) + 1

//             data = { attempted_on, attempt_count, score, score_percent, attempt_status, pass_percent, crs_subscr_id, re_attempt_duration, document_id, all_questions: randomQues, pass_criteria, completed_status: "2" }


//         } else {
//             // let wrong_answers_count = _.filter(randomQues, { is_correct: false }).length


//             if (Number(min_pass_ques) > Number(score)) {
//                 attempt_status = 0
//             } else {
//                 attempt_status = 1
//             }
//             console.log('attempt_status', attempt_status)

//             data = {
//                 attempted_on, attempt_count, crs_subscr_id, re_attempt_duration, document_id, all_questions: randomQues, pass_criteria, score, attempt_status, score_percent: null, pass_percent: null, completed_status: "2"
//             }

//         }
//         this.setState({ attempt_status, isFinalSubmited: true, score })

//         console.log('data_______', data)

//         urlSocket.post("subscr_crs/assmnt_submsn", { data })
//             .then(async (res) => {
//                 console.log(res, "count_timer")
//                 if (res.data.response_code === 500) {
//                     // console.log('res', res.data)
//                     // this.courseTopicUpdate(document_id, 2)
//                     this.setState({ showMyAns: false, finalSubmitConfirm: false, count_down: true }, () => { this.courseTopicUpdate(document_id, 2) })
//                     console.log("removelistner")
//                     window.removeEventListener("beforeunload", this.handleBeforeUnload)

//                 }
//             })
//     }

//     courseTopicUpdate = (document_id, status) => {
//         console.log(document_id, status)
//         let entireObj = [...this.state.course_content]
//         var course_info_name = JSON.parse(sessionStorage.getItem("CourseName"))
//         let course_name = course_info_name.name_of_the_course
//         var authUser = JSON.parse(sessionStorage.getItem("authUser"))
//         var crs_subscr_id = authUser._id
//         var course_info_id = JSON.parse(sessionStorage.getItem("CourseDetails"))
//         console.log(course_name, crs_subscr_id, course_info_id, entireObj)
//         var VnestedValue;
//         let keyToFind = 'document_id'
//         let foundObj;


//         JSON.stringify(entireObj, (_, nestedValue) => {
//             // console.log(nestedValue,"inside json")
//             if (nestedValue && nestedValue[keyToFind] === document_id) {
//                 if (nestedValue.children === undefined) {
//                     nestedValue.children = []
//                 }
//                 // console.log("statusstatusstatus", status)
//                 foundObj = nestedValue.content_status = status
//                 VnestedValue = nestedValue
//             }
//             this.setState({ course_content: entireObj, nestedValue })
//             // console.log(nestedValue, "nestedValue")
//             // VnestedValue = nestedValue
//             return nestedValue

//         })
//         if (status === 2) {
//             try {
//                 // urlSocket.post('mngsubcrs/updt_crs_tree', { course_content: VnestedValue, name_of_the_course: this.state.name_of_the_course, crs_subscr_id: this.state.crs_subscr_id })
//                 urlSocket.post('mngsubcrs/updt_crs_tree', { course_content: entireObj, name_of_the_course: course_name, subscriber_id: crs_subscr_id, course_info_id: course_info_id })
//                     .then((res) => {
//                         console.log('updt_cntnt_status ', res.data)
//                     })
//             } catch (error) {
//                 console.log(error)

//             }
//             this.onUpdateCourseStatus(entireObj, crs_subscr_id, course_info_id)
//             return foundObj

//         }

//     }
//     onUpdateCourseStatus = (course_content, crs_subscr_id, course_info_id) => {
//         //console.log('course_content', course_content) 
//         // let { crs_subscr_id, course_info_id, } = this.state
//         console.log('crs_subscr_id, course_info_id', crs_subscr_id, course_info_id)
//         try {
//             urlSocket.post('mngsubcrs/crs_cntnt_status_update', { subscriber_id: crs_subscr_id, course_info_id, course_content })
//                 .then((res) => {
//                     // console.log('onUpdateCourseStatus :', res.data)
//                     if (res.data.response_code === 500) {
//                         // let data= _.filter(course_content,e=>{
//                         // _.filter(e.children,i=>{
//                         // return i.document_type === 2
//                         // })
//                         // })
//                         var data = JSON.parse(sessionStorage.getItem("onClickLink"))

//                         this.props.onClickLink(data)
//                         // return <Link to="/Exams"/>
//                         // window.location.reload(false)

//                     }
//                 })
//         } catch (error) {

//         }
//     }

//     // update_content_status=(entireObj)=>{
//     // var course_info_name = JSON.parse(sessionStorage.getItem("CourseName"))
//     // var crs_subscr_id = JSON.parse(sessionStorage.getItem("crs_subscr_id"))
//     // let course_name = course_info_name.name_of_the_course
//     // var course_info_id = JSON.parse(sessionStorage.getItem("CourseDetails"))
//     // //6288c1b7597503d19c83f06a

//     // try {
//     // // urlSocket.post('mngsubcrs/updt_crs_tree', { course_content: VnestedValue, name_of_the_course: this.state.name_of_the_course, crs_subscr_id: this.state.crs_subscr_id })
//     // urlSocket.post('mngsubcrs/updt_crs_tree', { course_content: entireObj, name_of_the_course: course_name, subscriber_id: crs_subscr_id ,course_info_id : course_info_id })
//     // .then((res) => {
//     // console.log('updt_cntnt_status ', res.data)
//     // })
//     // } catch (error) {
//     // console.log(error)

//     // }
//     // }



//     onTimeupCourse = () => {
//         this.setState({ timeUp: true, selectedQues: this.state.randomQues.length + 1 })
//         this.finalSubmition()
//     }

//     getAttemptedQuesCount = (questions, index) => {
//         console.log(questions, "questions")
//         let attempted_ques = questions.filter((data) => (data.submited !== undefined && data.submited)).length
//         console.log(attempted_ques, "attempted_ques")
//         return attempted_ques
//     }

//     previous_ques = (questions) => {
//         console.log(questions, "questions")
//         let attempted_ques = questions.filter((data) => (data.submited !== undefined && data.submited)).length
//         console.log(attempted_ques, "attempted_ques")
//         return attempted_ques
//     }

//     getAttemptedQuesCount_ = (questions) => {
//         let attempted_ques = questions.filter((data) => (data.user_selected !== null)).length
//         console.log(attempted_ques, "attempted_ques")
//         return attempted_ques
//     }

//     getunAttemptedQuesCount = (questions) => {
//         let unattempted = questions.filter((data) => (data.user_selected === null)).length
//         return unattempted
//     }

//     goTomatchedQues = (_id) => {
//         let index = this.state.randomQues.findIndex((elem) => { return elem._id === _id })
//         return index
//     }

//     goToQues = (selectedQues) => {
//         console.log('this.state.randomQues[', this.state.randomQues[selectedQues], selectedQues)
//         let is_correct = this.state.randomQues[selectedQues].is_correct
//         console.log(is_correct, "iscorrect", selectedQues)
//         this.setState({ selectedQues, showMyAns: false, show_allpage: true, is_correct })
//     }

//     getAttendedPercentage = (questions) => {
//         try {
//             if (questions.length) {
//                 let attempted_ques = questions.filter((data) => (data.submited !== undefined && data.submited)).length
//                 let total_no_ques = questions.length
//                 let percentage = ((attempted_ques + 1) / total_no_ques) * 100
//                 return percentage
//             }
//         } catch (error) {
//             console.log('error', error)
//         }
//     }

//     onSubmitInterMediateReview = async () => {
//         this.setState({ submiting: true })
//         let { correctAnswer, user_selected, question_type } = this.state

//         let isCorrect = null

//         if (question_type === "Single-Choice") {

//             if (correctAnswer === user_selected) {
//                 isCorrect = true
//             } else {
//                 isCorrect = false
//             }

//         }
//         if (question_type === "Multi-Choice") {
//             isCorrect = this.state.correctAnswer
//         } else {
//             isCorrect = false
//         }


//         if ("Text-Box") {
//             console.log('Text-Box')
//         } else {
//         }


//         let { randomQues, selectedQues, } = this.state
//         let currentQues = randomQues[selectedQues]
//         currentQues.is_correct = isCorrect
//         currentQues.submited = true

//         let already_selected_ans = randomQues[selectedQues].user_selected

//         if (randomQues[selectedQues].user_selected === null || randomQues[selectedQues].user_selected === undefined) {
//             currentQues.user_selected = user_selected
//         } else {
//             currentQues.user_selected = already_selected_ans

//         }


//         console.log('updt_crs_sbscr_assmnt_rspns', currentQues, randomQues, selectedQues, this.state.correctAnswer)
//         await urlSocket.post("subscr_crs/updt_crs_sbscr_assmnt_rspns", { data: currentQues })
//             .then(async (res) => {
//                 console.log(res, "res")
//                 if (res.data.response_code === 500) {
//                     // let AllquestionsList = res.data.data

//                     this.setState({ randomQues, isSubmited: true, submiting: false, showMyAns: true, isCorrect })
//                 }
//             })
//             .catch((err) => {
//                 this.setState({ submiting: false })
//             })


//     }

//     render() {
//         return (
//             <React.Fragment>
//                 <div className="" style={{ marginTop: this.state.isFinalSubmited ? "-10px" : "20px" }}>
//                     {
//                         !this.state.isFinalSubmited && !this.state.showMyAns && (
//                             <Container style={{ background: '#ECECEC', maxWidth: '1440px' }}>
//                                 <Row className='pt-4 pb-3' >
//                                     <Col md={12}>
//                                         {/* {
//  this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length !== this.state.totalValue && this.state.previous !== true ?
//  <div className='qaprocessCntr' >You are attempting {this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length + 1}/ {this.state.totalValue}</div>
//  :
//  this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length === this.state.totalValue && this.state.previous !== true ?
//  <div className='qaprocessCntr' >You are attempting {this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length}/ {this.state.totalValue}</div>
//  :
//  this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length > this.state.totalValue && this.state.previous !== true ?
//  <div className='qaprocessCntr' >You are attempting {this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length}/ {this.state.totalValue}</div>
//  :
//  null

//  } */}
//                                         {
//                                             <div className='qaprocessCntr' >You are attempting {this.state.selectedQues + 1}/ {this.state.totalValue}</div>
//                                         }
//                                         {/* {
//  console.log(this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length + 1 > this.state.totalValue, "start_check", this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length, this.state.totalValue)
//  } */}
//                                         {/* <div className='qaprocessCntr' >You are attempting {this.getAttemptedQuesCount(this.state.randomQues) + 1}/ {this.state.totalValue}</div> */}
//                                         {/* <Progress strokeLinecap="square" percent={this.getAttendedPercentage(this.state.randomQues)} showInfo={false} strokeColor={'#5E5E5E'} /> */}

//                                         <Progress strokeLinecap="square" percent={((this.state.selectedQues + 1) / this.state.totalValue) * 100} showInfo={false} strokeColor={'#5E5E5E'} />

//                                         {/* {
//  this.state.previous === true ?
//  <div className='qaprocessCntr' >You are attempting {this.state.selected_index}/ {this.state.totalValue}</div>
//  :
//  null

//  } */}
//                                         {/* {
//  this.state.previous === true ?
//  <Progress strokeLinecap="square" percent={this.getAttendedPercentage(this.state.randomQues)} showInfo={false} strokeColor={'#5E5E5E'} />
//  :
//  null
//  } */}
//                                         {/* {
//  console.log(this.state.selected_index, "index")
//  } */}

//                                     </Col>
//                                 </Row>
//                             </Container>
//                         )}
//                     <Container className="pt-3 pb-3 ps-0 pe-0" style={{ background: 'white', maxWidth: '1440px' }}>
//                         <Row>
//                             <div className="col-md-12">
//                                 <div className="row">
//                                     <div className="col-md-4 quizTitle"><h1 style={{ fontSize: '28px' }} onClick={() => console.log('this.state.randomQues', this.state.randomQues)}>Quiz</h1> </div>
//                                     <div className="col-md-8 text-end timerDiv" style={{ textAlign: 'right', padding: 0, margin: 0 }}>
//                                         {
//                                             // ((this.state.selectedQues + 1) <= (this.state.randomQues.length)) && <div> ({this.state.selectedQues + 1} of {this.state.randomQues.length} )</div>
//                                         }
//                                         {
//                                             this.state.course_duration > 0 && (
//                                                 <>
//                                                     {/* {
//  console.log(this.state.course_duration , "this.state.course_duration")
//  } */}
//                                                     {
//                                                         !this.state.count_down ?
//                                                             <div className='countDownTimerDiv d-flex align-items-center justify-content-end'>
//                                                                 <div><i className='bx bx-time-five' style={{ fontSize: '18px', marginTop: '13px', marginRight: '3px' }}></i></div>

//                                                                 <CountdownTimer className="mt-2" style={{ fontSize: '14px' }} hideDay={true} count={this.state.course_duration} border onEnd={() => { this.onTimeupCourse() }} />

//                                                             </div>
//                                                             :
//                                                             null
//                                                     }
//                                                     <div className='fs-18'>
//                                                         {/* Questions Attempted: {this.getAttemptedQuesCount(this.state.randomQues)} */}
//                                                         {
//                                                             (this.state.min_pass_ques !== undefined && this.state.min_pass_ques !== null) &&
//                                                             <div>
//                                                                 Min. pass Questions : {this.state.min_pass_ques}
//                                                             </div>
//                                                         }
//                                                     </div>
//                                                 </>
//                                             )
//                                         }
//                                         {
//                                             this.state.timeUp && <h4>Time UP!!!!</h4>
//                                         }
//                                     </div>
//                                 </div>
//                             </div>
//                         </Row>
//                         {
//                             !this.state.isFinalSubmited && (
//                                 <hr />
//                             )
//                         }
//                         {
//                             !this.state.showMyAns &&
//                             <Row className='justify-content-left' style={{ minHeight: "30vh" }}>
//                                 <div className="col-md-12">
//                                     {
//                                         this.state.isFinalSubmited ?
//                                             <div className=''>
//                                                 <FinalResult attempt_status={this.state.attempt_status} pass_criteria={this.state.pass_criteria} score={this.state.score} randomQues={this.state.randomQues} goBack={() => this.props.history.push('/course/CourseDetails')} />
//                                                 <UserAnswers questionsList={this.state.randomQues} score={this.state.score} randomQues={this.state.randomQues} />
//                                             </div>
//                                             :
//                                             <>

//                                                 {
//                                                     this.state.randomQues.map((data, index) => (
//                                                         <div key={index} >
//                                                             {
//                                                                 index === this.state.selectedQues &&
//                                                                 <div>
//                                                                     <h3 className='mt-4 mb-4 fontInter fontWeightNormal'>{index + 1}. {data.question}</h3>
//                                                                     <div className=''>
//                                                                         {
//                                                                             (data.media_url !== undefined && data.media_url !== null) &&
//                                                                             <audio controls>
//                                                                                 <source src={this.state.baseurl + data.media_url} type="audio/ogg" />
//                                                                                 <source src={this.state.baseurl + data.media_url} type="audio/mpeg" />
//                                                                             </audio>
//                                                                         }
//                                                                     </div>
//                                                                     {
//                                                                         data.question_type === "Single-Choice" ?
//                                                                             <SingleChoiseQuest options={data.options_displayed} onChangeOption={(e) => this.onChangeOption(e.target.value, data, index)} user_selected={data.user_selected} />
//                                                                             :
//                                                                             data.question_type === "Multi-Choice" ?
//                                                                                 // <MultiChoiseQuest options={data.options_displayed} onChangeOption={(value, index) => this.onChangeMultiOption(value,index,data)} user_selected={data.user_selected} />
//                                                                                 <MultiChoiseQuest options={data.options_displayed} onChangeOption={(e, index) => this.onChangeMultiOption(e, index, data)} user_selected={data.user_selected} />
//                                                                                 // <MultiChoiseQuest options={data.options_displayed} onChangeOption={(e) => this.onChangeOption(e.target.value, data, index)} user_selected={data.user_selected} />

//                                                                                 :
//                                                                                 data.question_type === "Text-Box" ?
//                                                                                     <TextInputBox OnchangeTextAnswer={(value) => this.OnchangeTextAnswer(value, data)} user_selected={data.user_selected} />
//                                                                                     :
//                                                                                     null
//                                                                     }
//                                                                 </div>
//                                                             }
//                                                         </div>
//                                                     ))
//                                                 }

//                                             </>
//                                     }
//                                 </div>
//                             </Row>
//                         }

//                         {
//                             this.state.showMyAns ?
//                                 <Row>
//                                     <div className="row">
//                                         <MyAnswers questionsList={this.state.randomQues} goToQues={(idx) => this.goToQues(idx)} attempted_count={this.getAttemptedQuesCount_(this.state.randomQues)} unattempted_count={this.getunAttemptedQuesCount(this.state.randomQues)} />

//                                         <div className="col-md-12">
//                                             <div className="d-flex justify-content-left">
//                                                 <div className=""><Button className='mx-2 quizCntrlBtns quizCntrlBtnsOutlined' onClick={() => this.setState({ showMyAns: false, show_allpage: true })} >Review Responses</Button></div>
//                                                 <div className=""><Button className='mx-2 quizCntrlBtns quizCntrlBtnsFill' onClick={() => this.setState({ finalSubmitConfirm: true })}>Submit Quiz</Button></div>
//                                             </div>
//                                         </div>

//                                     </div>
//                                 </Row>
//                                 :
//                                 null
//                         }
//                         {/* {
//  console.log(this.state.selectedQues, "this.state.selectedQues")
//  } */}
//                         {
//                             (!this.state.showMyAns && !this.state.isFinalSubmited) &&
//                             <div className="col-md-12">
//                                 <div className="d-flex justify-content-left">
//                                     {
//                                         this.state.selectedQues > 0 ?
//                                             // <Button className="quizCntrlBtns quizCntrlBtnsFill" onClick={() => this.onPreviosQues(this.state.selectedQues)}>Previous</Button>
//                                             <Button className="quizCntrlBtns quizCntrlBtnsFill" onClick={() => this.onPreviosQues(this.state.selectedQues)}>Previous</Button>

//                                             :
//                                             null
//                                     }
//                                     {/* {
//  console.log(this.state.selectedQues + 1,this.state.randomQues.length,this.state.selectedQues + 1 === this.state.randomQues.length,"this.state.selectedQues + 1 !== this.state.randomQues.length","705",this.state.selectedQues + 1 === this.state.randomQues.length)
//  } */}
//                                     {
//                                         (this.state.selectedQues + 1 !== this.state.randomQues.length) &&

//                                         this.state.show_allpage && <Button className='mx-2 quizCntrlBtns quizCntrlBtnsFill' onClick={() => this.onSubmitInterMediateReview()}>Review Responses</Button>
//                                     }
//                                     {/* {
//  (this.state.selectedQues +1 === this.state.randomQues.length) || (this.state.selectedQues +1 !== this.state.randomQues.length) &&
 
//  this.state.show_allpage && <Button className='mx-2 quizCntrlBtns quizCntrlBtnsFill' onClick={() => this.onSubmitInterMediateReview()}>Review Responses</Button>
//  } */}
//                                     {
//                                         ((this.state.selectedQues) < (this.state.randomQues.length - 1)) && <Button className="quizCntrlBtns quizCntrlBtnsOutlined" onClick={() => { this.onSubmitNext(); }}>Next</Button>
//                                     }

//                                     {
//                                         !this.state.isSubmited ?
//                                             <>

//                                                 {
//                                                     (this.state.selectedQues + 1 === this.state.randomQues.length) &&

//                                                     <>
//                                                         <Button className=" quizCntrlBtns quizCntrlBtnsFill" type="success" onClick={() => this.onSubmitNext(this.state.selectedQues)} loading={this.state.submiting}>{this.state.submiting ? 'Submiting...' : 'Review Response'} </Button>

//                                                         {/* {
//  console.log("checking11",this.state.isSubmited )
//  } */}
//                                                     </>

//                                                 }
//                                                 {/* {
//  console.log(this.state.selectedQues -1 === this.state.randomQues.length,"this.state.selectedQues -1 === this.state.randomQues.length")

//  } */}
//                                             </>
//                                             :
//                                             this.state.isSubmited ?
//                                                 <>

//                                                     {
//                                                         (this.state.selectedQues + 1 === this.state.randomQues.length) &&

//                                                         <>
//                                                             <Button className=" quizCntrlBtns quizCntrlBtnsFill" type="success" onClick={() => this.onSubmitNext(this.state.selectedQues)} loading={this.state.submiting}>{this.state.submiting ? 'Submiting...' : 'Review Response'} </Button>

//                                                             {
//                                                                 console.log("checking11", this.state.isSubmited)
//                                                             }
//                                                         </>

//                                                     }
//                                                     {/* {
//  console.log(this.state.selectedQues -1 === this.state.randomQues.length,"this.state.selectedQues -1 === this.state.randomQues.length")

//  } */}
//                                                 </>
//                                                 :
//                                                 null
//                                     }
//                                 </div>
//                             </div>

//                         }

//                         {
//                             this.state.finalSubmitConfirm &&
//                             // <SweetAlert warning showCancel confirmBtnText="Yes, Submit!" confirmBtnBsStyle="danger" title="Are you sure you want to submit Assessment?" onConfirm= onCancel= focusCancelBtn>
//                             <SweetAlert title="Are you sure?" warning showCancel confirmBtnText="cancel" cancelBtnText="OK" confirmBtnBsStyle="danger" cancelBtnBsStyle="success" onCancel={() => this.finalSubmition()} onConfirm={() => this.setState({ finalSubmitConfirm: false })} >Do you want to save the changes?

//                                 {/* <h4 className='m-4'>Questions Attempted: {this.getAttemptedQuesCount_(this.state.randomQues)}</h4>
//  {
//  this.getunAttemptedQuesCount(this.state.randomQues) === 0 &&
//  <h4 className='m-4'> {this.getunAttemptedQuesCount(this.state.randomQues)} Questions are still unattempted</h4>
//  } */}
//                             </SweetAlert>
//                         }
//                         {/* {
//  this.state.attempt_notification &&
//  <SweetAlert success title="Re Assessment" onConfirm={() => { this.setState({ attempt_notification: false }) }}>Attempt: {this.state.attempt_count === 0 ? 1 : this.state.attempt_count} </SweetAlert>
 
//  } */}

//                         {
//                             this.state.notAllowed &&
//                             <SweetAlert danger title="Re Assessment" onConfirm={() => { this.props.history.push('/course/CourseDetails'); this.setState({ notAllowed: false }) }}>Please Try after {moment(this.state.try_after).format("DD-MM-YYYY HH:mm")} </SweetAlert>
//                         }
//                     </Container>
//                 </div>
//             </React.Fragment>
//         )
//     }
// }










///// 23-1-23 [yesvin anna updated code]
// import React, { Component } from 'react'
// import _ from 'lodash'
// import moment from 'moment'
// import { Progress, Button } from 'antd'
// import { Container, Row, Col } from "reactstrap"
// import SweetAlert from 'react-bootstrap-sweetalert'
// import CountdownTimer from "react-component-countdown-timer"

// import SingleChoiseQuest from './Component/SingleChoiseQuest'
// import MultiChoiseQuest from './Component/MultiChoiseQuest'
// import TextInputBox from './Component/TextInputBox'
// import UserAnswers from './Component/UserAnswers'
// import FinalResult from './Component/FinalResult'
// import urlSocket from '../../helpers/urlSocket'
// import MyAnswers from './Component/MyAnswers'

// // import "react-component-countdown-timer/lib/styles.css"

// import './CSS/countercss.scss'


// export default className index extends Component {
//     constructor(props) {
//         super(props)
//         this.state = {
//             randomQues: [],
//             attempted_on: new Date(),
//             baseurl: '',
//             question_type: '',
//             correctAnswer: '',
//             user_selected_multi: [],
//             showMyAns: false,
//             submiting: false,
//             isSubmited: false,
//             colorChange: false,
//             isFinalSubmited: false,
//             finalSubmitConfirm: false,
//             comp_perc: 0,
//             totalValue: 0,
//             selectedQues: 0,
//             attempt_count: 0,
//             course_duration: 0,
//             timeUp: null,
//             isCorrect: null,
//             is_correct: null,
//             pass_criteria: null,
//             user_selected: null,
//             min_pass_ques: null,
//             attempt_status: null,
//             re_attempt_duration: null,
//             count_down : false
//         }
//     }

//     componentDidMount() {
//         console.log("objectobjectobjectobjectobjectobjectobjectobjectobject",this.state.count_down,this.props.get_assessment)
//         if(this.state.count_down !== true){
//          console.log("!!!")   
//         window.addEventListener("beforeunload", this.handleBeforeUnload)
//         }
//         this.getSession()
//         this.getQuestionsAPI()
//     }

//     getSession = () => {
//         var getconfsession = JSON.parse(sessionStorage.getItem("getconfsession"))
//         let baseurl = getconfsession.baseurl
//         this.setState({ baseurl })
//     }

//     componentWillUnmount() {
//         console.log("unmount",this.state.count_down)
//         window.removeEventListener("beforeunload", this.handleBeforeUnload)
//     }

//     handleBeforeUnload = (e) => {
//         e.preventDefault()
//         const message = "Are you sure you want to leave? All provided data will be lost."
//         e.returnValue = message
//         return message
//     }

//     convertToSec = (time_duration) => {
//         const [hours, minutes] = time_duration.split(':')
//         const totalSeconds = (+hours) * 60 * 60 + (+minutes) * 60
//         // console.log(totalSeconds,"tyming")
//         return Number(totalSeconds)
//     }

//     getQuestionsAPI = async () => {

//         //var Exams = JSON.parse(sessionStorage.getItem("Exams"))
//         var Exams = this.props.examData
//         if (Exams !== null) {
//             console.log('Exams', Exams)
//             let { randomQuestions, max_no_of_optn, min_pass_perc, total_no_ques, time_duration, no_of_attempt, re_attempt_duration, document_id, pass_criteria, min_pass_ques, avl_randomization } = Exams
//             let course_duration = this.convertToSec(time_duration)

//             let attempted_on = new Date()
//             let totalValue = Number(randomQuestions.length)
//             var correct_answers = []
//             var wrong_answers = []

//             // let randomQues = randomQuestions.sort(() => Math.random() - 0.5)

//             if (avl_randomization) {
//                 for (let index = 0; index < randomQuestions.length; index++) {
//                     const options = randomQuestions[index].options

//                     correct_answers = options.filter(word => word.is_ans === "1")
//                     wrong_answers = _.filter(options, { is_ans: "0" })
//                     var shuff = _.shuffle(wrong_answers)
//                     var remaining_options = max_no_of_optn - correct_answers.length
//                     shuff = shuff.slice(0, remaining_options)
//                     var total_ans = correct_answers.concat(shuff)
//                     var final_shuffled = _.shuffle(total_ans)

//                     let all_above_op1 = "None of the above".toLowerCase()
//                     let all_above_op2 = "All of the above".toLowerCase()

//                     let all_txt_answer = {}
//                     let except_all_above_options = []

//                     final_shuffled.forEach(data => {
//                         if (data.option.toLowerCase() === all_above_op1) {
//                             all_txt_answer = data
//                             except_all_above_options = final_shuffled.filter((data) => data.option.toLowerCase() !== all_above_op1)
//                             // console.log('None of the above', except_all_above_options)
//                         }
//                         if (data.option.toLowerCase() === all_above_op2) {
//                             all_txt_answer = data
//                             except_all_above_options = final_shuffled.filter((data) => data.option.toLowerCase() !== all_above_op2)
//                             // console.log('All of the above', except_all_above_options)
//                         }
//                     })
//                     if (except_all_above_options.length !== 0) {
//                         except_all_above_options.push(all_txt_answer)
//                         // console.log('except_all_above_options', except_all_above_options)
//                         randomQuestions[index].options_displayed = except_all_above_options
//                     } else {
//                         randomQuestions[index].options_displayed = final_shuffled
//                     }
//                 }
//                 // console.log('randomQuestions', randomQuestions)
//                 randomQuestions = _.shuffle(randomQuestions)
//             } else {
//                 for (let index = 0; index < randomQuestions.length; index++) {
//                     const options = randomQuestions[index].options
//                     correct_answers = options.filter(word => word.is_ans === "1")
//                     wrong_answers = _.filter(options, { is_ans: "0" })
//                     var remaining_options = max_no_of_optn - correct_answers.length
//                     let shuff = wrong_answers.slice(0, remaining_options)
//                     var total_ans = correct_answers.concat(shuff)
//                     // total_ans = _.shuffle(total_ans)   // if wrong answer on sequence please un commend

//                     let all_above_op1 = "None of the above".toLowerCase()
//                     let all_above_op2 = "All of the above".toLowerCase()

//                     let all_txt_answer = {}
//                     let except_all_above_options = []

//                     total_ans.forEach(data => {
//                         if (data.option.toLowerCase() === all_above_op1) {
//                             all_txt_answer = data
//                             except_all_above_options = total_ans.filter((data) => data.option.toLowerCase() !== all_above_op1)
//                             // console.log('None of the above', except_all_above_options)
//                         }
//                         if (data.option.toLowerCase() === all_above_op2) {
//                             all_txt_answer = data
//                             except_all_above_options = total_ans.filter((data) => data.option.toLowerCase() !== all_above_op2)
//                             // console.log('All of the above', except_all_above_options)
//                         }
//                     })
//                     if (except_all_above_options.length !== 0) {
//                         except_all_above_options.push(all_txt_answer)
//                         // console.log('except_all_above_options', except_all_above_options)
//                         randomQuestions[index].options_displayed = except_all_above_options
//                     } else {
//                         randomQuestions[index].options_displayed = total_ans
//                     }
//                 }
//                 // console.log('randomQuestions', randomQuestions)
//             }


//             this.setState({ randomQues: randomQuestions, course_duration, totalValue, min_pass_perc, total_no_ques, time_duration, no_of_attempt, attempted_on, re_attempt_duration, document_id, pass_criteria, min_pass_ques })
//             this.checkAssessmentAttempt()
//         }
//     }


//     checkAssessmentAttempt = () => {
//         var crs_subscr_id = JSON.parse(sessionStorage.getItem("crs_subscr_id"))
//         var crs_cntnt_info_id = JSON.parse(sessionStorage.getItem("CourseDetails"))
//         let document_id = this.state.document_id
//         let data = { crs_cntnt_info_id, crs_subscr_id, document_id }
//         // console.log('subscr_crs/chk_alrdy_attmptd APIIII', { data })
//         try {
//             urlSocket.post('subscr_crs/chk_alrdy_attmptd', data)
//                 .then((res) => {
//                     let data = res.data.data
//                     // console.log('res.data', res.data)
//                     let randomQues = res.data.question_list
//                     if (res.data.response_code === 500) {
//                         this.setState({ randomQues })
//                         console.log('chk_alrdy_attmptd', res.data)
//                         let { attempt_status, score, attempt_count, attempted_on, assessment_list } = data[0]
//                         attempt_status = Number(attempt_status)
//                         if (Number(attempt_status) === 1) {
//                             this.setState({ isFinalSubmited: true, course_duration: 0, attempt_count: Number(attempt_count), selectedQues: this.state.randomQues.length + 1, attempt_status, score })
//                         } else {
//                             if (assessment_list.no_of_attempt) {
//                                 if (Number(assessment_list.no_of_attempt) > Number(attempt_count)) {
//                                     let re_attempt_duration = assessment_list.re_attempt_duration
//                                     let re_attempt_duration_sec = this.convertToSec(re_attempt_duration)
//                                     console.log('Last attempted_on', attempted_on)
//                                     attempted_on = new Date(attempted_on)
//                                     var nxt_attmpt = moment(attempted_on).add('seconds', re_attempt_duration_sec)._d
//                                     // var nxt_attmpt = moment(attempted_on).add(parseInt(re_attempt_duration_sec), 'seconds')

//                                     console.log('Current Date', new Date())
//                                     console.log('nxt_attmpt', nxt_attmpt)

//                                     if (new Date() > nxt_attmpt) {
//                                         console.log('You can attend')
//                                         this.setState({ attempt_count, attempt_notification: true })
//                                     } else {
//                                         console.log('Please Tryback ater Sometime')
//                                         this.setState({ randomQues: [], notAllowed: true, try_after: nxt_attmpt })
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 })
//         } catch (error) {
//             console.log('error', error)
//         }
//     }


//     gotoNextQues = () => {
//         this.setState(state => ({ selectedQues: state.selectedQues + 1, isCorrect: null, isSubmited: false, user_selected: null, correctAnswer: '', }))
//         let totalQuesCount = this.state.randomQues.length
//         let currentQuesNo = this.state.selectedQues
//         console.log("object","gotonext",currentQuesNo === totalQuesCount)
//         if (currentQuesNo === totalQuesCount) {
//             this.setState(state => ({showMyAns: true, selectedQues: state.selectedQues - 1, isCorrect: null, isSubmited: false, user_selected: null, correctAnswer: '' }))
//         }
//     }

//     onChangeOption = (user_selected, data, index) => {
//         console.log(data, "user_data", index,user_selected)
//         data.user_selected = user_selected
//         // data.submited = false
//         let result = data.options_displayed.filter((data) => data.is_ans === '1')[0]
//         let correctAnswer = result.option
//         let question_type = "Single-Choice"
//         this.setState({ selected_index_: index, correctAnswer, user_selected, question_type })
//     }

//     OnchangeTextAnswer = (value, data) => {
//         console.log(value, data, "value of radio")
//         data.user_selected = value
//         let question_type = "Text-Box"
//         this.setState({ question_type, user_selected: value })
//     }

//     onChangeMultiOption = (e,index,data ) => {
//         console.log(e,"value",index,"indii",data)
//         data.options_displayed[index].user_selected = e
//         // // data.submited = false
//         let result = data.options_displayed.filter((data) => data.is_ans === '1')
//         let user_selected_multi = data.options_displayed.filter((data) => data.user_selected === true)

//         let correctAnswer = this.matchAnswers(result, user_selected_multi)
//         let question_type = "Multi-Choice"
//         console.log('user_selected_multi', user_selected_multi)
//         this.setState({ user_selected_multi, correctAnswer, user_selected: user_selected_multi, question_type })
//         data.user_selected = user_selected_multi  //
//     }

//     matchAnswers(a, b) {
//         return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index])
//     }

//     onPreviosQues = (selected_index) => {

//         let selectedQues = this.state.selectedQues - 1
//         let randomQues = this.state.randomQues
//         let user_selected = this.state.user_selected
//         let already_selected_ans = randomQues[selectedQues].user_selected
//         randomQues[selected_index].submited = false
//         console.log(randomQues, "randomQues")
//         console.log('user_selected', selected_index + 1, user_selected, selectedQues)
//         console.log('already_selected_ans', randomQues, already_selected_ans)
//         this.setState({ selectedQues, selected_index, user_selected: already_selected_ans })
//     }

//     onSubmitNext = (selected_index) => {

//         console.log(selected_index, "this.state.selectedQues")
//         this.setState({ submiting: true })
//         let { correctAnswer, user_selected, question_type } = this.state

//         let isCorrect = null

//         if (question_type === "Single-Choice") {

//             if (correctAnswer === user_selected) {
//                 isCorrect = true
//             } else {
//                 isCorrect = false
//             }

//         } else if (question_type === "Multi-Choice") {
//             isCorrect = this.state.correctAnswer
//             console.log(this.state.correctAnswer,"correctans")
//         } else if ("Text-Box") {
//             console.log('Text-Box')
//         } else {

//         }
//         console.log(isCorrect, "this.state.selectedQues",isCorrect)

//         this.setState({ isCorrect})
//         this.submitQuesAPI(isCorrect)
//     }


//     submitQuesAPI = async (isCorrect) => {
//         let { randomQues, selectedQues, user_selected } = this.state
//         let currentQues = randomQues[selectedQues]

//         if (isCorrect === "") {
//             isCorrect = this.state.is_correct
//             console.log(this.state.correctAnswer,"correctans")
//         }
//         console.log(this.state.correctAnswer,"correctans",isCorrect)
//         currentQues.is_correct = isCorrect
//         currentQues.submited = true

//         let already_selected_ans = randomQues[selectedQues].user_selected
//         console.log('randomQues[selectedQues]', randomQues[selectedQues],currentQues,already_selected_ans)

//         if (randomQues[selectedQues].user_selected === null || randomQues[selectedQues].user_selected === undefined) {
//             console.log('user_selected', user_selected)
//             currentQues.user_selected = user_selected
//         } else {
//             currentQues.user_selected = already_selected_ans
//             console.log('already_selected_ans', already_selected_ans)

//         }


//         console.log('updt_crs_sbscr_assmnt_rspns', currentQues)
//         await urlSocket.post("subscr_crs/updt_crs_sbscr_assmnt_rspns", { data: currentQues })
//             .then(async (res) => {
//                 console.log(res,"response")
//                 if (res.data.response_code === 500) {
//                     // let AllquestionsList = res.data.data

//                     this.setState({ randomQues, isSubmited: true, submiting: false })
//                     this.gotoNextQues()
//                 }
//             })
//             .catch((err) => {
//                 this.setState({ submiting: false })
//             })
//     }

//     // let wrong_answers_count = _.filter(randomQues, { is_correct: false }).length
//     finalSubmition = () => {
//         let { attempted_on, attempt_count, randomQues, min_pass_perc, total_no_ques, re_attempt_duration, document_id, pass_criteria, min_pass_ques } = this.state
//         var crs_subscr_id = JSON.parse(sessionStorage.getItem("crs_subscr_id"))
//         let attempt_status = null
//         let data = {}
//         let score = _.filter(randomQues, { is_correct: true }).length
//         if (pass_criteria === "Percentage") {

//             let score_percent = (score / Number(total_no_ques)) * 100

//             if (Number(score_percent) <= Number(min_pass_perc)) {
//                 attempt_status = 0
//             } else {
//                 attempt_status = 1
//             }

//             let pass_percent = parseFloat(min_pass_perc)

//             attempt_count = Number(attempt_count) + 1

//             data = { attempted_on, attempt_count, score, score_percent, attempt_status, pass_percent, crs_subscr_id, re_attempt_duration, document_id, all_questions: randomQues, pass_criteria }


//         } else {
//             // let wrong_answers_count = _.filter(randomQues, { is_correct: false }).length


//             if (Number(min_pass_ques) > Number(score)) {
//                 attempt_status = 0
//             } else {
//                 attempt_status = 1
//             }
//             console.log('attempt_status', attempt_status)

//             data = {
//                 attempted_on, attempt_count, crs_subscr_id, re_attempt_duration, document_id, all_questions: randomQues, pass_criteria, score, attempt_status, score_percent: null, pass_percent: null,
//             }

//         }
//         this.setState({ attempt_status, isFinalSubmited: true, score })

//         console.log('data_______', data)

//         urlSocket.post("subscr_crs/assmnt_submsn", { data })
//             .then(async (res) => {
//                 console.log(res,"count_timer")
//                 if (res.data.response_code === 500) {
//                     console.log('res', res.data)
//                     this.setState({ showMyAns: false, finalSubmitConfirm: false,count_down: true })
//                     console.log("removelistner")
//                       window.removeEventListener("beforeunload", this.handleBeforeUnload)
                    
//                 }
//             })
//     }

//     onTimeupCourse = () => {
//         this.setState({ timeUp: true, selectedQues: this.state.randomQues.length + 1 })
//         this.finalSubmition()
//     }

//     getAttemptedQuesCount = (questions, index) => {
//         console.log(questions, "questions")
//         let attempted_ques = questions.filter((data) => (data.submited !== undefined && data.submited)).length
//         console.log(attempted_ques, "attempted_ques")
//         return attempted_ques
//     }

//     previous_ques = (questions) => {
//         console.log(questions, "questions")
//         let attempted_ques = questions.filter((data) => (data.submited !== undefined && data.submited)).length
//         console.log(attempted_ques, "attempted_ques")
//         return attempted_ques
//     }

//     getAttemptedQuesCount_ = (questions) => {
//         let attempted_ques = questions.filter((data) => (data.user_selected !== null)).length
//         console.log(attempted_ques, "attempted_ques")
//         return attempted_ques
//     }

//     getunAttemptedQuesCount = (questions) => {
//         let unattempted = questions.filter((data) => (data.user_selected === null)).length
//         return unattempted
//     }

//     goTomatchedQues = (_id) => {
//         let index = this.state.randomQues.findIndex((elem) => { return elem._id === _id })
//         return index
//     }

//     goToQues = (selectedQues) => {
//         console.log('this.state.randomQues[', this.state.randomQues[selectedQues],selectedQues)
//         let is_correct = this.state.randomQues[selectedQues].is_correct
//         console.log(is_correct,"iscorrect",selectedQues)
//         this.setState({ selectedQues, showMyAns: false, show_allpage: true, is_correct })
//     }

//     getAttendedPercentage = (questions) => {
//         try {
//             if (questions.length) {
//                 let attempted_ques = questions.filter((data) => (data.submited !== undefined && data.submited)).length
//                 let total_no_ques = questions.length
//                 let percentage = ((attempted_ques+1) / total_no_ques) * 100
//                 return percentage
//             }
//         } catch (error) {
//             console.log('error', error)
//         }
//     }

//     onSubmitInterMediateReview = async () => {
//         this.setState({ submiting: true  })
//         let { correctAnswer, user_selected, question_type } = this.state

//         let isCorrect = null

//         if (question_type === "Single-Choice") {

//             if (correctAnswer === user_selected) {
//                 isCorrect = true
//             } else {
//                 isCorrect = false
//             }

//         } 
//         if (question_type === "Multi-Choice") {
//             isCorrect = this.state.correctAnswer
//         }else{
//             isCorrect = false
//         }
        
        
//          if ("Text-Box") {
//             console.log('Text-Box')
//         } else {
//         }


//         let { randomQues, selectedQues, } = this.state
//         let currentQues = randomQues[selectedQues]
//         currentQues.is_correct = isCorrect
//         currentQues.submited = true

//         let already_selected_ans = randomQues[selectedQues].user_selected

//         if (randomQues[selectedQues].user_selected === null || randomQues[selectedQues].user_selected === undefined) {
//             currentQues.user_selected = user_selected
//         } else {
//             currentQues.user_selected = already_selected_ans

//         }


//         console.log('updt_crs_sbscr_assmnt_rspns', currentQues,randomQues,selectedQues,this.state.correctAnswer)
//         await urlSocket.post("subscr_crs/updt_crs_sbscr_assmnt_rspns", { data: currentQues })
//             .then(async (res) => {
//                 console.log(res,"res")
//                 if (res.data.response_code === 500) {
//                     // let AllquestionsList = res.data.data

//                     this.setState({ randomQues, isSubmited: true, submiting: false, showMyAns: true, isCorrect })
//                 }
//             })
//             .catch((err) => {
//                 this.setState({ submiting: false })
//             })


//     }

//     render() {
//         return (
//             <React.Fragment>
//                 <div className="" style={{ marginTop: this.state.isFinalSubmited ? "-10px" : "0" }}>
//                     {
//                         !this.state.isFinalSubmited && !this.state.showMyAns && (
//                             <Container style={{ background: '#ECECEC', maxWidth: '1440px' }}>
//                                 <Row className='pt-4 pb-3' >
//                                     <Col md={12}>
//                                         {/* {
//                                             this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length !== this.state.totalValue && this.state.previous !== true  ?
//                                                 <div className='qaprocessCntr' >You are attempting {this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length + 1}/ {this.state.totalValue}</div>
//                                                 :
//                                                 this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length === this.state.totalValue && this.state.previous !== true  ?
//                                                     <div className='qaprocessCntr' >You are attempting {this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length}/ {this.state.totalValue}</div>
//                                                     :
//                                                     this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length > this.state.totalValue && this.state.previous !== true  ?
//                                                         <div className='qaprocessCntr' >You are attempting {this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length}/ {this.state.totalValue}</div>
//                                                         :
//                                                         null

//                                         } */}
//                                         {
//                                                 <div className='qaprocessCntr' >You are attempting {this.state.selectedQues + 1}/ {this.state.totalValue}</div>
//                                         }
//                                         {/* {
//                                             console.log(this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length + 1 > this.state.totalValue, "start_check", this.state.randomQues.filter((data) => (data.submited !== undefined && data.submited)).length, this.state.totalValue)
//                                         } */}
//                                         {/* <div  className='qaprocessCntr' >You are attempting {this.getAttemptedQuesCount(this.state.randomQues) + 1}/ {this.state.totalValue}</div> */}
//                                         {/* <Progress strokeLinecap="square" percent={this.getAttendedPercentage(this.state.randomQues)} showInfo={false} strokeColor={'#5E5E5E'} /> */}
                                   
//                                          <Progress strokeLinecap="square" percent={((this.state.selectedQues + 1) / this.state.totalValue)*100} showInfo={false} strokeColor={'#5E5E5E'} />
                                                
//                                         {/* {
//                                             this.state.previous === true  ?
//                                                 <div className='qaprocessCntr' >You are attempting {this.state.selected_index}/ {this.state.totalValue}</div>
//                                                 :
//                                                 null

//                                         } */}
//                                         {/* {
//                                             this.state.previous === true ?
//                                                 <Progress strokeLinecap="square" percent={this.getAttendedPercentage(this.state.randomQues)} showInfo={false} strokeColor={'#5E5E5E'} />
//                                                 :
//                                                 null
//                                         } */}
//                                         {/* {
//                                             console.log(this.state.selected_index, "index")
//                                         } */}

//                                     </Col>
//                                 </Row>
//                             </Container>
//                         )}
//                     <Container className="pt-3 pb-3" style={{ background: 'white', maxWidth: '636px' }}>
//                         <Row>
//                             <div className="col-md-12 ps-0 pe-0">
//                                 <div className="row">
//                                     <div className="col borNone quizTitle ps-0"><h1 style={{ fontSize: '28px', padding: 0, margin: 0 }} onClick={() => console.log('this.state.randomQues', this.state.randomQues)}>Quiz</h1> </div>
//                                     <div className="col borNone timerDiv" style={{ textAlign: 'right', padding: 0, margin: 0 }}>
//                                         {
//                                             // ((this.state.selectedQues + 1) <= (this.state.randomQues.length)) && <div> ({this.state.selectedQues + 1} of {this.state.randomQues.length} )</div>
//                                         }
//                                         {
//                                             this.state.course_duration > 0 && (
//                                                 <>
//                                                 {/* {
//                                                     console.log(this.state.course_duration , "this.state.course_duration")
//                                                 } */}
//                                                  {
//                                                     !this.state.count_down ?
//                                                     <div className='countDownTimerDiv d-flex align-items-center justify-content-end'>
//                                                         <div><i className='bx bx-time-five' style={{ fontSize: '18px', marginTop: '13px', marginRight: '3px' }}></i></div>
                                                           
//                                                                 <CountdownTimer className="mt-2" style={{ fontSize: '14px' }} hideDay={true} count={this.state.course_duration} border onEnd={() => { this.onTimeupCourse() }} />
                                                                
//                                                     </div>
//                                                 :
//                                                 null
//                                                             }
//                                                     <div className='fs-18'>
//                                                         {/* Questions Attempted: {this.getAttemptedQuesCount(this.state.randomQues)} */}
//                                                         {
//                                                             (this.state.min_pass_ques !== undefined && this.state.min_pass_ques !== null) &&
//                                                             <div>
//                                                                 Min. pass Questions : {this.state.min_pass_ques}
//                                                             </div>
//                                                         }
//                                                     </div>
//                                                 </>
//                                             )
//                                         }
//                                         {
//                                             this.state.timeUp && <h4>Time UP!!!!</h4>
//                                         }
//                                     </div>
//                                 </div>
//                             </div>
//                         </Row>
//                         {
//                             !this.state.isFinalSubmited && (
//                                 <hr />
//                             )
//                         }
//                         {
//                             !this.state.showMyAns &&
//                             <Row className='justify-content-left' style={{ minHeight: "30vh" }}>
//                                 <div className="col-md-12">
//                                     {
//                                         this.state.isFinalSubmited ?
//                                             <div className=''>
//                                                 <FinalResult attempt_status={this.state.attempt_status} pass_criteria={this.state.pass_criteria} score={this.state.score} randomQues={this.state.randomQues} goBack={() => this.props.history.push('/course/CourseDetails')} />
//                                                 <UserAnswers questionsList={this.state.randomQues} score={this.state.score} randomQues={this.state.randomQues} getCourseDetails = {this.props.getCourseDetails} />
//                                             </div>
//                                             :
//                                             <>

//                                                 {
//                                                     this.state.randomQues.map((data, index) => (
//                                                         <div key={index} >
//                                                             {
//                                                                 index === this.state.selectedQues &&
//                                                                 <div>
//                                                                     <h3 className='mt-4 mb-4 fontInter fontWeightNormal'>{index + 1}. {data.question}</h3>
//                                                                     <div className=''>
//                                                                         {
//                                                                             (data.media_url !== undefined && data.media_url !== null) &&
//                                                                             <audio controls>
//                                                                                 <source src={this.state.baseurl + data.media_url} type="audio/ogg" />
//                                                                                 <source src={this.state.baseurl + data.media_url} type="audio/mpeg" />
//                                                                             </audio>
//                                                                         }
//                                                                     </div>
//                                                                     {
//                                                                         data.question_type === "Single-Choice" ?
//                                                                             <SingleChoiseQuest options={data.options_displayed} onChangeOption={(e) => this.onChangeOption(e.target.value, data, index)} user_selected={data.user_selected} />
//                                                                             :
//                                                                             data.question_type === "Multi-Choice" ?
//                                                                                 // <MultiChoiseQuest options={data.options_displayed} onChangeOption={(value, index) => this.onChangeMultiOption(value,index,data)} user_selected={data.user_selected} />
//                                                                                 <MultiChoiseQuest options={data.options_displayed} onChangeOption={(e, index) => this.onChangeMultiOption(e, index, data)} user_selected={data.user_selected} />
//                                                                                 // <MultiChoiseQuest options={data.options_displayed} onChangeOption={(e) => this.onChangeOption(e.target.value, data, index)} user_selected={data.user_selected} />

//                                                                                 :
//                                                                                 data.question_type === "Text-Box" ?
//                                                                                     <TextInputBox OnchangeTextAnswer={(value) => this.OnchangeTextAnswer(value, data)} user_selected={data.user_selected} />
//                                                                                     :
//                                                                                     null
//                                                                     }
//                                                                 </div>
//                                                             }
//                                                         </div>
//                                                     ))
//                                                 }

//                                             </>
//                                     }
//                                 </div>
//                             </Row>
//                         }

//                         {
//                             this.state.showMyAns ?
//                                 <Row>
//                                     <div className="row">
//                                         <MyAnswers questionsList={this.state.randomQues} goToQues={(idx) => this.goToQues(idx)} attempted_count={this.getAttemptedQuesCount_(this.state.randomQues)} unattempted_count={this.getunAttemptedQuesCount(this.state.randomQues)} />

//                                         <div className="col-md-12">
//                                             <div className="d-flex justify-content-left">
//                                                 <div className=""><Button className='mx-2 quizCntrlBtns quizCntrlBtnsOutlined' onClick={() => this.setState({ showMyAns: false, show_allpage: true })} >Review Responses</Button></div>
//                                                 <div className=""><Button className='mx-2 quizCntrlBtns quizCntrlBtnsOutlined' onClick={() => this.setState({ finalSubmitConfirm: true })}>Submit Quiz</Button></div>
//                                             </div>
//                                         </div>

//                                     </div>
//                                 </Row>
//                                 :
//                                 null
//                         }
//                         {/* {
//                             console.log(this.state.selectedQues, "this.state.selectedQues")
//                         } */}
//                         {
//                             (!this.state.showMyAns && !this.state.isFinalSubmited) &&
//                             <div className="col-md-12">
//                                 <div className="d-flex justify-content-left">
//                                     {
//                                         this.state.selectedQues > 0 ?
//                                             // <Button className="quizCntrlBtns quizCntrlBtnsOutlined" onClick={() => this.onPreviosQues(this.state.selectedQues)}>Previous</Button>
//                                             <Button className="quizCntrlBtns quizCntrlBtnsOutlined" onClick={() => this.onPreviosQues(this.state.selectedQues)}>Previous</Button>

//                                             :
//                                             null
//                                     }
//                                     {/* {
//                                         console.log(this.state.selectedQues + 1,this.state.randomQues.length,this.state.selectedQues + 1 === this.state.randomQues.length,"this.state.selectedQues + 1 !== this.state.randomQues.length","705",this.state.selectedQues + 1 === this.state.randomQues.length)
//                                     } */}
//                                     {
//                                         (this.state.selectedQues +1 !== this.state.randomQues.length) &&
                                            
//                                         this.state.show_allpage && <Button className='mx-2 quizCntrlBtns quizCntrlBtnsOutlined' onClick={() => this.onSubmitInterMediateReview()}>Review Responses</Button>
//                                     }
//                                     {/* {
//                                         (this.state.selectedQues +1 === this.state.randomQues.length) || (this.state.selectedQues +1 !== this.state.randomQues.length) &&
                                            
//                                         this.state.show_allpage && <Button className='mx-2 quizCntrlBtns quizCntrlBtnsOutlined' onClick={() => this.onSubmitInterMediateReview()}>Review Responses</Button>
//                                     } */}
//                                     {
//                                         ((this.state.selectedQues) < (this.state.randomQues.length - 1)) && <Button className="quizCntrlBtns quizCntrlBtnsOutlinedGrey" onClick={() => { this.onSubmitNext(); }}>Next</Button>
//                                     }

//                                     {
//                                         !this.state.isSubmited ?
//                                             <>

//                                                 {
//                                                     (this.state.selectedQues + 1 === this.state.randomQues.length)&&
                                                    
//                                                     <>
//                                                     <Button className=" quizCntrlBtns quizCntrlBtnsOutlined" type="success" onClick={() => this.onSubmitNext(this.state.selectedQues)} loading={this.state.submiting}>{this.state.submiting ? 'Submiting...' : 'Review Response'} </Button>

//                                                     {
//                                                         console.log("checking11",this.state.isSubmited )
//                                                     }
//                                                     </>
                                                   
//                                                 }
//                                                 {/* {
//                                         console.log(this.state.selectedQues -1 === this.state.randomQues.length,"this.state.selectedQues -1 === this.state.randomQues.length")

//                                                 } */}
//                                             </>
//                                             :
//                                             this.state.isSubmited ?
//                                             <>

//                                             {
//                                                 (this.state.selectedQues + 1 === this.state.randomQues.length)&&
                                                
//                                                 <>
//                                                 <Button className=" quizCntrlBtns quizCntrlBtnsOutlined" type="success" onClick={() => this.onSubmitNext(this.state.selectedQues)} loading={this.state.submiting}>{this.state.submiting ? 'Submiting...' : 'Review Response'} </Button>

//                                                 {
//                                                     console.log("checking11",this.state.isSubmited )
//                                                 }
//                                                 </>
                                               
//                                             }
//                                             {/* {
//                                     console.log(this.state.selectedQues -1 === this.state.randomQues.length,"this.state.selectedQues -1 === this.state.randomQues.length")

//                                             } */}
//                                         </>
//                                         :
//                                         null
//                                         }
//                                 </div>
//                             </div>

//                         }
                                                             
//                         {
//                           this.state.finalSubmitConfirm &&
//                           // <SweetAlert warning showCancel confirmBtnText="Yes, Submit!" confirmBtnBsStyle="danger" title="Are you sure you want to submit Assessment?" onConfirm= onCancel= focusCancelBtn>
//                           <SweetAlert style={{width:"450px", margin:"0 auto"}}
//                                 title="" 
//                                 showCloseButton
//                                 //customIcon="" 
//                                 showCancel 
//                                 //confirmBtnText="cancel" 
//                                 //cancelBtnText="OK" 
//                                 //confirmBtnBsStyle="danger" 
//                                 //cancelBtnBsStyle="success" 
//                                 //onCancel={() => this.finalSubmition()} 
//                                 //onConfirm={() => this.setState({ finalSubmitConfirm: false })} 
                                
//                                 customButtons={
//                                     <React.Fragment>                                                                                
//                                         <Button className="quizCntrlBtns quizCntrlBtnsFill customSweetAlertButton" onClick={() => this.finalSubmition()}>Yes, Submit</Button>
//                                         <Button className="quizCntrlBtns quizCntrlBtnsOutlined customSweetAlertButton" onClick={() => this.setState({ finalSubmitConfirm: false })}>Cancel</Button>
//                                     </React.Fragment>
//                                 }

//                                 >

//                                 <div className='customSweetAlertIcn'></div>
//                                 <div className="customSweetAlertTxt">Are you sure you want to submit assessment?</div>
//                                 {/* <h4 className='m-4'>Questions Attempted: {this.getAttemptedQuesCount_(this.state.randomQues)}</h4> */}
                                
//                                 {
//                                     this.getunAttemptedQuesCount(this.state.randomQues) > 0 &&
//                                     <div>                                        
//                                         <h4 className='m-4'> {this.getunAttemptedQuesCount(this.state.randomQues)} Questions are still unattempted</h4>
//                                     </div>
//                                 }
//                           </SweetAlert>
//                         }
//                         {
//                             this.state.attempt_notification &&
//                             <SweetAlert success title="Re Assessment" onConfirm={() => { this.setState({ attempt_notification: false }) }}>Attempt: {this.state.attempt_count === 0 ? 1 : this.state.attempt_count} </SweetAlert>
                            
//                         }

//                         {
//                             this.state.notAllowed &&
//                             <SweetAlert danger title="Re Assessment" onConfirm={() => { this.props.history.push('/course/CourseDetails'); this.setState({ notAllowed: false }) }}>Please Try after {moment(this.state.try_after).format("DD-MM-YYYY HH:mm")} </SweetAlert>
//                         }
//                     </Container>
//                 </div>
//             </React.Fragment>
//         )
//     }
// }