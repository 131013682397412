import PropTypes from 'prop-types'
import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Badge } from "reactstrap"
import SimpleBar from "simplebar-react"
import noCardImg  from '../../../assets/images/noCardImg.png'

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../../assets/images/users/avatar-4.jpg"

//i18n
import { withTranslation } from "react-i18next"

class SearchDropDown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: false,
    }
  }


  render() {
    return (
          

          <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0"> {this.props.t("Courses")} </h6>
                </Col>
              </Row>
            </div>

            <SimpleBar style={{ height: "300px" }}>
                {
                    this.props.courseList.map((category, idx) => (

                        category.courses.map((course, courseIndex) => (
                            <Link to=""
                                className="text-reset notification-item"
                                key={courseIndex + "crlist"} style={{ borderBottom: "1" }}
                                onClick={() => { this.props.loadCourse(course) }}
                            >
                                <div className="d-flex">
                                    <img src={course.thumbnail !== undefined && course.thumbnail !== '' ? this.props.baseurl + course.thumbnail : noCardImg} className="me-3  avatar-sm" alt="user-pic" style={{ width: "auto" }} />

                                    <div className="flex-grow-1">
                                        <h6 className="mt-0 mb-1">{course.name_of_the_course}</h6>
                                        <div className="font-size-12 text-muted">
                                  <Badge
                                    className={'badge-soft-secondary ms-1 me-2'}
                                    color={'#fbfbfb'}
                                  >
                                    {course.media_type === "1" ? "Video" : course.media_type === "2" ? "Audio" : "Video & Audio"}
                                  </Badge>
                                            <Badge
                                                className={`${course.is_subscribed ? 'badge-soft-secondary ms-1' : 'badge-soft-primary ms-1'}`}
                                                color={'#fbfbfb'}
                                            >
                                                {course.is_subscribed ? 'Subscribed' : 'Subscribe'}
                                            </Badge>

                                        </div>
                                {/* <div>
                               

                                </div> */}
                                    </div>
                                </div>
                            </Link>
                        ))

                    ))

                }
            </SimpleBar>

            {/* <div className="p-2 border-top d-grid">
              <Link className="btn btn-sm btn-link font-size-14 text-center" to="#">
                <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{this.props.t("View More..")}</span>
              </Link>
            </div> */}
          </DropdownMenu>
    )
  }
}

SearchDropDown.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(SearchDropDown)
