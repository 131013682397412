import React, { Component } from "react"
import { Container } from "reactstrap"
import MetaTags from 'react-meta-tags'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class AboutUs extends Component {
  render() {
    return (
      <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Kalakriya - About us</title>
                    </MetaTags>
                    <Container>
                        <Breadcrumbs  breadcrumbItem="About Us" />
                        <div style={{ textAlign: "justify" }}>
                        Kalakriya-Series represents a modest but meaningful endeavour to preserve and popularize the performing arts of India in general and Bharatanatyam in particular. For a starter, it will display a fine collection of Audio Tracks and Videos on Bharatanatyam that carry both instructional and entertainment value. Each product showcased in Kalakriya-Series, will be a collectors item with its superior content created with the inputs from legendary gurus and fascinatingly formatted by a highly skilled technical team. Ideal techno tools for learning, these Bharatanatyam Videos and Audio Tracks will enable dance students and enthusiasts to gain access to the abundant and arcane wisdom of some of the greatest Bharathanatyam exponents of our times. Just as it is art-friendly, Kalakriya-Series is user-friendly too. Subscribe your Videos or Audio Tracks online. All that it needs is a click!!!


<br/>
<br/>
<span style={{fontWeight:'bold'}}>Our Products</span>
<br/>


All the products listed in this website are conceptualised and produced by our team. We do not sell products that are not created by us. 


                        </div>
                    </Container>
                </div>
            </React.Fragment>
    )
  }
}

export default AboutUs
