import React, { Component } from 'react'
import { Button } from 'antd'
import flag from '../../../assets/images/flag.png'

export default class FinalResult extends Component {

    getAttemptedQuesCount_ = (questions) => {
        let attempted_ques = questions.filter((data) => (data.user_selected !== null)).length
        return attempted_ques
    }

    getunAttemptedQuesCount = (questions) => {
        let unattempted = questions.filter((data) => (data.user_selected === null)).length
        return unattempted
    }


    render() {
        let { attempt_status, pass_criteria, score, randomQues } = this.props
        return (
            <div>


                <div className='d-flex align-items-center justify-content-left mt-3' style={{height: '20px'}}>                    
                    <img src={flag} className="me-2"/><h5 className='mt-2 w-100' style={{fontFamily: 'Quicksand', fontWeight: '700', color: '#3A4956'}}>Here is your result for the attempted {randomQues.length} questions</h5>
                </div> 

                 <div className='d-flex align-items-center justify-content-left'>                    
                    {
                        pass_criteria === "Percentage" ? <h4 className='mt-4 urAns w-100'>Score :  {score} / {randomQues.length} &nbsp;; Review responses below </h4> : <h4 className='mt-4 urAns w-100'>Score :  {score} / {randomQues.length} &nbsp;; Review responses below </h4>
                    }
                </div>

                {                    
                    // attempt_status === 1 ?
                    // <div>
                        
                    //     <h1 className='m-4' style={{ color: "green" }}>Congratulations! </h1>
                    //     <h1 style={{ color: "green" }}>You have completed the assessment.</h1>
                    //     <Button onClick={() => { this.props.goBack() }} >Go Back</Button>
                    // </div>
                    // :
                    // <div>
                    //     <h1 className='m-4'>Questions Attempted: {this.getAttemptedQuesCount_(randomQues)}</h1>
                    //     <h1 className='m-4'>Questions unattempted: {this.getunAttemptedQuesCount(randomQues)} </h1>
                    //     {
                    //         pass_criteria === "Percentage" ? <h1 className='m-4'>{score}/ {randomQues.length} </h1> : <h1 className='m-4'>{score} of {randomQues.length} </h1>
                    //     }
                    //     <h1 className='m-4' style={{ color: "red" }}>Sorry!</h1>
                    //     <h1 style={{ color: "red" }}> You can try again.</h1>
                    //     <Button onClick={() => { this.props.goBack() }} >Go Back</Button>
                    // </div>                                        
                }
            </div>
        )
    }
}
