import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { AvField, AvForm, AvInput } from "availity-reactstrap-validation"
import { Alert, Row, Col, Label, Button, Modal, Card, CardBody } from "reactstrap"
import { Link } from 'react-router-dom';
import Select from 'react-select'
import urlSocket from 'helpers/urlSocket';
import CryptoJS from 'crypto-js'
import { LoadingOutlined, EditOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom';
import NewUserForm from './NewUserForm';
import Amplify, { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import cautionIcon from '../../../assets/images/caution_icon.png'
import AuthCode from "react-auth-code-input"
import SocialMediaLogin from './SocialMediaLogin';
import ForgotScreen from './ForgotScreen';

const propTypes = {};

const defaultProps = {};

/**
 * 
 */

let cognitser = CognitoUser;
const SignIn = (props) => {
    const history = useHistory()
    const newForm = useRef()

    const [showPasswordBox, setshowPasswordBox] = useState(false)
    const [validFormat, setvalidFormat] = useState(false)
    const [enableCountryCode, setenableCountryCode] = useState(true)
    const [loginType, setloginType] = useState('')
    const [message, setmessage] = useState('')
    const [alertEnable, setalertEnable] = useState(false)
    const [alertColor, setalertColor] = useState("")
    const [countryCode, setCountryCode] = useState("+91")
    const [defaultCountryCode, setdefaultCountryCode] = useState('+91 India')
    const [buttonLoader, setbuttonLoader] = useState(false)
    const [userEmail, setuserEmail] = useState("")
    const [userNum, setuseruserNum] = useState("")
    const [newUser, setNewUser] = useState(false)
    const [alreadyLogged, setalreadyLogged] = useState(false)
    const [LoggedInfo, setLoggedInfo] = useState([])
    const [avFormValues, setavFormValues] = useState(null)
    const [userInfo, setuserInfo] = useState(null)
    const [signOutSuccess, setsignOutSuccess] = useState(false)
    const [signoutLoad, setsignoutLoad] = useState(false)
    const [triggerOtp, settriggerOtp] = useState(false)
    const [cognitoUser, setcognitoUser] = useState(null)
    const [Otp, setOtp] = useState("")
    const [verifyOtp, setverifyOtp] = useState(false)
    const [openForgotPassword, setopenForgotPassword] = useState(false)
    const [registeredUser, setregisteredUser] = useState(false)
    const [registeredUserInfo, setregisteredUserInfo] = useState(null)
    const [errorMessage, setErrorMessage] = useState("");


    useEffect(() => {
        // console.log("Mobile OTP")

    }, [])


    const checkUserExist = (username, values) => {

        try {
            urlSocket.post('sbscrmgt/check_user_exist', { username, login_type: loginType }).then((response) => {
                if (response.data.response_code === 500) {
                    if (response.data.data) {
                        setshowPasswordBox(true)
                        setNewUser(false)
                    }
                }
                else if (response.data.response_code === 504 && response.data.user_info?.length > 0) {
                    // if()
                    var loginSession = response.data.user_info[0]
                    if (loginSession.full_name !== null && loginSession.phone_number !== null && loginSession.email !== null && loginSession.gender !== null && loginSession.age_range !== null) {
                        var dynamicStateName = loginType === 2 ? setuserEmail : setuseruserNum
                        dynamicStateName(values.username)
                        setregisteredUserInfo(loginSession)
                        setNewUser(true)
                        localStorage.setItem("auth_type", 1)
                        setregisteredUser(true)
                    }
                    else{
                        var dynamicStateName = loginType === 2 ? setuserEmail : setuseruserNum
                        dynamicStateName(values.username)
                        setNewUser(true)
                        localStorage.setItem("auth_type", 1)
    
                    }


                }
                else {
                    var dynamicStateName = loginType === 2 ? setuserEmail : setuseruserNum
                    dynamicStateName(values.username)
                    setNewUser(true)
                    localStorage.setItem("auth_type", 1)

                }
            })
        } catch (error) {
            setmessage("Something has went wrong , Please try again later.")
            setalertColor("success")
            setalertEnable(true)
            fadeAlert()
        }
    }


    const awsSignInUser = (username, password) => {
        var ciphertext = CryptoJS.AES.encrypt(password, props.secretKey).toString()
        setbuttonLoader(true)
        try {
            urlSocket.post('sbscrmgt/signin', { username, Password: ciphertext, login_type: loginType }).then((response) => {
                if (response.data.response_code === 500) {
                    setbuttonLoader(false)
                    const loginSession = response.data.result[0]
                    if (loginSession.session_id !== null && loginSession.device_info !== null && loginSession.logged_in === false) {
                        localStorage.setItem('express-session', JSON.stringify({
                            session_id: loginSession.session_id,
                            device_info: loginSession.device_info,
                        }))
                        localStorage.setItem("auth_type", 1)
                        goToLandingPage(loginSession)
                    }
                    else {
                        var already_logged = []
                        loginSession.system_info["status"] = "1"
                        already_logged.push({
                            hostname: "This Device",
                            status: "0"
                        }, loginSession.system_info)
                        setalreadyLogged(true)
                        setLoggedInfo(already_logged)
                        setuserInfo(loginSession)


                    }
                }
                else {
                    setbuttonLoader(false)
                    setmessage(response.data.err)
                    setalertColor("danger")
                    setalertEnable(true)
                    fadeAlert()
                }
            })

        } catch (error) {

        }
    }


    const goToLandingPage = async (userInfo) => {
        sessionStorage.setItem("authUser", JSON.stringify(userInfo))
        var courseInfo = await JSON.parse(localStorage.getItem("CourseDetails"))
        if (courseInfo) {
            let name_of_the_course = courseInfo.name_of_the_course.trim().replace(/[^A-Z0-9]\s+/ig, "").replaceAll(' ', '-');
            history.push({
                pathname: '/course/CourseDetails',
                search: '?' + courseInfo._id + '?' + name_of_the_course.toLowerCase(),
            });
        }
        else {
            history.push("/dashboard")
        }
    }





    const formSubmit = (event, values) => {
        if (validFormat) {
            var username = loginType === 2 ? values.username.toLowerCase() : String(countryCode) + values.username
            if (showPasswordBox === false) {
                checkUserExist(username, values)
            }
            else {
                awsSignInUser(username, values.password)
            }
            setavFormValues(values)
        }
        else {
            setmessage("Enter formatted email id or mobile number")
            setalertColor("danger")
            setalertEnable(true)
            fadeAlert()
        }

    }


    const fadeAlert = () => {
        setTimeout(() => {
            setmessage("")
            setalertColor("")
            setalertEnable(false)
        }, 3000);
    }




    const validateUsername = (value) => {

        var mailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        var phoneFormat = /^([0-9]{3,})$/;
        if (String(value).length > 0) {
            var isPhoneFormat = phoneFormat.test(value)
            var isEmailFormat = mailFormat.test(value)
            if (isPhoneFormat) {
                setvalidFormat(true)
                setenableCountryCode(true)
                setloginType(1)

            }
            if (isEmailFormat) {
                setvalidFormat(true)
                setenableCountryCode(false)
                setloginType(2)
                setuserEmail(value)
            }
        }
        else {
            setenableCountryCode(true)

        }
    }


    const signOutDevice = async () => {
        setsignoutLoad(true)
        try {
            Amplify.configure({ Auth: { region: props.config.test_email.Region, userPoolId: props.config.test_email.UserPoolId, userPoolWebClientId: props.config.test_email.ClientId, } })
            cognitser = await Auth.signIn(String(userInfo.email)).then((res) => {
                if (res) {
                    settriggerOtp(true)
                    setcognitoUser(res)
                    setsignoutLoad(false)
                    setmessage("Please enter OTP sent to your registered email ID. Do not share OTP with others.")
                    setalertColor("success")
                    setalertEnable(true)
                    fadeAlert()

                }
            })
                .catch(error => {
                    setmessage("Something has went wrong , Please try again later.")
                    setalertColor("success")
                    setalertEnable(true)
                    fadeAlert()
                })
        }
        catch (error) {
            setmessage("Something has went wrong , Please try again later.")
            setalertColor("success")
            setalertEnable(true)
            fadeAlert()

        }
    }


    const sessionSignout = () => {
        try {
            urlSocket.post('handle-session/sign-out-device', {
                user_id: userInfo._id
            }).then((response) => {
                if (response.data.response_code === 500) {
                    formSubmit(null, avFormValues)
                }
            })
        } catch (error) {
        }
    }




    const proceedLogin = async () => {

        if (String(Otp).length === 6) {
            setverifyOtp(true)

            try {
                let answer = Otp
                let user = cognitoUser
                await Auth.sendCustomChallengeAnswer(user, String(answer))
                    .then((res) => {
                        if (res.signInUserSession === null) {
                            setmessage("Please Enter a Valid OTP")
                            setalertColor('danger')
                            setalertEnable(true)
                            setverifyOtp(false)
                            fadeAlert()

                        }
                        else {
                            setmessage("Signed out successfully from the other device / browser")
                            setalertColor('success')
                            setalertEnable(true)
                            fadeAlert()
                            sessionSignout()
                        }
                    })

            } catch (error) {

            }

        }

    }


    const forgotPassword = () => {
        setopenForgotPassword(true)


    }





    if (!newUser && !openForgotPassword) {
        return (
            <div>
                {
                    alreadyLogged ?
                        <div className='d-flex flex-column'>
                            <div className='mb-2'>
                                <img src={cautionIcon} style={{ width: "10%" }} />
                            </div>
                            <div className="mb-2">
                                <h6 style={{ fontSize: 15 }} className="modal-title" id="staticBackdropLabel">Dear {userInfo?.full_name}, you have to log out of another device / browser to login in the current device / browser. </h6>
                            </div>
                            <div className="mb-2">
                                <h6>Kalakriya courses can be accessed through only 1 device / browser at a time. You can signout from the other device / browser if you want to continue here. Please choose your option from the below:</h6>
                            </div>
                            <div className='d-flex flex-column mb-3'>
                                {
                                    LoggedInfo.map((ele, idx) => {
                                        return (
                                            <div
                                                style={{ borderRight: "none", background: "#fff", borderBottom: "1px solid #dedede" }}
                                                className='col-12 mb-3 p-3 d-flex flex-row justify-content-between align-items-center'
                                                key={"dvc" + idx}>
                                                <div className="col-2 align-items-center">
                                                    <span
                                                        className={
                                                            "avatar-title rounded-circle bg-white text-font-size-16"
                                                        }
                                                    >
                                                        {ele.status === "1" ?
                                                            <i className='bx bx-check-circle' style={{ color: 'green', fontSize: 20 }} /> :
                                                            <i className='bx bx-block' style={{ color: 'gray', fontSize: 20 }} />}
                                                    </span>

                                                </div>
                                                <div className='col-6'>
                                                    <h5 className="text-truncate mb-1 ">{ele.hostname}</h5>
                                                    {
                                                        (ele.status === "1" &&
                                                            signOutSuccess) ?
                                                            <p className='text-success mb-0'>Signed out successfully</p>
                                                            : ele.status !== "1" &&
                                                            <p className='mb-0'>Not activated</p>
                                                    }
                                                </div>
                                                <div className='col-4 align-items-end'>
                                                    {
                                                        ele.status === "1" && !triggerOtp &&
                                                        <>
                                                            {
                                                                signOutSuccess === false &&

                                                                <button className="btn btn-success" disabled={signoutLoad} onClick={() => signOutDevice()}>{signoutLoad && <LoadingOutlined />} {signoutLoad ? '...' : 'Sign out'}</button>

                                                            }

                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    triggerOtp &&
                                    <>
                                        <p>Please enter OTP sent to your registered email ID. Do not share OTP with others.</p>
                                        <div className="text-center otpBox">
                                            <AuthCode
                                                characters={6}
                                                allowedCharacters="^[0-9]"
                                                onChange={(session_otp) => setOtp(session_otp)}
                                                className="form-control form-control-lg text-center otpInput"
                                                pattern="[0-9]*"
                                                required
                                                inputStyle={{
                                                    padding: '7px',
                                                    borderRadius: '0.4rem',
                                                    fontSize: '1rem',
                                                    textAlign: 'center',
                                                    marginRight: '7px',
                                                    border: '1px solid rgb(206, 212, 218)',
                                                    textTransform: 'uppercase',
                                                }}
                                            />
                                        </div>
                                        <Row>
                                            <Col style={{ textAlign: 'end' }}>
                                                <h6 style={{ marginTop: '20px', cursor: 'pointer' }} onClick={() => signOutDevice()}>Resend OTP?</h6>
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light me-2" onClick={() => {
                                    setalreadyLogged(false);
                                    setLoggedInfo([])
                                    setuserInfo(null)
                                    setsignOutSuccess(false)
                                    settriggerOtp(false)
                                }}>Close</button>
                                <button type="button" className="btn btn-primary" disabled={verifyOtp || !triggerOtp} onClick={() => { proceedLogin() }}>{verifyOtp && <LoadingOutlined />} {verifyOtp ? 'Authenticating...' : 'Continue'}</button>
                            </div>
                            {
                                alertEnable &&
                                <Alert color={alertColor} className="mt-2" >{message}</Alert>
                            }


                        </div>

                        :
                        <>
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={formSubmit}
                                autoComplete="off"
                            >
                                <div>
                                    <div className="form-group">
                                        <div className='' style={{ marginBottom: "20px" }}>
                                            <div className='' style={{ marginBottom: "20px" }}>
                                                <h3 className="fontInter header-subtitle text-primary">Sign-Up / Login</h3>
                                            </div>
                                        </div>
                                        <Label className='inter-font m-0 fw-bold'>E-MAIL / MOBILE NUMBER</Label>
                                        <div className="inter-font font-size-14 text-secondary text-opacity-75">Enter your mobile number or e-mail to proceed</div>
                                        <div className='loginInputForm mt-3 border border-primary'>
                                            {
                                                enableCountryCode &&
                                                <Col md={4} style={{ minWidth: 150 }}>
                                                    <Select
                                                        showSearch
                                                        className="react-select-container me-2"
                                                        classNamePrefix="react-select"
                                                        placeholder='Select Country Code'
                                                        options={props.countryList}
                                                        value={{ country_list: defaultCountryCode }}
                                                        getOptionLabel={(option) => option.country_list}
                                                        onChange={(e) => { setdefaultCountryCode(e.code + " " + e.label); setCountryCode(e.code) }}
                                                    />
                                                </Col>
                                            }


                                            <AvField
                                                name="username"
                                                value={""}
                                                autoFocus
                                                className="inputComp"
                                                placeholder="Mobile No / E-Mail Address"
                                                // required
                                                onChange={(e) => { validateUsername(e.target.value) }}
                                                errorMessage={"Please enter Mobile No / E-Mail Address"}
                                            />
                                        </div>
                                        <div>
                                            {
                                                showPasswordBox &&
                                                <div className="form-group mt-4">
                                                    <div className='mt-2'>
                                                        <Label className='inter-font m-0 fw-bold mb-2'>PASSWORD</Label>
                                                        <AvField
                                                            name="password"
                                                            type="password"
                                                            autoFocus
                                                            className="form-control react-select-container"
                                                            required
                                                            placeholder="Enter Password"
                                                            errorMessage={"Please enter Password"}
                                                        />
                                                    </div>
                                                    <div className='d-flex justify-content-end'>
                                                        <span
                                                            onClick={() => { forgotPassword() }}
                                                            style={{
                                                                color: "#5e5e5e",
                                                                textDecoration: "underline",
                                                                cursor: "pointer",
                                                                "& :hover": {
                                                                    textDecoration: "underline"
                                                                },
                                                                fontSize: 14
                                                            }}
                                                        >
                                                            Forgot Password ?
                                                        </span>
                                                    </div>
                                                </div>
                                            }

                                            <p className='my-2'>By creating an account, you agree with our <Link to={"/termsandconditions"} role={"button"}>terms and conditions</Link> as well as <Link to="/privacyandpolicy" role={"button"}>privacy policy</Link> </p>
                                            <div className="mt-3 text-center">
                                            </div>
                                            <div className={"mt-3 pt-3 px-2"}>
                                                <button disabled={buttonLoader} className="btn br-5 btn-block waves-effect waves-light w-100 btn-primary mt-3" type="submit"> {buttonLoader && <LoadingOutlined />}  {buttonLoader ? 'Authenticating' : 'PROCEED'}</button>
                                            </div>
                                        </div>
                                        {
                                            alertEnable &&
                                            <Alert color={alertColor} className="mt-2" >{message}</Alert>
                                        }

                                    </div>
                                </div>
                            </AvForm>
                            <SocialMediaLogin onSuccessGoogle={props.onSuccessGoogle} onSuccessFacebook={props.onSuccessFacebook} onFailureGoogle={props.onFailureGoogle} />
                        </>

                }

            </div>);
    }
    if (openForgotPassword) {
        return (
            <ForgotScreen userEmail={userEmail} secretKey={props.secretKey} />
        )


    }
    else {
        return (
            <NewUserForm selectedCountryInfo ={defaultCountryCode} selectedCountrycode={countryCode} userInfo={registeredUserInfo} countryList={props.countryList} userEmail={userEmail} userNum={userNum} secretKey={props.secretKey} registeredUser={registeredUser} />
        )
    }
}

SignIn.propTypes = propTypes;
SignIn.defaultProps = defaultProps;
// #endregion

export default SignIn;