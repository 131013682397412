import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_THEME_IMAGE,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_PRELOADER,
  HIDE_RIGHT_SIDEBAR,
  SET_CART_COURSE_LIST_LENGTH,
  AUTH_USER_DATA,
  SET_BTN_LOAD,
  SET_INDEX_1,
  SET_INDEX_2,
  SET_INDEX_3
} from "./actionTypes"

export const changeLayout = layout => ({
  type: CHANGE_LAYOUT,
  payload: layout,
})

export const changePreloader = layout => ({
  type: CHANGE_PRELOADER,
  payload: layout,
})

export const changeLayoutWidth = width => ({
  type: CHANGE_LAYOUT_WIDTH,
  payload: width,
})

export const changeSidebarTheme = theme => ({
  type: CHANGE_SIDEBAR_THEME,
  payload: theme,
})


export const changeSidebarThemeImage = themeimage => ({
  type: CHANGE_SIDEBAR_THEME_IMAGE,
  payload: themeimage,
})

export const changeSidebarType = (sidebarType, isMobile) => {
  return {
    type: CHANGE_SIDEBAR_TYPE,
    payload: { sidebarType, isMobile },
  }
}

export const changeTopbarTheme = topbarTheme => ({
  type: CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
})

export const toggleRightSidebar = () => ({
  type: TOGGLE_RIGHT_SIDEBAR,
  payload: null,
})

export const showRightSidebar = (isopen) => ({
  type: SHOW_RIGHT_SIDEBAR,
  payload: isopen,
})

export const hideRightSidebar = () => ({
  type: HIDE_RIGHT_SIDEBAR,
  payload: null,
})
export const setCartCourseListLength = (length) => ({
  type: SET_CART_COURSE_LIST_LENGTH,
  payload: length,
});
export const setBtnLoad = (load) => ({
  type: SET_BTN_LOAD,
  payload: load,
});
export const setIndex1 = (idx) => ({
  type: SET_INDEX_1,
  payload: idx,
});
export const setIndex2 = (idx) => ({
  type: SET_INDEX_2,
  payload: idx,
});
export const setIndex3 = (idx) => ({
  type: SET_INDEX_3,
  payload: idx,
});



export const authUser = (user) => ({
  type: AUTH_USER_DATA,
  payload: user,
});