import React, { useEffect, useRef, useState  } from "react"
import { Link } from "react-router-dom"

import $ from 'jquery'


let { videojs, registerIVSTech, registerIVSQualityPlugin, IVSPlayer } = window
let player = "";

// Functional Component
const IVSVideoPlayerCRS = ({
    streamURLProps,
    playerID,
    playerPoster,
    hideLabel,
    updateSliderDelay

    }) => {    
    
    // //("streamURLPROPS",streamURLProps);
    // //("_playerID",playerID);
    const playerRef = useRef(null)

    let ivsPlayerPRO;
    let PlayerState;

    const videoJsOptions = {
      playbackRates: [0.25, 0.5, 1, 1.5, 2],
      techOrder: ["AmazonIVS"],
      autoplay: false,
      preload: "auto",
      muted: false,
      controls: false,
      controlBar: false
    }

    registerIVSTech(videojs)
    //registerIVSQualityPlugin(videojs)    

    useEffect( () => {        
        let player = videojs("courseVideo"+playerID, videoJsOptions);
        playerRef.current = player;
        // //("Rendering: ", playerRef.current);        
        player.src(streamURLProps);
        player.pause();
        player.currentTime(0);
        ivsPlayerPRO = player.getIVSPlayer()
        PlayerState = player.getIVSEvents().PlayerState  
        ivsPlayerPRO.addEventListener(PlayerState.PLAYING, () => {
          //("Playing");
          hideLabel("playing", player);
        })
        
        ivsPlayerPRO.addEventListener(PlayerState.BUFFERING, () => {
          
        })
      
        ivsPlayerPRO.addEventListener(PlayerState.ENDED, () => {
          hideLabel("playing", player);
          //updateSliderDelay(3000);
        })
      
        ivsPlayerPRO.addEventListener(PlayerState.IDLE, () => {
          //("Pause");
          hideLabel("playing", player);
        })
        
        var playPauseButton = player.addChild("component");
        var playPauseButtonDom = playPauseButton.el();
        playPauseButtonDom.innerHTML = "";
        playPauseButton.addClass("vjs-custom-button");
        playPauseButton.addClass("vjs-custom-plyPusebutton");
        playPauseButton.addClass("vjs-custom-play-button");
            
        playPauseButtonDom.onclick = function(){    
            var totalDuration = player.duration();
            //("duration: ", totalDuration);
            //updateSliderDelay(totalDuration*10);
            var element = this;
            if(!player.paused()){        
                element.classList.remove("vjs-custom-pause-button");
                element.classList.add("vjs-custom-play-button");
                player.pause();                
            }
            else {        
                element.classList.remove("vjs-custom-play-button");
                element.classList.add("vjs-custom-pause-button");        
                player.play(); 
            }
        }
        //initVideo();
    }, [streamURLProps])

    const initVideo = () => {       
      //player.enableIVSQualityPlugin();      
    }

    

    return (
      <video id={"courseVideo"+playerID} className="video-js vjs-crs-theme vjs-big-play-centered" poster={playerPoster} controls={!false} playsInline style={{marginBottom: "0"}}></video>
    )
}

export default IVSVideoPlayerCRS








//// 29-8-22 yesvin
// import React, { useEffect, useRef, useState  } from "react"
// import { Link } from "react-router-dom"

// import $ from 'jquery'


// let { videojs, registerIVSTech, registerIVSQualityPlugin, IVSPlayer } = window
// let player = "";

// // Functional Component
// const IVSVideoPlayerCRS = ({
//     streamURLProps,
//     playerName,
//     playerPoster,
//     hideLabel

//     }) => {    
    
//     //("streamURLPROPS",streamURLProps);
//     const playerRef = useRef(null)

//     let ivsPlayerPRO;
//     let PlayerState;

//     const videoJsOptions = {
//       playbackRates: [0.25, 0.5, 1, 1.5, 2],
//       techOrder: ["AmazonIVS"],
//       autoplay: false,
//       preload: "auto",
//       muted: false,
//       controls: false,
//       controlBar: false
//     }

//     registerIVSTech(videojs)
//     //registerIVSQualityPlugin(videojs)    

//     useEffect( () => {        
//         let player = videojs("courseVideo", videoJsOptions);
//         playerRef.current = player;
//         //("Rendering: ", playerRef.current);        
//         player.src(streamURLProps);
//         player.pause();
//         player.currentTime(0);
//         ivsPlayerPRO = player.getIVSPlayer()
//         PlayerState = player.getIVSEvents().PlayerState  
//         ivsPlayerPRO.addEventListener(PlayerState.PLAYING, () => {
//           //("Playing");
//           hideLabel("playing", player);
//         })
        
//         ivsPlayerPRO.addEventListener(PlayerState.BUFFERING, () => {
          
//         })
      
//         ivsPlayerPRO.addEventListener(PlayerState.ENDED, () => {
//           hideLabel("playing", player);
//         })
      
//         ivsPlayerPRO.addEventListener(PlayerState.IDLE, () => {
//           //("Pause");
//           hideLabel("playing", player);
//         })
        
//         var playPauseButton = player.addChild("component");
//         var playPauseButtonDom = playPauseButton.el();
//         playPauseButtonDom.innerHTML = "";
//         playPauseButton.addClass("vjs-custom-button");
//         playPauseButton.addClass("vjs-custom-plyPusebutton");
//         playPauseButton.addClass("vjs-custom-play-button");
            
//         playPauseButtonDom.onclick = function(){    
//             var element = this;
//             if(!player.paused()){        
//                 element.classList.remove("vjs-custom-pause-button");
//                 element.classList.add("vjs-custom-play-button");
//                 player.pause();                
//             }
//             else {        
//                 element.classList.remove("vjs-custom-play-button");
//                 element.classList.add("vjs-custom-pause-button");        
//                 player.play(); 
//             }
//         }
//         //initVideo();
//     }, [streamURLProps])

//     const initVideo = () => {       
//       //player.enableIVSQualityPlugin();      
//     }

    

//     return (
//         <video id="courseVideo" className="video-js vjs-crs-theme vjs-big-play-centered" poster={playerPoster} controls={!false} playsInline style={{marginBottom: "0"}}></video>
//     )
// }

// export default IVSVideoPlayerCRS