import React from 'react'
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Progress, Button } from 'antd'
import '../CSS/style.css'
// import { Link } from 'react-router-dom';
// import { useHistory } from "react-router-dom";
import Certification from 'pages/Certification';


// ({ questionsList })
export default class UserAnswers extends React.Component {
    constructor(props) {
        super(props)
        this.state={
            questionsList:[]
        }
    }

    componentDidMount(){
        let questionsList = this.props.questionsList
        console.log(questionsList,"questionsList")
        this.setState({questionsList})
        // var side_bar_course = JSON.parse(sessionStorage.getItem("side_bar_course"))
        // console.log(side_bar_course,"get_session_data")
    }
    handleBeforeUnload = (e) => {
        e.preventDefault()
        const message = "Are you sure you want to leave? All provided data will be lost."
        e.returnValue = message
        return message
    }

    retake_quiz=()=>{
        console.log("retqke quizzz")
        window.removeEventListener("beforeunload", this.handleBeforeUnload)
       document.location.href = ("/course/CourseInProgress") // retake the assessment test(quiz)

        // var retake_quiz = JSON.parse(sessionStorage.getItem("onClickLink"))
        // console.log(retake_quiz,"retake_quiz")
        // this.props.getCourseDetails(retake_quiz,"true")
        // this.props.history.push("/course/CourseInProgress"); 
        // this.props.history.push('/course/CourseInProgress')
        // window.open("/course/CourseInProgress")
    }

    proceed_nxt=(e)=>{
        console.log("course information")
        // document.location.href= ("/Certification")

        // this.props.getNextData('success !!!')

        var course_info_id = JSON.parse(sessionStorage.getItem("CourseDetails"))
        var course_info_name = JSON.parse(sessionStorage.getItem("CourseName"))
        console.log(course_info_id,"course!!",course_info_name)
        var course_name = course_info_name.name_of_the_course.trim().replace(/[^A-Z0-9]\s+/ig, "").replaceAll(' ', '-')
        document.location.href = ("/course/CourseDetails" + '?' + course_info_id + '?' + course_name)
        // retake the assessment test(quiz)
    }

    render(){
    return (
        <>      

            {
                this.state.questionsList.map((data, index) => (
                    <div key={index} >
                        <div className='questionStyle d-flex fs-18' style={{ justifyContent: 'space-between' }}>
                            <div>
                                <div className="m-3 fontInter d-flex align-items-center" role={"button"} >
                                    <div className={data.user_selected === null || data.user_selected === undefined || data.user_selected.length === 0 ? "questionUnAnswered me-2 d-flex align-items-center justify-content-center" : "questionAnswered me-2 d-flex align-items-center justify-content-center"}>{index + 1} </div><div style={{fontSize: '15px', fontWeight: '700'}}>{data.question}</div>
                                </div>
                                {
                                    ((data.user_selected !== undefined) && (data.user_selected !== null) && (data.question_type === "Multi-Choice")) && data.user_selected.map((op1, key) => (
                                        <div key={key} className="m-3 text-start fontInter d-flex" style={{fontSize: '15px'}}><i className='bx bx-chevron-right me-2' style={{fontSize: 'x-large'}}></i>{key + 1}. {op1.option}</div>
                                    ))                                    
                                }
                                {
                                    ((data.user_selected !== undefined) && (data.user_selected !== null) && data.question_type === "Single-Choice") && <div className="m-3 text-start fontInter d-flex" style={{fontSize: '15px'}}><i className='bx bx-chevron-right me-2' style={{fontSize: 'x-large'}}></i>{data.user_selected} </div>
                                }
                                {
                                    ((data.user_selected !== undefined) && (data.user_selected !== null) &&
                                    <div className='align-self-center'>
                                        {
                                            data.is_correct ?
                                            // <div className='d-flex align-items-center justify-content-left m-3'><CheckOutlined value="Correct" className="fs-10" style={{color: '#138700'}}/> <div className="ms-2" style={{fontSize: '21px', color: '#138700', fontWeight: '700'}}>Correct</div></div> :
                                            // <div className='d-flex align-items-center justify-content-left m-3'><CloseOutlined value="Incorrect" className="fs-10" style={{color: '#DC0F2E'}} /> <div className="ms-2"  style={{fontSize: '21px', color: '#DC0F2E', fontWeight: '700'}}>Incorrect</div></div>
                                            <div className='d-flex align-items-center justify-content-left m-3'><CheckOutlined value="Correct" className="fs-10" style={{color: '#00953B'}}/> <div className="ms-2" style={{fontSize: '21px', color: '#00953B', fontWeight: '700'}}>Correct</div></div> :
                                            <div className='d-flex align-items-center justify-content-left m-3'><CloseOutlined value="Incorrect" className="fs-10" style={{color: '#EA0029'}} /> <div className="ms-2"  style={{fontSize: '21px', color: '#EA0029', fontWeight: '700'}}>Incorrect</div></div>
                                        }
                                    </div>
                                    )
                                }
                                {
                                    ((data.question_type === "Multi-Choice") && (data.user_selected !== null) && (data.user_selected.length === 0)) && <div className="m-3 text-start fontInter" ><i className='bx bx-chevron-right me-2'></i>-- --</div>
                                }
                                {
                                    ((data.question_type === "Single-Choice") && (data.user_selected === null)) && <div className="m-3 text-startfontInter" ><i className='bx bx-chevron-right me-2'></i>-- --</div>
                                }
                                {/* {
                                    ((data.user_selected !== undefined) && (data.user_selected !== null) && (data.user_selected.length === 0)) && <div className="m-3 text-start" >N/A</div>
                                } */}
                            </div>                                                        
                        </div>
                        <hr />                        
                    </div>

                ))
            }
            <div className="row">                               
                <div className="col-md-12 mt-5">
                <div className="d-flex justify-content-left">
                {/* <div className=""><Button className='mx-2 quizCntrlBtns quizCntrlBtnsOutlined' onClick={() => this.setState({ })}>Retake Quiz</Button></div> */}
                {/* <div className=""><Button className='mx-2 quizCntrlBtns quizCntrlBtnsFill' onClick={() => this.setState({ })}>Proceed</Button></div> */}
             <Button className='mx-2 quizCntrlBtns quizCntrlBtnsOutlined' style={{width: '130px', height: '40px'}} onClick={this.retake_quiz}>Retake Quiz</Button>
                <div className=""><Button className='mx-2 quizCntrlBtns quizCntrlBtnsFill' style={{width: '125px', height: '40px'}} onClick={(e)=>{this.proceed_nxt(e)}}>Proceed</Button></div>

                </div>
                </div>
                {
                    this.state.show_certificate &&
                    <Certification/>
                }

            </div> 
        </>
    )
}

}













// magarisa old code 16/12/22
// import React from 'react'
// import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
// import { Progress, Button } from 'antd'
// import '../CSS/style.css'

// export default function UserAnswers({ questionsList }) {
//     return (
//         <>      

//             {
//                 questionsList.map((data, index) => (
//                     <div key={index} >
//                         <div className='questionStyle d-flex fs-18' style={{ justifyContent: 'space-between' }}>
//                             <div>
//                                 <div className="m-3 fontInter d-flex align-items-center" role={"button"} >
//                                     <div className={data.user_selected === null || data.user_selected === undefined || data.user_selected.length === 0 ? "questionUnAnswered me-2 d-flex align-items-center justify-content-center" : "questionAnswered me-2 d-flex align-items-center justify-content-center"}>{index + 1}</div><div>{data.question}</div>
//                                 </div>
//                                 {
//                                     ((data.user_selected !== undefined) && (data.user_selected !== null) && (data.question_type === "Multi-Choice")) && data.user_selected.map((op1, key) => (
//                                         <div key={key} className="m-3 text-start fontInter" ><i className='bx bx-chevron-right me-2'></i>{key + 1}. {op1.option}</div>
//                                     ))                                    
//                                 }
//                                 {
//                                     ((data.user_selected !== undefined) && (data.user_selected !== null) && data.question_type === "Single-Choice") && <div className="m-3 text-start fontInter"><i className='bx bx-chevron-right me-2'></i>{data.user_selected} </div>
//                                 }
//                                 {
//                                     ((data.user_selected !== undefined) && (data.user_selected !== null) &&
//                                     <div className='align-self-center'>
//                                         {
//                                             data.is_correct ?
//                                             <div className='d-flex align-items-center justify-content-left m-3'><CheckOutlined value="Correct" className="fs-10 text-success" /> <div className="text-success ms-2 textBold">Correct</div></div> :
//                                             <div className='d-flex align-items-center justify-content-left m-3'><CloseOutlined value="Incorrect" className="fs-10 text-danger" /> <div className="text-danger ms-2 textBold">Incorrect</div></div>
//                                         }
//                                     </div>
//                                     )
//                                 }
//                                 {
//                                     ((data.question_type === "Multi-Choice") && (data.user_selected !== null) && (data.user_selected.length === 0)) && <div className="m-3 text-start fontInter" ><i className='bx bx-chevron-right me-2'></i>-- --</div>
//                                 }
//                                 {
//                                     ((data.question_type === "Single-Choice") && (data.user_selected === null)) && <div className="m-3 text-startfontInter" ><i className='bx bx-chevron-right me-2'></i>-- --</div>
//                                 }
//                                 {/* {
//                                     ((data.user_selected !== undefined) && (data.user_selected !== null) && (data.user_selected.length === 0)) && <div className="m-3 text-start" >N/A</div>
//                                 } */}
//                             </div>                                                        
//                         </div>
//                         <hr />                        
//                     </div>

//                 ))
//             }
//             <div className="row">                               
//                 <div className="col-md-12">
//                 <div className="d-flex justify-content-left">
//                 <div className=""><Button className='mx-2 quizCntrlBtns quizCntrlBtnsOutlined' onClick={() => this.setState({  })}>Retake Quiz</Button></div>
//                 <div className=""><Button className='mx-2 quizCntrlBtns quizCntrlBtnsFill' onClick={() => this.setState({ })}>Proceed</Button></div>
//                 </div>
//                 </div>

//             </div> 
//         </>
//     )
// }
