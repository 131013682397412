import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table, Badge } from "reactstrap";
import { map } from "lodash";
import logoLight from "../../../assets/images/kalakriya_logo.png";

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import canvas from 'html2canvas';
import urlSocket from "helpers/urlSocket";
import moment  from 'moment'
import RazorpayLogo from '../../../assets/images/Razorpay-logo.png'
import StripeLogo from '../../../assets/images/Stripe-logo.png'
import * as html2pdf from 'html2pdf.js';

const InvoiceDetail = (props) => {
  document.title = "Invoice Detail | Kalakriya";
  const pageDiv = useRef(null);
  const [invoiceDetail, setInvoiceDetail] = useState(JSON.parse(sessionStorage.getItem("invoiceData")));
  const [authUser, setauthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")))
  const [showButton, setShowButton] = useState(true);

  const printInvoice = () => {
    setShowButton(false)
    setTimeout(() => {
    window.print();
    }, 100);
  };

  const downloadInvoice = async () => {
   setShowButton(false)

    setTimeout(() => {      
    try {
      const content = pageDiv.current;
      const doc = new jsPDF();
      const contentElement = document.getElementById('content');
      const options = {
        filename: `${invoiceDetail._id}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'pt', format: 'letter', orientation: 'portrait' },
        margin: 5
      };
      contentElement.style.fontSize = '10px';
      html2pdf().set(options).from(contentElement).save();
    } catch (error) {
      
    }
  }, 100);

  setTimeout(() => {
    const contentElement = document.getElementById('content');
    contentElement.style.fontSize = '';
    setShowButton(true)    
  }, 500);
  };

  return (
    <React.Fragment>
      <div className="page-content" style={{ marginTop: 10 }}>
        <Container >
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h3 className='textCapitalize' style={{ fontFamily: "poppins,sans-serif", color: '' }}>{'INVOICE'}</h3>
          </div>
          <div ref={pageDiv} id='content'>
            {
              showButton &&
              <Row className="justify-content-end mb-3">
                <div>
                  <Link
                    to="/my-orders"
                    className="btn btn-primary btn-md"
                  >
                    <i className="mdi mdi-arrow-left me-1" />Back to my orders{" "}
                  </Link>
                </div>
              </Row>
            }
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="invoice-title">
                      <span className="float-end font-size-11">Order ID : <span className="font-size-15" style={{ fontWeight: 500 }}>{invoiceDetail.order_id}</span></span>
                      <div className="auth-logo mb-4">
                        <img src={logoLight} alt="logo-light" className="logo-light-element" height="40" />
                      </div>
                    </div>
                    <hr />
                    <Row>
                      <Col sm="6">
                        <address>
                          <strong>Billed To:</strong>
                          <br />
                          <span className="font-size-11">Name : <span className="font-size-15" style={{ fontWeight: 500 }}>{authUser.full_name}</span></span>
                          <br />
                          <span className="font-size-11">Email : <span className="font-size-15" style={{ fontWeight: 500 }}>{authUser.email}</span></span>
                          <br />
                          <span className="font-size-11">Phone : <span className="font-size-15" style={{ fontWeight: 500 }}>{authUser.full_ph_no}</span></span>
                          <br />
                        </address>
                      </Col>
                      <Col sm="6" className="text-sm-end">
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" className="mt-3">
                        <address>
                          <strong>Payment Method:</strong>
                          <br />
                          <img src={invoiceDetail.payment_type === "0" ? RazorpayLogo : StripeLogo} height={45} />
                          <br />
                          <span className="font-size-11">{invoiceDetail.payment_type === "0" ? "Payment" : "Payment Intern"}  ID : <span className="font-size-15" style={{ fontWeight: 500 }}>{invoiceDetail.payment_type === "0" ? invoiceDetail.payment_info.razorpay_payment_id : invoiceDetail.payment_info.invoice_id}</span></span><br/>
                          {/* {
                              invoiceDetail.subscr_cycle !== null &&
                              <>
                          <span className="font-size-11">Renewed for year : <strong>{invoiceDetail.subscr_cycle}</strong></span><br/>
                          </>
                          } */}
                          {/* <span className="font-size-11">Subscription period : <strong>{moment(invoiceDetail.payment_info.created_at * 1000).format('DD-MMM-YYYY')} - {(() => {
                            const originalDate = new Date(invoiceDetail.payment_info.created_at * 1000);
                            const newDate = new Date(originalDate);
                            newDate.setFullYear(originalDate.getFullYear() + 1);
                            newDate.setDate(newDate.getDate() - 1);

                            return moment(newDate).format('DD-MMM-YYYY');
                          })()}</strong></span> */}
                        </address>
                      </Col>
                      <Col sm="6" className="mt-3 text-sm-end">
                        <address>
                          <strong>Order Date:</strong>
                          <br />
                          {new Intl.DateTimeFormat('en-US', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric'
                          }).format(new Date(invoiceDetail.order_info?.created_at * 1000))}
                          
                          <br />
                          <span style={{fontSize:'13px'}} className='text-primary'>(Date as per Indian Standard Time.)</span>
                          <br />
                        </address>
                      </Col>
                    </Row>
                    <div className="py-2 mt-3">
                      <h3 className="font-size-15 fw-bold">Order summary</h3>
                    </div>
                    <div className="table-responsive">
                      <Table className="table-nowrap">
                        <thead>
                          <tr >
                            <th className="bg-white">No.</th>
                            <th className="bg-white">Item</th>
                            <th className="bg-white text-end ">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {map(
                            invoiceDetail.customer_products,
                            (item, key) => (
                              <tr key={key}>
                                <td className="bg-white">{key + 1}</td>
                                <td className="bg-white">
                                 
                                  <div>
                                    <div> {item.name_of_the_course}</div>
                                    <div style={{fontSize:"13px"}}>Course Access Validity - 1 year</div>
                                    <div style={{fontSize:"13px"}}>Course Access Period - {moment(invoiceDetail.payment_info.created_at * 1000).format('DD-MMM-YYYY')} - {(() => {
                            const originalDate = new Date(invoiceDetail.payment_info.created_at * 1000);
                            const newDate = new Date(originalDate);
                            newDate.setFullYear(originalDate.getFullYear() + 1);
                            newDate.setDate(newDate.getDate() - 1);

                            return moment(newDate).format('DD-MMM-YYYY');
                          })()}</div>

                                  </div>
                                  </td>
                                <td className="bg-white text-end">{invoiceDetail.payment_type === '0' ? (
                                  <i className="bx bx-rupee" />
                                ) : (
                                  <>US<i className="bx bx-dollar" /></>
                                )}{invoiceDetail.payment_type === '0' ? item.inr_price : item.usd_price}</td>
                              </tr>
                            )
                          )}
                          <tr>
                            <td colSpan="2" className="bg-white  text-end">
                              <strong>Status</strong>
                            </td>
                            <td className="bg-white text-end">
                              <Badge className={"font-size-12 badge-soft-" + (invoiceDetail.status === "2" ? "success" : "danger")} >
                                {invoiceDetail.status === "2" ? "Payment Completed" : "Payment Pending"}
                              </Badge>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" className="bg-white border-0 text-end">
                              <strong>Total</strong>
                            </td>
                            <td className="bg-white border-0 text-end">
                              <h4 className="m-0">
                                {invoiceDetail.payment_type === '0' ? (
                                  <i className="bx bx-rupee" />
                                ) : (
                                  <>US<i className="bx bx-dollar" /></>
                                )}
                                {invoiceDetail.order_info.amount / 100}
                                <i className="bx bx-purchase-tag-alt ms-2" style={{ color: '#7900E9' }} />
                              </h4></td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    {showButton &&
                      <div className="d-print-none">
                        <div className="float-end">
                          <div
                            to="#"
                            onClick={downloadInvoice}
                            className="btn btn-success btn-md"
                          >
                            <i className="bx bxs-download font-size-15" />
                          </div>
                        </div>
                      </div>
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

InvoiceDetail.propTypes = {
  match: PropTypes.any,
};

export default InvoiceDetail;


