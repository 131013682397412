import React, { useState } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Progress, Row } from "reactstrap"
import PropTypes from 'prop-types';
import noImg from '../../assets/images/no-img.jpg';
import ribbon from '../../assets/images/icons/combo.png';
import moment from 'moment';
import img1 from "../../assets/images/bg/bg_2.png"



const textStyle = {
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};


function MoreViewCard({
    toViewMore
}) {



    return (
        <div>
            
            <div style={{ borderRadius: 30, backgroundColor: "rgba(255,255,255,0.6)", margin: 10, cursor: "pointer", border: "1px solid #dedede" }}
                onClick={() => {
                    toViewMore()
                }}
            >

                <div className='bg-transition'
                    style={{
                        borderRight: "1px solid #dedede",
                        backgroundImage: `url(${img1})`,
                        backgroundRepeat: "no-repeat",
                        backgroundOrigin: "border-box",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        height: 200,
                        borderRadius: "30px 30px 10px 10px",

                    }}
                ></div>
                <div style={{ display: "flex", flexGrow: 1, flexDirection: "column", justifyContent: "flex-start", marginTop: 15, padding: "0 20px", minHeight: 150 }}>
                <div className='textOverFlowEllipsisHeader' style={{ fontSize: 15, lineHeight: 1.2, fontWeight: "bold" }} >Click here to view more subscribed courses..</div>
                </div>
            </div>
        </div>

    )
}

export default MoreViewCard
MoreViewCard.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    description_of_the_course: PropTypes.string,
    likes: PropTypes.number,
    comp_perc: PropTypes.number,
    pending: PropTypes.number,
    courseDetails: PropTypes.func,
};
