import React, { Component } from 'react'
import { Breadcrumb, BreadcrumbItem, Container, Row, Col, Button, CardBody, Progress, Spinner } from "reactstrap"
import { withRouter, Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import { useDispatch, connect } from "react-redux";

import SimpleBar from "simplebar-react"
import urlSocket from '../../helpers/urlSocket'
import CourseTree from "./Component/CourseTree"

import SortableTree, { getFlatDataFromTree } from 'react-sortable-tree/dist/index.cjs.js'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';

import { setCartCourseListLength } from '../../store/layout/actions'

import IVSVideoPlayerCRS from "./Component/IVSVideoPlayer-courseDetails"
import IVSVideoPlayer from './Component/IVSVideoPlayer'
import './CSS/style.scss'
import "./CSS/vjs-custom-theme.css"
import moment from 'moment';
import validateSession from 'routes/validateSession';

import _ from "lodash"
import { error } from 'toastr';

let { videojs, registerIVSTech, registerIVSQualityPlugin, IVSPlayer } = window


class CourseDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            courseSelected: null,
            course_info_id: null,
            org_name_of_the_course: null,
            name_of_the_course: null,
            course_flow_id: null,
            course_status: null,
            course_content: [],
            loaded: false,
            languageSelected: 1,
            multiAngleMode: false,
            videoAngle: 0,
            videoURL: null,
            multiAngleVideos: [],
            selectedContent: null,
            loadPlayer: null,
            thumbnailID: 0,
            onlyContent: null,
            axiosTimeout: false,
            videoDuration: 0,
            apiRequesting: false,
            whenContentLoading: false,
            courseAction: null,
            selected_pos:0,
            selectedContentIndex:0,
            videoPlayer:null,
            windowSize:null
        }
        this.refDiv = React.createRef()
        this.contentDiv = React.createRef()
    }



    async componentDidMount() {
        var authuser = JSON.parse(sessionStorage.getItem("authUser"))
        var location = window.location.href;
        var splitString = location.split("?");
        var name_of_the_course = splitString[splitString.length - 1];
        var course_id = splitString[splitString.length - 2];
        var decoded_name_of_the_course = decodeURIComponent(name_of_the_course);
        let breadCrumb = [
            { label: 'Dashboard', link: '/dashboard', course:false },
            { label: decoded_name_of_the_course, link: '/course/CourseDetails?' + course_id + '?' + name_of_the_course, course:true  },
        ];
        var getconfsession = JSON.parse(localStorage.getItem("getconfsession"));
        let baseurl = getconfsession ? getconfsession.baseurl : ""

        var window_size = window.innerWidth

        window.addEventListener('resize', this.handleResize);

        this.setState({
            course_id,
            name_of_the_course,
            subscriber: authuser,
            breadCrumb,
            baseurl,
            windowSize:window_size
        }, () => {
            this.fetchCourseInfo(course_id)
        })

    }

    handleResize = () => {
        this.setState({
            windowSize:window.innerWidth
        })
      };

    //   processNestedData = (nestedData)=> {
    //     _.forEach(nestedData, (item,pos) => {
    //       if (_.isArray(item.children) && !_.isEmpty(item.children)) {
    //          processNestedData(item.children,request_document_id,content_status,current_time);
    //       }
    //       else{
    //         //   if (item.document_id === request_document_id) {
    //             // if(item.content_status !== 2){
    //               item.content_status = content_status
    //               item.current_time = current_time
    //             // }
                  
    //         //   }
    //       }
    //     });
    //     return nestedData
    //   };
    

     getParentIndex=(data, childIndex)=> {
        for (let i = childIndex - 1; i >= 0; i--) {
            if (data[i].children && data[i].children.includes(data[childIndex])) {
                return i;
            }
        }
        return null; // Return null if parent is not found
    }
    



    treeDataToFlat = (treeData) => {
        var array_node_data = []
        var getNodeKey = ({ treeIndex }) => treeIndex
        var flatData = getFlatDataFromTree({ treeData: treeData, getNodeKey, ignoreCollapsed: false })

        var nodeIndex = 0
        //   await this.processNestedData()
        flatData.map((data_flat, flat_index) => {
            if (data_flat.node.document_type === 1) {
                data_flat.node["reference_id"] = flat_index
                data_flat.node["tree_index"] = nodeIndex
                array_node_data.push(data_flat.node)
            }
            if(flat_index !== 0 && data_flat.node.document_type !== 1) nodeIndex = nodeIndex + 1
        })

        return array_node_data
    }


    fetchCourseInfo = (course_id) => {
        this.setState({
            axiosTimeout: false
        })
        try {
            urlSocket.post('mngsubcrs/get_course_info', { course_id: course_id, subscriber_id: this.state.subscriber._id })
                .then(response => {
                    if (response.data.response_code === 500) {
                        var filterFlatData = this.treeDataToFlat(response.data.data.course_content)
                        let cartCountLength = response.data.cart_count
                        this.setState({
                            courseSelected: response.data.data,
                            flatData: filterFlatData,
                            totalContent:filterFlatData.length,
                            resumeData: response.data.data.last_accessed ? response.data.data.last_accessed : null,
                            resumeDataTiming: response.data.data.last_accessed ? response.data.data.last_accessed.current_time : null,
                            subscribed: response.data.is_subscribed,
                            apiRequesting: false,
                            loaded: true,
                        }, () => {
                            this.setState({
                                contentHeight: this.contentDiv.current.clientHeight,
                            })
                        })
                        localStorage.removeItem("CourseDetails")
                        this.props.dispatch(setCartCourseListLength(cartCountLength));

                    }

                })
                .catch((error) => {
                    this.setState({
                        axiosTimeout: true,
                        apiRequesting: false,
                    })
                })
        } catch (error) {
            this.setState({
                axiosTimeout: true,
                apiRequesting: false,
            })
        }

    }

    resetCourse = () => {
        if (this.state.videoPlayer) {
            this.state.videoPlayer.dispose()
        }
        this.setState({
            selectedContent: null,
            contentData: null,
            contentStatus: null,
            contentDuration:null,
            contentName: null,
            contentType: null,
            thumbnailID: null,
            whenContentLoading: null,
            contentUniquedId: null,
            selectedContentIndex: null,
            disablePrevious: null,
            disableNext: null,        
            loadPlayer: null,
            videoPlayer:null
        })
    }

    convertToSeconds = (time) => {

        var hms = time;   // your input string
        var a = hms.split(':'); // split it at the colons

        // minutes are worth 60 seconds. Hours are worth 60 minutes.
        var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
        return seconds
    }


    onloadContent = async(data, index, cAction) => {
        var express_session = JSON.parse(localStorage.getItem('express-session'))
        if(express_session){
            var result = await validateSession(express_session)
            if(JSON.parse(sessionStorage.getItem("authUser"))===null){
             this.props.history.push('/home')   
            }
        }
       
        try {
            if (data.reference_id !== this.state.contentUniquedId) {
                var totalContent = this.state.flatData.length - 1
                if (this.state.videoPlayer) {
                    this.state.videoPlayer.dispose()
                }
                this.setState({ loadPlayer: false, whenContentLoading: true, courseAction: cAction })
                var englishIdx = _.findIndex(data.content[0].language, { language_id: this.state.subscriber.lang_pref.language_id })
                if (englishIdx !== -1) {
                    var content = data.content[0].language[englishIdx]
                }
                var getCurrentIndex = this.getContentIndexPosition(data)
                if (data.document_type === 1) {
                    setTimeout(() => {
                        this.setState({
                            selectedContent: content.media_files,
                            contentData: data,
                            contentStatus: data.content_status,
                            contentDuration:data.crs_mins,
                            contentName: data.content[0].content_name,
                            contentType: content.content_type === 1 ? "VIDEO" : content.content_type === 2 && "AUDIO",
                            isPreviewAvailable:data.content[0].avl_preview,
                            previewStartTime:this.convertToSeconds(data.content[0].preview_start_time),
                            previewEndTime:this.convertToSeconds(data.content[0].preview_end_time),
                            thumbnailID: this.state.thumbnailID + 1,
                            whenContentLoading: true,
                            contentUniquedId: data.reference_id,
                            selectedContentIndex: getCurrentIndex,
                            disablePrevious: getCurrentIndex === 0 ? true : false,
                            disableNext: getCurrentIndex === totalContent ? true : false,            
                            loadPlayer: true,
                            englishIdx
                        })
                    }, 100);
                    setTimeout(() => {
                        this.setState({
                            whenContentLoading: false
                        })
                    }, 1000);
    
                }
            }
        } catch (error) {
        }
      
    }


    contentPreviewHistory=(data)=>{
        try {
            urlSocket.post('content_info/create-preview-history',{
                content_data : data,
                subscriber_info : this.state.subscriber,
                course_info : this.state.courseSelected
            }).then((response)=>{
            })
            
        } catch (error) {
            
        }




    }

    contentInformation = (data) => {
        this.setState({
            videoDuration: data.duration
        })
    }

    getContentIndexPosition = (data) => {
        return _.findIndex(this.state.flatData, { reference_id: data.reference_id })
    }

    loadPNVideos = (action) => {
        var getCurrentIndex = this.getContentIndexPosition(this.state.contentData)
        var totalContent = this.state.flatData.length

        

        var PNIndex = (action === "NEXT" && (getCurrentIndex + 1) === totalContent) ? totalContent :
            (action === "PREVIOUS" && (getCurrentIndex) === 0) ? 0 :
                (action === "NEXT" && (getCurrentIndex + 1) < totalContent) ? getCurrentIndex + 1 :
                    (action === "PREVIOUS" && (getCurrentIndex + 1) <= totalContent) ? getCurrentIndex - 1 : getCurrentIndex


        if (PNIndex !== totalContent) {
            this.onloadContent(this.state.flatData[PNIndex], PNIndex, null)
            this.setState({
                contentUniquedId: null,
                disablePrevious: PNIndex === 0 ? true : false,
                disableNext: PNIndex === totalContent ? true : false,
            })
        }
        else {
            this.setState({
                selectedContent: null,
                loadPlayer: null
            })
        }
    }

    replaySameVideo = () => {
        this.setState({
            contentUniquedId:null
        }, () => {
            this.onloadContent(this.state.contentData, this.state.selectedContentIndex, null)
        })
    }

    resumeCourse = (action) => {

        if (action === "BEGINING") {
            var getCurrentIndex = 0
            var totalContent = this.state.flatData.length
            this.onloadContent(this.state.flatData[getCurrentIndex], getCurrentIndex, action)
            this.setState({
                contentUniquedId: null,
                disablePrevious: getCurrentIndex === 0 ? true : false,
                disableNext: (getCurrentIndex + 1) === totalContent ? true : false,
            })
        }

        if (action === "RESUME") {
            var getCurrentIndex = this.getContentIndexPosition(this.state.resumeData)
            var totalContent = this.state.flatData.length
            this.onloadContent(this.state.flatData[getCurrentIndex], getCurrentIndex, action)
            this.setState({
                contentUniquedId: null,
                disablePrevious: getCurrentIndex === 0 ? true : false,
                disableNext: (getCurrentIndex + 1) === totalContent ? true : false,
            })
        }
    }

    timeConvert(totalSeconds) {

        if(!totalSeconds) return ""
        const secs = totalSeconds;
        const duration = moment.duration(secs, 'seconds');
 
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();
        const seconds = duration.seconds();
 
        const result = `${hours} hrs ${minutes} min ${seconds} sec`;
 
        return result;



    }

    toHoursAndMinutes = (totalSeconds) => {
        if(!totalSeconds) return ""
        // const totalMinutes = Math.floor(totalSeconds / 60);
        // const seconds = totalSeconds % 60;
        // const hours = Math.floor(totalMinutes / 60);
        // const minutes = totalMinutes % 60;
        // var result = hours !== 0 ? hours + " hr " + minutes + " mints" + Math.floor(seconds) + " secs" : minutes !== 0 ? minutes + " mints " + Math.min(seconds-1) + " secs" : Math.floor(seconds) + " secs"
        // return result
        const secs = totalSeconds;
        const duration = moment.duration(secs, 'seconds');
 
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();
        const seconds = duration.seconds();
 
        const result = `${hours} hrs ${minutes} min ${seconds} sec`;
 
        return result;
    }


    updateVideoStatus = (data, selectedContent) => {
        var id = this.state.courseSelected._id
        var document_id = selectedContent.document_id
        var content_status = data.status
        var current_time = data.currentTime
        var selected_idx= selectedContent.tree_index
        // this.state.selected_pos
        var reference_id = selectedContent.reference_id

        this.setState({
            axiosTimeout: false
        })
        // //(reference_id,selected_idx,document_id,'document_id')
        try {
           urlSocket.post("content_info/update-content", {
                id, document_id, content_status, current_time ,selected_idx,reference_id
            })
                .then(response => {
                    if (response.data.response_code === 500) {
                        var filterFlatData = this.treeDataToFlat(response.data.data.course_content)
                        var data = response.data.data.course_content[selected_idx].children
                        var updatedContent = filterFlatData[this.state.selectedContentIndex]
                        this.setState({
                            courseSelected: response.data.data,
                            contentData: updatedContent,
                            contentStatus: updatedContent.content_status,
                            flatData: filterFlatData,
                            resumeData: updatedContent,
                            resumeDataTiming: updatedContent.current_time
                            // selectedContent : content.media_files
                        }, () => {
                        })
                    }
                })
                .catch(error => {
                    this.setState({
                        axiosTimeout: true
                    })
                })

        } catch (error) {
            this.setState({
                axiosTimeout: true
            })
        }
    }

    handleAddtoCart = () => {
        this.setState({
            apiRequesting: true
        })
        var courseSelected = _.cloneDeep(this.state.courseSelected)
        try {
            var annual_plan = courseSelected.inr_price
            courseSelected["course_price"] = annual_plan
            this.setState({
                courseSelected
            },()=>{
                // console.log(this.state.courseSelected,'courseSelected')
            urlSocket.post('customer_basket/add-to-customer-cart', {
                user_id: this.state.subscriber._id,
                course: this.state.courseSelected,
                annual_plan
            })
                .then(response => {
                    if (response.data.response_code === 500) {
                        this.fetchCourseInfo(this.state.courseSelected._id)
                    }

                })
                .catch(error => {
                    this.setState({
                        apiRequesting: false,
                    })
                })
            })
        } catch (error) {
            this.setState({
                apiRequesting: false,
            })
        }
    }

    handleRemoveFromCart = () => {
        this.setState({
            apiRequesting: true
        })
        try {
            urlSocket.post('customer_basket/remove-from-customer-cart', {
                user_id: this.state.subscriber._id,
                course_id: this.state.courseSelected._id,
                session_id: this.state.courseSelected.basket_session_id
            })
                .then((response) => {
                    if (response.data.response_code === 500) {
                        this.fetchCourseInfo(this.state.courseSelected._id)
                    }
                })
                .catch(error => {
                    this.setState({
                        apiRequesting: false,
                    })
                })
        } catch (error) {
            this.setState({
                apiRequesting: false,
            })
        }

    }


    updateLastAccess=(data,contentInfo)=>{
        var id = this.state.courseSelected._id
        try {
            urlSocket.post('content_info/update-last-access-history',{
                id,contentInfo
            }).then((response)=>{

            })
        } catch (error) {
            
        }
    }

    render() {
        let { courseSelected } = this.state
        if (this.state.loaded) {

            let courseCompletedPercentage = this.state.subscribed ? ((courseSelected.no_of_content_completed / courseSelected.no_of_content) * 100) : 0
            // console.log(this.state.courseSelected,'this.state.courseSelected')
            return (
                <React.Fragment>
                    <MetaTags>
                    <title>Kalakriya - {this.state.courseSelected.name_of_the_course} adavus, kalakshetra bani - Slokam Mandalas Hastas & Adavus, Alarippu, Jathiswaram, Shabdam, Varnam & Keerthanam, hastas, bhedas,Pushpanjali, Alarippu, Kavuthuvam, Thodayamangalam & Mallari, Navasandhi Kavuthuvam, Jathiswaram, Sabdam, Varnam video, Varnam & Jathis audio, bharatanatyam Abhinaya, Navarasa by Kalanidhi Narayanan, padams and javalis, thillana by priyadarsini govind, margam by priyadarsini govind, nattuvangam</title>
                    </MetaTags>
                    {/* <MetaTags>
                        <title>Page 1</title>
                        <meta name="description" content="Some description." />
                        <meta property="og:title" content="Kalakriya" />
                        <meta property="og:url" content="http://localhost.com:3000/course/CourseDetails?65b0ecf9907129f1967986a0?Abhinaya - Demonstration & Recital - the beauty & breadth"></meta>
                        <meta property="og:image" content="https://img-c.udemycdn.com/course/480x270/567828_67d0.jpg" />
                        <meta property="og:image:secure_url" content="https://img-c.udemycdn.com/course/480x270/567828_67d0.jpg"></meta>
                    </MetaTags> */}
                    <div className="page-content" style={{ marginTop: '10px', height: "calc(100vh)" }} >
                        <Container fluid style={{ maxWidth: 1680, width: "100%" }}>
                            <Row>
                                <Col className='col-auto d-none d-xs-none d-sm-none d-md-none d-lg-block' style={{ height: "93vh", overflow: "scroll", }} >
                                    <div style={{ width: 380 }}>
                                        <div>
                                            <h4 className='quicksand-font' >Course Material</h4>
                                        </div>
                                        <div>
                                            <SimpleBar className="h-100 " ref={this.refDiv} >
                                                <CourseTree
                                                    onClickLink={(data, index) => this.onloadContent(data, index, null)}
                                                    subscribed={this.state.subscribed}
                                                    course_content={this.state.courseSelected.course_content}
                                                    contentUniquedId={this.state.contentUniquedId}
                                                    selectedIndex={(pos)=>{this.setState({selected_pos :pos})}}
                                                />
                                            </SimpleBar>
                                        </div>
                                    </div>
                                </Col>
                                <Col className='d-flex flex-1 flex-column' style={{ height: "90vh", overflow: "scroll", border: 0 }}>
                                    <div className='d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center  mb-3'>
                                        <Breadcrumb >
                                            {
                                                this.state.breadCrumb.map((data, i) => {
                                                    return <BreadcrumbItem style={{cursor:"pointer"}} key={i}><Link to={data.link}  onClick={() => {
                                                        data.course && this.resetCourse()
                                                    }}>{data.label}</Link></BreadcrumbItem>
                                                })
                                            }
                                        </Breadcrumb>
                                        <div className='d-flex flex-row align-items-center'>


                                        <div className='me-3'><span className='text-primary' onClick={()=>{this.props.history.push("/landing")}} style={{fontSize:"14px" ,cursor:"pointer"}}>Back</span></div>
                                        {
                                            this.state.subscribed &&
                                            <div className='d-flex flex-column'>
                                                <div style={{ fontSize: 14, marginBottom: 5 }}>Completed - {courseSelected.no_of_content_completed} / {courseSelected.no_of_content}</div>
                                                <Progress color="success" className="animated-progess" value={courseCompletedPercentage} barStyle={{ color: "#fff", borderRadius: 0 }} style={{ backgroundColor: "#dedede" }}></Progress>
                                            </div>
                                        }
                                        </div>
                                    </div>
                                    {
                                        this.state.loadPlayer && this.state.selectedContent && this.state.contentType === "VIDEO" ?
                                            <div >
                                                <div style={{ background: "rgba(58, 73, 86, 0.3)", }}>
                                                    <div className='ivsPlayerContainer' style={{ width: this.state.windowSize && this.state.windowSize <= 992 ? "100%" : "70%", margin: "0 auto" }}>
                                                        <IVSVideoPlayer
                                                            baseurl={this.state.baseurl}
                                                            content={this.state.selectedContent}
                                                            content_name={this.state.contentName}
                                                            contentStatus={this.state.contentStatus}
                                                            thumbnailID={this.state.thumbnailID}
                                                            isPreviewAvailable={this.state.isPreviewAvailable}
                                                            previewStartTime = {this.state.previewStartTime}
                                                            previewEndTime = {this.state.previewEndTime}
                                                            contentInformation={(data) => {
                                                                this.contentInformation(data)
                                                            }}
                                                            updateVideoStatus={(data) => {
                                                                this.updateVideoStatus(data, this.state.contentData)
                                                            }}
                                                            updateLastAccess={(data)=>{
                                                                this.updateLastAccess(data,this.state.contentData)}
                                                            }
                                                            subscribed={this.state.subscribed}
                                                            loadPNVideos={(action) => {
                                                                this.loadPNVideos(action)
                                                            }}
                                                            disablePrevious={this.state.disablePrevious}
                                                            disableNext={this.state.disableNext}
                                                            courseAction={this.state.courseAction}
                                                            resumeDataTiming={this.state.resumeDataTiming}
                                                            selectedContentIndex = {this.state.selectedContentIndex}
                                                            totalContent = {this.state.totalContent}
                                                            playFromBegining = {() => this.onloadContent(this.state.flatData[0], 0, null)}
                                                            playAgainSame = {() => {
                                                                this.replaySameVideo()
                                                            }}
                                                           setPlayer = {(player) => this.setState({
                                                            videoPlayer:player
                                                           })}
                                                           addToCart = {() => {
                                                            this.handleAddtoCart()
                                                           }}
                                                           contentPreviewHistory={()=>{
                                                            this.contentPreviewHistory(this.state.contentData)
                                                           }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div >
                                                <div>
                                                    {
                                                        this.state.courseSelected.hero_files.length > 0 ?
                                                            <Swiper className="courseInfoSwiper"
                                                                slidesPerView={1}
                                                                modules={[Autoplay]}
                                                                autoplay={false}
                                                                onSlideChange={() => {
                                                                    if (this.state.previousPlayer) {
                                                                        var element = this.state.previousPlayer.el_.children[7];
                                                                        this.state.previousPlayer.pause();
                                                                        this.state.previousPlayer.currentTime(0);
                                                                        this.state.previousPlayer.hasStarted(false);
                                                                        element.classList.remove("vjs-custom-pause-button");
                                                                        element.classList.add("vjs-custom-play-button");
                                                                    }
                                                                }}
                                                                // onSwiper={(swiper) => //(swiper)}
                                                            >
                                                                {
                                                                    this.state.courseSelected.hero_files.map((item, index) => {
                                                                        if (item.file_type === "image") {
                                                                            return (
                                                                                <SwiperSlide key={index + "swp"} className="" data-swiper-autoplay={this.state.sliderDelay}>
                                                                                    <img src={this.state.baseurl + item.output_url} />
                                                                                </SwiperSlide>
                                                                            )
                                                                        }
                                                                        else {
                                                                            return (
                                                                                <SwiperSlide key={index + "swp"} className="" data-swiper-autoplay="30000">
                                                                                    <IVSVideoPlayerCRS playerID={index} streamURLProps={this.state.baseurl + item.output_url} hideLabel={(eventtype, player) => this.hideLabel(eventtype, index, player)} playerPoster={item.masterImage} />
                                                                                </SwiperSlide>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </Swiper> :
                                                            <div className='bg-secondary' style={{ display: "flex", width: "100%", height: 227, justifyContent: "center", alignItems: "center" }}>
                                                                <div className='text-white text-opacity-25' style={{ fontSize: 50 }}>{this.state.courseSelected.name_of_the_course}</div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>

                                    }
                                    {
                                        (!this.state.loadPlayer && !this.state.selectedContent) ?
                                            <div ref={this.contentDiv}>
                                                <div className='mx-0 mx-lg-5  mb-3 pb-3 mt-3' style={{ marginTop: !this.state.subscribed && 20, borderBottom: "1px solid #dedede" }} >
                                                    <h2 className='quicksand-font fw-bold mb-3 text-dark' style={{textTransform: 'capitalize'}}>{this.state.courseSelected.name_of_the_course}</h2>
                                                    <div className='row'>

                                                        <div className='d-flex flex-row gap-2 mb-3 col-lg-12 col-xl-6'>
                                                            <div className='col-4'>
                                                                <div className='quicksand-font text-secondary text-opacity-75' style={{ lineHeight: 1.2, fontSize: 14 }} >On-demand-video</div>
                                                                <div style={{ lineHeight: 1, fontSize: 15 }} >{this.timeConvert(this.state.courseSelected.course_duration)}</div>
                                                            </div>
                                                            <div className='col-2'>
                                                                <div className='quicksand-font text-secondary text-opacity-75' style={{ lineHeight: 1.2, fontSize: 14 }} >Tracks</div>
                                                                <div style={{ lineHeight: 1, fontSize: 15 }} >{courseSelected.no_of_content}</div>
                                                            </div>
                                                            <div className='col-6'>
                                                                <div className='quicksand-font text-secondary text-opacity-75' style={{ lineHeight: 1.2, fontSize: 14 }} >Artist</div>
                                                                <div style={{ lineHeight: 1, fontSize: 15 }} >
                                                                    {
                                                                        courseSelected.artist.map((item, idx) => {
                                                                            return <span className='me-1' key={"artst" + idx}>{item.artist_name}</span>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex flex-row gap-3 col-lg-12 col-xl-6 justify-content-xl-end'>
                                                            {
                                                                this.state.subscribed && 
                                                                <div>
                                                                    <button
                                                                        type="button"
                                                                        className="d-none d-md-block btn btn-secondary btn-label"
                                                                        disabled={this.state.whenContentLoading}
                                                                        onClick={() => {
                                                                            this.resumeCourse("BEGINING")
                                                                        }}
                                                                    >
                                                                        <i className="mdi mdi-play label-icon"></i> Play from begining
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="d-block d-md-none btn btn-sm btn-secondary btn-label"
                                                                        disabled={this.state.whenContentLoading}
                                                                        onClick={() => {
                                                                            this.resumeCourse("BEGINING")
                                                                        }}
                                                                    >
                                                                        <i className="mdi mdi-play label-icon"></i> Play from begining
                                                                    </button>
                                                                </div>
                                                            }

                                                            {
                                                                (this.state.subscribed && this.state.courseSelected.last_accessed) &&
                                                                <div>
                                                                    <button
                                                                        type="button"
                                                                        className="d-none d-md-block btn btn-warning btn-label"
                                                                        onClick={() => {
                                                                            this.resumeCourse("RESUME")
                                                                        }}
                                                                    >
                                                                        <i className="mdi mdi-skip-next label-icon"></i> Resume course
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="d-block d-md-none btn btn-sm btn-warning btn-label"
                                                                        onClick={() => {
                                                                            this.resumeCourse("RESUME")
                                                                        }}
                                                                    >
                                                                        <i className="mdi mdi-skip-next label-icon"></i> Resume course
                                                                    </button>
                                                                </div>
                                                            }

                                                            {
                                                                !this.state.subscribed &&
                                                                <div>
                                                                    <div className='quicksand-font text-secondary text-opacity-75' style={{ lineHeight: 1.5, fontSize: 14 }} >Price</div>
                                                                    <div style={{ lineHeight: 1, fontSize: 15 }} >₹{this.state.courseSelected.crs_subscptn_plans.find(plan => plan.crs_subscptn_plan === 'Annually')?.inr_price} | US${this.state.courseSelected.crs_subscptn_plans.find(plan => plan.crs_subscptn_plan === 'Annually')?.usd_price}</div>
                                                                </div>
                                                            }
                                                            {
                                                                !this.state.subscribed && this.state.courseSelected.added_cart ?
                                                                    <div className='mt-1 '>
                                                                        <div className={this.state.apiRequesting ? 'bg-secondary' : 'bg-danger'} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", padding: "10px 20px", borderRadius: 8 }}>
                                                                            {this.state.apiRequesting && <Spinner color='white' size={"sm"} className='me-2' ></Spinner>}
                                                                            <span
                                                                                style={{ cursor: "pointer", fontWeight: "500", color: "#fff", fontSize: 16, lineHeight: 1, marginRight: 10 }}
                                                                                onClick={() => { !this.state.apiRequesting && this.handleRemoveFromCart() }}
                                                                            >Remove from Cart</span><span style={{ color: "#fff", fontSize: 19, lineHeight: 1 }}> <i className="mdi mdi-cart-remove" /> </span>
                                                                        </div>
                                                                    </div> :
                                                                    !this.state.subscribed && !this.state.courseSelected.added_cart ?
                                                                        <div className='mt-1 '>
                                                                            <div className={this.state.apiRequesting ? 'bg-secondary' : 'bg-primary'} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", padding: "10px 20px", borderRadius: 8 }}>
                                                                                {this.state.apiRequesting && <Spinner color='white' size={"sm"} className='me-2' ></Spinner>}
                                                                                <span
                                                                                    style={{ cursor: "pointer", fontWeight: "500", color: "#fff", fontSize: 16, lineHeight: 1, marginRight: 10 }}
                                                                                    onClick={() => { !this.state.apiRequesting && this.handleAddtoCart() }}
                                                                                >Subscribe</span><span style={{ color: "#fff", fontSize: 19, lineHeight: 1 }}> <i className="mdi mdi-cart-variant" /> </span>
                                                                            </div>
                                                                        </div> : null
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='mx-0 mx-lg-5  d-xs-block d-sm-block d-md-block d-lg-none'>
                                                    <div>
                                                        <h4 className='quicksand-font' >Course Material</h4>
                                                    </div>
                                                    <div>
                                                        <SimpleBar className="h-100 " ref={this.refDiv} >
                                                            <CourseTree
                                                                onClickLink={(data, index) => this.onloadContent(data, index, null)}
                                                                subscribed={this.state.subscribed}
                                                                course_content={this.state.courseSelected.course_content}
                                                                contentUniquedId={this.state.contentUniquedId}
                                                                selectedIndex={(pos) => { this.setState({ selected_pos: pos }) }}
                                                            />
                                                        </SimpleBar>
                                                    </div>
                                                </div>
                                                <div className='mx-0 mx-lg-5 '>
                                                    <div dangerouslySetInnerHTML={{ __html: this.state.courseSelected.description_of_the_course.trim() }} className='courseDescription' style={{ fontFamily: "poppins,sans-serif" }}></div>
                                                </div>
                                                {
                                                    this.state.contentHeight > 600 &&
                                                    <div className='mx-0 mx-lg-5  mb-3 pb-3 mt-3 pt-3' style={{ borderTop: "1px solid #dedede" }} >
                                                    <div className='row'>

                                                        <div className='d-flex flex-row gap-5 mb-3 col-lg-12 col-xl-6'>
                                                            <div>
                                                                <div className='quicksand-font text-secondary text-opacity-75' style={{ lineHeight: 1.2, fontSize: 14 }} >On-demand-video</div>
                                                                <div style={{ lineHeight: 1, fontSize: 15 }} >{this.timeConvert(this.state.courseSelected.course_duration)}</div>
                                                            </div>
                                                            <div>
                                                                <div className='quicksand-font text-secondary text-opacity-75' style={{ lineHeight: 1.2, fontSize: 14 }} >Tracks</div>
                                                                <div style={{ lineHeight: 1, fontSize: 15 }} >{courseSelected.no_of_content}</div>
                                                            </div>
                                                            <div>
                                                                <div className='quicksand-font text-secondary text-opacity-75' style={{ lineHeight: 1.2, fontSize: 14 }} >Artist</div>
                                                                <div style={{ lineHeight: 1, fontSize: 15 }} >
                                                                    {
                                                                        courseSelected.artist.map((item, idx) => {
                                                                            return <span className='me-1' key={"artst" + idx}>{item.artist_name}</span>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex flex-row gap-3 col-lg-12 col-xl-6 justify-content-xl-end'>
                                                            {
                                                                this.state.subscribed && 
                                                                <div>
                                                                    <button
                                                                        type="button"
                                                                        className="d-none d-md-block btn btn-secondary btn-label"
                                                                        disabled={this.state.whenContentLoading}
                                                                        onClick={() => {
                                                                            this.resumeCourse("BEGINING")
                                                                        }}
                                                                    >
                                                                        <i className="mdi mdi-play label-icon"></i> Play from begining
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="d-block d-md-none btn btn-sm btn-secondary btn-label"
                                                                        disabled={this.state.whenContentLoading}
                                                                        onClick={() => {
                                                                            this.resumeCourse("BEGINING")
                                                                        }}
                                                                    >
                                                                        <i className="mdi mdi-play label-icon"></i> Play from begining
                                                                    </button>
                                                                </div>
                                                            }

                                                            {
                                                                (this.state.subscribed && this.state.courseSelected.last_accessed) &&
                                                                <div>
                                                                    <button
                                                                        type="button"
                                                                        className="d-none d-md-block btn btn-warning btn-label"
                                                                        onClick={() => {
                                                                            this.resumeCourse("RESUME")
                                                                        }}
                                                                    >
                                                                        <i className="mdi mdi-skip-next label-icon"></i> Resume course
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="d-block d-md-none btn btn-sm btn-warning btn-label"
                                                                        onClick={() => {
                                                                            this.resumeCourse("RESUME")
                                                                        }}
                                                                    >
                                                                        <i className="mdi mdi-skip-next label-icon"></i> Resume course
                                                                    </button>
                                                                </div>
                                                            }

                                                            {
                                                                !this.state.subscribed &&
                                                                <div>
                                                                    <div className='quicksand-font text-secondary text-opacity-75' style={{ lineHeight: 1.5, fontSize: 14 }} >Price</div>
                                                                    <div style={{ lineHeight: 1, fontSize: 15 }} >₹{this.state.courseSelected.crs_subscptn_plans.find(plan => plan.crs_subscptn_plan === 'Annually')?.inr_price} | US${this.state.courseSelected.crs_subscptn_plans.find(plan => plan.crs_subscptn_plan === 'Annually')?.usd_price}</div>
                                                                </div>
                                                            }
                                                            {
                                                                !this.state.subscribed && this.state.courseSelected.added_cart ?
                                                                    <div className='mt-1 '>
                                                                        <div className={this.state.apiRequesting ? 'bg-secondary' : 'bg-danger'} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", padding: "10px 20px", borderRadius: 8 }}>
                                                                            {this.state.apiRequesting && <Spinner color='white' size={"sm"} className='me-2' ></Spinner>}
                                                                            <span
                                                                                style={{ cursor: "pointer", fontWeight: "500", color: "#fff", fontSize: 16, lineHeight: 1, marginRight: 10 }}
                                                                                onClick={() => { !this.state.apiRequesting && this.handleRemoveFromCart() }}
                                                                            >Remove from Cart</span><span style={{ color: "#fff", fontSize: 19, lineHeight: 1 }}> <i className="mdi mdi-cart-remove" /> </span>
                                                                        </div>
                                                                    </div> :
                                                                    !this.state.subscribed && !this.state.courseSelected.added_cart ?
                                                                        <div className='mt-1 '>
                                                                            <div className={this.state.apiRequesting ? 'bg-secondary' : 'bg-primary'} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", padding: "10px 20px", borderRadius: 8 }}>
                                                                                {this.state.apiRequesting && <Spinner color='white' size={"sm"} className='me-2' ></Spinner>}
                                                                                <span
                                                                                    style={{ cursor: "pointer", fontWeight: "500", color: "#fff", fontSize: 16, lineHeight: 1, marginRight: 10 }}
                                                                                    onClick={() => { !this.state.apiRequesting && this.handleAddtoCart() }}
                                                                                >Subscribe</span><span style={{ color: "#fff", fontSize: 19, lineHeight: 1 }}> <i className="mdi mdi-cart-variant" /> </span>
                                                                            </div>
                                                                        </div> : null
                                                            }

                                                        </div>
                                                    </div>
                                                </div>                                                }
                                            </div> :
                                            <div ref={this.contentDiv}>
                                                <div className='mx-0 mx-lg-5  mb-3 pb-3 mt-3' style={{ marginTop: !this.state.subscribed && 20, borderBottom: "1px solid #dedede" }}>
                                                    <h2 className='quicksand-font fw-bold mb-1 text-dark'>{this.state.courseSelected.name_of_the_course}</h2>
                                                    <h4 className='quicksand-font fw-bold mb-3 text-secondary'>{this.state.contentName}</h4>
                                                    <div className='d-flex flex-row justify-content-between'>
                                                        <div className='d-flex flex-row gap-5'>
                                                            <div>
                                                                <div className='quicksand-font text-secondary text-opacity-75' style={{ lineHeight: 1.5, fontSize: 14 }} >Duration</div>
                                                                <div style={{ lineHeight: 1, fontSize: 15 }} >{this.toHoursAndMinutes(this.state.contentDuration)}</div>
                                                            </div>
                                                            <div>
                                                                <div className='quicksand-font text-secondary text-opacity-75' style={{ lineHeight: 1.5, fontSize: 14 }} >Artist</div>
                                                                <div style={{ lineHeight: 1, fontSize: 15 }} >
                                                                    {
                                                                        courseSelected.artist.map((item, idx) => {
                                                                            return <span className='me-1' key={"ast" + idx}>{item.artist_name}</span>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex flex-row gap-3'>
                                                            {
                                                                this.state.subscribed &&
                                                                <div>
                                                                    <button
                                                                        type="button"
                                                                        className="d-none d-md-block btn btn-secondary btn-label"
                                                                        disabled={this.state.whenContentLoading}
                                                                        onClick={() => {
                                                                            this.resumeCourse("BEGINING")
                                                                        }}
                                                                    >
                                                                        <i className="mdi mdi-play label-icon"></i> Play from begining
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="d-block d-md-none btn btn-sm btn-secondary btn-label"
                                                                        disabled={this.state.whenContentLoading}
                                                                        onClick={() => {
                                                                            this.resumeCourse("BEGINING")
                                                                        }}
                                                                    >
                                                                        <i className="mdi mdi-play label-icon"></i> Play from begining
                                                                    </button>
                                                                </div>
                                                            }
                                                            {
                                                                !this.state.subscribed &&
                                                                <div>
                                                                    <div className='quicksand-font text-secondary text-opacity-75' style={{ lineHeight: 1.5, fontSize: 14 }} >Price</div>
                                                                    <div style={{ lineHeight: 1, fontSize: 15 }} >₹{this.state.courseSelected.crs_subscptn_plans.find(plan => plan.crs_subscptn_plan === 'Annually')?.inr_price} | ${this.state.courseSelected.crs_subscptn_plans.find(plan => plan.crs_subscptn_plan === 'Annually')?.usd_price}</div>
                                                                </div>
                                                            }
                                                            {
                                                                !this.state.subscribed && this.state.courseSelected.added_cart ?
                                                                    <div className='mt-1 '>
                                                                        <div className={this.state.apiRequesting ? 'bg-secondary' : 'bg-danger'} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", padding: "10px 20px", borderRadius: 8 }}>
                                                                            {this.state.apiRequesting && <Spinner color='white' size={"sm"} className='me-2' ></Spinner>}
                                                                            <span
                                                                                style={{ cursor: "pointer", fontWeight: "500", color: "#fff", fontSize: 16, lineHeight: 1, marginRight: 10 }}
                                                                                onClick={() => { !this.state.apiRequesting && this.handleRemoveFromCart() }}
                                                                            >Remove from Cart</span><span style={{ color: "#fff", fontSize: 19, lineHeight: 1 }}> <i className="mdi mdi-cart-remove" /> </span>
                                                                        </div>
                                                                    </div> :
                                                                    !this.state.subscribed && !this.state.courseSelected.added_cart ?
                                                                        <div className='mt-1 '>
                                                                            <div className={this.state.apiRequesting ? 'bg-secondary' : 'bg-primary'} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", padding: "10px 20px", borderRadius: 8 }}>
                                                                                {this.state.apiRequesting && <Spinner color='white' size={"sm"} className='me-2' ></Spinner>}
                                                                                <span
                                                                                    style={{ cursor: "pointer", fontWeight: "500", color: "#fff", fontSize: 16, lineHeight: 1, marginRight: 10 }}
                                                                                    onClick={() => { !this.state.apiRequesting && this.handleAddtoCart() }}
                                                                                >Subscribe</span><span style={{ color: "#fff", fontSize: 19, lineHeight: 1 }}> <i className="mdi mdi-cart-variant" /> </span>
                                                                            </div>
                                                                        </div> : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='mx-0 mx-lg-5 mt-3 d-xs-block d-sm-block d-md-block d-lg-none'>
                                                    <div>
                                                        <h4 className='quicksand-font' >Course Material</h4>
                                                    </div>
                                                    <div>
                                                        <SimpleBar className="h-100 " ref={this.refDiv} >
                                                            <CourseTree
                                                                onClickLink={(data, index) => this.onloadContent(data, index, null)}
                                                                subscribed={this.state.subscribed}
                                                                course_content={this.state.courseSelected.course_content}
                                                                contentUniquedId={this.state.contentUniquedId}
                                                                selectedIndex={(pos) => { this.setState({ selected_pos: pos }) }}
                                                            />
                                                        </SimpleBar>
                                                    </div>
                                                </div>
                                            </div>
                                    }

                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>

            )
        } else
            if (this.state.axiosTimeout) {
                return <React.Fragment>
                    <div className="page-content" style={{ marginTop: 0 }}  >
                        <Container fluid >
                            <Row style={{ minHeight: "calc(100vh - 450px)" }}>
                                <div className='d-flex w-100 flex-column justify-content-center align-items-center'>
                                    <div>Something went wrong. Please try again after sometime.</div>
                                </div>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            }
            else {
                return <React.Fragment>
                    <div className="page-content" style={{ marginTop: 0 }}  >
                        <Container fluid >
                            <Row style={{ minHeight: "calc(100vh - 450px)" }}>
                                <div className='d-flex w-100 flex-column justify-content-center align-items-center'>
                                    <Spinner className="mb-2" color="primary" />
                                    <div>Loading ...</div>
                                </div>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            }
    }
}

const mapStateToProps = (state) => {
    return {
        layoutType: state.Layout.layoutType,
        cartCourseListLength: state.Layout.cartCourseListLength,
        searchTerm: state.Layout.searchTerm,
        SearchCourseList: state.Layout.SearchCourseList
    };
};

export default connect(mapStateToProps)(CourseDetails);







