import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { GoogleLogin, } from 'react-google-login'
import { useEffect } from 'react';
import FacebookLogin from 'react-facebook-login'
import { Row, } from "reactstrap"
import { gapi } from 'gapi-script';

const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const googleClientId = "551007879090-n1ajhogtio99hj1pip568fkur020ngqr.apps.googleusercontent.com"
const facebook_appID = "724562953163176"


const SocialMediaLogin = (props) => {

    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: googleClientId,
                scope: 'email',
            });
        }

        gapi.load('client:auth2', start);
    }, []);




    return (
        <React.Fragment>
            <div >
                <Row className='text-center justify-content-around' style={{ marginTop: "50px", padding: "0 20px" }}>
                    <GoogleLogin
                        clientId={googleClientId}
                        onSuccess={props.onSuccessGoogle}
                        cookiePolicy={'single_host_origin'}
                        onFailure={props.onFailureGoogle}
                    />
                    {/* <br/>
                     <FacebookLogin
                        appId={facebook_appID}
                        fields="name,email,picture"
                        callback={props.onSuccessFacebook}
                    /> */}
                </Row>

            </div>
        </React.Fragment>);
}

SocialMediaLogin.propTypes = propTypes;
SocialMediaLogin.defaultProps = defaultProps;
// #endregion

export default SocialMediaLogin;