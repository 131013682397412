import React, { useState } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Progress, Row } from "reactstrap"
import PropTypes from 'prop-types';
import noImg from '../../assets/images/no-img.jpg';
import ribbon from '../../assets/images/icons/combo.png';
import moment from 'moment';




const textStyle = {
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};


function SubscribedCard({
    image,
    title,
    description_of_the_course,
    courseDetails,
    no_of_content_completed,
    total_percentage,
    isLogedUser,
    selected_course,
    baseurl,
    description,
    handleAddToCart,
    removeFromCart,
    viewCourse

}) {


    let courseCompletedPercentage = selected_course.is_subscribed ? ((selected_course.no_of_content_completed / selected_course.no_of_content) * 100) : 0

    return (
        <div style={{ cursor: "pointer" }} onClick={() => {
            viewCourse()
        }}>
            {
                selected_course.combo_crs === 1 ?
                    <div style={{ position: "absolute", top: 6, left: 6, }}>
                        <img alt='' src={ribbon} draggable='false' style={{ padding: 1, width: "50%", }} />
                    </div>
                    : null
            }
            <div style={{ borderRadius: 30, backgroundColor: "rgba(255,255,255,0.6)", margin: 10, cursor: "pointer", border: "1px solid #dedede" }}
               
            >

                <div className='bg-transition'
                    style={{
                        borderRight: "1px solid #dedede",
                        backgroundImage: `url(${image === null ? noImg : baseurl + selected_course.thumbnail})`,
                        backgroundRepeat: "no-repeat",
                        backgroundOrigin: "border-box",
                        backgroundPosition: "right",
                        backgroundSize: "cover",
                        height: 200,
                        borderRadius: "30px 30px 10px 10px",

                    }}
                ></div>
                <div style={{ display: "flex", flexGrow: 1, flexDirection: "column", justifyContent: "flex-start", marginTop: 15, padding: "0 20px", minHeight: 150 }}>
                    <div >
                        <div style={{ minHeight: 70 }}>
                            <div className='mb-1' style={{ fontSize: 13 }}>{selected_course?.course_category?.category}</div>
                            <div className='mb-2'>
                                <div className='textOverFlowEllipsisHeader' style={{ fontSize: 15, lineHeight: 1.2, fontWeight: "bold" }} role='button' onClick={() => { viewCourse(); }} >
                                    {selected_course.name_of_the_course}
                                </div>
                            </div>
                        </div>
                        {
                            !selected_course?.is_subscribed ?
                                <div className=" font-size-12 mb-3 text-secondary" style={textStyle}>
                                    {selected_course.short_description}
                                </div>
                                :
                                <div className='d-flex flex-column'>
                                    <Progress color="success" value={courseCompletedPercentage} barStyle={{ color: "#fff" }}>
                                        {/* {courseCompletedPercentage}% */}
                                    </Progress>
                                    <div className='mb-1 mt-2 quicksand-font' style={{ paddingRight: 5, fontSize: 18, color: "#74788d", fontWeight: "bold" }}>
                                        {selected_course.no_of_content_completed} / {selected_course.no_of_content}
                                    </div>
                                </div>

                        }

                    </div>


                    {
                        !selected_course?.is_subscribed ?
                            <div className='mt-1 '>
                                <div className='my-2' ><span style={{ fontWeight: "800", lineHeight: 1, color: "#0A3161" }} > ₹{selected_course.inr_price} &nbsp;&nbsp;&nbsp;</span><span style={{ fontWeight: "800", lineHeight: 1, color: "#0A3161" }} > ${selected_course.usd_price}</span></div>

                            </div> :
                            <div className='mt-1'>

                                <div className='text-danger' style={{ fontSize: 12, textAlign: "right", }}>Expires on {moment(selected_course.expiry_date).format("ll")}</div>
                            </div>
                    }

                    {/* {
                    <div
                        className={
                            selected_course?.is_subscribed ? 'bg-success bg-opacity-75 d-flex justify-content-center align-items-center' : 'bg-secondary bg-opacity-75 d-flex justify-content-center align-items-center'
                        }

                        onClick={() => {
                            viewCourse()
                        }}

                        style={{ width: 40, height: 40, borderRadius: 20, cursor:"pointer" }}>
                        <i className={selected_course?.is_subscribed ? 'mdi mdi-play' : 'mdi mdi-information-variant'} style={{ fontSize: 30, color: "#fff" }} />
                    </div>
                } */}




                </div>


            </div>
        </div>

    )
}

export default SubscribedCard
SubscribedCard.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    description_of_the_course: PropTypes.string,
    likes: PropTypes.number,
    comp_perc: PropTypes.number,
    pending: PropTypes.number,
    courseDetails: PropTypes.func,
};
