import React, { Component } from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Row, Col } from "reactstrap"
// import { Carousel } from "react-responsive-carousel"

import kLogo from "../../../assets/images/k-logo.png"


export default class CarouselPage extends Component {
    render() {
        return (
            <div className='d-flex align-items-center justify-content-center '>
                <Row>
                    <Col md={12} className="pe-4">
                        <div>
                            <div className="inter-font text-sm-end text-md-end text-lg-end text-xl-end mb-4" >
                                <span style={{
                                    fontSize: 20,
                                    fontWeight: 500,
                                    lineHeight: 0.8,
                                    textTransform: "uppercase",
                                    color: "#858c8f"
                                }}>Welcome to</span><br />
                                <span style={{ fontSize: 70, fontWeight: 300, lineHeight: 0.8 }}>Kalakriya</span>

                            </div>
                        </div>

                        <div className="mb-5">
                            <p className="text-sm-end text-md-end text-lg-end text-xl-end" style={{ fontSize: 16, fontWeight: 300 }}>Ideal techno tools for learning, these Bharatanatyam videos will enable dance students and enthusiasts to gain access to the abundant and arcane wisdom of some of the greatest Bharathanatyam exponents of our times. Just as it is art-friendly, Kalakriya is user-friendly too. Subscribe your course online. All that it needs is a register here!!!</p>
                        </div>

                        <div className="d-flex justify-content-start justify-content-lg-end mb-4">
                                <img src={kLogo} alt="magarisa_logo" style={{ width: '300px', height: 'auto' }} />
                            </div>


                    </Col>
                </Row>
            </div>
        )
    }
}






